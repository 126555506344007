// src/contexts/SubscriptionContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


interface Subscription {
  status: string;
  plan: {
    applicationLimit: number;
    mockInterviewLimit: number;
  } | null;
  limits: {
    applicationLimit: number;
    mockInterviewLimit: number;
  };
  usage: {
    applications: number;
    mockInterviews: number;
  };
}

interface SubscriptionContextType {
  subscription: Subscription;
  checkSubscriptionStatus: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [subscription, setSubscription] = useState({
    status: 'inactive',
    plan: null,
    limits: {
      applicationLimit: 0,
      mockInterviewLimit: 0
    },
    usage: {
      applications: 0,
      mockInterviews: 0
    }
  });

  // Check subscription status on mount
  useEffect(() => {
    checkSubscriptionStatus();
  }, []);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await fetch('/v1/users/getUser', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      setSubscription({
        status: data.subscriptionStatus,
        plan: data.currentPlan,
        limits: {
          applicationLimit: data.currentPlan?.applicationLimit || 0,
          mockInterviewLimit: data.currentPlan?.mockInterviewLimit || 0
        },
        usage: {
          applications: data.totalApplications || 0,
          mockInterviews: data.mockInterviewsCompleted || 0
        }
      });
    } catch (error) {
      console.error('Error checking subscription:', error);
    }
  };

  return (
    <SubscriptionContext.Provider value={{ subscription, checkSubscriptionStatus }}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export const useSubscription = () => useContext(SubscriptionContext);