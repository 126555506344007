import React, { useState } from 'react';
import './styles/tutorials.scss';
import ResumeFeatures from './resume/components/ResumeFeatures';
import Sidebar from '../Sidebar';

const Tutorials: React.FC = () => {
  const [activeTab, setActiveTab] = useState('resume');

  const tabs = [
    { id: 'resume', label: 'Resume Tailoring' },
    { id: 'coverLetter', label: 'Cover Letter' },
    { id: 'jobSearch', label: 'Job Search' },
    { id: 'applicationTracker', label: 'Application Tracker' },
    { id: 'portfolio', label: 'Portfolio' }
  ];

  return (
    <div className="tutorials-page-container">
      <Sidebar />
      <div className="tutorials-main-content">
        <div className="features-tab-section">
          <div className="tab-container">
            <div className="tab-list" role="tablist">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  role="tab"
                  aria-selected={activeTab === tab.id}
                  aria-controls={`panel-${tab.id}`}
                  className={`tab ${activeTab === tab.id ? 'active' : ''}`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <span className="tab-text">{tab.label}</span>
                </button>
              ))}
            </div>
          </div>

          {tabs.map((tab) => (
            <div
              key={tab.id}
              role="tabpanel"
              id={`panel-${tab.id}`}
              className={`content-box ${activeTab === tab.id ? 'active' : ''}`}
              aria-labelledby={tab.id}
            >
              <div className="video-container">
                {tab.id === 'resume' ? (
                  <ResumeFeatures />
                ) : (
                  <div className="coming-soon">
                    <h3>Coming Soon</h3>
                    <p>This tutorial is currently under development.</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tutorials; 