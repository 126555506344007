import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaAngleDown, FaAngleUp, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineFilter } from 'react-icons/ai';
import jobTitles from '../jobs_titles.json';
import Sidebar from './Sidebar';
import '../styles/components/jobSearch.scss';
import ReactPaginate from 'react-paginate';
import JobTile from './JobTile';
import JobDetailsModal, { JobData } from './JobDetailsModal';
import { JobButtonContext, normalizeJobTypeForFilter, STANDARD_JOB_TYPES } from '../constant';
import axiosClient from '../axios-client';
import { debounce } from 'lodash';

// Define types for better type safety
interface JobHub {
  value: string;
  label: string;
}

interface JobType {
  value: string;
  label: string;
}

interface Job {
  _id: string;
  title: string;
  company: string;
  location: string;
  jobType: string;
  description: string;
  requirements: string[];
  skills: string[];
  industry?: string;
  matchScore?: number;
  [key: string]: any;
}

interface FilterDropdownProps {
  options: { value: string; label: string }[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  title: string;
  type: string;
  loading: boolean;
  activeDropdown: string | null;
  setActiveDropdown: (type: string | null) => void;
  jobs: Job[];
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  options,
  selectedValues,
  setSelectedValues,
  title,
  type,
  loading,
  activeDropdown,
  setActiveDropdown,
  jobs
}) => {
  const isOpen = activeDropdown === type;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (loading) return;
    setActiveDropdown(isOpen ? null : type);
  };

  const handleOptionSelect = (optionValue: string) => {
    const newValues = selectedValues.includes(optionValue)
      ? selectedValues.filter((v) => v !== optionValue)
      : [...selectedValues, optionValue];
    setSelectedValues(newValues);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!isOpen) return;
      const target = e.target as HTMLElement;
      if (!target.closest('.dropdown')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, setActiveDropdown]);

  // Calculate counts for each option
  const optionCounts = options.reduce((acc, option) => {
    const count = jobs.filter(job => {
      switch (type) {
        case 'locations':
          const normalizedJobLocation = job.location?.toLowerCase().trim();
          const normalizedFilter = option.value.toLowerCase().replace(/_/g, ' ').trim();
          return normalizedJobLocation?.includes(normalizedFilter);
        case 'companies':
          return job.company === option.value;
        default:
          return false;
      }
    }).length;
    return { ...acc, [option.value]: count };
  }, {} as { [key: string]: number });

  return (
    <div className={`dropdown ${isOpen ? 'active' : ''}`}>
      <button
        className="dropdown-button"
        onClick={handleToggle}
        disabled={loading}
        style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
      >
        {title}
        {selectedValues.length > 0 && (
          <span className="selected-count">({selectedValues.length})</span>
        )}
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </button>
      {isOpen && (
        <div className="dropdown-content open">
          {options
            .filter(option => optionCounts[option.value] > 0 || selectedValues.includes(option.value))
            .map((option) => (
              <div
                key={option.value}
                className="checkbox-item"
                onClick={() => handleOptionSelect(option.value)}
              >
                <div className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(option.value)}
                    readOnly
                  />
                  <label>{option.label}</label>
                </div>
                <span className="option-count">{optionCounts[option.value]}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const DevTestJobSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [filteredResults, setFilteredResults] = useState<Job[]>([]);
  const [error, setError] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [selection, setSelection] = useState<JobData | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [showAllCompanies, setShowAllCompanies] = useState(true);
  const itemsPerPage = showAllCompanies ? 10000 : 54;
  const [availableJobHubs] = useState<JobHub[]>([
    { value: 'remote', label: 'Remote' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'onsite', label: 'On-site' }
  ]);
  const [availableCompanies, setAvailableCompanies] = useState<JobType[]>([]);
  const [loading, setLoading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  // Get current page from URL
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  
  // Apply filters to job results
  useEffect(() => {
    if (jobResults.length === 0) return;
    
    let filtered = [...jobResults];
    
    // Apply location filters
    if (selectedLocations.length > 0) {
      filtered = filtered.filter(job => {
        const jobLocation = job.location?.toLowerCase().trim() || '';
        return selectedLocations.some(location => {
          const normalizedLocation = location.toLowerCase().replace(/_/g, ' ').trim();
          return jobLocation.includes(normalizedLocation);
        });
      });
    }
    
    // Apply company filters
    if (selectedCompanies.length > 0) {
      filtered = filtered.filter(job => 
        selectedCompanies.includes(job.company)
      );
    }
    
    setFilteredResults(filtered);
    
    // Update pagination based on filtered results
    setTotalResults(filtered.length);
    const newTotalPages = Math.max(1, Math.ceil(filtered.length / itemsPerPage));
    
    console.log(`Filter effect: Setting totalPages to ${newTotalPages} based on ${filtered.length} filtered results`);
    setTotalPages(newTotalPages);
    
    // Reset to page 1 if current page would be out of bounds
    const maxPage = Math.max(1, Math.ceil(filtered.length / itemsPerPage));
    if (currentPage > maxPage) {
      searchParams.set('page', '1');
      setSearchParams(searchParams, { replace: true });
    }
  }, [jobResults, selectedLocations, selectedCompanies, itemsPerPage]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate('/auth/login');
          }
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please check your permissions.');
      }
    };

    fetchUserData();
  }, [navigate]);

  // Load jobs initially and when page changes
  useEffect(() => {
    // Only load jobs if we're on page 1 or if no filters are applied
    if (currentPage === 1 || (selectedLocations.length === 0 && selectedCompanies.length === 0)) {
      loadJobs(currentPage);
    }
  }, [currentPage]);
  
  // Reset to page 1 when filters change
  useEffect(() => {
    if (currentPage !== 1 && (selectedLocations.length > 0 || selectedCompanies.length > 0)) {
      searchParams.set('page', '1');
      setSearchParams(searchParams, { replace: true });
    }
  }, [selectedLocations, selectedCompanies]);

  const loadJobs = async (page = currentPage) => {
    try {
      setLoading(true);
      
      // Create a controller to handle timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout for larger requests
      
      console.log(`Loading jobs for page ${page} with itemsPerPage=${itemsPerPage}`);
      
      const response = await axiosClient.get('dev-test/jobs', {
        params: {
          oneJobPerCompany: true,
          fields: 'title,company,location,jobType,skills,logo,categories',
          page: 1, // Always load page 1 when showing all companies
          limit: showAllCompanies ? 1000 : itemsPerPage, // Request all companies at once
          calculateTotalPages: true
        },
        signal: controller.signal
      });
      
      // Clear the timeout
      clearTimeout(timeoutId);

      const { jobs, totalJobs, totalPages: pages } = response.data;
      
      console.log(`API response received: ${jobs.length} jobs, ${totalJobs} total jobs, ${pages} total pages`);
      
      // No need to sort again if backend is already sorting
      const uniqueCompanies = jobs.map((job: Job) => ({
        value: job.company,
        label: job.company
      }));

      setJobResults(jobs);
      setFilteredResults(jobs);
      setAvailableCompanies(uniqueCompanies);
      setTotalResults(totalJobs);
      
      // Ensure we have the correct total pages from backend (should be 12 for 639 jobs with 54 per page)
      if (pages) {
        console.log(`Setting totalPages to ${pages} from backend response`);
        setTotalPages(pages);
      } else {
        const calculatedPages = Math.ceil(totalJobs / itemsPerPage);
        console.log(`Calculating totalPages: ${calculatedPages} (${totalJobs} jobs / ${itemsPerPage} per page)`);
        setTotalPages(calculatedPages);
      }
      
      setTotalCompanies(totalJobs); // Set the total number of companies
      
    } catch (error: any) {
      console.error('Error loading jobs:', error);
      
      // Handle timeout specifically
      if (error.name === 'AbortError' || error.message.includes('timeout')) {
        setError('Request timed out. Please try again or refresh the page.');
      }
      
      setJobResults([]);
      setFilteredResults([]);
      setTotalResults(0);
      setTotalPages(1);
      setTotalCompanies(0);
    } finally {
      setLoading(false);
    }
  };

  // Debounce filter changes to prevent too many re-renders
  const debouncedFilterEffect = useCallback(
    debounce(() => {
      if (jobResults.length === 0) return;
      
      let filtered = [...jobResults];
      
      // Apply location filters
      if (selectedLocations.length > 0) {
        filtered = filtered.filter(job => {
          const jobLocation = job.location?.toLowerCase().trim() || '';
          return selectedLocations.some(location => {
            const normalizedLocation = location.toLowerCase().replace(/_/g, ' ').trim();
            return jobLocation.includes(normalizedLocation);
          });
        });
      }
      
      // Apply company filters
      if (selectedCompanies.length > 0) {
        filtered = filtered.filter(job => 
          selectedCompanies.includes(job.company)
        );
      }
      
      setFilteredResults(filtered);
      
      // Update pagination based on filtered results
      setTotalResults(filtered.length);
      const newTotalPages = Math.max(1, Math.ceil(filtered.length / itemsPerPage));
      console.log(`Debounced filter: Setting totalPages to ${newTotalPages} based on ${filtered.length} filtered results`);
      setTotalPages(newTotalPages);
      
      // Reset to page 1 if current page would be out of bounds
      const maxPage = Math.max(1, Math.ceil(filtered.length / itemsPerPage));
      if (currentPage > maxPage) {
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
      }
    }, 300),
    [jobResults, selectedLocations, selectedCompanies, itemsPerPage, currentPage]
  );

  // Apply filters to job results with debounce
  useEffect(() => {
    debouncedFilterEffect();
    
    // Cleanup function
    return () => {
      debouncedFilterEffect.cancel();
    };
  }, [jobResults, selectedLocations, selectedCompanies, debouncedFilterEffect]);

  // Display debug info when pagination changes
  useEffect(() => {
    console.log(`Current page changed to ${currentPage}, total pages: ${totalPages}`);
  }, [currentPage, totalPages]);

  // Handle URL page changes
  useEffect(() => {
    // If we have filters applied, just update the current visible data slice
    if (selectedLocations.length > 0 || selectedCompanies.length > 0) {
      console.log(`URL page changed to ${currentPage} with filters - using client-side pagination`);
      // Let the getCurrentPageJobs handle showing the right slice
    } else {
      // If no filters, load data from server for the new page
      console.log(`URL page changed to ${currentPage} without filters - loading from server`);
      loadJobs(currentPage);
    }
  }, [currentPage]); // Only depend on currentPage

  const clearFilters = async () => {
    setIsClearing(true);
    setLoading(true);
    
    setSelectedLocations([]);
    setSelectedCompanies([]);
    setSearchParams({ page: '1' });
    
    try {
      // Create a controller to handle timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 second timeout
      
      const response = await axiosClient.get('dev-test/jobs', {
        params: {
          oneJobPerCompany: true,
          fields: 'title,company,location,jobType,skills,logo,categories',
          page: 1,
          limit: itemsPerPage,
          calculateTotalPages: true
        },
        signal: controller.signal
      });
      
      // Clear the timeout
      clearTimeout(timeoutId);
      
      const { jobs, totalJobs, totalPages: pages } = response.data;
      
      setJobResults(jobs);
      setFilteredResults(jobs);
      setTotalResults(totalJobs);
      
      // Use the same pagination logic as loadJobs
      if (pages) {
        console.log(`Setting totalPages to ${pages} from backend response`);
        setTotalPages(pages);
      } else {
        const calculatedPages = Math.ceil(totalJobs / itemsPerPage);
        console.log(`Calculating totalPages: ${calculatedPages} (${totalJobs} jobs / ${itemsPerPage} per page)`);
        setTotalPages(calculatedPages);
      }
      
      setTotalCompanies(totalJobs);
    } catch (error: any) {
      console.error('Error clearing filters:', error);
      
      // Handle timeout specifically
      if (error.name === 'AbortError' || error.message.includes('timeout')) {
        setError('Request timed out. Please try again or refresh the page.');
      } else {
        setError('Failed to clear filters. Please try again.');
      }
    } finally {
      setLoading(false);
      setIsClearing(false);
    }
  };

  const handlePageClick = (data: { selected: number }) => {
    const newPage = data.selected + 1;
    console.log(`Page click handler: navigating to page ${newPage}`);
    
    // Update URL without refreshing the page
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams, { replace: true });
    
    // Always scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    // If we're using client-side filtering, no need to fetch data again
    if (selectedLocations.length > 0 || selectedCompanies.length > 0) {
      console.log('Using client-side pagination with filters applied');
      // The useEffect for URL changes will handle updating the visible jobs
    } else {
      // Otherwise, load jobs for the new page from the server
      console.log('Fetching new page data from server');
      loadJobs(newPage);
    }
  };

  // Get current page of jobs - modified to return all jobs when showAllCompanies is true
  const getCurrentPageJobs = () => {
    if (showAllCompanies) {
      return filteredResults; // Return all filtered results
    }
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    
    // Log pagination info for debugging
    console.log(`Pagination info:
      - Current page: ${currentPage}
      - Total pages: ${totalPages}
      - Total results: ${filteredResults.length}
      - Items per page: ${itemsPerPage}
      - Showing items ${startIndex+1}-${Math.min(endIndex, filteredResults.length)} of ${filteredResults.length}
    `);
    
    return filteredResults.slice(startIndex, endIndex);
  };

  // Debug logging when important values change
  useEffect(() => {
    console.log(`Pagination state updated:
      - totalPages: ${totalPages}
      - currentPage: ${currentPage}
      - filteredResults.length: ${filteredResults.length}
      - totalCompanies: ${totalCompanies}
    `);
  }, [totalPages, currentPage, filteredResults.length, totalCompanies]);

  // Add a useEffect to add custom CSS for pagination
  useEffect(() => {
    // Add custom CSS for pagination
    const style = document.createElement('style');
    style.textContent = `
      .pagination-container .pagination {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
        list-style: none !important;
        padding: 0 !important;
        margin: 20px 0 !important;
        gap: 5px !important;
      }
      .pagination-container .page-item {
        display: inline-block !important;
        margin: 0 2px !important;
      }
      .pagination-container .pagination-info {
        text-align: center !important;
        margin-top: 10px !important;
        font-size: 14px !important;
        color: #666 !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Toggle between showing all companies and pagination
  const toggleShowAllCompanies = () => {
    setShowAllCompanies(!showAllCompanies);
    // Reload jobs with new limit
    loadJobs(1);
  };

  return (
    <div className="job-search-container">
      <Sidebar />
      <div className="job-search">
        <div className={`filter-container ${scrolled ? 'scrolled' : ''}`}>
          <div className={`filter-content ${loading ? 'filters-loading' : ''}`}>
            <button 
              className="mobile-menu-button" 
              onClick={() => {
                const sidebar = document.querySelector('.sidebar');
                sidebar?.classList.toggle('expanded');
              }}
            >
              <FiMenu />
            </button>
            
            <button 
              className="filters-button" 
              disabled={loading}
              style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
            >
              <AiOutlineFilter />
              Filters
            </button>
            
            <div className="total-count">
              {loading ? 'Loading...' : `${totalCompanies} companies`}
            </div>
            
            <FilterDropdown
              options={loading ? [] : availableJobHubs}
              selectedValues={selectedLocations}
              setSelectedValues={setSelectedLocations}
              title="Location"
              type="locations"
              loading={loading}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              jobs={jobResults}
            />
            <FilterDropdown
              options={loading ? [] : availableCompanies}
              selectedValues={selectedCompanies}
              setSelectedValues={setSelectedCompanies}
              title="Company"
              type="companies"
              loading={loading}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              jobs={jobResults}
            />
            <button 
              className={`clear-filters-button ${loading ? "disabled" : ""} ${
                selectedLocations.length > 0 || selectedCompanies.length > 0 ? "has-filters" : ""
              }`} 
              onClick={loading ? undefined : clearFilters}
              disabled={loading}
            >
              {isClearing ? 'Clearing...' : 'Clear Filters'}
            </button>
          </div>
        </div>

        <div className="job-results job-tiles-container">
          {loading ? (
            <>
              {[...Array(Math.min(itemsPerPage, 54))].map((_, index) => (
                <div key={index} className="job-tile skeleton" />
              ))}
            </>
          ) : getCurrentPageJobs().length > 0 ? (
            <>
              
              {getCurrentPageJobs().map((job, index) => (
                <JobTile
                  key={`${job._id}-${index}`}
                  job={job}
                  userId={user?._id}
                  handleSelection={setSelection}
                  context={JobButtonContext.MAIN}
                />
              ))}
            </>
          ) : (
            <p>No jobs found matching the selected filters.</p>
          )}
        </div>

        {/* Only show pagination if not showing all companies */}
        {totalCompanies > 0 && !showAllCompanies && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<FaAngleLeft />}
              nextLabel={<FaAngleRight />}
              pageCount={totalPages || 12}
              onPageChange={handlePageClick}
              forcePage={currentPage - 1}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="prev"
              nextClassName="next"
              disabledClassName="disabled"
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              breakLabel="..."
              breakClassName="break"
              breakLinkClassName="break-link"
              pageLinkClassName="page-link"
              pageClassName="page-item"
              renderOnZeroPageCount={null}
              key={`paginate-${totalPages}-${currentPage}`}
            />
            <div className="pagination-info">
              Page {currentPage} of {totalPages || 12}
            </div>
          </div>
        )}
      </div>

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default DevTestJobSearch;