import React from 'react';
import '../../styles/components/applicationTracker/applicationResume.scss';

interface ApplicationResumeProps {
  jobId: string;
  resumeData: any;
}

const ApplicationResume: React.FC<ApplicationResumeProps> = ({ jobId, resumeData }) => {
  // Add date formatting function from Resume.tsx
  const formatProjectDuration = (startDate: string, endDate: string | null, isOngoing: boolean = false): string => {
    if (!startDate) return "";
    if (isOngoing) return `${startDate} - Present`;
    return endDate ? `${startDate} - ${endDate}` : startDate;
  };

  if (!resumeData) {
    return (
      <div className="no-data-container">
        <p>No resume data available</p>
      </div>
    );
  }

  // Create array of contact info items that exist
  const contactInfo = [
    resumeData.email,
    resumeData.phone,
    resumeData.linkedin,
    resumeData.location
  ].filter(Boolean); // Filter out null/undefined/empty values

  return (
    <div className="application-resume">
      <div className="resume-header">
        <h1>{resumeData.firstName} {resumeData.lastName}</h1>
        <div className="contact-info">
          {contactInfo.map((info, index) => (
            <React.Fragment key={index}>
              <span>{info}</span>
              {index < contactInfo.length - 1 && <span className="divider">|</span>}
            </React.Fragment>
          ))}
        </div>
      </div>

      {resumeData.education && resumeData.education.length > 0 && (
        <section className="education-section">
          <h2>EDUCATION</h2>
          {resumeData.education.map((edu: any, index: number) => (
            <div key={index} className="education-item">
              <div className="education-header">
                <h3>{edu.university}</h3>
                {edu.gpa && <span className="gpa">GPA: {edu.gpa}</span>}
              </div>
              <div className="degree-field">
                <span>
                  {edu.degree}
                  {edu.field && `, ${edu.field}`}
                </span>
                <span className="graduation-date">{edu.graduationDate}</span>
              </div>
            </div>
          ))}
        </section>
      )}

      {resumeData.experience && resumeData.experience.length > 0 && (
        <section className="experience-section">
          <h2>EXPERIENCE</h2>
          {resumeData.experience.map((exp: any, index: number) => (
            <div key={index} className="experience-item">
              <div className="experience-header">
                <h3>{exp.title}</h3>
                <span className="dates">
                  {exp.startDate} - {exp.isCurrentPosition ? 'Present' : exp.endDate}
                </span>
              </div>
              <div className="experience-subheader">
                <span className="company-name">{exp.company}</span>
                <span className="location">{exp.location}</span>
              </div>
              <ul className="bullet-points">
                {exp.description.map((desc: any) => (
                  <li key={desc.id}>{desc.text}</li>
                ))}
              </ul>
            </div>
          ))}
        </section>
      )}

      {resumeData.projects && resumeData.projects.length > 0 && (
        <section className="projects-section">
          <h2>PROJECTS</h2>
          {resumeData.projects.map((project: any, index: number) => (
            <div key={index} className="project-item">
              <div className="project-header">
                <div className="project-title-container">
                  <h3>{project.title}</h3>
                  {project.project_name && (
                    <span className="project-name">{project.project_name}</span>
                  )}
                </div>
                <span className="dates">
                  {formatProjectDuration(project.startDate, project.endDate, project.isOngoing)}
                </span>
              </div>
              <ul className="bullet-points">
                {project.description.map((desc: any) => (
                  <li key={desc.id}>{desc.text}</li>
                ))}
              </ul>
            </div>
          ))}
        </section>
      )}

      {resumeData.skills && resumeData.skills.length > 0 && (
        <section className="skills-section">
          <h2>SKILLS</h2>
          <div className="skills-content">
            {resumeData.skills.join(', ')}
          </div>
        </section>
      )}
    </div>
  );
};

export default ApplicationResume; 