import axiosClient from '../axios-client';

interface ProcessedJobDetails {
  responsibilities: string[];
  qualifications: string[];
  salary: string;
  jobType: string;
  workEnvironment: string;
  skills?: string[];
  summary?: string;
  job_url?: string;
  isProcessed: boolean;
}

/**
 * Fetch job details - if job hasn't been processed, the backend will process it
 * @param jobId Job ID to fetch details for
 * @returns The job details with processed data if available
 */
const getJobDetails = async (jobId: string): Promise<ProcessedJobDetails> => {
  try {
    console.log(`Fetching job details for ${jobId}`);
    const response = await axiosClient.get(`/job-details/${jobId}`);
    
    // Check if we got valid data
    if (!response.data || typeof response.data !== 'object') {
      throw new Error('Invalid response data');
    }

    console.log('Received job details response:', response.data);
    console.log('JOB URL EXISTS IN RESPONSE?', !!response.data.job_url);
    console.log('Job URL value:', response.data.job_url);

    // Ensure all required fields are present with defaults
    const processedData: ProcessedJobDetails = {
      responsibilities: response.data.responsibilities || [],
      qualifications: response.data.qualifications || [],
      salary: response.data.salary || '',
      jobType: response.data.jobType || '',
      workEnvironment: response.data.workEnvironment || '',
      skills: response.data.skills || [],
      summary: response.data.summary || '',
      job_url: response.data.job_url || '', // Ensure we set a default empty string if undefined
      isProcessed: true
    };

    console.log('Processed job_url in service:', processedData.job_url);
    return processedData;
  } catch (error) {
    console.error('Error fetching job details:', error);
    throw error;
  }
};

/**
 * Force reprocessing of a job's details
 * @param jobId Job ID to reprocess
 * @returns The updated job details
 */
const resetJobProcessing = async (jobId: string): Promise<ProcessedJobDetails> => {
  try {
    console.log(`Resetting job processing for ${jobId}`);
    const response = await axiosClient.post(`/job-details/${jobId}/reset`);
    
    // Check if we got valid data
    if (!response.data || typeof response.data !== 'object') {
      throw new Error('Invalid response data');
    }

    console.log('Received reset job processing response:', response.data);

    // Ensure all required fields are present with defaults
    const processedData: ProcessedJobDetails = {
      responsibilities: response.data.responsibilities || [],
      qualifications: response.data.qualifications || [],
      salary: response.data.salary || '',
      jobType: response.data.jobType || '',
      workEnvironment: response.data.workEnvironment || '',
      skills: response.data.skills || [],
      summary: response.data.summary || '',
      job_url: response.data.job_url,
      isProcessed: true
    };

    return processedData;
  } catch (error) {
    console.error('Error resetting job processing:', error);
    throw error;
  }
};

export const jobDetailsService = {
  getJobDetails,
  resetJobProcessing,
};

export default jobDetailsService; 