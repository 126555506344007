/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { createPortal } from 'react-dom';
import "../../styles/components/applicationTracker/jobDetailsModal.scss";
import JobDescriptionIcon from "../../assets/job-description.svg";
import ResumeIcon from "../../assets/document-icon.svg";
// Removed the import for useApplicationModal due to the error
import axiosClient from '../../axios-client';
import { blobStorageService } from '../../services/blobStorage.service';
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import DeleteConfirmModal from './DeleteConfirmModal';
import ApplicationResume from './ApplicationResume';
import ApplicationCoverLetter from './ApplicationCoverLetter';

export interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  preferredQualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[];
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: any;
  job_url?: string;
  status?: 'applied' | 'interviewing' | 'offer';
  appliedDate?: string;
  lastStatusUpdate?: string;
  isDemo?: boolean;
  logo?: string;
  _preserveLogoState?: boolean;
}

interface JobDetailsModalProps {
  job: JobData;
  isOpen: boolean;
  onClose: () => void;
  onStatusChange?: (newStatus: 'applied' | 'interviewing' | 'offer') => void;
  onDelete?: (jobId: string) => void;
}

// Add this interface for application documents
interface ApplicationDocuments {
  resume: any;
  coverLetter: string | null;
}

// Add this for caching
const documentsCache = new Map<string, ApplicationDocuments>();

const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  job: initialJob,
  isOpen,
  onClose,
  onStatusChange,
  onDelete,
}) => {
  const [selectedSection, setSelectedSection] = useState("job-details");
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<'applied' | 'interviewing' | 'offer'>(initialJob.status || 'applied');
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [job, setJob] = useState<JobData>(initialJob);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  // New state for application documents with better typing
  const [applicationDocuments, setApplicationDocuments] = useState<ApplicationDocuments | null>(null);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [documentsError, setDocumentsError] = useState<string | null>(null);

  // Reset state when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setShowDeleteConfirm(false);
      setHasAnimated(false);
      setError(null);
      setApplicationDocuments(null);
      setDocumentsError(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  // Add effect to fetch full job details
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!isOpen) return;
      
      setIsLoading(true);
      
      // For demo jobs, use the initial data without fetching
      if (initialJob.isDemo || initialJob.jobType === "DEMO" || initialJob._id.startsWith('demo-')) {
        setJob(initialJob);
        setIsLoading(false);
        setError(null);
        return;
      }

      // For real jobs, fetch from server
      try {
        const response = await axiosClient.get(`/jobs/${initialJob._id}`);
        setJob(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching job details:', err);
        setError('Failed to load job details');
        // Keep the initial job data if fetch fails
        setJob(initialJob);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobDetails();
  }, [isOpen, initialJob]);

  // Fetch application documents when modal opens
  useEffect(() => {
    const fetchApplicationDocuments = async () => {
      if (!isOpen || !job._id) return;

      // Check cache first
      const cachedDocuments = documentsCache.get(job._id);
      if (cachedDocuments) {
        console.log('Using cached documents for job:', job._id);
        setApplicationDocuments(cachedDocuments);
        return;
      }

      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (!user._id) {
          setDocumentsError('User not found');
          return;
        }

        setDocumentsLoading(true);
        const response = await axiosClient.get(`/application-documents/${job._id}`, {
          params: {
            userId: user._id
          }
        });

        if (response.data) {
          const documents: ApplicationDocuments = {
            resume: response.data.resume || null,
            coverLetter: response.data.coverLetter || null
          };
          
          // Update cache
          documentsCache.set(job._id, documents);
          
          setApplicationDocuments(documents);
          setDocumentsError(null);
        }
      } catch (err) {
        console.error('Error fetching application documents:', err);
        setDocumentsError('Failed to load application documents');
        setApplicationDocuments(null);
      } finally {
        setDocumentsLoading(false);
      }
    };

    fetchApplicationDocuments();
  }, [isOpen, job._id]);

  // Update the close handler
  const handleClose = () => {
    setShowDeleteConfirm(false);
    setHasAnimated(false);
    setError(null);
    onClose();
  };

  // Update logo loading logic
  useEffect(() => {
    const loadLogo = async () => {
      if (!job.company) return;

      // For demo jobs, use the preserved logo
      if ((job.isDemo || job.jobType === "DEMO" || job._id.startsWith('demo-')) && job._preserveLogoState && job.logo) {
        setLogoUrl(job.logo);
        setLogoVariant('square');
        return;
      }

      // For real jobs, fetch the logo
      try {
        const logo = await blobStorageService.getBestLogoVariant(job.company);
        if (logo) {
          setLogoUrl(logo.url);
          setLogoVariant(logo.variant);
        }
      } catch (err) {
        console.error('Error loading company logo:', err);
      }
    };

    loadLogo();
  }, [job]);

  const getJobTypeClass = (jobType: string) => {
    if (!jobType) return "";
    
    if (jobType === "DEMO") return "demo";
    
    switch (jobType.toLowerCase()) {
      case "full time":
        return "full-time";
      case "part time":
        return "part-time";
      case "internship":
        return "internship";
      case "contract":
        return "contract";
      default:
        return "";
    }
  };

  const formatJobType = (jobType: string) => {
    if (!jobType) return "";
    
    if (jobType === "DEMO") return "DEMO";
    
    switch (jobType.toLowerCase()) {
      case "full time":
        return "FULL-TIME";
      case "part time":
        return "PART-TIME";
      default:
        return jobType.toUpperCase();
    }
  };

  const getPostedDate = (daysToLive: number | undefined) => {
    console.log("days_to_live value:", daysToLive);
    if (daysToLive === undefined || daysToLive === null) {
      return "Recently Posted";
    }

    const totalDays = 15;
    const daysAgo = totalDays - daysToLive;

    switch (daysAgo) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        return `${daysAgo} days ago`;
    }
  };

  const handleButtonClick = () => {
    if (job.isDemo || job.jobType === "DEMO") {
      navigate('/job-search');
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = (success: boolean) => {
    if (success && onDelete) {
      // Clear cache for this job
      documentsCache.delete(job._id);
      onDelete(job._id);
    }
    setShowDeleteConfirm(false);
    onClose();
  };

  if (!isOpen) return null;

  const tabsConfig = [
    {
      id: "job-details",
      label: "Job Details",
      icon: (
        <img
          src={JobDescriptionIcon}
          alt="Job Description Icon"
          width="16"
          height="16"
          className="tab-icon"
          style={{ color: 'currentColor' }}
        />
      ),
    },
    {
      id: "resume",
      label: "Resume",
      icon: (
        <img
          src={ResumeIcon}
          alt="Document Icon"
          width="16"
          height="16"
          className="tab-icon"
          style={{ color: 'currentColor' }}
        />
      ),
    },
    {
      id: "cover-letter",
      label: "Cover Letter",
      icon: (
        <img
          src={ResumeIcon}
          alt="Document Icon"
          width="16"
          height="16"
          className="tab-icon"
          style={{ color: 'currentColor' }}
        />
      ),
    }
  ];

  const renderContent = () => {
    switch (selectedSection) {
      case "resume":
        if (documentsLoading) {
          return (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading resume...</p>
            </div>
          );
        }
        if (documentsError) {
          return (
            <div className="error-container">
              <p>{documentsError}</p>
            </div>
          );
        }
        return <ApplicationResume 
          jobId={job._id} 
          resumeData={applicationDocuments?.resume || null} 
        />;
      case "cover-letter":
        if (documentsLoading) {
          return (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading cover letter...</p>
            </div>
          );
        }
        if (documentsError) {
          return (
            <div className="error-container">
              <p>{documentsError}</p>
            </div>
          );
        }
        return <ApplicationCoverLetter 
          jobId={job._id} 
          coverLetterContent={applicationDocuments?.coverLetter || null} 
        />;
      case "job-details":
      default:
        const jobToDisplay = job;
        const isDemo = jobToDisplay.isDemo || jobToDisplay.jobType === "DEMO";

        return (
          <>
            {isDemo && (
              <div className="demo-notification">
                <div className="demo-notification-content">
                  <div className="demo-text">
                    <strong>Demo Job Tile</strong>
                    <p>This is a preview to help you understand how the application tracker works. Once you start applying to jobs, your actual applications will appear here instead.</p>
                  </div>
                </div>
              </div>
            )}
            <div className="content-container">
              <div className="left-column">
                <h3>About the Job:</h3>
                {isDemo ? (
                  <p>
                    QuickCruit is seeking a talented {jobToDisplay.title} to join our growing team. This role offers an opportunity to work on innovative projects while developing your professional skills. The ideal candidate is passionate about creating impactful solutions and thrives in a collaborative environment.
                  </p>
                ) : (
                  <p>{jobToDisplay.summary || "No description provided."}</p>
                )}
                
                <h3>Requirements:</h3>
                {isDemo ? (
                  <ul>
                    <li>• Bachelor's degree in Marketing, Business, or related field</li>
                    <li>• 3+ years of experience in product marketing or similar role</li>
                    <li>• Strong analytical and strategic thinking abilities</li>
                    <li>• Excellent written and verbal communication skills</li>
                    <li>• Experience with market research and competitive analysis</li>
                  </ul>
                ) : jobToDisplay.qualifications && jobToDisplay.qualifications.length > 0 ? (
                  <ul>
                    {jobToDisplay.qualifications.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No requirements specified.</p>
                )}

                <h3>Responsibilities:</h3>
                {isDemo ? (
                  <ul>
                    <li>• Develop and execute product marketing strategies</li>
                    <li>• Create compelling product messaging and positioning</li>
                    <li>• Conduct market research and analyze competitive landscape</li>
                    <li>• Collaborate with cross-functional teams to drive product success</li>
                    <li>• Track and report on key performance metrics</li>
                  </ul>
                ) : jobToDisplay.responsibilities && jobToDisplay.responsibilities.length > 0 ? (
                  <ul>
                    {jobToDisplay.responsibilities.map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No responsibilities specified.</p>
                )}

                <h3>Skills:</h3>
                {isDemo ? (
                  <div className="skills">
                    <span className="skill-badge">Product Marketing</span>
                    <span className="skill-badge">Market Research</span>
                    <span className="skill-badge">Strategic Planning</span>
                    <span className="skill-badge">Data Analysis</span>
                    <span className="skill-badge">Project Management</span>
                    <span className="skill-badge">Communication</span>
                  </div>
                ) : (
                  <div className="skills">
                    {jobToDisplay.skills ? (
                      Array.isArray(jobToDisplay.skills) ? (
                        jobToDisplay.skills.map((skill, index) => (
                          <span key={index} className="skill-badge">
                            {skill.replace(/^•\s*/, "").trim()}
                          </span>
                        ))
                      ) : (
                        jobToDisplay.skills.split(",").map((skill, index) => (
                          <span key={index} className="skill-badge">
                            {skill.trim()}
                          </span>
                        ))
                      )
                    ) : (
                      <p>No skills specified.</p>
                    )}
                  </div>
                )}
              </div>
              <div className="right-column">
                <div className="job-info-box">
                  {isDemo ? (
                    <>
                      <h4>Posted</h4>
                      <p>Today</p>
                      <h4>Salary Range</h4>
                      <p>$180,000—$250,000 USD</p>
                      <h4>Experience Level</h4>
                      <p>Mid Career (3+ years)</p>
                      <h4>Job Environment</h4>
                      <p>Hybrid</p>
                      <h4 className="about-company">About the Company</h4>
                      <p className="about-company-text">
                        QuickCruit is a leading technology company revolutionizing the job search and application process. We're dedicated to helping job seekers find and land their dream roles through innovative solutions and cutting-edge technology.
                      </p>
                    </>
                  ) : (
                    <>
                      <h4>Posted</h4>
                      <p>{getPostedDate(jobToDisplay.days_to_live?.$numberInt)}</p>
                      <h4>Salary Range</h4>
                      <p>{jobToDisplay.salary || "Not available"}</p>
                      <h4>Experience Level</h4>
                      <p>{jobToDisplay.experience_level || "Not specified"}</p>
                      <h4>Job Environment</h4>
                      <p>{jobToDisplay.environment || "Not specified"}</p>
                      <h4 className="about-company">About the Company</h4>
                      <p className="about-company-text">
                        {jobToDisplay.company} is a leader in its industry providing
                        innovative solutions and services.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const handleTabChange = (tabId: string) => {
    setSelectedSection(tabId);
  };

  return (
    <>
      {createPortal(
        <div className={`modal-backdrop ${isOpen ? "show" : ""}`}>
          <div className={`modal-content ${isOpen ? "show" : ""}`}>
            <div className="sticky-container">
              <div className="modal-header">
                <div className="tabs">
                  {tabsConfig.map((tab) => (
                    <div
                      key={tab.id}
                      className={`tab ${selectedSection === tab.id ? "selected" : ""}`}
                      onClick={() => handleTabChange(tab.id)}
                    >
                      <span className="tab-icon">{tab.icon}</span>
                      <span>{tab.label}</span>
                    </div>
                  ))}
                </div>
                <button className="close-button" onClick={handleClose}>
                  ×
                </button>
              </div>
              {selectedSection === "job-details" && (
                <>
                  <div className={`header-section ${hasAnimated ? 'animate' : ''} ${selectedSection === "job-details" ? 'visible' : ''}`}>
                    <div className="job-title-section">
                      <div className="top-row">
                        <div className={`company-logo-wrapper ${logoVariant === 'horizontal' ? 'horizontal-logo-container' : ''}`}>
                          {logoUrl ? (
                            <img
                              src={logoUrl}
                              alt={`${job.company} logo`}
                              className={`company-logo ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
                            />
                          ) : (
                            <div className="logo-placeholder" />
                          )}
                        </div>
                        {job.jobType && (
                          <span
                            className={`job-type-tag ${getJobTypeClass(job.jobType)}`}
                          >
                            {formatJobType(job.jobType)}
                          </span>
                        )}
                      </div>
                      <div className="bottom-row">
                        <div className="job-title">
                          <h2>{job.title}</h2>
                          <p className="company-location">
                            {job.company} • {job.location}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="button-group">
                      {!job.isDemo && !job.jobType?.includes("DEMO") && (
                        <button className="delete-job-button" onClick={handleDeleteClick}>
                          <div className="trash-icon">
                            <FaTrash size={16} />
                          </div>
                          Delete
                        </button>
                      )}
                      <button
                        className="tailor-button"
                        onClick={handleButtonClick}
                      >
                        {job.isDemo || job.jobType === "DEMO" ? "Land Your Dream Job 🚀" : "Mock Interview"}
                      </button>
                    </div>
                  </div>
                  <hr className="separator-line" />
                </>
              )}
            </div>
            <div className="modal-body">
              {isLoading ? (
                <div className="loading-overlay">
                  <div className="loading-spinner"></div>
                  <p>Loading job details...</p>
                </div>
              ) : error ? (
                <div className="error-message">
                  <p>{error}</p>
                  <button onClick={() => setError(null)}>Dismiss</button>
                </div>
              ) : (
                renderContent()
              )}
            </div>
          </div>
        </div>,
        document.getElementById('job-details-modal-root')!
      )}
      {showDeleteConfirm && (
        <DeleteConfirmModal
          showModal={showDeleteConfirm}
          jobId={job._id}
          onConfirm={handleDeleteConfirm}
          setModalData={({ showModal }) => setShowDeleteConfirm(showModal)}
        />
      )}
    </>
  );
};

export default JobDetailsModal; 