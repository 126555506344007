import React, { useState, useEffect, useRef } from "react";
import '../styles/components/resume.scss';
import {
  ResumeText,
  BulletPointSuggestion,
  Education,
  Experience,
  Project,
} from "./ResumeComponent";
import aiIcon from '../assets/ai-icon.svg';

/** --------------------------------------------------------------------
 * Date Formatting Functions
 * -------------------------------------------------------------------- */
const formatDateRange = (startDate: string, endDate: string | null, isCurrentPosition: boolean = false): string => {
  if (!startDate) return "";
  if (isCurrentPosition) return `${startDate} - Present`;
  return endDate ? `${startDate} - ${endDate}` : startDate;
};

const formatProjectDuration = (startDate: string, endDate: string | null, isOngoing: boolean = false): string => {
  if (!startDate) return "";
  if (isOngoing) return `${startDate} - Present`;
  return endDate ? `${startDate} - ${endDate}` : startDate;
};

interface ResumeProps {
  revisedResume: ResumeText;
  isEditingLocked: boolean;
  handleFieldChange: (updater: (prev: ResumeText) => ResumeText) => void;
  handleTailorBulletPoint: (
    expIndex: number,
    descId: string
  ) => Promise<void>;
  selectedBulletPoint: BulletPointSuggestion | null;
  handleAcceptSuggestion: (expIndex: number, descId: string) => void;
  handleRejectSuggestion: () => void;
  expandedBulletPoint: { expIndex: number, descId: string } | null;
}

const Resume: React.FC<ResumeProps> = ({
  revisedResume,
  isEditingLocked,
  handleFieldChange,
  handleTailorBulletPoint,
  selectedBulletPoint,
  handleAcceptSuggestion,
  handleRejectSuggestion,
  expandedBulletPoint,
}) => {
  const [selectedPoint, setSelectedPoint] = useState<{
    expIndex: number;
    descId: string;
  } | null>(null);
  const [showAIButton, setShowAIButton] = useState(false);
  const [aiButtonPosition, setAiButtonPosition] = useState({ top: 0, left: 0 });
  const bulletPointRef = useRef<HTMLDivElement>(null);
  const [suggestionWidth, setSuggestionWidth] = useState<number>(0);
  const [aiButtonWidth, setAiButtonWidth] = useState<number>(0);

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString().trim();

    if (!selectedText) {
      setShowAIButton(false);
      return;
    }

    const range = selection?.getRangeAt(0);
    if (!range) return;

    // Find the bullet point containing the selection
    const bulletPointElement = range.commonAncestorContainer.parentElement?.closest('.bullet-points li');
    if (!bulletPointElement) {
      setShowAIButton(false);
      return;
    }

    // Get the expIndex and descId from data attributes
    const expIndex = parseInt(bulletPointElement.getAttribute('data-exp-index') || '-1');
    const descId = bulletPointElement.getAttribute('data-desc-id') || '';

    if (expIndex === -1 || !descId) {
      setShowAIButton(false);
      return;
    }

    const rect = range.getBoundingClientRect();
    const buttonWidth = 160;
    const centerPosition = rect.left + (rect.width * 0.7) - (buttonWidth / 2);

    setAiButtonPosition({
      top: rect.bottom + 5,
      left: centerPosition,
    });
    setSelectedPoint({ expIndex, descId });
    setShowAIButton(true);
  };

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);

  // Add a resize observer to track width changes
  useEffect(() => {
    if (bulletPointRef.current) {
      setSuggestionWidth(bulletPointRef.current.offsetWidth);
      setAiButtonWidth(bulletPointRef.current.offsetWidth);

      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target instanceof HTMLElement) {
            setSuggestionWidth(entry.target.offsetWidth);
            setAiButtonWidth(entry.target.offsetWidth);
          }
        }
      });

      resizeObserver.observe(bulletPointRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [selectedPoint]);

  // Also add a window resize listener for good measure
  useEffect(() => {
    const handleResize = () => {
      if (bulletPointRef.current) {
        setSuggestionWidth(bulletPointRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`resume-rectangle ${isEditingLocked ? "locked" : ""}`}>
      <div className="resume-header">
        <div
          contentEditable={!isEditingLocked}
          suppressContentEditableWarning
          onBlur={(e) =>
            handleFieldChange((prev) => ({
              ...prev,
              firstName: e.currentTarget?.innerText ? e.currentTarget.innerText.split(' ')[0] : prev.firstName,
              lastName: e.currentTarget?.innerText ? e.currentTarget.innerText.split(' ').slice(1).join(' ') : prev.lastName,
            }))
          }
          className="resume-name"
        >
          {revisedResume.firstName} {revisedResume.lastName}
        </div>
        <p className="contact-info">
          {revisedResume.email && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    email: e.currentTarget?.innerText ?? prev.email,
                  }))
                }
              >
                {revisedResume.email}
              </div>
              {(revisedResume.phone || revisedResume.linkedin || revisedResume.website || revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.phone && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    phone: e.currentTarget?.innerText ?? prev.phone,
                  }))
                }
              >
                {revisedResume.phone}
              </div>
              {(revisedResume.linkedin || revisedResume.website || revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.linkedin && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    linkedin: e.currentTarget?.innerText ?? prev.linkedin,
                  }))
                }
              >
                {revisedResume.linkedin}
              </div>
              {(revisedResume.website || revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.website && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    website: e.currentTarget?.innerText ?? prev.website,
                  }))
                }
              >
                {revisedResume.website}
              </div>
              {(revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.location && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    location: e.currentTarget?.innerText ?? prev.location,
                  }))
                }
              >
                {revisedResume.location}
              </div>
              {(revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.citizenship && (
            <div
              contentEditable={!isEditingLocked}
              suppressContentEditableWarning
              onBlur={(e) =>
                handleFieldChange((prev) => ({
                  ...prev,
                  citizenship: e.currentTarget?.innerText ?? prev.citizenship,
                }))
              }
            >
              {revisedResume.citizenship}
            </div>
          )}
        </p>
      </div>

      <div className="education-section">
        <h2>EDUCATION</h2>
        {revisedResume.education.map((edu: Education, index: number) => (
          <div key={index} className="education-item">
            <div className="education-header">
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedEducation = [...prev.education];
                    updatedEducation[index].university = e.currentTarget?.innerText ?? edu.university;
                    return { ...prev, education: updatedEducation };
                  })
                }
                className="education-title"
              >
                {edu.university}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedEducation = [...prev.education];
                    updatedEducation[index].gpa = e.currentTarget?.innerText ?? edu.gpa;
                    return { ...prev, education: updatedEducation };
                  })
                }
                className="gpa right-aligned"
              >
                {edu.gpa && `GPA: ${edu.gpa}`}
              </div>
            </div>
            <div className="education-subheader">
              <div className="degree-field">
                <span
                  contentEditable={!isEditingLocked}
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    handleFieldChange((prev) => {
                      const updatedEducation = [...prev.education];
                      updatedEducation[index].degree = e.currentTarget?.innerText ?? edu.degree;
                      return { ...prev, education: updatedEducation };
                    })
                  }
                >
                  {edu.degree}
                </span>
                {edu.field && (
                  <span
                    contentEditable={!isEditingLocked}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      handleFieldChange((prev) => {
                        const updatedEducation = [...prev.education];
                        updatedEducation[index].field = e.currentTarget?.innerText ?? edu.field;
                        return { ...prev, education: updatedEducation };
                      })
                    }
                  >
                    , {edu.field}
                  </span>
                )}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedEducation = [...prev.education];
                    updatedEducation[index].graduationDate = e.currentTarget?.innerText ?? edu.graduationDate;
                    return { ...prev, education: updatedEducation };
                  })
                }
                className="graduation-date right-aligned"
              >
                {edu.graduationDate}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="experience-section">
        <h2>EXPERIENCE</h2>
        {revisedResume.experience.map((exp: Experience, expIndex: number) => (
          <div key={expIndex} className="experience-item">
            <div className="experience-header">
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].title = e.currentTarget?.innerText ?? exp.title;
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="job-title"
              >
                {exp.title}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    const dateText = e.currentTarget?.innerText;
                    if (!dateText) return prev; // Keep previous state if null
                    const dates = dateText.split(" - ");
                    if (dates.length > 0) {
                      updatedExperience[expIndex].startDate = dates[0];
                      if (dates.length > 1) {
                        if (dates[1].toLowerCase() === "present") {
                          updatedExperience[expIndex].endDate = null;
                          updatedExperience[expIndex].isCurrentPosition = true;
                        } else {
                          updatedExperience[expIndex].endDate = dates[1];
                          updatedExperience[expIndex].isCurrentPosition = false;
                        }
                      }
                    }
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="duration right-aligned"
              >
                {formatDateRange(exp.startDate, exp.endDate, exp.isCurrentPosition)}
              </div>
            </div>
            <div className="experience-subheader">
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].company = e.currentTarget?.innerText ?? exp.company;
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="company-name"
              >
                {exp.company}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].location = e.currentTarget?.innerText ?? exp.location;
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="location right-aligned"
              >
                {exp.location}
              </div>
            </div>
            <ul className="bullet-points">
              {exp.description.map((desc: { id: string; text: string }, descIndex: number) => (
                <li
                  key={desc.id}
                  className={`
                    ${selectedPoint?.descId === desc.id ? "selected" : ""}
                    ${expandedBulletPoint?.descId === desc.id ? "highlighted" : ""}
                  `}
                  data-exp-index={expIndex}
                  data-desc-id={desc.id}
                >
                  <div
                    ref={bulletPointRef}
                    contentEditable={!isEditingLocked}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      handleFieldChange((prev) => {
                        const updatedExperience = [...prev.experience];
                        const currentDescription = updatedExperience[expIndex].description.find(
                          (d) => d.id === desc.id
                        );

                        if (currentDescription) {
                          currentDescription.text = e.currentTarget?.innerText ?? desc.text;
                        }
                        return { ...prev, experience: updatedExperience };
                      })
                    }
                  >
                    {desc.text}
                  </div>

                  {selectedPoint?.expIndex === expIndex &&
                    selectedPoint?.descId === desc.id &&
                    showAIButton && (
                      <div
                        className={`tailor-ai-btn-container visible`}
                        style={{
                          position: "absolute",
                          width: aiButtonWidth ? `${aiButtonWidth}px` : 'auto',
                          maxWidth: '100%',
                          zIndex: 1000,
                          left: '80%',           // Add this
                          transform: 'translateX(-50%)'  // Add this
                        }}
                      >
                        <button
                          className="tailor-ai-btn"
                          onClick={() => {
                            handleTailorBulletPoint(expIndex, desc.id);
                            setShowAIButton(false);
                          }}
                        >
                          <img 
                            src={aiIcon}
                            alt="AI Icon" 
                            width={20} 
                            height={20}
                            style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                          />
                          <span className="btn-text">Tailor with AI</span>
                        </button>
                      </div>
                    )}

                  {selectedBulletPoint &&
                    selectedBulletPoint.expIndex === expIndex &&
                    selectedBulletPoint.descId === desc.id && (
                      <div 
                        className="bullet-point-suggestion" 
                        role="button"
                        style={{ 
                          width: suggestionWidth ? `${suggestionWidth}px` : 'auto',
                          maxWidth: '100%'
                        }}
                      >
                        <div className="suggestion-header">
                          <img 
                            src={aiIcon}
                            alt="AI Icon" 
                            width={20} 
                            height={20}
                            style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                          />
                          <span className="suggestion-title">
                            Suggestion
                          </span>
                        </div>
                        
                        {selectedBulletPoint.isLoading ? (
                          <div className="skeleton-lines">
                            <div className="skeleton-loader" style={{ width: '100%' }}></div>
                            <div className="skeleton-loader" style={{ width: '60%' }}></div>
                          </div>
                        ) : (
                          <>
                            {selectedBulletPoint.suggested ? (
                              <div>{selectedBulletPoint.suggested}</div>
                            ) : (
                              ""
                            )}
                            <button
                              className="bullet-point-button"
                              onClick={() => handleAcceptSuggestion(expIndex, desc.id)}
                            >
                              Approve
                            </button>
                            <button onClick={handleRejectSuggestion}>
                              Dismiss
                            </button>
                          </>
                        )}
                      </div>
                    )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="projects-section">
        <h2>PROJECTS</h2>
        {revisedResume.projects.map((project, index) => (
          <div key={index} className="project-item">
            <div className="project-header">
              <div className="project-title-container">
                <div
                  contentEditable={!isEditingLocked}
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    handleFieldChange((prev) => {
                      const updatedProjects = [...prev.projects];
                      updatedProjects[index].title = e.currentTarget?.innerText ?? project.title;
                      return { ...prev, projects: updatedProjects };
                    })
                  }
                  className="project-title"
                >
                  {project.title}
                </div>
                <div
                  contentEditable={!isEditingLocked}
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    handleFieldChange((prev) => {
                      const updatedProjects = [...prev.projects];
                      updatedProjects[index].project_name = e.currentTarget?.innerText ?? project.project_name;
                      return { ...prev, projects: updatedProjects };
                    })
                  }
                  className="project-name"
                >
                  {project.project_name}
                </div>
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedProjects = [...prev.projects];
                    const dateText = e.currentTarget?.innerText;
                    if (!dateText) return prev; // Keep previous state if null
                    const dates = dateText.split(" - ");
                    if (dates.length > 0) {
                      updatedProjects[index].startDate = dates[0];
                      if (dates.length > 1) {
                        if (dates[1].toLowerCase() === "present") {
                          updatedProjects[index].endDate = null;
                          updatedProjects[index].isOngoing = true;
                        } else {
                          updatedProjects[index].endDate = dates[1];
                          updatedProjects[index].isOngoing = false;
                        }
                      }
                    }
                    return { ...prev, projects: updatedProjects };
                  })
                }
                className="project-duration right-aligned"
              >
                {formatProjectDuration(project.startDate, project.endDate, project.isOngoing)}
              </div>
            </div>
            <ul className="bullet-points">
              {project.description.map((desc: { id: string; text: string }, descIndex: number) => (
                <li
                  key={desc.id}
                  className={`
                    ${selectedPoint?.descId === desc.id ? "selected" : ""}
                    ${expandedBulletPoint?.descId === desc.id ? "highlighted" : ""} 
                  `}
                  data-exp-index={index}
                  data-desc-id={desc.id}
                >
                  <div
                    contentEditable={!isEditingLocked}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      handleFieldChange((prev) => {
                        const updatedProjects = [...prev.projects];
                        const currentDescription = updatedProjects[index].description.find(
                          (d) => d.id === desc.id
                        );

                        if (currentDescription) {
                          currentDescription.text = e.currentTarget?.innerText ?? desc.text;
                        }
                        return { ...prev, projects: updatedProjects };
                      })
                    }
                    className="project-description"
                  >
                    {desc.text}
                  </div>

                    {selectedPoint?.expIndex === index && 
                    selectedPoint?.descId === desc.id &&
                    showAIButton && (
                      <div
                        className={'tailor-ai-btn-container visible'}
                        style={{
                          position: 'absolute',
                          width: aiButtonWidth ? `${aiButtonWidth}px` : 'auto',
                          maxWidth: '100%',
                          zIndex: 1000,
                          left: '80%',           // Now matches the experience section
                          transform: 'translateX(-50%)'
                        }}
                      >
                        <button
                          className="tailor-ai-btn"
                          onClick={() => {
                            handleTailorBulletPoint(index, desc.id);
                            setShowAIButton(false);
                          }}
                        >
                          <img 
                            src={aiIcon}
                            alt="AI Icon" 
                            width={20} 
                            height={20}
                            style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                          />
                          <span className="btn-text">Tailor with AI</span>
                        </button>
                      </div>
                    )}

                    {selectedBulletPoint &&
                      selectedBulletPoint.expIndex === index &&
                      selectedBulletPoint.descId === desc.id && (
                        <div 
                          className="bullet-point-suggestion" 
                          role="button"
                          style={{ 
                            width: suggestionWidth ? `${suggestionWidth}px` : 'auto',
                            maxWidth: '100%'
                          }}
                        >
                          <div className="suggestion-header">
                            <img 
                              src={aiIcon}
                              alt="AI Icon" 
                              width={20} 
                              height={20}
                              style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                            />
                            <span className="suggestion-title">
                              Suggestion
                            </span>
                          </div>
                          
                          {selectedBulletPoint.isLoading ? (
                            <div className="skeleton-lines">
                              <div className="skeleton-loader" style={{ width: '100%' }}></div>
                              <div className="skeleton-loader" style={{ width: '60%' }}></div>
                            </div>
                          ) : (
                            <>
                              {selectedBulletPoint.suggested ? (
                                <div>{selectedBulletPoint.suggested}</div>
                              ) : (
                                ""
                              )}
                              <button
                                className="bullet-point-button"
                                onClick={() => handleAcceptSuggestion(index, desc.id)}
                              >
                                Approve
                              </button>
                              <button onClick={handleRejectSuggestion}>
                                Dismiss
                              </button>
                            </>
                          )}
                        </div>
                      )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="projects-section">  {/* Using same class as other sections */}
        <h2>SKILLS</h2>
        <div className="skills-interests-content">
          <div className="skills-line">
            <span
              contentEditable={!isEditingLocked}
              suppressContentEditableWarning
              onBlur={(e) =>
                handleFieldChange((prev) => ({
                  ...prev,
                  skills: e.currentTarget?.innerText
                    ? e.currentTarget.innerText
                        .split(",")
                        .map((skill) => skill.trim())
                    : prev.skills,
                }))
              }
              className="resume-skills"
            >
              {revisedResume.skills.join(", ")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;