/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { createPortal } from 'react-dom';
import "../styles/components/jobDetailsModal.scss";
import ResumeComponent from "./ResumeComponent";
import CoverLetter from "./CoverLetter"; // Import the new CoverLetter component
import axiosClient from "../axios-client";
import aiIcon from "../assets/ai-blue.svg"; // Path to AI blue icon
import JobDescriptionIcon from "../assets/job-description.svg"; // Path to job description icon
import { v4 as uuidv4 } from "uuid";
import { ResumeText } from './ResumeComponent';
import { addUtmParameters } from '../utils/utm';
import ApplicationConfirmModal from './ApplicationConfirmModal';
import { useApplicationModal } from '../contexts/ApplicationModalContext';
import { blobStorageService } from '../services/blobStorage.service';
import { toast } from 'react-hot-toast';
import { jobDetailsService } from "../services/jobDetails.service";

export interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[];
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: any;
  job_url: string;
  isProcessed?: boolean | string;
  workEnvironment?: string;
}

interface JobDetailsModalProps {
  job: JobData;
  isOpen: boolean;
  onClose: () => void;
}

const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  job: initialJob,
  isOpen,
  onClose,
}) => {
  const [selectedSection, setSelectedSection] = useState("job-details");
  const [resumeText, setResumeText] = useState<ResumeText | null>(null);
  const [resumeId, setResumeId] = useState<string>('');
  const [showCoverLetterTab, setShowCoverLetterTab] = useState(false);
  const [showResumeTab, setShowResumeTab] = useState(false); // New state to control Resume tab visibility
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0); // For logo fallback
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [job, setJob] = useState<JobData>(initialJob);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [transitionState, setTransitionState] = useState<'loading' | 'transitioning' | 'loaded'>('loading');

  // Add new state variables to track tailoring status
  const [hasResumeBeenTailored, setHasResumeBeenTailored] = useState(false);
  const [hasCoverLetterBeenTailored, setHasCoverLetterBeenTailored] = useState(false);

  // Add cache state
  const [cachedResumeContent, setCachedResumeContent] = useState<{
    suggestions: any[];
    score: number | null;
  } | null>(null);
  
  const [cachedCoverLetter, setCachedCoverLetter] = useState<string | null>(null);

  const [showApplicationConfirm, setShowApplicationConfirm] = useState(false);
  const [hasLeftForExternalSite, setHasLeftForExternalSite] = useState(false);

  const { setModalData } = useApplicationModal();

  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);

  const [showAllLocations, setShowAllLocations] = useState(false);

  // Add new state variables for job details processing
  const [isProcessingJobDetails, setIsProcessingJobDetails] = useState(false);
  const [processingError, setProcessingError] = useState<string | null>(null);

  // Extract job type from job data
  const extractJobType = (job: JobData): string => {
    // Method 1: direct extraction from standard fields
    if (job.jobType) {
      return job.jobType;
    } 
    // Method 2: force a fallback value
    return "Full Time"; // Default fallback
  };

  // Add helper function to normalize job type strings
  const normalizeJobType = (jobType: string): string => {
    if (!jobType) return '';
    
    // Remove both prefix and any surrounding quotes
    return jobType
      .replace(/^Job Type:\s*/i, '')
      .replace(/^['"]|['"]$/g, '') // Remove surrounding quotes
      .toLowerCase()
      .replace(/[-_]/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  };

  // Function to map job types to corresponding class names
  const getJobTypeClass = (jobType: string): string => {
    if (!jobType) return '';
    
    if (jobType === 'DEMO') return 'demo';
    
    const normalizedType = normalizeJobType(jobType);
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'full-time';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'part-time';
      case 'internship':
      case 'intern':
        return 'internship';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'contract';
      default:
        return normalizedType.replace(/\s+/g, '-');
    }
  };

  const formatJobType = (jobType: string): string => {
    if (!jobType) return '';
    
    if (jobType === 'DEMO') return 'DEMO';
    
    const normalizedType = normalizeJobType(jobType);
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'FULL-TIME';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'PART-TIME';
      case 'internship':
      case 'intern':
        return 'INTERNSHIP';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'CONTRACT';
      default:
        return jobType.toUpperCase().replace(/[-_]/g, ' ');
    }
  };

  // Function to format job type with proper capitalization for display in the details sidebar
  const normalizeJobTypeForDisplay = (jobType: string): string => {
    if (!jobType) return 'Not specified';
    
    const normalizedType = normalizeJobType(jobType);
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'Full-Time';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'Part-Time';
      case 'internship':
      case 'intern':
        return 'Internship';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'Contract';
      default:
        // Capitalize first letter of each word
        return normalizedType
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }
  };

  // Log job URL for debugging in componentDidMount-like effect
  useEffect(() => {
    if (isOpen) {
      console.log('JobDetailsModal opened with job:', {
        _id: job._id,
        title: job.title,
        job_url: job.job_url
      });
    }
  }, [isOpen, job]);

  useEffect(() => {
    const loadLogo = async () => {
      if (job.company) {
        const logo = await blobStorageService.getBestLogoVariant(job.company);
        if (logo) {
          setLogoUrl(logo.url);
          setLogoVariant(logo.variant);
        }
      }
    };
    loadLogo();
  }, [job.company]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isOpen]);

  // Add cleanup function
  const cleanupJobData = () => {
    setHasAnimated(false);  // Reset animation state when modal closes
    // Clear all states
    setHasResumeBeenTailored(false);
    setHasCoverLetterBeenTailored(false);
    setShowCoverLetterTab(false);
    setShowResumeTab(false); // Reset resume tab visibility
    setSelectedSection("job-details");
    setResumeText(null);
    setCachedResumeContent(null);
    setCachedCoverLetter(null);
    setResumeId('');
    setError(null);
    setIsLoading(false);
    setIsProcessingJobDetails(false);
    
    // Clear localStorage items for this job
    localStorage.removeItem(`coverLetter_${job._id}`);
    localStorage.removeItem(`resumeContent_${job._id}`);
    localStorage.removeItem(`resumeScore_${job._id}`);
    localStorage.removeItem(`tailoredResume_${job._id}`);
    localStorage.removeItem(`resumeId_${job._id}`);
  };

  // Add cleanup for any leftover transitions
  useEffect(() => {
    return () => {
      // Clean up any transitions when component unmounts
      document.body.classList.remove('skeleton-transitioning');
      setTransitionState('loading'); // Reset state for next open
      
      // Ensure no flickering on next open
      const skeletonEl = document.querySelector('.skeleton-loading');
      if (skeletonEl) {
        skeletonEl.classList.remove('fade-out');
        const headings = skeletonEl.querySelectorAll('.section-heading');
        headings.forEach(heading => {
          heading.classList.remove('persistent');
        });
      }
    };
  }, []);

  // Add cleanup logic when modal closes
  const handleClose = () => {
    // Reset loading/transition states immediately to avoid flashing on future opens
    setIsLoading(false);
    setIsProcessingJobDetails(false);
    setTransitionState('loading');
    document.body.classList.remove('skeleton-transitioning');
    cleanupJobData();
    onClose();
  };

  // Add cleanup for loading timers
  useEffect(() => {
    // Initialize timer references
    let loadingTimer: NodeJS.Timeout | null = null;
    
    return () => {
      // Clear any pending timers when the component unmounts or when dependencies change
      if (loadingTimer) clearTimeout(loadingTimer);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchResume();
    } else {
      cleanupJobData();
    }
  }, [isOpen, job._id]);

  // Add new effect to handle tab changes
  useEffect(() => {
    // Don't clear cache when switching tabs
    if (selectedSection === "job-details") {
      setHasResumeBeenTailored(false);
      setHasCoverLetterBeenTailored(false);
    }
  }, [selectedSection]);

  // Add effect to persist cover letter in localStorage
  useEffect(() => {
    if (cachedCoverLetter) {
      localStorage.setItem(`coverLetter_${job._id}`, cachedCoverLetter);
    }
  }, [cachedCoverLetter, job._id]);

  // Load cached cover letter from localStorage when component mounts
  useEffect(() => {
    const savedCoverLetter = localStorage.getItem(`coverLetter_${job._id}`);
    if (savedCoverLetter) {
      setCachedCoverLetter(savedCoverLetter);
      setHasCoverLetterBeenTailored(true);
      setShowCoverLetterTab(true);
    }
  }, [job._id]);

  // Add effect to handle window focus
  useEffect(() => {
    const handleWindowFocus = () => {
      if (hasLeftForExternalSite) {
        setModalData({ 
          showModal: true, 
          jobTitle: job.title, 
          companyName: job.company,
          jobId: job._id,
          resumeContent: resumeText,
          coverLetterContent: cachedCoverLetter
        });
        setHasLeftForExternalSite(false);
      }
    };

    window.addEventListener('focus', handleWindowFocus);
    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [hasLeftForExternalSite, job.title, job.company, job._id, setModalData, resumeText, cachedCoverLetter]);

  // Update the fetchJobDetails function
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (isOpen && initialJob._id) {
        try {
          setIsLoading(true);
          setTransitionState('loading');
          setError(null);
          setProcessingError(null);
          
          // Get basic job info first and ensure we capture any job_url that might be present
          const basicJob = { 
            ...initialJob,
            job_url: initialJob.job_url || '' // Ensure job_url is never undefined
          };
          
          // Log the initial job data for debugging
          console.log('Initial job data:', {
            _id: basicJob._id,
            title: basicJob.title,
            job_url: basicJob.job_url,
            isProcessed: basicJob.isProcessed
          });
          
          // Update the state immediately with whatever job_url we have from initial job
          setJob(basicJob);
          
          // Properly handle isProcessed regardless of type (convert string "True"/"False" to boolean)
          const isProcessed = 
            typeof basicJob.isProcessed === 'boolean' ? basicJob.isProcessed :
            typeof basicJob.isProcessed === 'string' ? 
              basicJob.isProcessed.toLowerCase() === 'true' : false;

          console.log(`Job ${basicJob._id} isProcessed:`, isProcessed);
          
          // If job is already processed and has all data, use it directly
          if (
            isProcessed && 
            Array.isArray(basicJob.responsibilities) && 
            basicJob.responsibilities.length > 0
          ) {
            console.log('Job already processed, using cached data');
            setJob(basicJob);
            
            // Ensure headings stay visible when transitioning
            const skeletonEl = document.querySelector('.skeleton-loading');
            if (skeletonEl) {
              // First, make sure headings remain visible
              const headings = skeletonEl.querySelectorAll('.section-heading');
              headings.forEach(heading => {
                heading.classList.add('persistent');
              });
              
              // Then add fade-out to the skeleton (content only)
              skeletonEl.classList.add('fade-out');
              
              // Mark as transitioning - this helps prevent flickering
              document.body.classList.add('skeleton-transitioning');
              setTransitionState('transitioning');
            }
            
            // Use a simpler approach with a single timeout
            setTimeout(() => {
              // Handle all state changes at once to prevent multiple renders
              setIsLoading(false);
              setTransitionState('loaded');
              
              // Use RAF to ensure DOM updates complete before removing class
              requestAnimationFrame(() => {
                // Short delay to ensure content is rendered first
                setTimeout(() => {
                  document.body.classList.remove('skeleton-transitioning');
                }, 50);
              });
            }, 550);
            
            return;
          }
          
          // If not processed, process it
          try {
            console.log('Job not processed, getting processed data');
            setIsProcessingJobDetails(true);
            const processedData = await jobDetailsService.getJobDetails(initialJob._id);
            
            // Debug processed data
            console.log('Processed data received:', processedData);
            console.log('Received job_url from API:', processedData.job_url);
            
            // Create an updated job object that prioritizes job_url from the API response
            const updatedJob = {
              ...basicJob,
              responsibilities: processedData.responsibilities || [],
              qualifications: processedData.qualifications || [],
              salary: processedData.salary || '',
              jobType: processedData.jobType || '',
              workEnvironment: processedData.workEnvironment || '',
              skills: processedData.skills || [],
              summary: processedData.summary || '',
              isProcessed: true,
            };
            
            // CRITICAL: Prioritize job_url from API response over the initial job
            if (processedData.job_url && processedData.job_url.trim() !== '') {
              updatedJob.job_url = processedData.job_url;
              console.log('Using job_url from API:', processedData.job_url);
            } else if (!updatedJob.job_url || updatedJob.job_url.trim() === '') {
              // Fallback to empty string if both sources are empty/undefined
              updatedJob.job_url = '';
              console.log('No job_url found in either source, using empty string');
            } else {
              console.log('Keeping original job_url:', updatedJob.job_url);
            }
            
            console.log('Setting job state with final job_url:', updatedJob.job_url);
            
            // Update the job state with the final job object
            setJob(updatedJob);

            // Ensure headings stay visible when transitioning
            const skeletonEl = document.querySelector('.skeleton-loading');
            if (skeletonEl) {
              // First, make sure headings remain visible
              const headings = skeletonEl.querySelectorAll('.section-heading');
              headings.forEach(heading => {
                heading.classList.add('persistent');
              });
              
              // Then add fade-out to the skeleton (content only)
              skeletonEl.classList.add('fade-out');
              
              // Mark as transitioning - this helps prevent flickering
              document.body.classList.add('skeleton-transitioning');
              setTransitionState('transitioning');
            }
            
            // Use a simpler approach with a single timeout
            setTimeout(() => {
              // Handle all state changes at once to prevent multiple renders
              setIsProcessingJobDetails(false);
              setIsLoading(false);
              setTransitionState('loaded');
              
              // Use RAF to ensure DOM updates complete before removing class
              requestAnimationFrame(() => {
                // Short delay to ensure content is rendered first
                setTimeout(() => {
                  document.body.classList.remove('skeleton-transitioning');
                }, 50);
              });
            }, 550);
            
          } catch (processError: any) {
            console.error('Error processing job details:', processError);
            // If we get a 500 error, it means processing failed
            if (processError?.response?.status === 500) {
              setProcessingError('Failed to process job details. Please try again.');
              // Set a retry function
              const retryProcessing = async () => {
                setProcessingError(null);
                setIsProcessingJobDetails(true);
                try {
                  const retriedData = await jobDetailsService.resetJobProcessing(initialJob._id);
                  
                  // Debug retried data
                  console.log('Retry processing successful');
                  console.log('Retried data received:', retriedData);
                  console.log('Received job_url from retry API:', retriedData.job_url);
                  
                  // Create updated job object with retried data
                  const updatedJob = {
                    ...basicJob,
                    responsibilities: retriedData.responsibilities || [],
                    qualifications: retriedData.qualifications || [],
                    salary: retriedData.salary || '',
                    jobType: retriedData.jobType || '',
                    workEnvironment: retriedData.workEnvironment || '',
                    skills: retriedData.skills || [],
                    summary: retriedData.summary || '',
                    isProcessed: true,
                  };
                  
                  // CRITICAL: Handle job_url the same way as in the main processing
                  if (retriedData.job_url && retriedData.job_url.trim() !== '') {
                    updatedJob.job_url = retriedData.job_url;
                    console.log('Using job_url from retry API:', retriedData.job_url);
                  } else if (!updatedJob.job_url || updatedJob.job_url.trim() === '') {
                    // Fallback to empty string if both sources are empty/undefined
                    updatedJob.job_url = '';
                    console.log('No job_url found in retry, using empty string');
                  } else {
                    console.log('Keeping original job_url after retry:', updatedJob.job_url);
                  }
                  
                  console.log('Setting job state after retry with final job_url:', updatedJob.job_url);
                  
                  // Update the job state with the final retried object
                  setJob(updatedJob);
                  
                  setProcessingError(null);
                  // Add a small delay before removing the loading state
                  setTimeout(() => {
                    setIsProcessingJobDetails(false);
                    setIsLoading(false);
                  }, 800);
                } catch (retryError) {
                  console.error('Error retrying job processing:', retryError);
                  setProcessingError('Failed to process job details. Please try again later.');
                  setIsProcessingJobDetails(false);
                  setIsLoading(false);
                }
              };
              // Add retry function to error state
              setProcessingError(prev => prev + ' Click to retry.');
              // Add retry handler
              (window as any).retryJobProcessing = retryProcessing;
              setIsLoading(false);
            } else {
              setError('Failed to load job details. Please try again.');
              setIsLoading(false);
              setIsProcessingJobDetails(false);
            }
          }
        } catch (err) {
          console.error('Error fetching job details:', err);
          setError('Failed to load job details. Please try again.');
          setIsLoading(false);
          setIsProcessingJobDetails(false);
        }
      }
    };

    fetchJobDetails();
  }, [isOpen, initialJob]);

  // Add the retry handler cleanup
  useEffect(() => {
    return () => {
      delete (window as any).retryJobProcessing;
    };
  }, []);

  const user = JSON.parse(String(localStorage.getItem("user")));

  const transformData = (data: any): ResumeText => {
    const transformDescriptions = (items: any[]) => items.map(item => ({
      ...item,
      description: item.description.map((desc: any) =>
        typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
      ),
    }));

    return {
      ...data,
      education: data.education.map((edu: any) => ({
        degree: edu.degree || '',
        field: edu.field || '',
        university: edu.university || '',
        graduationDate: edu.graduationDate || '',
        gpa: edu.gpa || ''
      })),
      experience: transformDescriptions(data.experience),
      projects: transformDescriptions(data.projects),
    };
  };

  const fetchResume = async () => {
    try {
      if (!user?._id || !job?._id) {
        throw new Error('Missing user or job data');
      }

      setIsLoading(true); // Add loading state

      // Try to get existing resume
      const resume = await axiosClient.get(`builder/get-resume/${user._id}/${job._id}`);
      
      if (resume?.data) {
        setResumeId(resume.data._id);
        setResumeText(transformData(resume.data));
      } else {
        // Fallback to user data if no resume exists
        const response = await axiosClient.get(`users/${user._id}`);
        if (response?.data) {
          setResumeText(transformData(response.data));
        } else {
          throw new Error('No user data found');
        }
      }
    } catch (error) {
      console.error("Error fetching resume:", error);
      setError(error instanceof Error ? error.message : 'Failed to fetch resume');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTailorButtonClick = () => {
    setShowResumeTab(true); // Show the resume tab
    setShowCoverLetterTab(true);
    setSelectedSection("resume");
    // Don't set hasResumeBeenTailored here as it should be set after API call completes
  };

  const handleNavigateToCoverLetter = () => {
    setSelectedSection("cover-letter");
  };

  if (!isOpen) return null;

  const tabsConfig = [
    {
      id: "job-details",
      label: "Job Details",
      icon: (
        <img
          src={JobDescriptionIcon}
          alt="Job Description Icon"
          width="16"
          height="16"
          className="tab-icon"
        />
      ),
    },
    ...(showResumeTab
      ? [
          {
            id: "resume",
            label: "Resume",
            icon: (
              <img
                src={aiIcon}
                alt="Resume Icon"
                width="16"
                height="16"
                className="tab-icon"
              />
            ),
          },
        ]
      : []),
    ...(showCoverLetterTab
      ? [
          {
            id: "cover-letter",
            label: "Cover Letter",
            icon: (
              <img
                src={aiIcon}
                alt="Cover Letter Icon"
                width="16"
                height="16"
                className="tab-icon"
              />
            ),
          },
        ]
      : []),
  ];

  // Simplified getPostedDate function
  const getPostedDate = (daysToLive: any) => {
    // Handle MongoDB $numberInt format or regular number
    const days = daysToLive?.$numberInt 
      ? parseInt(daysToLive.$numberInt) 
      : typeof daysToLive === 'number' 
        ? daysToLive 
        : null;

    if (days === null) {
      return "Recently Posted";
    }

    const totalDays = 15;
    const daysAgo = totalDays - days;

    switch (daysAgo) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        return `${daysAgo} days ago`;
    }
  };

  const getStatusClass = (daysToLive: any): string => {
    const days = daysToLive?.$numberInt 
      ? parseInt(daysToLive.$numberInt) 
      : typeof daysToLive === 'number' 
        ? daysToLive 
        : 0;

    if (days >= 10) return 'recent';
    if (days >= 5) return 'moderate';
    return 'old';
  };

  // Function to get all unique locations
  const getLocations = (location: string): string[] => {
    if (!location) return [];
    const locationsArray = location.split(';').map(loc => loc.trim());
    return Array.from(new Set(locationsArray));
  };

  // Function to get first location for header
  const getFirstLocation = (location: string): string => {
    if (!location) return '';
    return location.split(';')[0].trim();
  };

  const renderLocations = (locations: string[]) => {
    // Remove the first location since it's shown in the header
    const additionalLocations = locations.slice(1);
    if (additionalLocations.length === 0) return null;

    const visibleLocations = showAllLocations 
      ? additionalLocations 
      : additionalLocations.slice(0, 3);
    const hasMore = additionalLocations.length > 3;

    return (
      <div className="locations-section">
        <h4>Additional Locations</h4>
        <div className={`locations-list ${showAllLocations ? 'expanded' : ''}`}>
          {visibleLocations.map((location, index) => (
            <p key={index}>{location}</p>
          ))}
          {hasMore && !showAllLocations && (
            <p className="ellipsis">...</p>
          )}
          {hasMore && (
            <button 
              className="show-more-button"
              onClick={() => setShowAllLocations(!showAllLocations)}
            >
              {showAllLocations ? 'Show Less' : `Show ${additionalLocations.length - 3} More`}
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    // Combine both loading states 
    const isDataLoading = isLoading || isProcessingJobDetails;
    
    if (isDataLoading) {
      console.log("Showing skeleton loading UI");
      return (
        <div className="flex flex-col w-full skeleton-loading">
          {/* Job description section with skeleton loading */}
          <div className="job-description">
            <div className="content-container">
              <div className="left-column">
                {/* About the Job */}
                <div className="detail-section">
                  <h3 className="section-heading">About the Job:</h3>
                  <div className="skeleton-content">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                </div>
                
                {/* Requirements/Qualifications */}
                <div className="detail-section">
                  <h3 className="section-heading">Requirements:</h3>
                  <div className="skeleton-content">
                    <div className="skeleton-list">
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Responsibilities */}
                <div className="detail-section">
                  <h3 className="section-heading">Responsibilities:</h3>
                  <div className="skeleton-content">
                    <div className="skeleton-list">
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="skeleton-item">
                        <span className="bullet-point">•</span>
                        <div className="skeleton-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Skills */}
                <div className="detail-section">
                  <h3 className="section-heading">Skills:</h3>
                  <div className="skeleton-content">
                    <div className="skills skeleton-skills">
                      <div className="skeleton-skill"></div>
                      <div className="skeleton-skill"></div>
                      <div className="skeleton-skill"></div>
                      <div className="skeleton-skill"></div>
                      <div className="skeleton-skill"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="job-info-box">
                  <h4 className="section-heading">Posted <span className={`status-dot ${getStatusClass(job.days_to_live || initialJob.days_to_live)}`}></span></h4>
                  <div className="skeleton-content">
                    <div className="skeleton-data"></div>
                  </div>
                  
                  <h4 className="section-heading">Salary Range</h4>
                  <div className="skeleton-content">
                    <div className="skeleton-data"></div>
                  </div>
                  
                  <h4 className="section-heading">Experience Level</h4>
                  <div className="skeleton-content">
                    <div className="skeleton-data"></div>
                  </div>
                  
                  <h4 className="section-heading">Job Type</h4>
                  <div className="skeleton-content">
                    <div className="skeleton-data"></div>
                  </div>
                  
                  <h4 className="section-heading">Work Environment</h4>
                  <div className="skeleton-content">
                    <div className="skeleton-data"></div>
                  </div>
                  
                  <h4 className="section-heading about-company">About the Company</h4>
                  <div className="skeleton-content">
                    <div className="skeleton-data skeleton-text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-container">
          <p>{error}</p>
        </div>
      );
    }

    if (processingError) {
      return (
        <div className="error-container animate-subtle-pulse" onClick={() => (window as any).retryJobProcessing?.()}>
          <p>{processingError}</p>
        </div>
      );
    }

    const jobToDisplay = job;

    switch (selectedSection) {
      case "job-details":
        return (
          <div 
            className="flex flex-col w-full" 
            style={{ 
              transition: "opacity 0.3s ease",
              transform: "translateZ(0)", // Force GPU acceleration to reduce flickering
              backfaceVisibility: "hidden" // Prevent flickering during transitions
            }}
          >
            {/* Job description section */}
            <div className="job-description">
              <div className="content-container">
                <div className="left-column">
                  {/* About the Job */}
                  <div className="detail-section">
                    <h3 className="section-heading">About the Job:</h3>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p>{jobToDisplay.summary || "No description provided."}</p>
                    </div>
                  </div>
                  
                  {/* Requirements/Qualifications */}
                  <div className="detail-section">
                    <h3 className="section-heading">Requirements:</h3>
                    <div className="section-content" data-replacing-skeleton="true">
                      {jobToDisplay.qualifications && jobToDisplay.qualifications.length > 0 ? (
                        <ul className="requirements-list">
                          {jobToDisplay.qualifications.map((req, index) => (
                            <li key={index}>
                              <span className="bullet-point">•</span>
                              <span className="requirement-text">{req}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No requirements specified.</p>
                      )}
                    </div>
                  </div>
                  
                  {/* Responsibilities */}
                  <div className="detail-section">
                    <h3 className="section-heading">Responsibilities:</h3>
                    <div className="section-content" data-replacing-skeleton="true">
                      {jobToDisplay.responsibilities && jobToDisplay.responsibilities.length > 0 ? (
                        <ul className="responsibilities-list">
                          {jobToDisplay.responsibilities.map((resp, index) => (
                            <li key={index}>
                              <span className="bullet-point">•</span>
                              <span className="responsibility-text">{resp}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No responsibilities specified.</p>
                      )}
                    </div>
                  </div>
                  
                  {/* Skills */}
                  <div className="detail-section">
                    <h3 className="section-heading">Skills:</h3>
                    <div className="section-content" data-replacing-skeleton="true">
                      <div className="skills">
                        {jobToDisplay.skills ? (
                          Array.isArray(jobToDisplay.skills) ? (
                            jobToDisplay.skills.map((skill, index) => (
                              <span key={index} className="skill-badge">
                                {skill.replace(/^•\s*/, "").trim()}
                              </span>
                            ))
                          ) : (
                            jobToDisplay.skills.split(",").map((skill, index) => (
                              <span key={index} className="skill-badge">
                                {skill.trim()}
                              </span>
                            ))
                          )
                        ) : (
                          <p>No skills specified.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-column">
                  <div className="job-info-box">
                    <h4 className="section-heading">
                      Posted
                      <span className={`status-dot ${getStatusClass(jobToDisplay.days_to_live)}`} />
                    </h4>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p>{getPostedDate(jobToDisplay.days_to_live)}</p>
                    </div>
                    
                    {/* Salary Information */}
                    <h4 className="section-heading">Salary Range</h4>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p>{jobToDisplay.salary || "N/A"}</p>
                    </div>
                    
                    {/* Experience Level */}
                    <h4 className="section-heading">Experience Level</h4>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p>{jobToDisplay.experience_level || "Not specified"}</p>
                    </div>
                    
                    {/* Job Type - renamed from Employment Type */}
                    <h4 className="section-heading">Job Type</h4>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p>{normalizeJobTypeForDisplay(extractJobType(jobToDisplay))}</p>
                    </div>
                    
                    {/* Work Environment */}
                    <h4 className="section-heading">Work Environment</h4>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p>{jobToDisplay.workEnvironment || jobToDisplay.environment || "Not specified"}</p>
                    </div>
                    
                    {/* Locations */}
                    {renderLocations(getLocations(jobToDisplay.location))}
                    
                    {/* About Company */}
                    <h4 className="section-heading about-company">About the Company</h4>
                    <div className="section-content" data-replacing-skeleton="true">
                      <p className="about-company-text">
                        {jobToDisplay.company} is a leader in its industry providing
                        innovative solutions and services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "resume":
        return resumeText && (
          <ResumeComponent
            jobDescription={job}
            resumeText={resumeText}
            handleTailorButtonClick={handleTailorButtonClick}
            resumeId={resumeId}
            jobId={jobToDisplay._id}
            onNavigateToCoverLetter={handleNavigateToCoverLetter}
            hasBeenTailored={hasResumeBeenTailored}
            setHasBeenTailored={setHasResumeBeenTailored}
            cachedContent={cachedResumeContent}
            setCachedContent={setCachedResumeContent}
          />
        );
      case "cover-letter":
        return resumeText && (
          <CoverLetter 
            job={job} 
            resumeText={resumeText} 
            hasBeenTailored={hasCoverLetterBeenTailored}
            setHasBeenTailored={setHasCoverLetterBeenTailored}
            cachedContent={cachedCoverLetter}
            setCachedContent={setCachedCoverLetter}
          />
        );
      default:
        return null;
    }
  };

  // Modify tab switching to preserve tailoring state
  const handleTabChange = (tabId: string) => {
    setSelectedSection(tabId);
  };

  // Directly log job object to debug job_url
  console.log('DEBUG: Current job data in modal:', {
    _id: job._id,
    title: job.title,
    job_url: job.job_url
  });

  const handleExternalApply = (e: React.MouseEvent) => {
    e.preventDefault();
    
    // Get the application URL, ensuring it's not an empty string
    const applicationUrl = job.job_url && job.job_url.trim() !== '' ? job.job_url : null;
    
    console.log('Apply externally clicked - URL:', applicationUrl);
    
    if (applicationUrl) {
      // First check if we have resume content
      if (!resumeText) {
        console.error('No resume content available');
        toast.error('Resume content is required');
        return;
      }

      // Store the current state in localStorage to persist it
      const applicationData = {
        resumeContent: resumeText,
        coverLetterContent: cachedCoverLetter,
        jobId: job._id,
        resumeId: resumeId
      };

      localStorage.setItem('currentApplication', JSON.stringify(applicationData));

      // Set the modal data
      setModalData({ 
        showModal: true, 
        jobTitle: job.title, 
        companyName: job.company,
        jobId: job._id,
        resumeId: resumeId,
        resumeContent: resumeText,
        coverLetterContent: cachedCoverLetter
      });

      setHasLeftForExternalSite(true);
      window.open(addUtmParameters(applicationUrl), '_blank', 'noopener,noreferrer');
    } else {
      console.error('No job URL available:', job);
      toast.error('Application link not available for this job posting');
      
      // If we have the update job URL function, offer to update
      if (job._id) {
        const shouldUpdate = window.confirm('Would you like to provide an application URL for this job?');
        if (shouldUpdate) {
          handleUpdateJobUrl();
        }
      }
    }
  };

  const handleApplicationConfirm = (didApply: boolean) => {
    if (didApply) {
      // Here we'll later add the logic to track the application
    }
    setShowApplicationConfirm(false);
  };

  // Add a function to update job URL if missing
  const handleUpdateJobUrl = async () => {
    const newUrl = prompt("Enter the correct job application URL:", "");
    if (!newUrl) return;
    
    try {
      console.log(`Updating job URL for ${job._id} to ${newUrl}`);
      const response = await axiosClient.post(`/jobs/${job._id}/update-url`, {
        job_url: newUrl
      });
      
      if (response.data) {
        // Update the job object locally with the new URL
        setJob(prev => ({
          ...prev,
          job_url: newUrl
        }));
        
        toast.success('Job URL updated successfully');
      }
    } catch (error) {
      console.error('Error updating job URL:', error);
      toast.error('Failed to update job URL');
    }
  };

  return createPortal(
    <div className={`modal-backdrop ${isOpen ? "show" : ""}`}>
      <div className={`modal-content ${isOpen ? "show" : ""}`}>
        <div className="sticky-container">
          <div className="modal-header">
            <div className="tabs">
              {tabsConfig.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab ${selectedSection === tab.id ? "selected" : ""}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <span className="tab-icon">{tab.icon}</span>
                  <span>{tab.label}</span>
                </div>
              ))}
            </div>
            <button className="close-button" onClick={handleClose}>
              ×
            </button>
          </div>
          <div className={`header-section ${hasAnimated ? 'animate' : ''} ${selectedSection === "job-details" ? 'visible' : ''}`}>
            <div className="job-title-section">
              <div className="top-row">
                <div className={`company-logo-wrapper ${logoVariant === 'horizontal' ? 'horizontal-logo-container' : ''}`}>
                  {logoUrl ? (
                    <img
                      src={logoUrl}
                      alt={`${job.company} logo`}
                      className={`company-logo ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
                    />
                  ) : (
                    <div className="logo-placeholder" />
                  )}
                </div>
                {/* Always show job type tag with extracted value */}
                <span
                  className={`job-type-tag ${getJobTypeClass(extractJobType(job))}`}
                >
                  {formatJobType(extractJobType(job))}
                </span>
              </div>
              <div className="bottom-row">
                <div className="job-title">
                  <h2>{job.title}</h2>
                  <p className="company-location">
                    {job.company} • {getFirstLocation(job.location)}
                  </p>
                </div>
              </div>
            </div>
            <div className="button-group">
              <a
                href="#"
                onClick={handleExternalApply}
                className="external-link"
              >
                Apply Externally <svg className="external-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 3V9H19V6.414L11.207 14.207L9.793 12.793L17.586 5H15V3H21Z" fill="currentColor"/>
                      <path d="M19 19H5V5H12V3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V12H19V19Z" fill="currentColor"/>
                  </svg>
              </a>
              <button
                className="tailor-button"
                onClick={handleTailorButtonClick}
              >
                Tailor My Resume
              </button>
            </div>
          </div>
          {selectedSection === "job-details" && <hr className="separator-line" />}
        </div>
        <div className="modal-body">
          {renderContent()}
        </div>
      </div>
    </div>,
    document.getElementById('job-details-modal-root')!
  );
};

export default JobDetailsModal;