import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./SubscriptionPlans.scss";
import { FaCheck, FaGift } from 'react-icons/fa';
import { HiOutlineCheck } from 'react-icons/hi';
import Sidebar from '../../components/Sidebar';
import axiosClient from '../../axios-client';
import { mainUrl, stripPublishingKey } from '../../constant';
import { toast } from 'react-hot-toast';
import { loadStripe } from "@stripe/stripe-js";
import { CreditCard, Check, Zap } from "lucide-react";
import { stripeService } from '../../services/stripe.service';

// Initialize Stripe
const stripePromise = loadStripe(stripPublishingKey || "");

const plans = [
  {
    name: "Basic",
    price: "$9",
    period: "/month",
    features: [
      "Up to 10 job applications",
      "Basic tracking features",
      "Email notifications",
    ],
    icon: CreditCard,
    popular: false,
    priceId: undefined, // Basic plan is free
  },
  {
    name: "Pro",
    price: "$19",
    period: "/month",
    features: [
      "Unlimited applications",
      "Advanced analytics",
      "Priority support",
      "Custom notes",
    ],
    icon: Zap,
    popular: true,
    priceIds: {
      monthly: "price_1QshtDDCMUgWP2cVxnCi5mLV",
      quarterly: "price_1Qss4LDCMUgWP2cVQIqxiLSL"
    }
  }
];

const SubscriptionPlans = () => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'quarterly'>('quarterly');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [syncingCustomer, setSyncingCustomer] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<any>(null);
  const [portalUrl, setPortalUrl] = useState<string | null>(null);
  const [userBillingCycle, setUserBillingCycle] = useState<'monthly' | 'quarterly' | null>(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const pricingData = {
    free: {
      monthly: 0,
      quarterly: 0,
    },
    pro: {
      monthly: 20,
      quarterly: 18, // $18/month when billed quarterly
    }
  };

  const getPriceDisplay = (plan: 'free' | 'pro') => {
    if (plan === 'free') {
      return {
        amount: 0,
        period: '/month',
        billing: 'Free forever'
      };
    }
    
    if (billingCycle === 'monthly') {
      return {
        amount: pricingData[plan].monthly,
        period: '/month',
        billing: 'Billed monthly'
      };
    } else {
      return {
        amount: pricingData[plan].quarterly,
        period: '/month',
        billing: 'Billed $54 every 3 months'
      };
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get('session_id');

    if (sessionId) {
      verifySubscription(sessionId);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    // Listen for sidebar expansion changes
    const handleSidebarChange = (e: CustomEvent) => {
      setIsSidebarExpanded(e.detail.expanded);
    };

    window.addEventListener('sidebarStateChange' as any, handleSidebarChange);

    // Check initial sidebar state
    const sidebarState = localStorage.getItem('sidebarExpanded');
    setIsSidebarExpanded(sidebarState === 'true');

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('sidebarStateChange' as any, handleSidebarChange);
    };
  }, []);

  const verifySubscription = async (sessionId: string) => {
    try {
      setIsLoading(true);
      const { data } = await axiosClient.get(`/stripe/checkout-sessions/${sessionId}/verify`);
      
      if (data.subscription) {
        setIsSubscribed(true);
        setCurrentPlan(data.subscription.planId);
        
        // Set the user's billing cycle
        setUserBillingCycle(data.subscription.metadata?.isQuarterly === 'true' ? 'quarterly' : 'monthly');
        
        // Update user in localStorage
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          user.subscriptionStatus = data.status;
          user.currentSubscription = data.subscription._id;
          user.billingCycle = data.subscription.metadata?.isQuarterly === 'true' ? 'quarterly' : 'monthly';
          localStorage.setItem('user', JSON.stringify(user));
        }

        toast.success('Subscription activated successfully!');
      }
    } catch (err) {
      console.error('Subscription verification failed:', err);
      toast.error('Failed to verify subscription. Please contact support if this persists.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const userData = localStorage.getItem("user");
      if (userData) {
        const parsedUser = JSON.parse(userData);
        setUser(parsedUser);
        
        if (parsedUser.stripeCustomerId) {
          // Force verify subscription status
          const { data } = await axiosClient.get(`/stripe/subscriptions/${parsedUser.stripeCustomerId}`);
          
          const hasActiveSubscription = data.subscription && 
            ['active', 'trialing'].includes(data.subscription.status);
          
          setIsSubscribed(hasActiveSubscription);
          setCurrentPlan(data.subscription?.planId);

          if (hasActiveSubscription) {
            // Set the user's billing cycle
            setUserBillingCycle(data.subscription.metadata?.isQuarterly === 'true' ? 'quarterly' : 'monthly');
            
            // Get fresh portal URL for managing subscription
            try {
              const portalResponse = await axiosClient.get('/stripe/portal-session', {
                params: {
                  return_url: window.location.href // Return to the same page after portal actions
                }
              });
              setPortalUrl(portalResponse.data.url);
            } catch (portalError) {
              console.error('Failed to get portal URL:', portalError);
              toast.error('Unable to access subscription management. Please try again.');
            }
          }
        } else {
          setIsSubscribed(false);
          setUserBillingCycle(null);
        }
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch subscription data");
    }
  };

  const handleSubscribe = async (plan: typeof plans[0]) => {
    try {
      // If it's the free plan, no need to go through Stripe
      if (!plan.priceIds) {
        // Handle free plan subscription
        await axiosClient.post('/auth/subscribe-free');
        await fetchData(); // Refresh data
        toast.success('Successfully subscribed to Free plan');
        return;
      }

      setSubscriptionLoading(true);
      setError(null);

      if (!user?.stripeCustomerId) {
        // Show syncing state
        setSyncingCustomer(true);
        // Sync customer ID if missing
        await axiosClient.post('/auth/sync-stripe');
        const { data: updatedUser } = await axiosClient.get('/auth/me');
        setUser(updatedUser);
        setSyncingCustomer(false);
        
        if (!updatedUser.stripeCustomerId) {
          throw new Error('Payment system not ready - please try again in 10 seconds');
        }
      }

      // Get the correct price ID based on billing cycle
      const priceId = plan.priceIds[billingCycle];

      // Create subscription directly (this will return a checkout session)
      const { data } = await axiosClient.post('/stripe/subscriptions', {
        priceId
      });

      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe not initialized');
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId
      });

      if (error) {
        throw error;
      }
      
    } catch (err: any) {
      setError(err.message || 'Payment initialization failed');
      toast.error(err.message || 'Could not start payment process');
    } finally {
      setSubscriptionLoading(false);
      setSyncingCustomer(false);
    }
  };

  // Add a function to handle portal navigation
  const handleManageSubscription = async () => {
    try {
      setIsLoading(true);
      console.log('Creating portal session...');
      
      // Get the base URL based on environment
      const baseUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001'
        : 'https://www.quickcruit.ai';
      
      // Get a fresh portal URL each time to ensure it's not expired
      const { data } = await axiosClient.get('/stripe/portal-session', {
        params: {
          return_url: `${baseUrl}/subscription`
        }
      });
      
      console.log('Portal session response:', data);
      
      // Open portal in same tab
      if (data?.url) {
        console.log('Redirecting to portal URL:', data.url);
        window.location.href = data.url;
      } else {
        console.error('No portal URL received:', data);
        toast.error('Unable to access subscription management. Please try again.');
      }
    } catch (error: any) {
      console.error('Failed to access subscription management:', error);
      const errorMessage = error?.response?.data?.message 
        || error?.message 
        || 'Unable to access subscription management. Please try again.';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const renderSubscriptionButton = (plan: typeof plans[0]) => {
    const isPro = plan.name === 'Pro';
    const isCurrentPlan = (isPro && isSubscribed) || (!isPro && !isSubscribed);
    const isCurrentBillingCycle = userBillingCycle === billingCycle;

    if (isCurrentPlan && isCurrentBillingCycle) {
      return (
        <>
          <button 
            className="signup-button disabled"
            disabled={true}
          >
            Your Current Plan
          </button>
          {isPro && isSubscribed && (
            <a 
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleManageSubscription();
              }}
              className="manage-subscription-link"
            >
              Manage my subscription
            </a>
          )}
        </>
      );
    }

    if (!isCurrentBillingCycle && isPro && isSubscribed) {
      return (
        <>
          <button 
            onClick={() => handleSubscribe(plan)}
            className={`signup-button ${isPro ? 'primary' : 'secondary'}`}
          >
            Switch to This Plan
          </button>
          <span className="placeholder-text"></span>
        </>
      );
    }

    if (!user) {
      return (
        <>
          <Link to="/auth/signup" className={`signup-button ${isPro ? 'primary' : 'secondary'}`}>
            {isPro ? 'Continue with Pro' : 'Get Started'}
          </Link>
          <span className="placeholder-text"></span>
        </>
      );
    }

    return (
      <>
        <button 
          onClick={() => handleSubscribe(plan)}
          className={`signup-button ${isPro ? 'primary' : 'secondary'}`}
          disabled={subscriptionLoading || syncingCustomer}
          title={
            syncingCustomer 
              ? "Setting up your payment account..." 
              : !user?.stripeCustomerId 
              ? "Finalizing your account setup..." 
              : undefined
          }
        >
          {subscriptionLoading ? 'Processing...' : 
           syncingCustomer ? 'Setting up...' :
           isPro ? `Upgrade to Pro` : 'Get Started'}
        </button>
        <span className="placeholder-text"></span>
      </>
    );
  };

  return (
    <>
      <Sidebar />
      <div className="pricing-container-wrapper">
        <div className="pricing-content">
          <div className="pricing-page-second">
            <div className="pricing-container">
              <h1>Make every application count</h1>
              <p className="description">From smart job matching to AI-powered applications - tools that get you noticed</p>

              <div className="billing-toggle" data-active={billingCycle}>
                <button 
                  className={`toggle-btn ${billingCycle === 'monthly' ? 'active' : ''}`}
                  onClick={() => setBillingCycle('monthly')}
                >
                  Monthly
                </button>
                <button 
                  className={`toggle-btn ${billingCycle === 'quarterly' ? 'active' : ''}`}
                  onClick={() => setBillingCycle('quarterly')}
                >
                  Quarterly
                </button>
              </div>

              <div className="pricing-cards">
                {/* Free Plan */}
                <div className="pricing-card">
                  <div className="card-header">
                    <h2 className="pricing-tier-title">Free</h2>
                    <p className="description">Start your job search journey with essential tools and features.</p>
                    <div className="price">
                      <span className="amount">${getPriceDisplay('free').amount}</span>
                      <span className="period">{getPriceDisplay('free').period}</span>
                    </div>
                    <div className="billing-info">{getPriceDisplay('free').billing}</div>
                    <div className="button-container">
                      {renderSubscriptionButton(plans[0])}
                    </div>
                  </div>
                  <ul className="features">
                    <li>
                      <HiOutlineCheck className="check-icon" />
                      Tailored Job Matches
                    </li>
                    <li>
                      <HiOutlineCheck className="check-icon" />
                      Job Tracking 
                    </li>
                    <li>
                      <HiOutlineCheck className="check-icon" />
                      Shareable Portfolio
                    </li>
                  </ul>
                </div>

                {/* Pro Plan */}
                <div className="pricing-card pro">
                  <div className="card-header">
                    <h2 className="pricing-tier-title">Pro</h2>
                    <p className="description">Unlock AI-powered tools to maximize your job search success.</p>
                    <div className="price">
                      <span className="amount">${getPriceDisplay('pro').amount}</span>
                      <span className="period">{getPriceDisplay('pro').period}</span>
                    </div>
                    <div className="billing-info">{getPriceDisplay('pro').billing}</div>
                    <div className="button-container">
                      {renderSubscriptionButton(plans[1])}
                    </div>
                  </div>
                  <ul className="features">
                    {/* <li>
                      <FaGift className="check-icon gift" />
                      7-day free trial included
                    </li> */}
                    <li>
                      <HiOutlineCheck className="check-icon" />
                      Everything in Free
                    </li>
                    <li>
                      <HiOutlineCheck className="check-icon" />
                      AI Resume Tailoring
                    </li>
                    <li>
                      <HiOutlineCheck className="check-icon" />
                      AI Cover Letter Generator
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlans;
