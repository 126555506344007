import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import JobTile from "./JobTile";
import {
  FaAngleDown,
  FaAngleUp,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import jobTitles from "../jobs_titles.json";
import Sidebar from "./Sidebar";
import "../styles/components/jobSearch.scss";
import { fetchMatchedJobs } from "../jobService";
import ReactPaginate from "react-paginate";
import { User } from "firebase/auth";
import JobDetailsModal, { JobData } from "./JobDetailsModal";
import {
  JobButtonContext,
  normalizeExperienceLevel,
  experienceLevelOptions,
  JOB_CATEGORIES,
  type JobSubcategory
} from "../constant";
import { AiOutlineClose, AiOutlineFilter } from "react-icons/ai";
import { getApplications } from '../services/applicationService';
import { FiMenu } from 'react-icons/fi';

// Define types for better type safety
interface JobHub {
  value: string;
  label: string;
}

interface ExperienceLevel {
  value: string;
  label: string;
}

interface Job {
  _id: string;
  title: string;
  company: string;
  location: string;
  experience_level: string;
  description?: string;
  requirements?: string[];
  skills?: string[];
  matchScore?: number;
  industry?: string;
  jobType?: string;
  categories?: string[];
  days_to_live?: any;
  [key: string]: any;
}

interface GroupedSubcategories {
  [key: string]: JobSubcategory[];
}

interface JobCache {
  jobs: Job[];
  timestamp: number;
  filters: {
    locations: string[];
    experienceLevels: string[];
    industries: string[];
  };
}

const defaultPreferences = {
  locations: ["open_to_all"],
  experienceLevel: ["ENTRY_LEVEL"],
  industries: Object.keys(jobTitles).map((key) => ({ value: key, label: key })),
};

interface FilterDropdownProps {
  options: (JobHub | ExperienceLevel | JobSubcategory)[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  title: string;
  type: string;
  loading: boolean;
  activeDropdown: string | null;
  setActiveDropdown: (type: string | null) => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ 
  options, 
  selectedValues, 
  setSelectedValues, 
  title, 
  type, 
  loading,
  activeDropdown,
  setActiveDropdown
}) => {
  const isOpen = activeDropdown === type;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (loading) return;
    setActiveDropdown(isOpen ? null : type);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!isOpen) return;
      const target = e.target as HTMLElement;
      if (!target.closest('.dropdown')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, setActiveDropdown]);

  const isCategory = type === 'industries';

  // Group subcategories by parent category
  const groupedOptions = isCategory ? 
    (options as JobSubcategory[]).reduce((acc: GroupedSubcategories, option: JobSubcategory) => {
      if (!acc[option.parentCategory]) {
        acc[option.parentCategory] = [];
      }
      acc[option.parentCategory].push(option);
      return acc;
    }, {}) : {};

  return (
    <div 
      className={`dropdown ${isOpen ? 'active' : ''}`} 
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="dropdown-button"
        onClick={handleToggle}
        disabled={loading}
        style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
      >
        {title}
        {selectedValues.length > 0 && (
          <span className="selected-count">{selectedValues.length}</span>
        )}
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </button>
      <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
        {isCategory ? (
          // Render hierarchical categories
          Object.entries(groupedOptions).map(([categoryName, subcategories]) => (
            <div key={categoryName} className="category-group">
              <div className="checkbox-item category-parent">
                <div className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={subcategories.every(sub => selectedValues.includes(sub.value))}
                    ref={input => {
                      if (input) {
                        input.indeterminate = subcategories.some(sub => selectedValues.includes(sub.value)) &&
                          !subcategories.every(sub => selectedValues.includes(sub.value));
                      }
                    }}
                    onChange={() => {
                      const subValues = subcategories.map(sub => sub.value);
                      const allSelected = subcategories.every(sub => selectedValues.includes(sub.value));
                      if (allSelected) {
                        setSelectedValues(selectedValues.filter(v => !subValues.includes(v)));
                      } else {
                        const newValues = [...selectedValues];
                        subValues.forEach(value => {
                          if (!newValues.includes(value)) {
                            newValues.push(value);
                          }
                        });
                        setSelectedValues(newValues);
                      }
                    }}
                    id={`${type}-${categoryName}`}
                  />
                  <label htmlFor={`${type}-${categoryName}`}>{categoryName}</label>
                </div>
              </div>
              <div className="subcategories">
                {subcategories.map((subcategory) => (
                  <div key={subcategory.value} className="checkbox-item subcategory">
                    <div className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(subcategory.value)}
                        onChange={() => {
                          const newValues = selectedValues.includes(subcategory.value)
                            ? selectedValues.filter((v) => v !== subcategory.value)
                            : [...selectedValues, subcategory.value];
                          setSelectedValues(newValues);
                        }}
                        id={`${type}-${subcategory.value}`}
                      />
                      <label htmlFor={`${type}-${subcategory.value}`}>{subcategory.label}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          // Render regular options
          (options as (JobHub | ExperienceLevel)[]).map((option) => (
            <div key={option.value} className="checkbox-item">
              <div className="checkbox-label">
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option.value)}
                  onChange={() => {
                    const newValues = selectedValues.includes(option.value)
                      ? selectedValues.filter((v) => v !== option.value)
                      : [...selectedValues, option.value];
                    setSelectedValues(newValues);
                  }}
                  id={`${type}-${option.value}`}
                />
                <label htmlFor={`${type}-${option.value}`}>{option.label}</label>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const JobSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedExperienceLevels, setSelectedExperienceLevels] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [error, setError] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const dropdownRefs = useRef<{ [key: string]: any }>({});
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [selection, setSelection] = useState<JobData | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const itemsPerPage = 54;
  const [availableJobHubs, setAvailableJobHubs] = useState<JobHub[]>([]);
  const [availableExperienceLevels, setAvailableExperienceLevels] = useState<ExperienceLevel[]>(experienceLevelOptions);
  const [availableCategories, setAvailableCategories] = useState<JobHub[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [tempSelectedLocations, setTempSelectedLocations] = useState<string[]>([]);
  const [tempSelectedExperienceLevels, setTempSelectedExperienceLevels] = useState<string[]>([]);
  const [tempSelectedIndustries, setTempSelectedIndustries] = useState<string[]>([]);
  const [originalMatchedJobs, setOriginalMatchedJobs] = useState<Job[]>([]);
  const [appliedJobs, setAppliedJobs] = useState<Set<string>>(new Set());
  const [fullCategorySet, setFullCategorySet] = useState<Set<string>>(new Set());
  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({});
  const [preferencesSaved, setPreferencesSaved] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [allJobsCache, setAllJobsCache] = useState<JobCache | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Separate URL page state from data fetching
  const [currentPageData, setCurrentPageData] = useState<{
    page: number;
    jobs: Job[];
  }>({
    page: parseInt(searchParams.get("page") || "1", 10),
    jobs: []
  });

  const handleJobsResponse = useCallback((response: any) => {
    const isBackgroundLoad = response.jobs.length > 270;
    
    // Debug: Log a sample job to check fields
    if (response.jobs && response.jobs.length > 0) {
      console.log("API JOB SAMPLE WITH JOB_URL:", {
        _id: response.jobs[0]._id,
        title: response.jobs[0].title,
        job_url: response.jobs[0].job_url,  // specifically log job_url
        has_job_url: !!response.jobs[0].job_url
      });
    }
    
    if (isBackgroundLoad) {
      // For background load, update the full dataset
      setOriginalMatchedJobs(response.jobs as Job[]);
      
      // Update total counts based on full dataset
      setTotalResults(response.jobs.length);
      setTotalPages(Math.ceil(response.jobs.length / itemsPerPage));
    } else {
      // For initial load, set the first page of results
      const initialJobs = response.jobs as Job[];
      setOriginalMatchedJobs(initialJobs);
      
      // Set initial pagination state
      setTotalResults(response.totalJobs);
      setTotalPages(Math.ceil(response.totalJobs / itemsPerPage));
      
      // Set current page jobs
      const page = parseInt(searchParams.get("page") || "1", 10);
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setJobResults(initialJobs.slice(startIndex, endIndex));
    }
    
    // Process all jobs for filter options
    console.log(`Processing categories for ${response.jobs.length} jobs (${isBackgroundLoad ? 'background' : 'initial'} load)`);
    
    // Step 1: Collect all unique categories from jobs
    const matchedCategories = new Set<string>();
    response.jobs.forEach((job: any) => {
      if (job.categories && Array.isArray(job.categories)) {
        job.categories.forEach((category: string) => matchedCategories.add(category));
      }
    });

    // Update full category set if this is a background load
    if (isBackgroundLoad) {
      setFullCategorySet(matchedCategories);
    }
    
    // Collect all unique locations
    const uniqueLocations = Array.from(
      new Set(response.jobs.map((job: any) => job.location))
    )
      .filter(Boolean)
      .map((location: any) => ({
        value: location.toLowerCase().replace(/\s+/g, "_"),
        label: location,
      }));

    // Merge with existing locations if it's a background load
    if (isBackgroundLoad) {
      setAvailableJobHubs(prevHubs => {
        const existingValues = new Set(prevHubs.map(hub => hub.value));
        const newHubs = uniqueLocations.filter(hub => !existingValues.has(hub.value));
        return [...prevHubs, ...newHubs];
      });
    } else {
      setAvailableJobHubs([
        ...uniqueLocations,
        { value: "open_to_all", label: "Open to All Locations" },
      ]);
    }

    // Use the appropriate category set
    const categoriesToUse = isBackgroundLoad ? 
      matchedCategories : 
      new Set([
        ...Array.from(matchedCategories),
        ...Array.from(fullCategorySet)
      ]);
    
    console.log('Categories in current job set:', Array.from(categoriesToUse));

    // Step 2: Create a map of parent categories to their matched subcategories
    const parentToMatchedSubs = new Map<string, Set<string>>();
    
    // Initialize the map with empty sets for each parent category
    JOB_CATEGORIES.categories.forEach(category => {
      parentToMatchedSubs.set(category.name, new Set<string>());
    });

    // Fill the map with matched subcategories
    categoriesToUse.forEach(matchedCategory => {
      const parentCategory = JOB_CATEGORIES.categories.find(category =>
        category.subcategories.includes(matchedCategory)
      );
      
      if (parentCategory) {
        const existingSet = parentToMatchedSubs.get(parentCategory.name);
        if (existingSet) {
          existingSet.add(matchedCategory);
        }
      }
    });

    // Step 3: Convert to dropdown options format
    const categoryOptions: JobSubcategory[] = [];
    parentToMatchedSubs.forEach((matchedSubs, parentName) => {
      if (matchedSubs.size > 0) {
        Array.from(matchedSubs).forEach(sub => {
          const hasMatchingJobs = response.jobs.some((job: any) => 
            job.categories?.includes(sub)
          );
          
          if (hasMatchingJobs) {
            categoryOptions.push({
              value: sub.toLowerCase().replace(/[^a-z0-9]+/g, '_'),
              label: sub,
              parentCategory: parentName
            });
          }
        });
      }
    });

    // Remove any parent categories that ended up with no subcategories
    const finalCategoryOptions = categoryOptions.filter(option => {
      const parentHasSubcategories = categoryOptions.some(
        opt => opt.parentCategory === option.parentCategory
      );
      return parentHasSubcategories;
    });

    // Sort categories
    finalCategoryOptions.sort((a, b) => {
      const categoryOrder = [
        "Technology",
        "Data & Analytics",
        "Business",
        "Finance",
        "Engineering",
        "Healthcare",
        "Sales",
        "Marketing",
        "Design",
        "Legal"
      ];
      
      const indexA = categoryOrder.indexOf(a.parentCategory);
      const indexB = categoryOrder.indexOf(b.parentCategory);
      
      if (indexA !== indexB) {
        return indexA - indexB;
      }
      
      return a.label.localeCompare(b.label);
    });

    // If it's a background load, merge with existing categories
    if (isBackgroundLoad) {
      setAvailableCategories(prevCategories => {
        const existingValues = new Set(prevCategories.map(cat => cat.value));
        const newCategories = finalCategoryOptions.filter(cat => !existingValues.has(cat.value));
        return [...prevCategories, ...newCategories];
      });
    } else {
      setAvailableCategories(finalCategoryOptions);
    }

    // Update experience levels
    const uniqueExpLevels = new Set<string>();
    response.jobs.forEach((job: any) => {
      const level = normalizeExperienceLevel(job.experience_level);
      if (level) {
        uniqueExpLevels.add(level);
      }
    });

    if (isBackgroundLoad) {
      setAvailableExperienceLevels(prevLevels => {
        const existingValues = new Set(prevLevels.map(level => level.value));
        const newLevels = Array.from(uniqueExpLevels)
          .filter(level => !existingValues.has(level))
          .map(level => ({
            value: level as string,
            label: experienceLevelOptions.find(opt => opt.value === level)?.label || level
          })) as ExperienceLevel[];
        return [...prevLevels, ...newLevels];
      });
    } else {
      setAvailableExperienceLevels(
        Array.from(uniqueExpLevels).map(level => ({
          value: level as string,
          label: experienceLevelOptions.find(opt => opt.value === level)?.label || level
        })) as ExperienceLevel[]
      );
    }
  }, [searchParams, itemsPerPage]);

  // Modified job fetching logic
  useEffect(() => {
    const fetchAndFilterJobs = async () => {
      // Only show loading on initial or filter changes
      const shouldShowLoading = isInitialLoad || 
        !allJobsCache || 
        JSON.stringify([selectedLocations, selectedExperienceLevels, selectedIndustries]) !== 
        JSON.stringify([allJobsCache.filters.locations, allJobsCache.filters.experienceLevels, allJobsCache.filters.industries]);

      if (shouldShowLoading) {
        setLoading(true);
      }

      try {
        // Check if we can use cached data
        const canUseCachedData = allJobsCache && 
          JSON.stringify([selectedLocations, selectedExperienceLevels, selectedIndustries]) === 
          JSON.stringify([allJobsCache.filters.locations, allJobsCache.filters.experienceLevels, allJobsCache.filters.industries]) &&
          Date.now() - allJobsCache.timestamp < 5 * 60 * 1000; // Cache valid for 5 minutes

        if (canUseCachedData && allJobsCache) {
          // Filter cached data
          let filteredJobs = [...allJobsCache.jobs];
          
          // Apply location filter
          if (selectedLocations.length > 0) {
            filteredJobs = filteredJobs.filter(job => 
              selectedLocations.includes(job.location?.toLowerCase().replace(/\s+/g, "_"))
            );
          }
          
          // Apply experience level filter
          if (selectedExperienceLevels.length > 0) {
            filteredJobs = filteredJobs.filter(job => 
              selectedExperienceLevels.includes(normalizeExperienceLevel(job.experience_level))
            );
          }
          
          // Apply category filter
          if (selectedIndustries.length > 0) {
            filteredJobs = filteredJobs.filter(job => {
              if (!job.categories || !Array.isArray(job.categories)) return false;
              const normalizedJobCategories = job.categories.map(category => 
                category.toLowerCase().replace(/[^a-z0-9]+/g, '_')
              );
              return selectedIndustries.some(selectedCategory => 
                normalizedJobCategories.includes(selectedCategory)
              );
            });
          }

          console.log(`Filtered jobs: ${filteredJobs.length} (from ${allJobsCache.jobs.length})`);
          
          // Update results
          setOriginalMatchedJobs(filteredJobs);
          const page = parseInt(searchParams.get("page") || "1", 10);
          const startIndex = (page - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          
          setTotalResults(filteredJobs.length);
          setTotalPages(Math.ceil(filteredJobs.length / itemsPerPage));
          setJobResults(filteredJobs.slice(startIndex, endIndex));
          
          setLoading(false);
          return;
        }

        // Initial fast load for immediate display
        console.log('Making initial fast load request...');
        const response = await fetchMatchedJobs({
          page: 1,
          limit: 270,
          locations: selectedLocations,
          jobTypes: selectedExperienceLevels,
          industries: selectedIndustries,
        });

        console.log('Initial response:', response);
        handleJobsResponse(response);
        
        // If there are more results, fetch them in the background
        if (response.hasMoreResults) {
          console.log('Has more results, fetching in background...');
          const fullResponse = await fetchMatchedJobs({
            page: 1,
            limit: 10000,
            locations: selectedLocations,
            jobTypes: selectedExperienceLevels,
            industries: selectedIndustries,
            isBackgroundLoad: true
          });

          // Transform the response jobs to match our Job type
          const typedJobs: Job[] = fullResponse.jobs.map((job: any) => ({
            _id: job._id,
            title: job.title,
            company: job.company,
            location: job.location || '',
            experience_level: job.experience_level || '',
            description: job.description || '',
            requirements: job.requirements || [],
            skills: job.skills || [],
            matchScore: job.matchScore,
            industry: job.industry || '',
            jobType: job.jobType || '',
            categories: job.categories || [],
            days_to_live: job.days_to_live,
            ...job
          }));

          // Cache the full response
          const newCache: JobCache = {
            jobs: typedJobs,
            timestamp: Date.now(),
            filters: {
              locations: selectedLocations,
              experienceLevels: selectedExperienceLevels,
              industries: selectedIndustries
            }
          };
          setAllJobsCache(newCache);
          
          // Update current page from new cache
          const page = parseInt(searchParams.get("page") || "1", 10);
          const startIndex = (page - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          
          setTotalResults(typedJobs.length);
          setTotalPages(Math.ceil(typedJobs.length / itemsPerPage));
          setJobResults(typedJobs.slice(startIndex, endIndex));
          setOriginalMatchedJobs(typedJobs);
        }

      } catch (error) {
        console.error('Error fetching jobs:', error);
        setError('Failed to fetch jobs. Please try again.');
      } finally {
        setLoading(false);
        setIsInitialLoad(false);
      }
    };

    fetchAndFilterJobs();
  }, [
    selectedLocations, 
    selectedExperienceLevels, 
    selectedIndustries, 
    allJobsCache, 
    isInitialLoad, 
    itemsPerPage, 
    searchParams,
    handleJobsResponse
  ]);

  // Modified pagination handler
  const handlePageClick = (data: { selected: number }) => {
    const newPage = data.selected + 1;
    
    if (allJobsCache) {
      const startIndex = (newPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      
      // Get the correct slice of jobs for the new page
      const pageJobs = allJobsCache.jobs.slice(startIndex, endIndex);
      
      // Update URL first
      searchParams.set("page", newPage.toString());
      setSearchParams(searchParams);
      
      // Then update the displayed jobs
      setJobResults(pageJobs);
      setCurrentPageData(prevData => ({
        page: newPage,
        jobs: pageJobs
      }));
      
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  };

  // Modified effect for URL page changes
  useEffect(() => {
    if (allJobsCache) {
      const currentPage = parseInt(searchParams.get("page") || "1", 10);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      
      // Get the correct slice of jobs for the current page
      const pageJobs = allJobsCache.jobs.slice(startIndex, endIndex);
      
      setJobResults(pageJobs);
      setCurrentPageData({
        page: currentPage,
        jobs: pageJobs
      });
    }
  }, [searchParams, allJobsCache, itemsPerPage]);

  // Clear cache when filters change
  useEffect(() => {
    setAllJobsCache(null);
    setIsInitialLoad(true);
  }, [selectedLocations, selectedExperienceLevels, selectedIndustries]);

  // Clear cache on component mount or refresh
  useEffect(() => {
    const clearCache = () => {
      setAllJobsCache(null);
      setIsInitialLoad(true);
    };

    // Clear immediately on mount
    clearCache();

    // Also clear on window focus (handles refresh)
    window.addEventListener('focus', clearCache);
    return () => window.removeEventListener('focus', clearCache);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem("user");
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate("/auth/login");
          }
        } else {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data. Please check your permissions.");
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRefs.current) return;

      const clickedOutside = Object.values(dropdownRefs.current).every(
        (ref) => !ref?.contains(event.target as Node)
      );

      if (clickedOutside) {
        setDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const updatePreferences = useCallback(async (
    currentUser: User | null,
    key: string,
    updated: string[]
  ) => {
    if (!currentUser) return;

    try {
      const preferences = {
        ...defaultPreferences,
        [key]:
          updated.length > 0
            ? updated
            : defaultPreferences[key as keyof typeof defaultPreferences],
        locations: key === "locations" ? updated : selectedLocations,
        experienceLevel: key === "experienceLevel" ? updated[0] : selectedExperienceLevels[0],
        industries: key === "industries" ? updated : selectedIndustries,
      };

      await setDoc(
        doc(db, "users", currentUser.uid),
        { preferences },
        { merge: true }
      );

      setPreferencesSaved(true);
    } catch (error) {
      console.error("Error saving preferences:", error);
      setError("Failed to save preferences.");
    }
  }, [selectedLocations, selectedExperienceLevels, selectedIndustries]);

  const clearFilters = async () => {
    setIsClearing(true); // Set clearing state to true
    setLoading(true);
    
    // Clear all filters
    setSelectedLocations([]);
    setSelectedExperienceLevels([]);
    setSelectedIndustries([]);
    
    // Reset pagination
    setSearchParams({ page: "1" });
    
    try {
      // Fetch all jobs without filters
      const response = await fetchMatchedJobs({
        page: 1,
        limit: 270
      });
      
      handleJobsResponse(response);
      
      // Use unknown as intermediate type to fix conversion errors
      setOriginalMatchedJobs(response.jobs as unknown as Job[]);
      setJobResults(response.jobs.slice(0, itemsPerPage) as unknown as Job[]);
      setTotalResults(response.totalJobs);
      setTotalPages(Math.ceil(response.totalJobs / itemsPerPage));
    } catch (error) {
      console.error('Error clearing filters:', error);
      setError('Failed to clear filters. Please try again.');
    } finally {
      setLoading(false);
      setIsClearing(false); // Reset clearing state
    }
  };

  const handleSelection = useCallback((job: JobData) => {
    // Debug log job properties before setting selection
    console.log("Setting job selection with job_url:", job.job_url);
    console.log("Full job object:", JSON.stringify(job));
    
    // Ensure job_url is always a string, never undefined
    const jobWithUrl = {
      ...job,
      job_url: job.job_url || "" // Use empty string as fallback instead of undefined
    };
    
    setSelection(jobWithUrl);
  }, []);

  // Remove client-side filtering since it's now handled by backend
  const currentJobs = jobResults;

  const handleOpenFilterModal = () => {
    setTempSelectedLocations([...selectedLocations]);
    setTempSelectedExperienceLevels([...selectedExperienceLevels]);
    setTempSelectedIndustries([...selectedIndustries]);
    setIsFilterModalOpen(true);
    setIsModalClosing(false);
    document.body.style.overflow = "hidden";
  };

  const handleCloseFilterModal = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsFilterModalOpen(false);
      setIsModalClosing(false);
      document.body.style.overflow = "unset";
    }, 300);
  };

  const handleApplyFilters = () => {
    setSelectedLocations(tempSelectedLocations);
    setSelectedExperienceLevels(tempSelectedExperienceLevels);
    setSelectedIndustries(tempSelectedIndustries);
    handleCloseFilterModal();
  };

  // Add initial fetch for applied jobs
  useEffect(() => {
    const fetchInitialAppliedJobs = async () => {
      try {
        const applications = await getApplications();
        const appliedJobIds = new Set(applications.map(app => app.jobId));
        setAppliedJobs(appliedJobIds);
      } catch (error) {
        console.error('Error fetching initial applied jobs:', error);
      }
    };

    fetchInitialAppliedJobs();
  }, []); // Run once on component mount

  // Add a function to refresh applied jobs
  const refreshAppliedJobs = async () => {
    try {
      const applications = await getApplications();
      const appliedJobIds = new Set(applications.map(app => app.jobId));
      setAppliedJobs(appliedJobIds);
    } catch (error) {
      console.error('Error fetching applied jobs:', error);
    }
  };

  // Add event listener for application updates
  useEffect(() => {
    const handleApplicationUpdate = () => {
      refreshAppliedJobs();
    };

    window.addEventListener('application-updated', handleApplicationUpdate);
    return () => {
      window.removeEventListener('application-updated', handleApplicationUpdate);
    };
  }, []);

  return (
    <div className="job-search-container">
      <Sidebar />
      <div className="job-search">
        <div className={`filter-container ${scrolled ? "scrolled" : ""}`}>
          <div className={`filter-content ${loading ? "filters-loading" : ""}`}>
            <button 
              className="mobile-menu-button" 
              onClick={() => {
                const sidebar = document.querySelector('.sidebar');
                sidebar?.classList.toggle('expanded');
              }}
            >
              <FiMenu />
            </button>
            
            <button 
              className="filters-button" 
              onClick={handleOpenFilterModal}
              disabled={loading}
            >
              <AiOutlineFilter />
              Filters
            </button>
            
            <FilterDropdown
              options={loading ? [] : availableJobHubs}
              selectedValues={selectedLocations}
              setSelectedValues={setSelectedLocations}
              title="Location"
              type="locations"
              loading={loading}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
            />
            <FilterDropdown
              options={loading ? [] : availableExperienceLevels}
              selectedValues={selectedExperienceLevels}
              setSelectedValues={setSelectedExperienceLevels}
              title="Experience Level"
              type="experienceLevel"
              loading={loading}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
            />
            <FilterDropdown
              options={loading ? [] : availableCategories}
              selectedValues={selectedIndustries}
              setSelectedValues={setSelectedIndustries}
              title="Industry"
              type="industries"
              loading={loading}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
            />
            <button 
              className={`clear-filters-button ${loading ? "disabled" : ""} ${
                selectedLocations.length > 0 || 
                selectedExperienceLevels.length > 0 || 
                selectedIndustries.length > 0 ? "has-filters" : ""
              }`} 
              onClick={loading ? undefined : clearFilters}
              disabled={loading}
            >
              {isClearing ? 'Clearing...' : 'Clear Filters'}
            </button>
          </div>
        </div>

        <div className="job-results job-tiles-container">
          {loading ? (
            <>
              {[...Array(itemsPerPage)].map((_, index) => (
                <div key={index} className="job-tile skeleton" />
              ))}
            </>
          ) : currentJobs.length > 0 ? (
            currentJobs.map((job, index) => (
              <JobTile
                key={`${job._id}-${index}`}
                job={job}
                userId={user?._id}
                handleSelection={handleSelection}
                context={JobButtonContext.MAIN}
                isApplied={appliedJobs.has(job._id)}
                onApplicationUpdate={refreshAppliedJobs}
              />
            ))
          ) : (
            <p>No jobs found matching the selected filters.</p>
          )}
        </div>

        {totalResults > itemsPerPage && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<FaAngleLeft />}
              nextLabel={<FaAngleRight />}
              pageCount={totalPages}
              onPageChange={handlePageClick}
              forcePage={currentPageData.page - 1}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="prev"
              nextClassName="next"
              disabledClassName="disabled"
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              breakLabel="..."
              breakClassName="break"
              breakLinkClassName="break-link"
              pageLinkClassName="page-link"
              renderOnZeroPageCount={null}
              key={`paginate-${totalPages}-${currentPageData.page}`}
            />
          </div>
        )}
      </div>

      {isFilterModalOpen && (
        <div
          className={`filter-modal-overlay ${isFilterModalOpen ? "modal-open" : ""} ${
            isModalClosing ? "closing" : ""
          }`}
          onClick={handleCloseFilterModal}
        >
          <div className="filter-modal" onClick={(e) => e.stopPropagation()}>
            <button
              className="modal-close-button"
              onClick={handleCloseFilterModal}
              aria-label="Close modal"
            >
              <AiOutlineClose />
            </button>
            <h2>Filters</h2>
            
            <div className="filter-section">
              <span className="filter-label">Location</span>
              <FilterDropdown
                options={availableJobHubs}
                selectedValues={tempSelectedLocations}
                setSelectedValues={setTempSelectedLocations}
                title="Location"
                type="locations"
                loading={loading}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
            </div>

            <div className="filter-section">
              <span className="filter-label">Experience Level</span>
              <FilterDropdown
                options={availableExperienceLevels}
                selectedValues={tempSelectedExperienceLevels}
                setSelectedValues={setTempSelectedExperienceLevels}
                title="Experience Level"
                type="experienceLevel"
                loading={loading}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
            </div>

            <div className="filter-section">
              <span className="filter-label">Industry</span>
              <FilterDropdown
                options={availableCategories}
                selectedValues={tempSelectedIndustries}
                setSelectedValues={setTempSelectedIndustries}
                title="Industry"
                type="industries"
                loading={loading}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
            </div>

            <div className="modal-actions">
              <button className="cancel-button" onClick={handleCloseFilterModal}>
                Cancel
              </button>
              <button className="apply-button" onClick={handleApplyFilters}>
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default JobSearch;

// In jobMatching.service.js
