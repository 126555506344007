import React, { useEffect, useState, useRef } from "react";
import "../../styles/components/landing-pages/about.scss";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ResumeHighlight from "../../animations/components/ResumeHighlight";

const About: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const founderRef = useRef<HTMLElement | null>(null);

  // Original Founder text, fully static (no typewriter)
  const founderParagraphs = [
    `At QuickCruit, we started with a simple mission: to transform the job application process. What began as a solution to streamline resume writing and cover letter creation has evolved into something much more powerful.`,
    `Our journey led us to develop an AI-powered platform that puts cutting-edge job-hunting tools directly in your hands. We understand that each application represents more than just paperwork—it's a step toward your future. That's why we've built QuickCruit to handle the time-consuming aspects of job applications, letting you focus on what truly matters—finding roles that align with your career aspirations.`,
    `Today, we're proud to serve job seekers at every stage of their career journey. Whether you're a recent graduate, changing careers, or exploring new opportunities, our commitment remains the same: to make your path to meaningful employment clearer, faster, and more successful.`,
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Intersection Observer for fade in
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Add animate class immediately
          entry.target.classList.add('animate');
          
          if (entry.isIntersecting) {
            // Add visible class after a small delay to ensure animation triggers
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: '0px'
      }
    );

    // Observe all sections that need animation
    document.querySelectorAll('.mission-section, .founder-section, .cofounders-section, .message-box').forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Navbar />

      <div className="about-page-container">

        {/* --- MISSION SECTION (center aligned) --- */}
        <section className="mission-section">
          <div className="about-content">
            <h2>Our Mission</h2>
            <p>
              At QuickCruit, we believe that everyone deserves access to powerful 
              career tools. By blending AI-driven insights with a human touch, we 
              help candidates apply with confidence and clarity.
            </p>
            <p>
              Our goal is to make the job search more efficient, more transparent, 
              and more personal—so your skills and hard work genuinely shine through.
            </p>
          </div>
        </section>

        {/* --- FOUNDER SECTION (static message) --- */}
        <section className="founder-section" ref={founderRef}>
          <div className="founder-title-container">
            <h2 className="founder-title">A Note from Our Founder</h2>
          </div>

          <div className="founder-content">
            <div className="message-box">
              <p>
              Hi, I am Nilay Patel, founder of QuickCruit. This platform started as a simple Python script to help me escape the grind of rewriting resumes and cover letters for every job application. In truth, it felt like the process was working against me rather than for me.
              </p>
              <p>
              Realizing how common this struggle is, I turned that script into QuickCruit: an AI-powered service that puts powerful job-hunting tools in your hands. From resume optimization to tailored cover letters, we aim to remove the busywork so you can focus on what truly matters—finding a role that speaks to your ambitions.
              </p>
              <p>
              Whether you are a new grad, shifting careers, or simply looking for fresh challenges, I hope QuickCruit makes your path smoother. Our commitment is to help real people land real jobs, one application at a time.
              </p>
              <div className="founder-signature">
                <div className="name">Nilay Patel</div>
                <div className="title">Founder & CEO</div>
              </div>
            </div>
          </div>
        </section>

        {/* --- CO-FOUNDERS SECTION --- */}
        {/* <section className="cofounders-section">
          <h2>Meet The Team</h2>
          <div className="cofounders-grid">
            <div className="cofounder-card">
              <div className="cofounder-photo-container">
                <img
                  src="/path/to/nilay.jpg"
                  alt="Nilay's Photo"
                  className="cofounder-photo"
                />
              </div>
              <h3>Nilay Patel</h3>
              <p>Founder</p>
              <a
                href="https://www.linkedin.com/in/npatel84/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-link"
              >
                <img
                  src="/path/to/linkedin-icon.svg"
                  alt="LinkedIn"
                  className="linkedin-icon"
                />
              </a>
            </div>

            <div className="cofounder-card">
              <div className="cofounder-photo-container">
                <img
                  src="/path/to/karan.jpg"
                  alt="Karan's Photo"
                  className="cofounder-photo"
                />
              </div>
              <h3>Karan Pal</h3>
              <p>Co-Founder</p>
              <a
                href="https://in.linkedin.com/in/karan-pal-54a3791b6/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-link"
              >
                <img
                  src="/path/to/linkedin-icon.svg"
                  alt="LinkedIn"
                  className="linkedin-icon"
                />
              </a>
            </div>

            <div className="cofounder-card">
              <div className="cofounder-photo-container">
                <img
                  src="/path/to/pranav.jpg"
                  alt="Pranav's Photo"
                  className="cofounder-photo"
                />
              </div>
              <h3>Pranav Dadi</h3>
              <p>Co-Founder</p>
              <a
                href="https://www.linkedin.com/in/pranavdadi/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-link"
              >
                <img
                  src="/path/to/linkedin-icon.svg"
                  alt="LinkedIn"
                  className="linkedin-icon"
                />
              </a>
            </div>
          </div>
        </section> */}
      </div>

      <Footer />
    </>
  );
};

export default About;
