import React, { useState, KeyboardEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/components/auth.scss";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import logoImage from "../logo.svg";
import axiosClient from "../axios-client";
import { Bounce, toast } from "react-toastify";
import { GoogleLogin } from '@react-oauth/google';
import { IUser } from '../types/common'

const commonDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'icloud.com', 'aol.com'];
const commonTLDs = ['com', 'net', 'org', 'edu', 'gov', 'mil', 'int'];

const Signup: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [suggestedDomain, setSuggestedDomain] = useState('');
  const [suggestedTLD, setSuggestedTLD] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/.test(
        password
      )
    ) {
      setError(
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character."
      );
      return;
    }
    try {
      const result = await axiosClient.post('/auth/register', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password
      });

      const { user , tokens } = result.data;
      const userData = user as IUser;
      // Store tokens and user data
      localStorage.setItem('accessToken', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      localStorage.setItem('user', JSON.stringify(user));

      // Set the token in axios default headers
      axiosClient.defaults.headers.common['Authorization'] = `Bearer ${tokens.access.token}`;

      toast.success('Account created successfully! Please verify your email.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      if(!userData.isEmailVerified){
        await axiosClient.post('/auth/send-verification-email', {
          email: email,
        });
        toast.success('Email verification link sent. Please check your email.');
        navigate(`/auth/verify-email?email=${encodeURIComponent(email)}`);
      } else {
        navigate('/dashboard');
      }      
      
    } catch (error: any) {
      setError(error.message);
      toast(error.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const result = await axiosClient.post('/auth/google', {
        credential: credentialResponse.credential,
      });

      const { user, tokens } = result.data;
      localStorage.setItem('accessToken', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      localStorage.setItem('user', JSON.stringify(user));

      toast('Google Sign-In Successful', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce
      });
      if (!user.userOnboarding) {
        window.location.reload();
        navigate('/onboarding');
      }

      window.location.reload();
      navigate('/dashboard');
    } catch (error: any) {
      toast(error.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  // --- EMAIL SUGGESTION LOGIC ---

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setEmail(val);
    const { domain, tld } = getEmailSuggestion(val);
    setSuggestedDomain(domain);
    setSuggestedTLD(tld);
  };

  // Same Tab logic as in Login.tsx
  const handleEmailKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // If Tab is pressed (no Shift) and a suggestion exists, autocomplete
    // Otherwise, let Tab move to the next field
    if (
      e.key === 'Tab' &&
      !e.shiftKey &&
      (suggestedDomain || suggestedTLD)
    ) {
      e.preventDefault();
      if (suggestedDomain) {
        setEmail(email + suggestedDomain);
        setSuggestedDomain('');
      } else if (suggestedTLD) {
        setEmail(email + suggestedTLD);
        setSuggestedTLD('');
      }
    }
  };

  const getEmailSuggestion = (value: string) => {
    const atIndex = value.indexOf('@');
    if (atIndex === -1) return { domain: '', tld: '' };

    const afterAt = value.slice(atIndex + 1).toLowerCase();
    const dotIndex = afterAt.indexOf('.');
    
    // Handle domain suggestions
    if (dotIndex === -1) {
      if (afterAt.length > 0) {
        const matchedDomain = commonDomains.find(d => d.startsWith(afterAt));
        if (matchedDomain) {
          // Only return the remaining part of the suggestion
          return { domain: matchedDomain.slice(afterAt.length), tld: '' };
        }
      }
    } else {
      // Handle TLD suggestions
      const afterDot = afterAt.slice(dotIndex + 1);
      if (afterDot.length > 0) {
        const matchedTLD = commonTLDs.find(t => t.startsWith(afterDot));
        if (matchedTLD) {
          // Only return the remaining part of the TLD
          return { domain: '', tld: matchedTLD.slice(afterDot.length) };
        }
      }
    }
    
    return { domain: '', tld: '' };
  };

  // --- RENDER ---
  return (
    <div className="auth-wrapper">
      <header className="auth-header">
        <Link to="/">
          <img src={logoImage} alt="QuickCruit Logo" className="auth-logo" />
        </Link>
      </header>
      <div className="auth-container">
        <h2>Create Account</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignup}>
          <div className="name-fields">
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
          </div>
          <div className="email-field-with-suggestion">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleEmailKeyDown}
              placeholder="Email"
            />
            <div className="suggestion-overlay">
              <span className="typed-text">{email}</span>
              {(suggestedDomain || suggestedTLD) && (
                <span className="suggested-part">
                  {suggestedDomain || suggestedTLD}
                </span>
              )}
            </div>
          </div>
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <span onClick={togglePasswordVisibility}>
              {!passwordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
            </span>
          </div>
          <div className="password-field">
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
            />
            <span onClick={toggleConfirmPasswordVisibility}>
              {!confirmPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
            </span>
          </div>
          <button type="submit" className="auth-button">Sign up</button>
        </form>
        <div className="or-divider">Or continue with</div>
        <div className="google-button-container">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            shape="rectangular"
            width="390"
            logo_alignment="center"
            text="signin_with"
            onError={() => {
              toast.error('Google Sign-In Failed');
            }}
            useOneTap
          />
        </div>
        <p className="signup-link">
          Already have an account?<Link to="/auth/login">Sign in</Link>
        </p>
      </div>
      <div className="terms-text">
        By continuing, you agree to our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
      </div>
    </div>
  );
};

export default Signup;