import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import "../../styles/components/landing-pages/pricing.scss";
import { FaCheck, FaGift } from 'react-icons/fa';
import { HiOutlineCheck } from 'react-icons/hi';

const Pricing = () => {
  const [user, setUser] = useState(null);
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'quarterly'>('quarterly');

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const pricingData = {
    free: {
      monthly: 0,
      quarterly: 0,
    },
    pro: {
      monthly: 20,
      quarterly: 18, // $18/month when billed quarterly
    }
  };

  const getPriceDisplay = (plan: 'free' | 'pro') => {
    if (plan === 'free') {
      return {
        amount: 0,
        period: '/month',
        billing: 'Free forever'
      };
    }
    
    if (billingCycle === 'monthly') {
      return {
        amount: pricingData[plan].monthly,
        period: '/month',
        billing: 'Billed monthly'
      };
    } else {
      return {
        amount: pricingData[plan].quarterly,
        period: '/month',
        billing: 'Billed $54 every 3 months'
      };
    }
  };

  return (
    <>
      <Navbar />
      <div className="landing-pricing-page">
        <div className="landing-pricing-wrapper">
          <h1>Make every application count</h1>
          <p className="landing-pricing-description">From smart job matching to AI-powered applications - tools that get you noticed</p>

          <div className="landing-billing-toggle" data-active={billingCycle}>
            <button 
              className={`landing-toggle-btn ${billingCycle === 'monthly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly
            </button>
            <button 
              className={`landing-toggle-btn ${billingCycle === 'quarterly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('quarterly')}
            >
              Quarterly
            </button>
          </div>

          <div className="landing-pricing-cards">
            {/* Free Plan */}
            <div className="landing-pricing-card">
              <div className="landing-card-header">
                <h2 className="landing-pricing-tier-title">Free</h2>
                <p className="landing-pricing-description">Start your job search journey with essential tools and features.</p>
                <div className="landing-price">
                  <span className="landing-amount">${getPriceDisplay('free').amount}</span>
                  <span className="landing-period">{getPriceDisplay('free').period}</span>
                </div>
                <div className="landing-billing-info">{getPriceDisplay('free').billing}</div>
                <div className="landing-button-container">
                  <Link to="/auth/signup" className="landing-signup-button landing-secondary">
                    Get Started
                  </Link>
                  <span className="landing-placeholder-text"></span>
                </div>
              </div>
              <ul className="landing-features">
                <li className="landing-placeholder-feature">
                  <span className="landing-check-icon-placeholder"></span>
                  <span className="landing-text-placeholder"></span>
                </li>
                <li>
                  <HiOutlineCheck className="landing-check-icon" />
                  Tailored Job Matches
                </li>
                <li>
                  <HiOutlineCheck className="landing-check-icon" />
                  Job Tracking 
                </li>
                <li>
                  <HiOutlineCheck className="landing-check-icon" />
                  Shareable Portfolio
                </li>
              </ul>
            </div>

            {/* Pro Plan */}
            <div className="landing-pricing-card landing-pro">
              <div className="landing-card-header">
                <h2 className="landing-pricing-tier-title">Pro</h2>
                <p className="landing-pricing-description">Unlock AI-powered tools to maximize your job search success.</p>
                <div className="landing-price">
                  <span className="landing-amount">${getPriceDisplay('pro').amount}</span>
                  <span className="landing-period">{getPriceDisplay('pro').period}</span>
                </div>
                <div className="landing-billing-info">{getPriceDisplay('pro').billing}</div>
                <div className="landing-button-container">
                  <Link to="/auth/signup" className="landing-signup-button landing-primary">
                    Start Free Trial
                  </Link>
                  <span className="landing-no-card-text">No credit card required</span>
                </div>
              </div>
              <ul className="landing-features">
                <li>
                  <FaGift className="landing-check-icon landing-gift" />
                  7-day free trial included
                </li>
                <li>
                  <HiOutlineCheck className="landing-check-icon" />
                  Everything in Free
                </li>
                <li>
                  <HiOutlineCheck className="landing-check-icon" />
                  AI Resume Tailoring
                </li>
                <li>
                  <HiOutlineCheck className="landing-check-icon" />
                  AI Cover Letter Generator
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
