import React, { useState, useEffect } from 'react';
import '../styles/ResumeFeatures.scss';

interface FeatureItem {
  id: string;
  title: string;
  description: string;
}

const ResumeFeatures: React.FC = () => {
  // Set the first feature as active by default
  const [activeFeature, setActiveFeature] = useState<string>('editable');
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  
  // Initialize with the first feature active
  useEffect(() => {
    // This ensures the first item starts with description visible
    const timer = setTimeout(() => {
      const firstFeatureButton = document.querySelector('.feature-item') as HTMLElement;
      if (firstFeatureButton) {
        firstFeatureButton.classList.add('active');
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  const features: FeatureItem[] = [
    {
      id: 'editable',
      title: 'Google Docs-Like Editing',
      description: 'Experience the familiar, fluid document editing that makes resume creation feel natural and intuitive.'
    },
    {
      id: 'tailor',
      title: 'Tailor with AI',
      description: 'Select any bullet point and use the "Tailor with AI" button to get personalized suggestions to improve your content.'
    },
    {
      id: 'suggestions',
      title: 'Seamless Suggestions',
      description: 'Accept AI suggestions with one click and see them instantly reflected in your resume.'
    }
  ];

  const handleFeatureClick = (featureId: string) => {
    if (activeFeature === featureId || isTransitioning) {
      // If clicking the active feature or during transition, do nothing
      return;
    }
    
    setIsTransitioning(true);
    
    // Short delay before changing active feature for smoother transition
    setTimeout(() => {
      setActiveFeature(featureId);
      
      // Reset transition state after animation completes
      setTimeout(() => {
        setIsTransitioning(false);
      }, 400);
    }, 100);
  };

  // Function to render the appropriate content based on active feature
  const renderFeatureContent = () => {
    // Find the active feature
    const feature = features.find(f => f.id === activeFeature);
    
    let iconClass = '';
    let contentTitle = '';
    let contentDescription = '';
    
    if (feature) {
      switch (feature.id) {
        case 'editable':
          iconClass = 'editable-icon';
          contentTitle = 'Familiar Document Experience';
          contentDescription = 'Edit your resume with the same fluidity and intuitive control you\'d expect from Google Docs. Click anywhere to modify text, drag to reposition elements, and watch as your changes save automatically in real-time.';
          break;
        case 'tailor':
          iconClass = 'tailor-icon';
          contentTitle = 'Industry-Leading AI Tailoring';
          contentDescription = 'Select any section of your resume and let our advanced AI analyze your content against job descriptions. Receive targeted recommendations that increase your resume\'s relevance and impact, all within seconds.';
          break;
        case 'suggestions':
          iconClass = 'suggestions-icon';
          contentTitle = 'Frictionless Improvement';
          contentDescription = 'Review AI-generated enhancements and implement them instantly with a single click. What would normally take hours of careful rewording is accomplished in moments, maintaining your voice while elevating your professional presentation.';
          break;
      }
    }
    
    return (
      <div className="feature-content">
        <div className={`feature-content-container ${isTransitioning ? 'entering' : ''}`}>
          <div className={`feature-icon ${iconClass}`}></div>
          <h2 className="content-title">{contentTitle}</h2>
          <p className="content-description">{contentDescription}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="resume-features-container">
      <div className="features-column">
        {features.map((feature) => (
          <button 
            key={feature.id}
            className={`feature-item ${activeFeature === feature.id ? 'active' : ''}`}
            onClick={() => handleFeatureClick(feature.id)}
          >
            <div className="feature-text">
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          </button>
        ))}
      </div>
      
      <div className="feature-content-column">
        {renderFeatureContent()}
      </div>
    </div>
  );
};

export default ResumeFeatures; 