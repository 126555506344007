import React from "react";
import "../../styles/components/landing-pages/footer.scss";
import logoImage from "../../assets/logo.svg";
import { relative } from "path";
import Dropdown from "../../components/ui/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const Footer = () => {
  const handleSelect = (selected: string) => {
    console.log("Selected option:", selected);
  };
  return (
    <footer className="footer">
      <div className="footer-content-wrapper">
        <div className="footer__social-media">
          <img src={logoImage} alt="Logo" className="footer__logo" />
          <div className="footer__icons">
            <a 
              href="https://www.instagram.com/quickcruit.ai/" 
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size={"lg"} className="social-icon" />
            </a>
            <a 
              href="https://x.com/QuickCruit" 
              aria-label="X"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} size={"lg"} className="social-icon" />
            </a>
            <a 
              href="https://www.linkedin.com/company/quickcruit" 
              aria-label="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size={"lg"} className="social-icon" />
            </a>
          </div>

          <Dropdown
            options={[
              { label: "English", description: "English (US)" },
              { label: "Français", description: "French (France)" },
              { label: "Deutsch", description: "German" },
              { label: "日本語", description: "Japanese" },
              { label: "한국어", description: "Korean" },
            ]}
            placeholder="English"
            onSelect={handleSelect}
          />
          <p>&copy; 2025 QuickCruit, Inc.</p>
        </div>

        <div className="footer__links">
          <div>
            <h4>Features</h4>
            <ul>
              <li>
                <Link to="/features/resume-tailoring">Resume Tailoring</Link>
              </li>
              <li>
                <Link to="/features/cover-letter">Cover Letter Generation</Link>
              </li>
              {/* <li>
                <Link to="/features/outreach">Outreach AI</Link>
              </li> */}
              {/* <li>
                <Link to="/features/application-tracker">Application Tracker</Link>
              </li> */}
            </ul>
          </div>

          <div>
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4>Resources</h4>
            <ul>
              <li>
                <Link to="/terms">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;