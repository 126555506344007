import React, { useState, useEffect } from "react";
import "../styles/components/resumeComponent.scss";
import "react-circular-progressbar/dist/styles.css";
import axiosClient from "../axios-client";
import Resume from "./Resume";
import Suggestions from "./Suggestions";
import axios from "axios";
import { mainUrl } from "../constant";

export interface Education {
  degree: string;
  field: string;
  university: string;
  graduationDate: string; // Match the backend
  gpa?: string;
}

export interface Experience {
  title: string;
  company: string;
  location: string;
  startDate: string;
  endDate: string | null;
  isCurrentPosition: boolean;
  description: { id: string; text: string }[];
}

export interface Project {
  title: string;
  project_name: string;
  startDate: string;
  endDate: string | null;
  isOngoing: boolean;
  description: { id: string; text: string }[];
}

export interface ResumeText {
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  linkedin: string;
  website: string;
  location: string;
  citizenship: string;
  education: Education[];
  experience: Experience[];
  projects: Project[];
  skills: any;
  interests: string[];
}

interface ResumeComponentProps {
  jobDescription: any;
  resumeText: ResumeText;
  handleTailorButtonClick: () => void;
  resumeId: string;
  jobId: string;
  onNavigateToCoverLetter: () => void;
  hasBeenTailored: boolean;
  setHasBeenTailored: (value: boolean) => void;
  cachedContent: { suggestions: any[]; score: number | null } | null;
  setCachedContent: (
    content: { suggestions: any[]; score: number | null } | null
  ) => void;
}

export interface BulletPointSuggestion {
  id: string;
  original: string;
  suggested: string;
  isLoading: boolean;
  expIndex: number;
  descId: string;
}

interface BulletPoint {
  id: string;
  text: string;
}

const ResumeComponent: React.FC<ResumeComponentProps> = ({
  jobDescription,
  resumeText,
  handleTailorButtonClick,
  resumeId,
  jobId,
  onNavigateToCoverLetter,
  hasBeenTailored,
  setHasBeenTailored,
  cachedContent,
  setCachedContent,
}) => {
  const [revisedResume, setRevisedResume] = useState<ResumeText>(resumeText);
  const [isEditingLocked, setIsEditingLocked] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<BulletPointSuggestion[]>([]);
  const [score, setScore] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<string | null>(null);
  const [selectedBulletPoint, setSelectedBulletPoint] =
    useState<BulletPointSuggestion | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [expandedBulletPoint, setExpandedBulletPoint] = useState<{
    expIndex: number;
    descId: string;
  } | null>(null);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const fetchSuggestions = React.useCallback(async () => {
    if (hasBeenTailored) {
      return;
    }

    const user = JSON.parse(String(localStorage.getItem("user")));
    setLoading(true);
    try {
      setIsEditingLocked(true);
      const description = `title: ${jobDescription.original_title} responsibilities: ${jobDescription.responsibilities} qualification: ${jobDescription.qualifications} skills: ${jobDescription.skills}`;
      const response = await axiosClient.post("builder/resume-builder", {
        jobDescription: description,
        userDetails: JSON.stringify(user),
      });

      const transformedSuggestions = response.data.bullet_points.map(
        (item: any) => ({
          id: item.id,
          original: item.text,
          suggested: item.text,
          isLoading: false,
          expIndex: 0,
          descId: item.id,
        })
      );

      setSuggestions(transformedSuggestions);
      setScore(response.data.ATS_score);
      setHasBeenTailored(true);
      setLoading(false);
      setIsEditingLocked(false);

      // Cache the results
      setCachedContent({
        suggestions: transformedSuggestions,
        score: response.data.ATS_score,
      });
    } catch (err) {
      setLoading(false);
      setIsEditingLocked(false);
      console.error("Error in fetchSuggestions:", err);
      setError("Failed to fetch suggestions. Please try again.");
    }
  }, [hasBeenTailored, jobDescription, setHasBeenTailored, setCachedContent]);

  useEffect(() => {
    if (cachedContent) {
      setSuggestions(cachedContent.suggestions);
      setScore(cachedContent.score);
      setLoading(false);
      setIsEditingLocked(false);
      return;
    }

    if (!hasBeenTailored) {
      fetchSuggestions();
    } else {
      setLoading(false);
    }
  }, [hasBeenTailored, cachedContent, fetchSuggestions]);

  console.log('ressumeeeiD', resumeId)

  const handleToggleDropdown = (id: string) => {
    setExpandedIndex(expandedIndex === id ? null : id);
  };

  const handleFieldChange = (updater: (prev: ResumeText) => ResumeText) => {
    if (!isEditingLocked) {
      const updatedResume = updater(revisedResume);
      setRevisedResume(updatedResume);

      // Get all content from resume-rectangle container
      const allContent = {
        // Contact information
        ...(updatedResume.firstName && { firstName: updatedResume.firstName }),
        ...(updatedResume.lastName && { lastName: updatedResume.lastName }),
        ...(updatedResume.email && { email: updatedResume.email }),
        ...(updatedResume.phone && { phone: updatedResume.phone }),
        ...(updatedResume.linkedin && { linkedin: updatedResume.linkedin }),
        ...(updatedResume.website && { website: updatedResume.website }),
        ...(updatedResume.location && { location: updatedResume.location }),
        ...(updatedResume.citizenship && { citizenship: updatedResume.citizenship }),
        // Arrays and objects - only include if they exist and have content
        ...(updatedResume.education?.length > 0 && { education: updatedResume.education }),
        ...(updatedResume.experience?.length > 0 && { experience: updatedResume.experience }),
        ...(updatedResume.projects?.length > 0 && { projects: updatedResume.projects }),
        ...(Object.keys(updatedResume.skills || {}).length > 0 && { skills: updatedResume.skills }),
        ...(updatedResume.interests?.length > 0 && { interests: updatedResume.interests })
      };

      // Save to localStorage with job-specific key
      localStorage.setItem(`currentResume_${jobId}`, JSON.stringify(allContent));
      
      console.log('Resume content updated:', {
        sections: Object.keys(allContent),
        contactInfo: {
          hasName: !!(allContent.firstName || allContent.lastName),
          hasEmail: !!allContent.email,
          hasPhone: !!allContent.phone,
          missingFields: [
            ...(!allContent.firstName ? ['firstName'] : []),
            ...(!allContent.lastName ? ['lastName'] : []),
            ...(!allContent.email ? ['email'] : []),
            ...(!allContent.phone ? ['phone'] : [])
          ]
        },
        sectionCounts: {
          education: allContent.education?.length || 0,
          experience: allContent.experience?.length || 0,
          projects: allContent.projects?.length || 0,
          skills: Object.keys(allContent.skills || {}).length || 0,
          interests: allContent.interests?.length || 0
        }
      });
    }
  };

  const handleAcceptSingleSuggestion = async (
    suggestion: string,
    suggestionId: string
  ) => {
    try {
      let found = false;

      const updatedExperience = revisedResume.experience.map((experience) => {
        const updatedDescriptions = experience.description.map((bullet) => {
          if (bullet.id === suggestionId && !found) {
            found = true;
            return { ...bullet, text: suggestion };
          }
          return bullet;
        });
        return { ...experience, description: updatedDescriptions };
      });

      const updatedProjects = revisedResume.projects.map((project) => {
        const updatedDescriptions = project.description.map((bullet) => {
          if (bullet.id === suggestionId && !found) {
            found = true;
            return { ...bullet, text: suggestion };
          }
          return bullet;
        });
        return { ...project, description: updatedDescriptions };
      });

      if (found) {
        const updatedResume = {
          ...revisedResume,
          experience: updatedExperience,
          projects: updatedProjects
        };

        setRevisedResume(updatedResume);
        await saveResume(updatedResume);

        // Store the current state in localStorage
        const relevantResumeData = {
          education: updatedResume.education,
          experience: updatedResume.experience,
          projects: updatedResume.projects,
          skills: updatedResume.skills,
          interests: updatedResume.interests
        };
        
        localStorage.setItem(`currentResume_${jobId}`, JSON.stringify(relevantResumeData));

        const updatedSuggestions = suggestions.filter(
          (item) => item.id !== suggestionId
        );
        setSuggestions(updatedSuggestions);
        
        setCachedContent({
          suggestions: updatedSuggestions,
          score: cachedContent?.score ?? score
        });

        setExpandedBulletPoint(null);
      }
    } catch (error: unknown) {
      console.error("Error accepting suggestion:", error);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axiosClient.post(
        "/pdf/generate-pdf",
        revisedResume
      );

      if (response.data && response.data.downloadUrl) {
        window.open(`${mainUrl}${response.data.downloadUrl}`, '_blank');

        const link = document.createElement("a");
        link.href = response.data.downloadUrl;
        link.download = `${revisedResume.firstName}_${revisedResume.lastName}_resume.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('Download URL not received from server');
      }
    } catch (error: unknown) {
      console.error("Error generating PDF:", error);
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error response:", error.response.data);
      }
    }
  };

  const saveResume = async (resumeToSave = revisedResume as any) => {
    try {
      if (!resumeId) {
        console.log("No resumeId found, skipping save");
        return;
      }

      const user = JSON.parse(String(localStorage.getItem("user")));
      resumeToSave.userId = user._id;
      resumeToSave.jobId = jobId;

      const response = await axiosClient.put(
        `/builder/update-resume/${resumeId}`,
        resumeToSave
      );
      console.log("Resume updated:", response.data);
    } catch (error: unknown) {
      console.error("Error saving resume:", error);
    }
  };

  const handleTailorBulletPoint = async (expIndex: number, descId: string) => {
    const bulletPoint = revisedResume.experience[expIndex].description.find(
      (desc) => desc.id === descId
    );

    if (!bulletPoint) return;

    try {
      setSelectedBulletPoint({
        id: bulletPoint.id,
        original: bulletPoint.text,
        suggested: "",
        isLoading: true,
        expIndex,
        descId,
      });

      const description = `title: ${jobDescription.original_title} responsibilities: ${jobDescription.responsibilities} qualification: ${jobDescription.qualifications} skills: ${jobDescription.skills}`;
      const response = await axiosClient.post("builder/tailor-bullets", {
        bulletPoint: bulletPoint.text,
        jobDescription: description,
      });

      setSelectedBulletPoint({
        id: bulletPoint.id,
        original: bulletPoint.text,
        suggested: response.data,
        isLoading: false,
        expIndex,
        descId,
      });
    } catch (err) {
      setError(
        "Failed to fetch bullet point suggestion. Please try again later."
      );
      setSelectedBulletPoint(null);
    }
  };

  const handleAcceptSuggestion = async (expIndex: number, descId: string) => {
    if (selectedBulletPoint && !selectedBulletPoint.isLoading) {
      handleFieldChange((prev) => {
        const updatedExperience = [...prev.experience];
        const bulletIndex = updatedExperience[expIndex].description.findIndex(
          (bullet) => bullet.id === descId
        );

        if (bulletIndex !== -1) {
          updatedExperience[expIndex].description[bulletIndex].text =
            selectedBulletPoint.suggested;
        }

        return { ...prev, experience: updatedExperience };
      });
      setSelectedBulletPoint(null);
    }
  };

  const handleRejectSuggestion = () => {
    setSelectedBulletPoint(null);
  };

  // Only keep the sidebar responsiveness effect
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setIsSidebarCollapsed(true);
        setIsSidebarExpanded(false);
      } else {
        setIsSidebarCollapsed(false);
        setIsSidebarExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        isSidebarExpanded &&
        !target.closest(".expanded-sidebar") &&
        !target.closest(".collapsed-sidebar")
      ) {
        setIsSidebarExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSidebarExpanded]);

  const handleCoverLetterClick = async () => {
    // Store current resume state before navigating
    const relevantResumeData = {
      education: revisedResume.education,
      experience: revisedResume.experience,
      projects: revisedResume.projects,
      skills: revisedResume.skills,
      interests: revisedResume.interests
    };

    localStorage.setItem(`currentResume_${jobId}`, JSON.stringify(relevantResumeData));
    
    console.log('Saving resume before navigation:', {
      jobId,
      sections: Object.keys(relevantResumeData),
      experienceCount: relevantResumeData.experience.length
    });

    // Store in application data
    const applicationData = {
      resumeContent: relevantResumeData,
      jobId: jobId
    };

    localStorage.setItem('currentApplication', JSON.stringify(applicationData));

    await handleTailorButtonClick();
  };

  return (
    <div className="resume-container">
          <div className="left-container">
            <Resume
              revisedResume={revisedResume}
              isEditingLocked={isEditingLocked}
              handleFieldChange={handleFieldChange}
              handleTailorBulletPoint={handleTailorBulletPoint}
              selectedBulletPoint={selectedBulletPoint}
              handleAcceptSuggestion={handleAcceptSuggestion}
              handleRejectSuggestion={handleRejectSuggestion}
              expandedBulletPoint={expandedBulletPoint}
            />
          </div>

          {!isSidebarCollapsed && (
            <div className="right-container">
              <Suggestions
                suggestions={suggestions}
                expandedIndex={expandedIndex}
                handleToggleDropdown={handleToggleDropdown}
                isHovered={isHovered}
                handleTailorButtonClick={handleCoverLetterClick}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                handleSingleSuggestion={handleAcceptSingleSuggestion}
                score={score}
                handleDownload={handleDownload}
                setSuggestions={setSuggestions}
                loading={loading}
                errorMessage={error}
                setExpandedBulletPoint={setExpandedBulletPoint}
              />
            </div>
          )}

          {isSidebarCollapsed && !isSidebarExpanded && (
            <div className="collapsed-sidebar" onClick={() => setIsSidebarExpanded(true)}>
       <div className="score-preview">
                {score && <div className="score">{score}</div>}
              </div>
            </div>
          )}

          {isSidebarExpanded && (
            <div className="expanded-sidebar">
              <div className="suggestions-container">
                <button
                  className="close-button"
                  onClick={() => setIsSidebarExpanded(false)}
                >
                  <span>×</span>
                </button>
                <Suggestions
                  suggestions={suggestions}
                  expandedIndex={expandedIndex}
                  handleToggleDropdown={handleToggleDropdown}
                  isHovered={isHovered}
                  handleTailorButtonClick={handleCoverLetterClick}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleSingleSuggestion={handleAcceptSingleSuggestion}
                  score={score}
                  handleDownload={handleDownload}
                  setSuggestions={setSuggestions}
                  loading={loading}
                  errorMessage={error}
                  isInExpandedSidebar={true}
                  setExpandedBulletPoint={setExpandedBulletPoint}
                />
              </div>
            </div>
          )}
        </div>
  );
};

export default ResumeComponent;
