import { ArrowLeft, XCircle } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

const CancelPage = () => {
  return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8 text-center">
        <div className="mb-6">
          <XCircle className="w-20 h-20 text-red-500 mx-auto" />
        </div>
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Payment Failed</h1>
        <p className="text-gray-600 mb-8">
          We're sorry, but your payment could not be processed. Please try again or contact support.
        </p>
        <div className="space-y-4">
          <button className="w-full px-6 py-3 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors">
            Try Again
          </button>
          <Link
            to="/"
            className="inline-flex items-center justify-center w-full px-6 py-3 text-blue-600 border-2 border-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Return to Homepage
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CancelPage