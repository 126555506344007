import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/components/auth.scss';
import { Link } from 'react-router-dom';
import logoImage from '../logo.svg';
import axiosClient from '../axios-client';
import { toast } from 'react-toastify';
import { Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', '']);
  const inputsRef = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

  useEffect(() => {
    if (!email) {
      toast.error('Email address is missing');
      navigate('/auth/login');
      return;
    }
  }, [email, navigate]);

  const handleInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value)) { // Only allow numbers
      const newDigits = [...otpDigits];
      newDigits[index] = value;
      setOtpDigits(newDigits);

      // Auto-focus next input
      if (value !== '' && index < 5 && inputsRef.current[index + 1]) {
        inputsRef.current[index + 1]?.focus();
      }
    }

    // Handle paste event
    if (value.length === 6) {
      const pastedDigits = value.split('');
      setOtpDigits(pastedDigits);
      pastedDigits.forEach((digit, i) => {
        if (inputsRef.current[i]) {
          inputsRef.current[i]!.value = digit;
        }
      });
      inputsRef.current[5]?.focus();
    }
  };

  const verifyEmail = async () => {
    const otp = otpDigits.join('');
    if (otp.length !== 6) {
      toast.error('Please enter a 6-digit code', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    try {
      await axiosClient.post(`/auth/verify-email`, {
        email: email,
        otp: otp
      });
      toast.success('Email verified successfully!');
      navigate('/auth/login');
    } catch (err: any) {
      toast.error(err.response?.data?.message || 'Verification failed', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <div className="auth-wrapper">
    <header className="auth-header">
        <Link to="/">
            <img src={logoImage} alt="QuickCruit Logo" className="auth-logo" />
        </Link>
    </header>
    <div className="auth-container">
        <div className="verification-card">
            <h2>Email Verification</h2>
            <p className="subtitle">Enter the 6-digit code sent to your email. This code is valid for the next 10 minutes.</p>
            
            <div className="code-input-container">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                    <input
                        key={index}
                        ref={(el) => (inputsRef.current[index] = el)}
                        type="text"
                        maxLength={1}
                        className="code-input"
                        value={otpDigits[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => e.key === 'Backspace' && index > 0 && inputsRef.current[index - 1]?.focus()}
                        autoFocus={index === 0}
                    />
                ))}
            </div>

            <button className="verify-button" onClick={verifyEmail}>
                Verify Email
            </button>

            <p className="resend-text">
                Didn't get the code? <Link to="/auth/resend-verification" className="resend-link">Resend code</Link>
            </p>

            <div className="footer-links">
                <Link to="/help" className="footer-link">Need help?</Link>
                <Link to="/feedback" className="footer-link">Send feedback</Link>
            </div>
        </div>
    </div>
</div>
  );
};

export default VerifyEmail;