import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import '../styles/components/onboarding.scss';
import ProgressBar from './ui/ProgressBar';
import { Bounce, toast, ToastOptions } from 'react-toastify';
import axiosClient from '../axios-client';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from './ui/FileUploader';
import ReactConfetti from 'react-confetti';
import { jobTitles, JobOption } from '../data/jobTitles';

const locationOptions = [
  { value: 'san_francisco_bay_area', label: '🌉 San Francisco Bay Area' },
  { value: 'greater_seattle_area', label: '🌲 Greater Seattle Area' },
  { value: 'new_york_metropolitan_area', label: '🗽 New York Metropolitan Area' },
  { value: 'greater_los_angeles_area', label: '🌴 Greater Los Angeles Area' },
  { value: 'boston_metropolitan_area', label: '🏙️ Boston Metropolitan Area' },
  { value: 'chicago_metropolitan_area', label: '🌬️ Chicago Metropolitan Area' },
  { value: 'washington_dc_metro_area', label: '🏛️ Washington D.C. Metro Area' },
  { value: 'austin_metropolitan_area', label: '🎸 Austin Metropolitan Area' },
  { value: 'dallas_fort_worth_metroplex', label: '🤠 Dallas-Fort Worth Metroplex' },
  { value: 'atlanta_metropolitan_area', label: '🍑 Atlanta Metropolitan Area' },
  { value: 'denver_metropolitan_area', label: '🏔️ Denver Metropolitan Area' },
  { value: 'phoenix_metropolitan_area', label: '🌵 Phoenix Metropolitan Area' },
  { value: 'open_to_all', label: '🌍 Open to All Locations' },
];

const industryOptions = [
  { value: 'Software Engineering', label: '💻 Software Engineering' },
  { value: 'Data Science', label: '📊 Data Science' },
  { value: 'Cybersecurity & IT', label: '🔒 Cybersecurity & IT' },
  { value: 'Product', label: '🛠️ Product' },
  { value: 'Marketing', label: '📣 Marketing' },
  { value: 'Sales', label: '💼 Sales' },
  { value: 'Finance', label: '💰 Finance' },
  { value: 'Consulting', label: '🧑‍💼 Consulting' },
  { value: 'Logistics & Operations', label: '📦 Logistics & Operations' },
  { value: 'UI/UX & Design', label: '🎨 UI/UX & Design' },
  { value: 'Mechanical Engineering', label: '⚙️ Mechanical Engineering' },
  { value: 'Electrical Engineering', label: '🔌 Electrical Engineering' },
  { value: 'Civil Engineering', label: '🏗️ Civil Engineering' },
  { value: 'Chemical Engineering', label: '⚗️ Chemical Engineering' },
  { value: 'Aerospace Engineering', label: '✈️ Aerospace Engineering' },
  { value: 'Robotics & Automation', label: '🤖 Robotics & Automation' },
  { value: 'Biomedical Engineering', label: '🧬 Biomedical Engineering' },
  { value: 'Accounting', label: '📈 Accounting' },
];

const jobTypeOptions = [
    { value: 'full-time', label: '💼 Full-time' },
    { value: 'internship', label: '🎓 Internship' },
    { value: 'part-time', label: '⌛ Part-time' },
    { value: 'contract', label: '📝 Contract' }
];

const toastConfig: ToastOptions = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "light",
  style: {
    background: "#ffffff",
    color: "#000000",
    fontSize: "15px",
    fontWeight: 450,
    borderRadius: "4px",
    padding: "8px 16px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    border: "none",
    width: "fit-content",
    minWidth: "fit-content",
    maxWidth: "calc(100vw - 48px)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "12px",
    margin: "0 24px 24px 0",
    whiteSpace: "nowrap",
  },
  className: "custom-toast",
  icon: false,
  closeButton: ({ closeToast }) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '44px',
      width: '28px',
      flexShrink: 0,
    }}>
      <button
        onClick={(e) => {
          closeToast?.(e);
          const toastElement = document.querySelector(".Toastify__toast");
          if (toastElement) {
            toastElement.remove();
          }
        }}
        className="toast-close-button"
        style={{
          background: "transparent",
          border: "none",
          padding: 0,
          cursor: "pointer",
          opacity: 0.6,
          transition: "opacity 0.2s ease",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 3L3 13M3 3L13 13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </button>
    </div>
  ),
};

const successToastConfig: ToastOptions = {
  ...toastConfig,
  icon: (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      height: '44px',
      width: '20px',
      flexShrink: 0,
    }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289C15.3166 6.90237 14.6834 6.90237 14.2929 7.29289L9 12.5858L6.70711 10.2929C6.31658 9.90237 5.68342 9.90237 5.29289 10.2929C4.90237 10.6834 4.90237 11.3166 5.29289 11.7071L8.29289 14.7071C8.68342 15.0976 9.31658 15.0976 9.70711 14.7071L15.7071 8.70711Z"
          fill="#22C55E"
        />
      </svg>
    </div>
  ),
};

const errorToastConfig: ToastOptions = {
  ...toastConfig,
  icon: (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      height: '44px',
      width: '20px',
      flexShrink: 0,
    }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L11.4142 10L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L10 11.4142L7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929L8.58579 10L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L10 8.58579L12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289Z"
          fill="#FF4B55"
        />
      </svg>
    </div>
  ),
};

const Onboarding: React.FC = () => {
  const [step, setStep] = useState(1);
  const totalSteps = 7;
  const [fadeClass, setFadeClass] = useState('fade-in');
  const [loading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [resumeData, setResumeData] = useState<any>();
  const navigate = useNavigate();
  const [jobRole, setJobRole] = useState('');
  const [applicationGoal, setApplicationGoal] = useState('');
  const [suggestedText, setSuggestedText] = useState('');
  const [matchingJob, setMatchingJob] = useState<JobOption | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);

  const nextStep = () => {
    // Validation for each step
    /* Commenting out location validation
    if (step === 3 && selectedLocations.length === 0) {
      toast('Please select at least one location', errorToastConfig);
      return;
    }
    */
    
    if (step === 4 && selectedJobTypes.length === 0) {
      toast('Please select at least one job type', errorToastConfig);
      return;
    }
    
    if (step === 5 && selectedIndustries.length === 0) {
      toast('Please select at least one industry', errorToastConfig);
      return;
    }
    
    if (step === 6 && !jobRole.trim()) {
      toast('Please enter your desired job role', errorToastConfig);
      return;
    }
    
    if (step === 7 && !applicationGoal) {
      toast('Please enter your weekly application goal', errorToastConfig);
      return;
    }

    if (step < totalSteps) {
      setFadeClass('fade-out');
      setTimeout(() => {
        setStep(step + 1);
        setFadeClass('fade-in');
      }, 400);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setFadeClass('fade-out');
      setTimeout(() => {
        setStep(step - 1);
        setFadeClass('fade-in');
      }, 400);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      handleFileUpload(selectedFile);
    } else {
      setLoading(false);
    }
  };

  const handleFileUpload = async (file: File) => {
    if (!file) {
      toast('Please select a file', errorToastConfig);
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('resume', file);

    try {
      console.log('Starting resume upload...');
      const response = await axiosClient.post('/builder/resume-parser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000,
      });
      
      console.log('Resume upload response:', response);
      
      // Check if we have data in the response
      const responseData = response.data;
      console.log('Raw response data:', responseData);

      let parsedData;
      try {
        // Try to parse if it's a string, otherwise use as is
        parsedData = typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
        console.log('Successfully parsed data:', parsedData);
      } catch (parseError) {
        console.error('Error parsing response:', parseError);
        throw new Error('Failed to parse server response');
      }

      // Validate the parsed data has required fields
      if (!parsedData) {
        console.error('Invalid resume data:', parsedData);
        throw new Error('Invalid resume data received');
      }

      setLoading(false);
      setResumeData(parsedData);
      toast('Resume Uploaded Successfully', successToastConfig);
    } catch (error: any) {
      setLoading(false);
      const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
      console.error('Upload error details:', {
        message: errorMessage,
        response: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText,
        rawError: error
      });
      toast(`Failed to upload resume: ${errorMessage}`, errorToastConfig);
    }
  };

  const showToast = (message: string) => {
    toast(message, errorToastConfig);
  };

  const handleNavigationKeyDown = (e: globalThis.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      nextStep();
    }
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.repeat && document.activeElement?.tagName !== 'INPUT') {
        e.preventDefault();
        nextStep();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [step]);

  const handleFinish = async () => {
    // Final validation before finishing
    if (!selectedIndustries.length || !jobRole.trim() || !applicationGoal) {
      toast('Please complete all required fields before proceeding', errorToastConfig);
      return;
    }

    const user = JSON.parse(String(localStorage.getItem('user')));
    const token = localStorage.getItem('accessToken');
    
    if (user && token) {
      try {
        // Ensure token is set in axios headers
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        const response = await axiosClient.patch(`users/${user._id}`, {
          ...resumeData,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          userOnboarding: true,
          selectedIndustries,
          desiredJobRole: jobRole,
          applicationGoal: parseInt(applicationGoal),
          preferred_job_types: selectedJobTypes,
        });
        
        // Update local storage with new user data including response data
        const updatedUser = {
          ...user,
          ...response.data,  // Include any updated fields from the response
          userOnboarding: true,
          desiredJobRole: jobRole,
          applicationGoal: parseInt(applicationGoal),
          preferred_job_types: selectedJobTypes,
        };
        localStorage.setItem('user', JSON.stringify(updatedUser));

        const data = await axiosClient.get(`/auth/send-welcome-email/${user._id}`)
        if(data.data) {
          toast('Welcome email sent successfully', successToastConfig);
        } else {
          toast('Failed to send welcome email', errorToastConfig);
        }
        
        // Navigate to dashboard with replace to prevent back navigation
        navigate('/dashboard', { replace: true });
      } catch (error: any) {
        console.error('Error updating user:', error);
        // Check if it's an authentication error
        if (error.response?.status === 401) {
          const refreshToken = localStorage.getItem('refreshToken');
          if (refreshToken) {
            try {
              // Try to refresh the token
              const refreshResponse = await axiosClient.post('/auth/refresh-tokens', {
                refreshToken
              });
              const { accessToken } = refreshResponse.data;
              localStorage.setItem('accessToken', accessToken);
              // Retry the update
              handleFinish();
              return;
            } catch (refreshError) {
              console.error('Token refresh failed:', refreshError);
            }
          }
        }
        toast('Error updating profile. Please try again.', errorToastConfig);
      }
    } else {
      toast('Session expired. Please login again.', errorToastConfig);
      navigate('/auth/login', { replace: true });
    }
  };

  const toggleLocation = (value: string) => {
    if (value === 'open_to_all') {
      if (selectedLocations.includes('open_to_all')) {
        setSelectedLocations([]);
      } else {
        setSelectedLocations(locationOptions.map((option) => option.value));
      }
    } else {
      setSelectedLocations((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    }
  };

  const toggleIndustry = (industry: string) => {
    setSelectedIndustries((prev) =>
      prev.includes(industry) ? prev.filter((item) => item !== industry) : [...prev, industry]
    );
  };

  // Inline autocomplete functions
  const handleJobRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setJobRole(inputValue);

    if (inputValue === '') {
      setSuggestedText('');
      setMatchingJob(null);
      return;
    }

    const matching = jobTitles.find((job) =>
      job.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );

    if (matching) {
      setSuggestedText(matching.label.slice(inputValue.length));
      setMatchingJob(matching);
    } else {
      setSuggestedText('');
      setMatchingJob(null);
    }
  };

  const handleJobRoleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      if (suggestedText !== '') {
        e.preventDefault();
        if (matchingJob) {
          setJobRole(matchingJob.label);
          setSuggestedText('');
          setMatchingJob(null);
        }
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (jobRole.trim()) {
        nextStep();
      }
    }
  };

  const handleApplicationGoalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setApplicationGoal(e.target.value);
  };

  const handleApplicationGoalKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (applicationGoal.trim()) {
        nextStep();
      }
    }
  };

  const handleJobTypeSelect = (jobType: string) => {
    setSelectedJobTypes(prev => 
        prev.includes(jobType)
            ? prev.filter(t => t !== jobType)
            : [...prev, jobType]
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="welcome-container">
            <h1>Welcome to Your Personalized&nbsp;Job&nbsp;Search</h1>
            <p>
              Let's customize your experience to connect you with the
              perfect&nbsp;opportunities.
            </p>
            <button className="start-button" onClick={nextStep}>
              Get Started
            </button>
          </div>
        );
      case 2:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            <h1>Upload Your Resume</h1>
            <p className="resume-upload-description">
              We'll use this to automatically fill your profile details and match you with relevant jobs.
            </p>

            <FileUploader
              onFileSelect={handleFileChange}
              loading={loading}
              file={file}
              accept=".pdf,.docx"
            />

            <div className="next-wrapper">
              <button onClick={nextStep} disabled={loading} className="nav-button next-button">
                {loading ? (
                  <div className="loading-state-btn">
                    <div className="spinner" />
                  </div>
                ) : (
                  'Next'
                )}
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
              <div className="ml-10">
                <button onClick={nextStep} disabled={loading}>
                  Skip
                </button>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            <h1>What types of roles are you looking for?<span className="required">*</span></h1>
            <div className="bubble-group">
              {jobTypeOptions.map((option) => (
                <div
                  key={option.value}
                  className={`bubble ${selectedJobTypes.includes(option.value) ? 'selected' : ''}`}
                  onClick={() => handleJobTypeSelect(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>
            <div className="next-wrapper">
              <button onClick={nextStep} className="nav-button next-button">
                Next
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            <h1>Preferred Industry<span className="required">*</span></h1>
            <div className="bubble-group">
              {industryOptions.map((option) => (
                <div
                  key={option.value}
                  className={`bubble ${selectedIndustries.includes(option.value) ? 'selected' : ''}`}
                  onClick={() => toggleIndustry(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>
            <div className="next-wrapper">
              <button onClick={nextStep} className="nav-button next-button">
                Next
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            <h1>Desired Job Role<span className="required">*</span></h1>
            <div className="ghost-input-wrapper">
              <div className="ghost-input-container">
                <input
                  type="text"
                  ref={inputRef}
                  placeholder="type here"
                  value={jobRole}
                  onChange={handleJobRoleChange}
                  onKeyDown={handleJobRoleKeyDown}
                  className="job-role-input"
                  autoFocus
                  required
                />
                <div className="ghost-text">
                  {jobRole}
                  <span className="suggested-text">{suggestedText}</span>
                </div>
              </div>
            </div>
            <p className="autocomplete-instruction">
              Press <strong>Tab</strong> or <strong>→</strong> to accept the suggestion
            </p>
            <div className="next-wrapper">
              <button onClick={nextStep} className="nav-button next-button">
                Next
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            <h1>Weekly Application Goal<span className="required">*</span></h1>
            <p className="resume-upload-description">
              Recommended minimum: 30 applications per week
            </p>
            <input
              type="number"
              placeholder="type a number"
              autoFocus
              spellCheck="false"
              className="job-role-input"
              onBlur={(e) => e.target.focus()}
              value={applicationGoal}
              onChange={handleApplicationGoalChange}
              onKeyDown={handleApplicationGoalKeyDown}
              required
              style={{
                WebkitAppearance: 'none',
                MozAppearance: 'textfield'
              }}
            />
            <div className="next-wrapper">
              <button onClick={nextStep} className="nav-button next-button">
                Next
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <>
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
              pointerEvents: 'none'
            }}>
              <ReactConfetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                numberOfPieces={200}
                recycle={true}
                colors={['#0033A0', '#6C63FF', '#5A52D0', '#FFD700', '#FF69B4']}
                gravity={0.3}
                tweenDuration={5000}
              />
            </div>
            <div className="centered-step">
              <h1>You're All Set!</h1>
              <p>Thank you for providing your information.</p>
              <button className="finish-button" onClick={handleFinish}>
                Finish
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboarding-container">
      <ProgressBar currentStep={step} totalSteps={totalSteps} />
      {step === 1 && (
        <div className="floating-emoji-background">
          <div className="emoji emoji-1">🚀</div>
          <div className="emoji emoji-2">🌟</div>
          <div className="emoji emoji-3">💼</div>
        </div>
      )}
      <div className={`step-content ${fadeClass}`}>{renderStepContent()}</div>
    </div>
  );
};

export default Onboarding;
