import React, { useState, useEffect, useRef } from "react";
import "../../styles/components/landing-pages/navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../assets/logo.svg";
import { IoIosArrowDown } from "react-icons/io";

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mousePositionRef = useRef({ x: 0, y: 0 });
  const dropdownRefs = useRef<{ [key: string]: { trigger: HTMLElement | null; menu: HTMLElement | null } }>({
    aiFeatures: { trigger: null, menu: null },
    more: { trigger: null, menu: null }
  });
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 1024;

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    
    if (newIsOpen) {
      // Lock scroll when menu opens
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      // Restore scroll when menu closes
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  };

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, []);

  const handleJobsClick = () => {
    navigate('/jobs');
  };

  const handleDropdownClick = (dropdownId: string, event: React.MouseEvent) => {
    if (isMobile) {
      event.preventDefault();
      setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
    }
  };

  const handleMouseEnter = (dropdownId: string) => {
    if (!isMobile) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setActiveDropdown(dropdownId);
    }
  };

  const handleMouseLeave = (dropdownId: string, event: React.MouseEvent) => {
    if (!isMobile) {
      const trigger = dropdownRefs.current[dropdownId].trigger;
      const menu = dropdownRefs.current[dropdownId].menu;
      
      if (!trigger || !menu) return;

      const triggerRect = trigger.getBoundingClientRect();
      const menuRect = menu.getBoundingClientRect();
      
      // Store current mouse position
      mousePositionRef.current = { x: event.clientX, y: event.clientY };
      
      // Calculate if mouse is moving towards the dropdown
      const isMovingTowardsMenu = 
        event.clientY >= triggerRect.bottom && // Moving downwards
        event.clientY <= menuRect.bottom && // Not past the menu
        event.clientX >= menuRect.left - 50 && // Within menu bounds (with padding)
        event.clientX <= menuRect.right + 50; // Within menu bounds (with padding)

      if (!isMovingTowardsMenu) {
        timeoutRef.current = setTimeout(() => {
          setActiveDropdown(null);
        }, 100);
      }
    }
  };

  const handleMenuMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleMenuMouseLeave = () => {
    setActiveDropdown(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeDropdown) {
        const dropdown = dropdownRefs.current[activeDropdown];
        const clickedElement = event.target as HTMLElement;
        
        // Check if click is outside both trigger and menu
        const isOutsideTrigger = dropdown.trigger && !dropdown.trigger.contains(clickedElement);
        const isOutsideMenu = dropdown.menu && !dropdown.menu.contains(clickedElement);
        
        if (isOutsideTrigger && isOutsideMenu) {
          setActiveDropdown(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <div className="navbar-left">
          <Link to="/">
            <img src={logoImage} alt="Company Logo" className="logo" />
          </Link>
          <div className={`nav-menu ${isOpen ? "active" : ""}`}>
            <ul className={`nav-list ${isOpen ? "active" : ""}`}>
              <li className="nav-item">
                <Link to="/jobs" className="nav-link" onClick={handleJobsClick}>
                  Jobs
                </Link>
              </li>
              <li className="nav-item dropdown-container">
                <span 
                  className="nav-link dropdown-trigger"
                  ref={el => dropdownRefs.current.aiFeatures.trigger = el}
                  onClick={(e) => handleDropdownClick('aiFeatures', e)}
                  onMouseEnter={() => handleMouseEnter('aiFeatures')}
                  onMouseLeave={(e) => handleMouseLeave('aiFeatures', e)}
                >
                  Features <IoIosArrowDown className={`dropdown-arrow ${activeDropdown === 'aiFeatures' ? 'open' : ''}`} />
                </span>
                <div 
                  className={`dropdown-menu ${activeDropdown === 'aiFeatures' ? 'active' : ''} ${window.innerWidth > 1024 ? 'features-dropdown' : ''}`}
                  ref={el => dropdownRefs.current.aiFeatures.menu = el}
                  onMouseEnter={!isMobile ? handleMenuMouseEnter : undefined}
                  onMouseLeave={!isMobile ? handleMenuMouseLeave : undefined}
                >
                  <Link to="/features/resume-tailoring" className="dropdown-item">Resume Tailoring</Link>
                  <Link to="/features/cover-letter" className="dropdown-item">Cover Letter Generation</Link>
                  {/* <Link to="/features/outreach" className="dropdown-item">Outreach AI</Link> */}
                  {/* <Link to="/features/application-tracker" className="dropdown-item">Application Tracker</Link> */}
                  <Link 
                    to="/features/mock-interviews" 
                    className="dropdown-item coming-soon"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="coming-soon-tag">Coming Soon</span>
                    Mock Interviews
                  </Link>
                  <Link 
                    to="/features/career-coaching" 
                    className="dropdown-item coming-soon"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="coming-soon-tag">Coming Soon</span>
                    Career Coaching
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/pricing" className="nav-link">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
            </ul>
            {/* Move auth links outside of nav-list */}
            <div className="mobile-auth-link">
              <Link to="/auth/login" className="login-link">
                Login
              </Link>
              <Link to="/auth/signup" className="get-started">
                Get Started
              </Link>
            </div>
          </div>
        </div>
        {/* Only show Login and Get Started on larger screens */}
        <div className="navbar-right">
          <Link to="/auth/login" className="login-link">
            Login
          </Link>
          <Link to="/auth/signup" className="get-started">
            Get Started
          </Link>
        </div>
        <button className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
