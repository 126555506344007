import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiUser, FiLogOut, FiMenu, FiChevronUp } from 'react-icons/fi';
import { MessagesSquare, Sparkles, Wallet } from 'lucide-react';
import '../styles/components/sidebar.scss';
import DashboardIcon from '../assets/sidebar-icons/dashboard.svg';
import JobSearchIcon from '../assets/sidebar-icons/job-search.svg';
import BYOJIcon from '../assets/sidebar-icons/byoj.svg';
import ApplicationTrackerIcon from '../assets/sidebar-icons/application-tracker.svg';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [userData, setUserData] = useState<{ firstName: string; lastName: string; email: string } | null>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const [dropdownAnimation, setDropdownAnimation] = useState<'show' | 'hide' | ''>('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        handleDropdownClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUserData({
        firstName: parsedUser.firstName || '',
        lastName: parsedUser.lastName || '',
        email: parsedUser.email || ''
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    navigate('/');
    window.location.reload()
  };

  const toggleSidebar = () => {
    if (isExpanded) {
      setIsHovered(false);
      setShowProfileDropdown(false);
    }
    setIsExpanded(!isExpanded);
  };

  const handleDropdownClose = () => {
    setDropdownAnimation('hide');
    setTimeout(() => {
      setShowProfileDropdown(false);
      setDropdownAnimation('');
    }, 200);
  };

  const toggleProfileDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (showProfileDropdown) {
      handleDropdownClose();
    } else {
      setShowProfileDropdown(true);
      setDropdownAnimation('show');
    }
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const getInitials = () => {
    if (!userData) return '';
    return `${userData.firstName.charAt(0)}${userData.lastName.charAt(0)}`.toUpperCase();
  };

  const handleOverlayClick = () => {
    if (isMobile) {
      setIsExpanded(false);
      setShowProfileDropdown(false);
    }
  };

  return (
    <>
      {isMobile && (
        <button className="mobile-menu-button" onClick={toggleSidebar}>
          <FiMenu />
        </button>
      )}

      {isMobile && isExpanded && (
        <div className="sidebar-overlay visible" onClick={handleOverlayClick} />
      )}

      <div
        className={`sidebar ${isExpanded ? 'expanded' : ''} ${isHovered && !isExpanded ? 'hovered' : ''}`}
        onMouseEnter={() => !isMobile && !isExpanded && setIsHovered(true)}
        onMouseLeave={() => {
          if (!isMobile && !isExpanded) {
            setIsHovered(false);
            handleDropdownClose();
          }
        }}
      >
        <div className="sidebar-top">
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            <FiMenu />
          </div>
          <div className={`sidebar-icon ${isActive('/dashboard') ? 'active' : ''}`} onClick={() => navigate('/dashboard')}>
            <img src={DashboardIcon} alt="Dashboard" className="custom-icon" />
            <span className="icon-text">Dashboard</span>
          </div>
          <div className={`sidebar-icon ${isActive('/job-search') ? 'active' : ''}`} onClick={() => navigate('/job-search')}>
            <img src={JobSearchIcon} alt="Job Search" className="custom-icon" />
            <span className="icon-text">Job Search</span>
          </div>
          <div className={`sidebar-icon ${isActive('/application-tracker') ? 'active' : ''}`} onClick={() => navigate('/application-tracker')}>
            <img src={ApplicationTrackerIcon} alt="Application Tracker" className="custom-icon" />
            <span className="icon-text">Application Tracker</span>
          </div>
          <div className={`sidebar-icon ${isActive('/bring-your-job') ? 'active' : ''}`} onClick={() => navigate('/bring-your-job')}>
            <img src={BYOJIcon} alt="Bring Your Own Job" className="custom-icon" />
            <span className="icon-text">Bring Your Own Job</span>
          </div>
        </div>
        <div className="sidebar-bottom">
          <div className="profile-section" ref={profileRef} onClick={toggleProfileDropdown}>
            <div className="profile-trigger">
              <div className="avatar-wrapper">
                <div className="avatar">{getInitials()}</div>
              </div>
              {(isExpanded || isHovered) && (
                <>
                  <div className="user-info">
                    <span className="user-name">{userData?.firstName} {userData?.lastName}</span>
                    <span className="user-email">{userData?.email}</span>
                  </div>
                  <FiChevronUp className={`trigger-icon ${showProfileDropdown ? 'rotated' : ''}`} />
                </>
              )}
            </div>
            {showProfileDropdown && (
              <div className={`profile-dropdown ${dropdownAnimation}`}>
                <div className="dropdown-section">
                  <div className="dropdown-item" onClick={() => navigate('/account-info')}>
                    <FiUser />
                    <span>Profile</span>
                  </div>
                  <div className="dropdown-item" onClick={() => navigate('/subscription')}>
                    <Wallet className="lucide-icon" />
                    <span>Manage Plan</span>
                  </div>
                </div>
                <div className="dropdown-section">
                  <div className="dropdown-item" onClick={handleLogout}>
                    <FiLogOut />
                    <span>Log out</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;