import React from 'react';
import '../../styles/components/applicationTracker/applicationCoverLetter.scss';

interface ApplicationCoverLetterProps {
  jobId: string;
  coverLetterContent: string | null;
}

const ApplicationCoverLetter: React.FC<ApplicationCoverLetterProps> = ({ jobId, coverLetterContent }) => {
  if (!coverLetterContent) {
    return (
      <div className="no-data-container">
        <p>No cover letter available</p>
      </div>
    );
  }

  // Split the content into paragraphs and render them with proper spacing
  const paragraphs = coverLetterContent.split(/\n\s*\n/).map(p => p.trim()).filter(Boolean);

  return (
    <div className="application-cover-letter">
      <div className="cover-letter-content">
        <div className="content">
          {paragraphs.map((paragraph, index) => (
            <p key={index} className="cover-letter-paragraph">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationCoverLetter; 