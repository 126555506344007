import { resetServerContext } from "react-beautiful-dnd";
import { Flip } from "react-toastify";
import { FormLabel } from "../components/ui/Form";
import { reSplitAlphaNumeric } from "@tanstack/react-table";

type LogoVariant = 'horizontal' | 'square' | 'vertical';

interface CompanyLogoMapping {
  companyName: string;  // Exact company name as it appears in the job listings
  logos: {
    [key in LogoVariant]?: string;  // Full filename including hash
  };
}

// Each entry maps the EXACT company name to its logo filenames
export const LOGO_MAPPINGS: CompanyLogoMapping[] = [
  {
    companyName: "3M",
    logos: {
      horizontal: "3M_horizontal-4nJphCkGhLhZQiHqyDaYzLOfhVenFD.png"
    }
  },
  {
    companyName: "3Pillar",
    logos: {
      horizontal: "3Pillar_horizontal-QLphbQlMLvXP3QQfsni6Vl54GWcH1b.png"
    }
  },
  {
    companyName: "6Sense",
    logos: {
      square: "6sense_square-MKR7Ubaxu06PpnJEzNa6umV8TW7Onb.png"
    }
  },
  {
    companyName: "A10 Networks",
    logos: {
      horizontal: "A10_Networks_horizontal-a99vQvSpObpPTOh3C8eiR6LavxFTs6.png"
    }
  },
  {
    companyName: "AbbVie",
    logos: {
      square: "abbvie_square-kqTPpfr6tP1v16w4ucUb4f5HTXHFdS.png"
    }
  },
  {
    companyName: "Abbott",
    logos: {
      square: "abbott_square-fjktEv7pAZ2q8Lte8YK0p8QOLmKHyy.png"
    }
  },
  {
    companyName: "ABC Fitness",
    logos: {
      square: "abcfitness_square-VvekPIAKS1t4Gl0yiKAq8ZFscQG319.png"
    }
  },
  {
    companyName: "Abnormal Security",
    logos: {
      square: "abnormal_security_square-N1EXNTDyOivk7jC5jfVfn37AsbPlxp.png"
    }
  },
  {
    companyName: "Abridge",
    logos: {
      square: "abridge_square-NBYh4TDxIfvUKiqPRF5ob4Ggyr0TjT.png"
    }
  },
  {
    companyName: "ACDS",
    logos: {
      horizontal: "acds_horizontal-pTC2tApm9u5QqJ0Ngk5O1zgueCkNpw.png"
    }
  },
  {
    companyName: "Accucode AI",
    logos: {
      square: "accucode_ai_square-eDNKFkQIGzsmzEVYYSMKulzKJbclDv.png"
    }
  },
  {
    companyName: "Activision",
    logos: {
      horizontal: "activision_horizontal-U6gnKMsT02MbUHonAdw2HV7BJ7h135.png"
    }
  },
  {
    companyName: "Ada",
    logos: {
      square: "Ada_square-VCIxmo8atbvj4Xj4div7gi5WLENUTo.png"
    }
  },
  {
    companyName: "Adaptavist",
    logos: {
      square: "adaptavist_square-kD5iWkJvH9TMmIcxQ8NUpWFStUkqnA.png"
    }
  },
  {
    companyName: "Adobe",
    logos: {
      square: "adobe_square-V5CXLzVz8q0Jjbuvol5k8d6dWMSb9L.png"
    }
  },
  {
    companyName: "Adentro",
    logos: {
      square: "adentro_square-C5AR5QRWEtpPrU8uyxJmxmk3NIiWDa.png"
    }
  },
  {
    companyName: "Advance Auto",
    logos: {
      square: "advancedautoparts_square-Jr27LDfJGxKqKIkhSEhCajA1nBWIvf.png"
    }
  },
  {
    companyName: "AECOM",
    logos: {
      horizontal: "AECOM_horizontal-CWyuKvNDHmtvmrhwxKVo6KwrlYPGWh.png"
    }
  },
  {
    companyName: "Aero",
    logos: {
      horizontal: "aero_horizontal-3nbTqbVpD4fusouC1zS42Qwq5fec6i.png"
    }
  },
  {
    companyName: "Affinitiv",
    logos: {
      square: "affinitiv_square-C53dGwmA3KheJIdkJIZPnoXPfeNP5T.png"
    }
  },
  {
    companyName: "Affirm",
    logos: {
      square: "affirm_square-D6jzNAnJXHLmqPUftWCLbED0kBXjXn.png"
    }
  },
  {
    companyName: "Age of Learning",
    logos: {
      square: "ageoflearning_horizontal-MWzwDCZC1DaUPXOUeezJsCVVW98VUl.png"
    }
  },
  {
    companyName: "AI Fund",
    logos: {
      square: "aifund_square-p36bXDhXRG5LVpca3YI0GFMGAPXbO6.png"
    }
  },
  {
    companyName: "Aigen",
    logos: {
      square: "aigen_square-yktnMBHuThWWEbbPqDkzX9zaJX6i5n.png"
    }
  },
  {
    companyName: "Air Liquide",
    logos: {
      square: "airliquide_square-KQvwvwb3C1oNqdYP2im1S332jdTjIl.png"
    }
  },
  {
    companyName: "Airbnb",
    logos: {
      square: "airbnb_square-GdoHff1CsbrCOIl4d15vgMNrs2wiHK.png"
    }
  },
  {
    companyName: "Airbus",
    logos: {
      square: "airbus_square-Nxvyc5JlcBaQ50V87XHggj86T7bEyn.png"
    }
  },
  {
    companyName: "Airbyte",
    logos: {
      square: "Airbyte_square-TacYHZQT25scc35Pjr9y8p7NgkAQEQ.png"
    }
  },
  {
    companyName: "Aircall",
    logos: {
      square: "aircall_square-XkULXaI7SUqj3FThtIeUXwX9BeIiKq.png"
    }
  },
  {
    companyName: "Aisera",
    logos: {
      square: "aisera_square-sCKVmnLtMrK4aWFPBqEkqbrhG1Xvxp.png"
    }
  },
  {
    companyName: "Albedo",
    logos: {
      square: "albedo_square-O6RSaTE9crmTs0hvzf5M6j2wupOp0B.png"
    }
  },
  {
    companyName: "Alchemy",
    logos: {
      square: "alchemy_square-pf1i53nSry6A1LRadoGEH9CRURj54X.png"
    }
  },
  {
    companyName: "Algolia",
    logos: {
      square: "algolia_square-fOczIqchgAgA81MkT3fH0U2DyllUTC.png"
    }
  },
  {
    companyName: "Aledade",
    logos: {
      square: "aledade_square-XeuXZbtT9ofwP4ccC2j1r52cv4P2RK.png"
    }
  },
  {
    companyName: "Allegiant Air",
    logos: {
      horizontal: "allegiantair_horizontal-PtFXA3DgaOhKhk6bEfQmvkuzyQUyNT.png"
    }
  },
  {
    companyName: "Alloy.ai",
    logos: {
      square: "alloyai_square-Kevj3VfFamB7QaXOisY6woQfsraZEQ.png"
    }
  },
  {
    companyName: "Allstate",
    logos: {
      square: "allstate_square-0nbaz9W1YmQhiPYXSDhhimfT40xtG8.png"
    }
  },
  {
    companyName: "AllTrails",
    logos: {
      square: "alltrails_square-ISyC6pKvQt10NbaoCz6IW8LwN8p8Ev.png"
    }
  },
  {
    companyName: "Alluxio",
    logos: {
      square: "Alluxio_square-p4vpEFkb2Sty5MSlDyY7VXF2NRdVKb.png"
    }
  },
  {
    companyName: "Alma",
    logos: {
      horizontal: "Alma_horizontal-ou2kySPLRZXRTRO59a63wRSXda6KqM.png"
    }
  },
  {
    companyName: "Alpaca",
    logos: {
      square: "alpaca_square-RQJJNCjWpkIBZkECank4yYPIHJx1AR.png"
    }
  },
  {
    companyName: "AlphaSense",
    logos: {
      square: "AlphaSense_square-Rr90gkDOAk1YmqtUZd1f2grsThzqEk.png"
    }
  },
  {
    companyName: "Alpinestars",
    logos: {
      horizontal: "alpinestars_horizontal-fIRBC7x2Osh2PTGWUqZ5kv0W10BJpY.png"
    }
  },
  {
    companyName: "Altana",
    logos: {
      square: "altana_square-0Fxeoa1nI933UtDAXsroP5uiBp8xpC.png"
    }
  },
  {
    companyName: "Amazon",
    logos: {
      square: "amazon_square-QD5W5XyNxp81QAF3VFutwD0iw7DZTK.png"
    }
  },
  {
    companyName: "American Express",
    logos: {
      square: "amex_gbt_square-7JgifltglyBl7ReEfhSt8XU5fJ5vz2.png"
    }
  },
  {
    companyName: "American Express GBT",
    logos: {
      square: "amex_gbt_square-7JgifltglyBl7ReEfhSt8XU5fJ5vz2.png"
    }
  },
  {
    companyName: "Amplitude",
    logos: {
      square: "amplitude_square-EtDcrkZt7RJtT9Pw5PbY3WlUosIgbx.png"
    }
  },
  {
    companyName: "Analog Devices",
    logos: {
      horizontal: "analog_devices_horizontal-FIqvUeY3mYqsyGNcHDbvOBKnCll2VZ.png"
    }
  },
  {
    companyName: "Analytic Partners",
    logos: {
      square: "analytic_square-ue76ETqquDFaPF6IUAWKBURcr3MoJw.png"
    }
  },
  {
    companyName: "Anaplan",
    logos: {
      square: "anaplan_square-fTaAP2wtyO5hY2vng7ag5BXLQJSHv3.png"
    }
  },
  {
    companyName: "Anduril Industries",
    logos: {
      square: "Anduril%20Industries_square-8HmLl1SeOyKrlI7XvHnwIHRdUUOU3W.png"
    }
  },
  {
    companyName: "Angi",
    logos: {
      horizontal: "angi_horizontal-KHOm9KMazC1oQWLKbM6Jms8YqF4Zch.png"
    }
  },
  {
    companyName: "Anthropic",
    logos: {
      square: "Anthropic_square-yBgx69W7zW16bqC5MnSGr8JLapYMis.png"
    }
  },
  {
    companyName: "Anyscale",
    logos: {
      square: "Anyscale_square-KZQ28YLXmg7Y5rJAJQAzOYTGNJWk6r.png"
    }
  },
  {
    companyName: "Apollo",
    logos: {
      square: "apollo_square-YEGkK9AgPBQuhstXo54FGsy1Mm8RJW.png"
    }
  },
  {
    companyName: "Appen",
    logos: {
      square: "appen_square-HAfkSAlDNaFk78AB1SvrAe6tEcnHqF.png"
    }
  },
  {
    companyName: "Apple",
    logos: {
      square: "apple_square-uweqDAX1PK7rwpdXzpewmCqw5ZiA4i.png"
    }
  },
  {
    companyName: "AppLovin",
    logos: {
      square: "AppLovin_square-5bLwQmGGBZWga2ZlR14v04m2nSY68J.png"
    }
  },
  {
    companyName: "Applied Materials",
    logos: {
      square: "applied_materials_square-6b3NHEOv3jhrJpacc1KRTwPSXOQQ1Z.png"
    }
  },
  {
    companyName: "Apprenticely",
    logos: {
      horizontal: "apprenticely_horizontal-tY88OkLoQbcAYJ9GeNQU70S8Ui42Jj.png"
    }
  },
  {
    companyName: "Aptos",
    logos: {
      square: "aptos_square-fRNPrXR4c4uW957DZB9Ukr50HwoKK1.png"
    }
  },
  {
    companyName: "Arc Institute",
    logos: {
      square: "arc_square-hszSHS7nLuho1FRgUFhkdvt5dOXZvT.png"
    }
  },
  {
    companyName: "Arcade",
    logos: {
      horizontal: "arcade_horizontal-fVFYRHB3hBoTPDhSoh9EKjP4p1w6np.png"
    }
  },
  {
    companyName: "Arcadia",
    logos: {
      square: "Arcadia_square-NEDEt2lby4zJEvAjCom4y145mwEOuX.png"
    }
  },
  {
    companyName: "Archive",
    logos: {
      horizontal: "archive_horizontal-5yqHuhzWoViJxoiwslbgdFqvqeWv0j.png"
    }
  },
  {
    companyName: "Arena AI",
    logos: {
      square: "Arena_square-66Up52KkpkZd7hK4TvKqMEvmiFic8F.png"
    }
  },
  {
    companyName: "Argo Labs",
    logos: {
      square: "argo_labs_square-s8eQQlnNR7XxBURRV4VJBjzeHzE15k.png"
    }
  },
  {
    companyName: "ArsenalBio",
    logos: {
      square: "arsenal_bio_square-5Ls3d2mEponhUpI3wgVUM7HNOVBOGf.png"
    }
  },
  {
    companyName: "Arrow",
    logos: {
      horizontal: "arrow_horizontal-Ikxi3NFCpWEtCrwOhuq1aiRueWpC0R.png"
    }
  },
  {
    companyName: "Asana",
    logos: {
      square: "asana_square-mwaU2TNhnpulw29H3nex6EKLmVYcSo.png"
    }
  },
  {
    companyName: "Ashby",
    logos: {
      square: "ashby_square-lNG1rQghfbrqEV1VqQAsmZ43j6Hep9.png"
    }
  },
  {
    companyName: "ASAPP",
    logos: {
      square: "asapp_square-9NNU9yP7kZ6WFv2Oc2I1o0l6tnLAna.png"
    }
  },
  {
    companyName: "ASM Global",
    logos: {
      horizontal: "asm_global_horizontal-iwHwR2urbkNtCeBZo0CbgacREr5k6W.png"
    }
  },
  {
    companyName: "Assurant",
    logos: {
      square: "assurant_square-FOWu6QCZ7B6QLY4OnNHoKtA3VrGk4j.png"
    }
  },
  {
    companyName: "Astranis",
    logos: {
      square: "astranis_square-gTi77hfktfxKnHnGCdfbAW7rYL7Mvg.png"
    }
  },
  {
    companyName: "AssetWatch",
    logos: {
      square: "asset_watch_square-EAqoYBBW0TFD4qGuw5z3qt15fpk2p9.png"
    }
  },
  {
    companyName: "Assured",
    logos: {
      square: "assured_square-AvIuZlsmLPbPjIgp70NNaKII7BuF3S.png"
    }
  },
  {
    companyName: "Atlan",
    logos: {
      horizontal: "atlan_horizontal-0koCc10banAkMMQvlCdTDqhqCMIioX.png"
    }
  },
  {
    companyName: "Atlas",
    logos: {
      square: "atlas_square-dlnSCOrOEJ4O2yr4iLhJsyateMfIQj.png"
    }
  },
  {
    companyName: "Atwell",
    logos: {
      square: "atwell_square-KlIpQpE0ro79sHD2BdqjsxmtYzbtHD.png"
    }
  },
  {
    companyName: "AtoB",
    logos: {
      square: "atob_square-o8AwEe1noovl2NNHHPo9iUjzSsyxED.png"
    }
  },
  {
    companyName: "Atomic",
    logos: {
      square: "atomic_square-TSSvZaAiFsvzdtVjq67cVxYCq2queP.png"
    }
  },
  {
    companyName: "Atomic Machines",
    logos: {
      square: "atomic_machines_square-14r5JfXtLyIMskkbzebmvnmyWOPWTw.png"
    }
  },
  {
    companyName: "Attabotics",
    logos: {
      horizontal: "attabotics_horizontal-plVnsKaKCrY9SFyPBUihtsjkfTeirg.png"
    }
  },
  {
    companyName: "Attentive",
    logos: {
      horizontal: "attentive_horizontal-moRoYWME6OcbvNk0Df8qnoP5dcgAbt.png"
    }
  },
  {
    companyName: "Audicus",
    logos: {
      square: "audicus_square-WduOL9UcEHt19Zs0GaaV4sqeLXS3Ay.png"
    }
  },
  {
    companyName: "Aurora",
    logos: {
      square: "Aurora_square-PDHRrlGfKK7INmmiLVvIvDWvrg0AA2.png"
    }
  },
  {
    companyName: "Autodesk",
    logos: {
      square: "autodesk_square-iprtnEOpSEY0Iie7oUj8hkcmWedJq6.png"
    }
  },
  {
    companyName: "Automation Anywhere",
    logos: {
      square: "automationAnywhere_square-gEqWhHPMuzTLTOGcS2vvW0ENCto7Sq.png"
    }
  },
  {
    companyName: "Automattic",
    logos: {
      square: "automattic_square-BChBR9SO9u45Hem68czxC90T1jtyTi.png"
    }
  },
  {
    companyName: "Avant",
    logos: {
      square: "avant_square-7oL80H2qsGGERvVlCegNhTQ2vwD5TR.png"
    }
  },
  {
    companyName: "Ava Labs",
    logos: {
      square: "avalabs_horizontal-0KCtQ54MLTDsZvD0Zkyk7j07HwXKCh.png"
    }
  },
  {
    companyName: "Avara",
    logos: {
      square: "Avara_square-PSwIu8t7WTiy6EfVHGW3Ji1KoOYKtA.png"
    }
  },
  {
    companyName: "Avis",
    logos: {
      square: "avis_square-4DnEHtZzEoehZdD5bOL2zypIiutwvc.png"
    }
  },
  {
    companyName: "Axiom Zen",
    logos: {
      square: "axiomzen_square-fSYiMVkb9FyQ8RByKUXicEoxXrLb6I.png"
    }
  },
  {
    companyName: "Bank of America",
    logos: {
      horizontal: "bank_of_america_horizontal-Ufi9azvwY7qKou6jwXi3kf4Y6Ptctn.png"
    }
  },
  {
    companyName: "Baton",
    logos: {
      horizontal: "baton_horizontal-k8tnBxNbZOJEM6j1vFjX8SZ1QmlKyE.png"
    }
  },
  {
    companyName: "BD",
    logos: {
      horizontal: "bd_horizontal-C7ZPAdbNU5I4meVKipMENUoOoS7toU.png"
    }
  },
  {
    companyName: "BDG",
    logos: {
      horizontal: "BDG_horizontal-986Bd2U8tovgvt8Ng9Ge9B1Yr2Y81C.png"
    }
  },
  {
    companyName: "Beacon Biosignals",
    logos: {
      square: "beacon_biosignals_square-tGUcd3PY23Qw9J7PbQo7bjl7xo96nX.png"
    }
  },
  {
    companyName: "Beam",
    logos: {
      horizontal: "beam_horizontal-4sptjOQnqxGQVwLVewW3F89faeDOlX.png"
    }
  },
  {
    companyName: "Bed Bath & Beyond",
    logos: {
      square: "beyond_square-YamapuDXB4SB9ktWvClDmM97mCZJtH.png"
    }
  },
  {
    companyName: "Belkin",
    logos: {
      horizontal: "Belkin_horizontal-wNTaFLqEjeFyejx1js5qiDByDe5cg8.png"
    }
  },
  {
    companyName: "Belvedere Trading",
    logos: {
      square: "Belvedre_Trading_square-tMAeBVrckO8FX7TX8XVEtEo6oBrKi8.png"
    }
  },
  {
    companyName: "Bellese",
    logos: {
      square: "bellese_square-x0L90SNxH7o0K7ZQqWWJwqItFZPJhZ.png"
    }
  },
  {
    companyName: "Benchling",
    logos: {
      square: "benchling_square-AOmP1VQ8CXupHfPkX0zbr6vm0HIHHm.png"
    }
  },
  {
    companyName: "Beta",
    logos: {
      horizontal: "beta_horizontal-NJxQDK8ULD2xZu9lxvheR09wbboCjt.png"
    }
  },
  {
    companyName: "Beta Engineering",
    logos: {
      horizontal: "beta_engineering_horizontal-EvRWCEq0oy7sRYfb1ouKp483G08Yh4.png"
    }
  },
  {
    companyName: "Beta Technologies",
    logos: {
      horizontal: "beta_technologies_horizontal-XjTtn8hWmN7h3uYYrpCEmr9KYRgptb.png"
    }
  },
  {
    companyName: "Beyond Finance",
    logos: {
      square: "Beyond_Finance_square-ivO7fUcRpxXsVjaBt4WBNDRU944adR.png"
    }
  },
  {
    companyName: "Big Time",
    logos: {
      horizontal: "bigtime_horizontal-rTch552pQ90rZANo2AL89JKEGwDMzB.png"
    }
  },
  {
    companyName: "BillionToOne",
    logos: {
      horizontal: "billiontoone_horizontal-Db49AoGrZQdV6F05CixesjWXCIBULd.png"
    }
  },
  {
    companyName: "Bilt Rewards",
    logos: {
      square: "bilt_rewards_square-naCZ0F2weRqJciQgLgcyXMJftmjeNn.png"
    }
  },
  {
    companyName: "Binance",
    logos: {
      square: "binance_square-wMtFMUO3weUYpDFo4X1qanSDeHZdf9.png"
    }
  },
  {
    companyName: "Bitcoin Depot",
    logos: {
      square: "bitcoin_depot_square-5g9LcYYxKNjAAED1fm49Z9ZhAKAjop.png"
    }
  },
  {
    companyName: "BitGo",
    logos: {
      square: "bitgo_square-bEnJMLNczXEIYETQB5k7ncrDnvvvnw.png"
    }
  },
  {
    companyName: "Bixal",
    logos: {
      horizontal: "bixal_horizontal-Tx0Eh4cIWGTcI1prG1P69qT7mozGID.png"
    }
  },
  {
    companyName: "Blackbaud",
    logos: {
      square: "Blackboud_square-352BsJQqwHqXYbR9tf1i7mve80avc9.png"
    }
  },
  {
    companyName: "BlackRock",
    logos: {
      horizontal: "BlackRock_horizontal-Ko8VRRBCjHg9sXqUjCzgj3CzIEOqnB.png"
    }
  },
  {
    companyName: "Blend",
    logos: {
      horizontal: "blend_horizontal-xFGul9anHVY4BymCwXjvsojeWl1sMk.png"
    }
  },
  {
    companyName: "Blockchain.com",
    logos: {
      square: "blockchain_square-Y5LTybUlsbq6zTqOc9OkH4ZSDPLL3J.png"
    }
  },
  {
    companyName: "Blue Origin",
    logos: {
      square: "blueOrigin_square-BwOgE2YJWyXwfWSpg0AbHqSbZZpbjG.png"
    }
  },
  {
    companyName: "Blueberry",
    logos: {
      square: "blueberry_square-wgwkjkNd0ElmnI3SSV3ZJVw1mg3zIw.png"
    }
  },
  {
    companyName: "Bluesight",
    logos: {
      square: "Bluesight_square-0az1utkTl55Rp3bRk0H4SFmfnZaj4X.png"
    }
  },
  {
    companyName: "Bluepoint Games",
    logos: {
      horizontal: "bluepoint_games_horizontal-m4BAWSrxM83atkXNDZIMrArXyNILUu.png"
    }
  },
  {
    companyName: "BNY Mellon",
    logos: {
      horizontal: "bny_horizontal-h0qKMozg9Yq8ZDAHpXox3AL0tkSeCb.png"
    }
  },
  {
    companyName: "Bodo",
    logos: {
      square: "bodo_square-yDtNQLdUw80BqL8TLvXBHWvZ6kB2nP.png"
    }
  },
  {
    companyName: "Boeing",
    logos: {
      horizontal: "Boeing-UTl0lUziRQYAs2e67t8LiyHg0MSOp2.png"
    }
  },
  {
    companyName: "Bolt",
    logos: {
      horizontal: "bolt_horizontal-SQnQuXwLBN7OGaE1qkbxyALzZpwFEc.png"
    }
  },
  {
    companyName: "Bond Vet",
    logos: {
      square: "bond_vet_square-hYfia0985jVQWRVtJ8K3aikuGxrmss.png"
    }
  },
  {
    companyName: "Bonterra",
    logos: {
      square: "bonterra_square-6jBiic3x9GSCwKpzpX8v8Nn83i9E0D.png"
    }
  },
  {
    companyName: "Booz Allen",
    logos: {
      square: "boozallen_square-j6ABaueh8CdgVeBVxCjxdIJKNeMcW9.png"
    }
  },
  {
    companyName: "Bounteous",
    logos: {
      square: "bounteous_square-u1X5mCHHbC4eOWb4ZbCPEQ0gx3UDE4.png"
    }
  },
  {
    companyName: "Borg Warner",
    logos: {
      square: "bw_square-Mz2btnr6lIpVR8qeCwXjiw98SdXvIz.png"
    }
  },
  {
    companyName: "Box",
    logos: {
      horizontal: "box_horizontal-Bte5xJozEdrsW3Q5A8btkcyO71NtFC.png"
    }
  },
  {
    companyName: "BPM",
    logos: {
      square: "BPM_square-rXSZkSnrMwBDxr0ecaWSJGxJzUKuoM.png"
    }
  },
  {
    companyName: "Bracebridge Capital",
    logos: {
      square: "bracebridgecapital_square-zAPnvZoheB4OIgDIpq2tkPDeH8Lu3r.png"
    }
  },
  {
    companyName: "Brain Technologies",
    logos: {
      square: "brain_square-dFqFD0MF3hThtZssbgoKqm91qn3Pjb.png"
    }
  },
  {
    companyName: "Brave Health",
    logos: {
      square: "brave_health_square-spiaBx7QZ9Cqh1S9MmfRZcPpGAyXwX.png"
    }
  },
  {
    companyName: "Braze",
    logos: {
      horizontal: "braze_horizontal-tMY7S5RJEgEkkOKpSA5qKDbptipgzS.png"
    }
  },
  {
    companyName: "Breeze Airways",
    logos: {
      horizontal: "Breeze_Airways_horizontal-w5CMdLvYgjkwS0WKurDVOr2ICBs4jI.png"
    }
  },
  {
    companyName: "Brevo",
    logos: {
      square: "brevo_square-TzurCM6t2qtjNLh1s6yinhYTpQRugp.png"
    }
  },
  {
    companyName: "BrightEdge",
    logos: {
      horizontal: "BrightEdge_horizontal-FODLbrFoqWIZtuHko0AaFYxSmxXtrg.png"
    }
  },
  {
    companyName: "Brightseed",
    logos: {
      square: "brightseed_square-nkAVVuFlO2cQ1wxCkpvf8MltgUjjK8.png"
    }
  },
  {
    companyName: "Brightwheel",
    logos: {
      square: "brightwheel_square-87hO3h3JF0m9TAERNtbmRXiiJVXE0V.png"
    }
  },
  {
    companyName: "Brilliant",
    logos: {
      horizontal: "brilliant_horizontal-ppFI1eZrFu8p18y9TZ1KUhkrzCqA4o.png"
    }
  },
  {
    companyName: "Brillio",
    logos: {
      horizontal: "brillio2_horizontal-VBlvLRygI0R4o2cwYsZi0P5kusojyh.png"
    }
  },
  {
    companyName: "Bristol Myers",
    logos: {
      vertical: "BristolMyers_vertical-6iTfPi6TLdeRf6ZQ23cOw8vy1Ev9kC.png"
    }
  },
  {
    companyName: "Broadcom",
    logos: {
      square: "broadcom_square-ttAQAucT3rcfPI6huXqXNmheKplFd5.png"
    }
  },
  {
    companyName: "Buf Technologies",
    logos: {
      square: "buf_square-6cZanp0zfaaRVI9zEnihFeAMBWf2eM.png"
    }
  },
  {
    companyName: "Builder.io",
    logos: {
      square: "Builder_square-EWjzwoFe2MOSUrzmXXwpXONiXsdvoW.png"
    }
  },
  {
    companyName: "Bumble",
    logos: {
      square: "bumble_square-YK3bUHe0ks6gEuanaPIRDIcAz8VPGf.png"
    }
  },
  {
    companyName: "Butter",
    logos: {
      horizontal: "butterpayments_horizontal-vELYsjh47bx6hXuGRb4LZrb5IJw8u5.png"
    }
  },
  {
    companyName: "BV",
    logos: {
      square: "BV_square-yAn9jkX8N0RRUuANdDvWh9hPLGjIns.png"
    }
  },
  {
    companyName: "C3.ai",
    logos: {
      horizontal: "c3.ai_horizontal-dM811XwN1G27FzwLHIeWENUPTFZHAM.png"
    }
  },
  {
    companyName: "CAE",
    logos: {
      horizontal: "cae_horizontal-cYkz9xdbdZUZnf5c4PLTPfypTobgyu.png"
    }
  },
  {
    companyName: "Cadence",
    logos: {
      horizontal: "cadence_horizontal-Gmoc2Adz3saPC9ezSox6A3SVphktVC.png"
    }
  },
  {
    companyName: "CAI",
    logos: {
      horizontal: "cai_horizontal-krOTtn0qYqviOqtvf44U4Qm5oUyrq6.png"
    }
  },
  {
    companyName: "Calibrate",
    logos: {
      horizontal: "calibrate_horizontal-6xD5hhW08fDzPdulHDjCI7zhuZzD6x.png"
    }
  },
  {
    companyName: "Calm",
    logos: {
      square: "calm_square-5l6LWZZ9KgFNKCHl2onE8tyMOKcjhF.png"
    }
  },
  {
    companyName: "Canary Technologies",
    logos: {
      square: "canary_technologies_square-P7bjnRtZP869VaqBj2mivqTd2aFGBx.png"
    }
  },
  {
    companyName: "CaptivateIQ",
    logos: {
      horizontal: "captivateig_horizontal-Xqj4RTSqWxlAVWQ38ldfXtaiBch8f9.png"
    }
  },
  {
    companyName: "Carbon",
    logos: {
      horizontal: "carbon_horizontal-1MDeubpAJVzb4h1AsuTCDUuFeZUvBJ.png"
    }
  },
  {
    companyName: "Carbon Direct",
    logos: {
      square: "CarbonDirect_square-IWytr9pfbHnsJIyPY43eSLVBVkZXRf.png"
    }
  },
  {
    companyName: "Card",
    logos: {
      square: "card_square-hQx5XavIoHKzNLzoA16Gs8ap57m037.png"
    }
  },
  {
    companyName: "CarMax",
    logos: {
      square: "carmax_square-WGfRKMEp33vfXSKJ3Xe5gjRYH4BcVW.png"
    }
  },
  {
    companyName: "Carta",
    logos: {
      horizontal: "carta_horizontal-pNlRFzPgrEpRGxFvXDYaFQ2HSdswos.png"
    }
  },
  {
    companyName: "Capgemini",
    logos: {
      square: "capgemini_square-20IrSfzqcD7jkK8x31ULgtcBsI9Q4S.png"
    }
  },
  {
    companyName: "Capital One",
    logos: {
      horizontal: "capital_one_horizontal-QEmz1M3Z4wms5oOyvpwT4x3jGIcD5x.png"
    }
  },
  {
    companyName: "Catalist",
    logos: {
      horizontal: "catalist_horizontal-yDajQGNRd4dkc4zthOOf5aKynL67dS.png"
    }
  },
  {
    companyName: "CBRE",
    logos: {
      horizontal: "cbre_horizontal-gdhYrdChivauXtHRmezfTFEekttmvQ.png"
    }
  },
  {
    companyName: "Cellares",
    logos: {
      square: "cellares_square-GUm8tZjicHdjsB4ununEzQw31gy9xz.png"
    }
  },
  {
    companyName: "Center",
    logos: {
      horizontal: "center_horizontal-iH1f2LxKFuqHixkekYSWx8bXTiorob.png"
    }
  },
  {
    companyName: "Central",
    logos: {
      square: "central_square-E7HSybePBZumglhuXphRJQgLBYsNqg.png"
    }
  },
  {
    companyName: "Cents",
    logos: {
      horizontal: "cents_horizontal-xIU7byWVc8nd7WkJxQbpufBQa2QItw.png"
    }
  },
  {
    companyName: "CertiK",
    logos: {
      square: "certik_square-WqSXqYaoroLOL2bUrOWZOX8WeYDAvZ.png"
    }
  },
  {
    companyName: "CGS",
    logos: {
      horizontal: "CGS_horizontal-FTzb9KQvOzRyeCgwDSV8ZSJkHIX8ej.png"
    }
  },
  {
    companyName: "Chainlink Labs",
    logos: {
      square: "chainlink_labs_square-2kDLU5ArQRI2fX9mI8NdKy9Vyd9mZE.png"
    }
  },
  {
    companyName: "Character.AI",
    logos: {
      horizontal: "characterai_horizontal-Du8Vk3HRHldNMC9SYptu8q7ym6SVqC.png"
    }
  },
  {
    companyName: "ChargePoint",
    logos: {
      square: "chargepoint_square-srL2MjY3uIJU9drZo9t8ZBjRsGpn0N.png"
    }
  },
  {
    companyName: "Check",
    logos: {
      horizontal: "check_horizontal-9MrNypQqN74mIIRWFk5g2yRBvX2qso.png"
    }
  },
  {
    companyName: "Checkr",
    logos: {
      square: "checkr_square-q8jx9hSniiCa6aJjgy26KTKz7IZ1lk.png"
    }
  },
  {
    companyName: "Cherre",
    logos: {
      square: "cherre_square-1bEqb36JeZPGh6jRU9ASdk5TtQsQDa.png"
    }
  },
  {
    companyName: "Cherry Technologies",
    logos: {
      square: "cherrytechnologies_square-gfJIuRBqDldYqcS7MXgJ6RSNpUFI32.png"
    }
  },
  {
    companyName: "Chipotle",
    logos: {
      square: "chipotle_square-ucxJF4TItLPpOoUMLQJCECL0Psn9P9.png"
    }
  },
  {
    companyName: "Cigna",
    logos: {
      horizontal: "cigna_horizontal-exkF86kC5xbrSXo9ck47pfVZ7pVUIQ.png"
    }
  },
  {
    companyName: "Circle Medical",
    logos: {
      square: "circle_medical_square-XAYiaBDulrHCmUMYnZfE4zVu5Wyufp.png"
    }
  },
  {
    companyName: "Circle Pharma",
    logos: {
      square: "circle_pharma_square-aWgdkM75gRbHXqpEYXgo6yvfmfs3Hg.png"
    }
  },
  {
    companyName: "Cirrus Logic",
    logos: {
      horizontal: "cirrus_logic_horizontal-cgfNwEdPfNHiDP1BKisrXjj7nuGyxy.png"
    }
  },
  {
    companyName: "Citibank",
    logos: {
      horizontal: "citi_horizontal-hApk5m9lD2D5PWxtGxzmP1MH22lBc5.png"
    }
  },
  {
    companyName: "Cisco",
    logos: {
      horizontal: "cisco_horizontal-lsc2S94GgD3GD5V2IGSeIt0VszjjnD.png"
    }
  },
  {
    companyName: "cLabs",
    logos: {
      horizontal: "clabs_horizontal-ubot6sZim3AXRNwyh2p7kv6dUsdnop.png"
    }
  },
  {
    companyName: "ClassDojo",
    logos: {
      square: "ClassDojo_square-gFo2pZEJ3rL6k7XuYXIVDxCcle2f5R.png"
    }
  },
  {
    companyName: "Class Labs",
    logos: {
      square: "class_dojo_square-oj5DGlhn6XlihAlMSgaRcrCx40TOkM.png"
    }
  },
  {
    companyName: "CLEAR",
    logos: {
      square: "clear_square-Qho5ied71BZI6O7CRcx8KeepimP5g2.png"
    }
  },
  {
    companyName: "Clearwater Analytics",
    logos: {
      horizontal: "clearwater_analytics_horizontal-vCY0gGWWPQW1VxOhe82Y6BReEDxESG.png"
    }
  },
  {
    companyName: "CleverTap",
    logos: {
      square: "clevertap_square-yxGTbGDX2WJPLomk3dDWGzFUCHY7ZH.png"
    }
  },
  {
    companyName: "Clerk Chat",
    logos: {
      square: "clerk_chat_square-rteMBosxaiwKf6AtRTtMorm9gNYC0p.png"
    }
  },
  {
    companyName: "Clinc",
    logos: {
      horizontal: "clinc_horizontal-vxRHerDjvOMmbpjeOkvl8lBlYG0QR3.png"
    }
  },
  {
    companyName: "Clipboard Health",
    logos: {
      square: "clipboard_health_square-xKa2BWQYNkNPEEQjAn6rxQTzHc7BtQ.png"
    }
  },
  {
    companyName: "Cloudera",
    logos: {
      square: "cloudera_square-Za63CuibB0iGbBkoomPJv7Md25BY6V.png"
    }
  },
  {
    companyName: "Cloudflare",
    logos: {
      horizontal: "Cloudflare_horizontal-KEyGPBdcLBO4ZbvEeBfok0FcQZpIqC.png"
    }
  },
  {
    companyName: "Clubhouse",
    logos: {
      square: "clubhouse_square-puq61YBlzLFn7uKMsWAW7s4GjjhXBB.png"
    }
  },
  {
    companyName: "Coast",
    logos: {
      horizontal: "coast_horizontal-mVIrBKig4lGe76KHq2bwz37uNZ92of.png"
    }
  },
  {
    companyName: "CocaCola",
    logos: {
      horizontal: "cocacola_horizontal-kbJG4QVWjfgiyLymaicAAbEkPVRMa0.png"
    }
  },
  {
    companyName: "CockroachDB",
    logos: {
      square: "cockroach_labs_square-ZPZ6hFKtjob6HYQaouuhXfoK9CEwjk.png"
    }
  },
  {
    companyName: "Coda",
    logos: {
      horizontal: "coda_horizontal-km2xquQ9t2yMCwE7pUdLMV9OcTeIxq.png"
    }
  },
  {
    companyName: "Coforma",
    logos: {
      horizontal: "coforma_horizontal-SPYn22EZbo4AjfzEOziQ2Uu776tCbX.png"
    }
  },
  {
    companyName: "Cognite",
    logos: {
      horizontal: "cognite_horizontal-fkKZsvmlKxEUG2rwBwgqyoMw0LJJhH.png"
    }
  },
  {
    companyName: "Cognition",
    logos: {
      square: "cognition_square-nsgpJhzeyPi3BMp5HLaefA2kWrJxKy.png"
    }
  },
  {
    companyName: "Cognitiv",
    logos: {
      square: "cognitiv_square-VQD7HAvWIhBblQrKYg7J5h2OvDeO1g.png"
    }
  },
  {
    companyName: "Cognizant",
    logos: {
      square: "cognizant_square-Y40PRFTIK9XR1lEvfuI4xJ4CdOXDuq.png"
    }
  },
  {
    companyName: "Cohere",
    logos: {
      square: "cohere_square-SUP7vmbCD9FCpEqHSIgGGJU0TMnrNT.png"
    }
  },
  {
    companyName: "Coin Metrics",
    logos: {
      square: "coinmetrics_square-JJg2dVsUK4hxcpAZZZp1dGCHV1thms.png"
    }
  },
  {
    companyName: "Coins",
    logos: {
      square: "coins_square-xzRKzFGcTzOalOWq0W0UPQbkE7JPHf.png"
    }
  },
  {
    companyName: "Color",
    logos: {
      horizontal: "color_horizontal-htZ11Yf85gaovcBbpN5cozIY3yUcuu.png"
    }
  },
  {
    companyName: "Column",
    logos: {
      horizontal: "column_horizontal-F01J9QQl9yS2SKww0LY7wI09dUTPGB.png"
    }
  },
  {
    companyName: "Comcast",
    logos: {
      horizontal: "comcast_horizontal-xNVzsS7CHfuL9Iv89oef1v7kabUCJk.png"
    }
  },
  {
    companyName: "Commercetools",
    logos: {
      square: "commerce_tools_square-lF12k3bEfGA9qhyyraXWJggQclT5Fo.png"
    }
  },
  {
    companyName: "Commure",
    logos: {
      square: "commure_square-tm7VoGbaPCVH6ux8XfcafoSW9iHFBn.png"
    }
  },
  {
    companyName: "Concurrency",
    logos: {
      square: "concurrency_square-7fonZ5IFCvf0Q8pbx09RJHRLRCGfHM.png"
    }
  },
  {
    companyName: "Conga",
    logos: {
      square: "conga_square-YzSzk1ShPtKR8wcGen9YmsL5vuky1e.png"
    }
  },
  {
    companyName: "connectRN",
    logos: {
      square: "connectrn_square-4ORSqjcyXctz5JiQHmvT64CN8R1wD5.png"
    }
  },
  {
    companyName: "Contentsquare",
    logos: {
      horizontal: "contentsquare_horizontal-RxQ1Haplt2T7nfgLEGz6Z7OkSB4DLX.png"
    }
  },
  {
    companyName: "Comity",
    logos: {
      square: "comity_square-rAtscuLR5ei0V4oop3wJ90aETWcn6t.png"
    }
  },
  {
    companyName: "Compassion",
    logos: {
      square: "compassion_square-yQbEEVkOHjSN3qh2baTOsAYVAFQCOr.png"
    }
  },
  {
    companyName: "Compound",
    logos: {
      square: "compound_square-A8kx3DVmIMvk0UlciZ9f6kuNehMMGB.png"
    }
  },
  {
    companyName: "Conduit",
    logos: {
      square: "conduit_square-zaHO6lFNFnK6snwYH4ImXGdJDfVXgz.png"
    }
  },
  {
    companyName: "Continua",
    logos: {
      square: "continua_square-OSN72kRMlrSzp0TCJ9fN9YLd3dvSid.png"
    }
  },
  {
    companyName: "Corelight",
    logos: {
      square: "corelight_square-LTszu1n6Oum5RLM9p8Dw6cWmBFaZn0.png"
    }
  },
  {
    companyName: "CoreWeave",
    logos: {
      square: "coreweave_square-VRxXaXbcInCK6scra36E07KgU3oUoi.png"
    }
  },
  {
    companyName: "Cortex",
    logos: {
      square: "cortex_square-oBMssztREpJjGOrouqAuw1NSOh3wk8.png"
    }
  },
  {
    companyName: "Couchbase",
    logos: {
      square: "couchbase_square-n59M5fADwflt32jEeiq01R5cnRxOGl.png"
    }
  },
  {
    companyName: "Counsel",
    logos: {
      square: "counsel_square-XjLjJME6SWXxpn67jPVnP23Ix6vZmq.png"
    }
  },
  {
    companyName: "Coupa",
    logos: {
      square: "coupa_square-IB3obwa0PMvkuuZKtFThmEyYySNXLR.png"
    }
  },
  {
    companyName: "Coursera",
    logos: {
      square: "coursera_square-gtyWR177wmxc3AmloxwxSGXGQhCUfX.png"
    }
  },
  {
    companyName: "Cover Genius",
    logos: {
      square: "cover_genius_square-RUz2xGPKpecSKgM5Alqlut5fbcIiQr.png"
    }
  },
  {
    companyName: "CPrime",
    logos: {
      horizontal: "cprime_horizontal-GZKKmVWFLCWSTiGWfoaAvVbTfpcDcv.png"
    }
  },
  {
    companyName: "Credit Karma",
    logos: {
      square: "creditkarma_square-v76mHSqltfR7Ow1zfQgy8SnCNkJw6Q.png"
    }
  },
  {
    companyName: "Credo",
    logos: {
      vertical: "credo_vertical-sToDklA9s2yLdJv0xgFnMfxFLx1fIc.png"
    }
  },
  {
    companyName: "Crest",
    logos: {
      square: "Crest_square-P9487tj9aEnr8Ex8z25iBA3ofJvEdk.png"
    }
  },
  {
    companyName: "Crest Operations",
    logos: {
      horizontal: "crestoperations_horizontal-ptcAsAafThQ2GEb534NlOdNA11WDSc.png"
    }
  },
  {
    companyName: "Crossing Minds",
    logos: {
      square: "crossing_minds_square-LkoUkiJLVj5dJ5D6vjB3b9UflQ67p3.png"
    }
  },
  {
    companyName: "Critical Mass",
    logos: {
      square: "criticalmass_square-k3DW1lf6bfUZ8aSBnFuqIE2m82pPlG.png"
    }
  },
  {
    companyName: "CrowdStrike",
    logos: {
      square: "crowdstrike_square-kdg7jMxK9hfq2bZYaMjLLvRQaFwCp2.png"
    }
  },
  {
    companyName: "Cruise",
    logos: {
      horizontal: "cruise_horizontal-CxqMmdBYSMKIudcdWF8PAr24xnukkL.png"
    }
  },
  {
    companyName: "Crusoe",
    logos: {
      square: "crusoe_square-wFf9gJ9GcCADInhXk6CXqfe9GSBZCG.png"
    }
  },
  {
    companyName: "Crux",
    logos: {
      horizontal: "crux_horizontal-ejXaCujD2YZ1fo4zDso5ABsre1QgVd.png"
    }
  },
  {
    companyName: "Crypto.com",
    logos: {
      square: "crypto_square-jCdfAx738I9FJve9RNxAEG0WEoASx8.png"
    }
  },
  {
    companyName: "Cube",
    logos: {
      square: "cube_square-IXsUr0DAFZ8vBm4gKn9CbJ577sWfe8.png"
    }
  },
  {
    companyName: "Culdesac",
    logos: {
      horizontal: "culdesac_horizontal-6tAW7qmtXAY1jXmxLxdBTDrojAhvYL.png"
    }
  },
  {
    companyName: "Culture Amp",
    logos: {
      square: "culture amp_square-JrEEvS5NP3uhZ2ZzsMBEEydtYArV84.png"
    }
  },
  {
    companyName: "Curai Health",
    logos: {
      square: "curai_square-erDptlTt3P4zy7KPYzP8g7hjYEOO1S.png"
    }
  },
  {
    companyName: "Curative",
    logos: {
      square: "curative_square-cj5ysIx900vQARugpd2s5gcFuG7BEQ.png"
    }
  },
  {
    companyName: "Cure.ai",
    logos: {
      square: "cureai_square-Q5T2avJRE12EQdK1AkVEwz5Vxki02O.png"
    }
  },
  {
    companyName: "CVS Health",
    logos: {
      horizontal: "CVS_Health_horizontal-ukthI4cEpWFqy5Z0kRdhsTngj9jZ3n.png"
    }
  },
  {
    companyName: "CYE",
    logos: {
      horizontal: "cye_horizontal-TqZW1NPaSndOSQaPXPYUW0NtyibuQf.png"
    }
  },
  {
    companyName: "d-Matrix",
    logos: {
      square: "dmatrix_square-PWLhnYLnJBs2IDcgKZrfbhSyFEtFBp.png"
    }
  },
  {
    companyName: "Dagger",
    logos: {
      square: "Dagger_square-JDnaeZNXTRlvv60TLXv1wqqSfdPbXf.png"
    }
  },
  {
    companyName: "Daily Harvest",
    logos: {
      square: "daily harvest_square-fpZHDzMD05IvyM5m0D29bRouDDl41S.png"
    }
  },
  {
    companyName: "Daimler",
    logos: {
      horizontal: "DaimlerTruck_horizontal-V2BEVpjDLFs3UzLahp3q1heZsnFfVs.png"
    }
  },
  {
    companyName: "Dapper",
    logos: {
      square: "Dapper_square-NdaCTHqFto4zl0yoGHOc0B1ziSf6RH.png"
    }
  },
  {
    companyName: "Dash Financial Technologies",
    logos: {
      square: "dash_financial_technologies_square-a80lVozS2cZEhhx5JHOIBpDERMVZxG.png"
    }
  },
  {
    companyName: "Databento",
    logos: {
      square: "databento_square-SuqSUjf1dFIP527IuEG56GKsmebGkt.png"
    }
  },
  {
    companyName: "Databricks",
    logos: {
      horizontal: "databricks_horizontal-PO4u94c2moGrt8IWjp5USKRqKj3L0I.png"
    }
  },
  {
    companyName: "DataCamp",
    logos: {
      square: "datacamp_square-XmJhByLNU9JIujgsXGo542B5jOqEi1.png"
    }
  },
  {
    companyName: "Datadog",
    logos: {
      square: "datadog_square-VgWM3YlEFkSOERjpzUKcHPeb172haV.png"
    }
  },
  {
    companyName: "DataRobot",
    logos: {
      square: "DataRobot_square-0q0VbuQoq9kp4u3ojFlazvwbOPEKfM.png"
    }
  },
  {
    companyName: "Datavant",
    logos: {
      square: "datavant_square-i60XDSrcGpKXHA9dtnmHvOivK6nP49.png"
    }
  },
  {
    companyName: "Day9",
    logos: {
      horizontal: "day9_horizontal-O7j5uSz1jkzes8wdzKMBruE1F2qk4X.png"
    }
  },
  {
    companyName: "DAZN",
    logos: {
      square: "dazn_square-UhdJtTtCa2E4fBDNMNQfwmNGm3yIC7.png"
    }
  },
  {
    companyName: "Deel",
    logos: {
      horizontal: "deel_horizontal-ZU3uuENNMRBBYdekwtsh3qNCxoZUo8.png"
    }
  },
  {
    companyName: "Deepgram",
    logos: {
      square: "deepgram_square-bQ3IiIpcObCwAnhGibYH2YiaEhf4hd.png"
    }
  },
  {
    companyName: "DeepMind",
    logos: {
      square: "deepmind_square-VwBJpAqUjRnOL25FQ9aeVR0BrYA3ge.png"
    }
  },
  {
    companyName: "Dell",
    logos: {
      square: "dell_square-0x0MyWXJAAzjfKcQ7KjfSuVuZm9QUv.png"
    }
  },
  {
    companyName: "Deluxe",
    logos: {
      square: "deluxe_square-BOG9FWqxT1nhb6xamFwtk3NdbsAeDr.png"
    }
  },
  {
    companyName: "Demandbase",
    logos: {
      square: "demandbase_square-zaDXTm5NJSnGYIzyCQFyLx3fiOH0kB.png"
    }
  },
  {
    companyName: "Denver",
    logos: {
      square: "denver_square-agjl0T3YpXfKaj2SZUfkRlY9y1VL3d.png"
    }
  },
  {
    companyName: "Descript",
    logos: {
      square: "descript_square-4uvl8tQ8rDHrhsUNGxSAPGFIlhiog1.png"
    }
  },
  {
    companyName: "Delinea",
    logos: {
      square: "delinea_square-Gq1zIfg6hCMerYvRcgkmLEPJSB1E9H.png"
    }
  },
  {
    companyName: "Dexterity",
    logos: {
      square: "dexterity_square-j3UWyE51Deq0h1LwYUsdMZvHGB7bXv.png"
    }
  },
  {
    companyName: "Diligent Robotics",
    logos: {
      square: "diligent_robotics_square-0HfwNYc5lA5NqV5Bs1GE1IZYgRIAiS.png"
    }
  },
  {
    companyName: "Digital Media Management",
    logos: {
      horizontal: "digital_media_management_horizontal-4xh4U26x2of9VMT9pV6t6wVszf5Dn0.png"
    }
  },
  {
    companyName: "Digital Turbine",
    logos: {
      square: "digital_turbine%20_square-ITQdeUozTArdVpskIGbj0EaJB5OxT6.png"
    }
  },
  {
    companyName: "Dimensional",
    logos: {
      square: "dimensional_square-18NaxIgR96sSIuzhoWl9IDo7Ui9Nx4.png"
    }
  },
  {
    companyName: "Discord",
    logos: {
      square: "discord_square-ajPApSOdChbqw8xbvi4fdz8wZmfDVY.png"
    }
  },
  {
    companyName: "Discord",
    logos: {
      square: "discord_square-ajPApSOdChbqw8xbvi4fdz8wZmfDVY.png"
    }
  },
  {
    companyName: "Discover",
    logos: {
      horizontal: "Discover_horizontal-xe4hKwcHackJZZgyeMaL16FbVy1q2H.png"
    }
  },
  {
    companyName: "Disney",
    logos: {
      horizontal: "disney_horizontal-BH5Sq3P35fI55LtzpVsz1wpYrurzwk.png"
    }
  },
  {
    companyName: "DoraHacks",
    logos: {
      square: "DoraHacks_square-k0bSfLJvZwEJGkRkh1SvxD2n1OlkG9.png"
    }
  },
  {
    companyName: "DJE Holdings",
    logos: {
      square: "dje_holdings_square-lUJOCUENbNQHbWns7uKF6xGoVpB37r.png"
    }
  },
  {
    companyName: "Docugami",
    logos: {
      square: "docugami_square-8FVz3s1IE7P4Bwz3bDFRLy0U1yO31s.png"
    }
  },
  {
    companyName: "DocuSign",
    logos: {
      square: "docusign_square-zq4CXhUGRktdACggtf05BlC52F1rXQ.png"
    }
  },
  {
    companyName: "Domo",
    logos: {
      square: "domo_square-Vyj4y2zxWf78aCh30tpfAG4JlNGHzJ.png"
    }
  },
  {
    companyName: "DoorDash",
    logos: {
      square: "doordash_horizontal-8ubR5BMgu0fMOWEPIIFf8h5nEFJrjB.png"
    }
  },
  {
    companyName: "Doran Jones",
    logos: {
      horizontal: "doran_jones_horizontal-dLkGYg2JDNmmWpqc2ZvDjOeBO3FS3z.png"
    }
  },
  {
    companyName: "Dow",
    logos: {
      horizontal: "dow_horizontal-6hi6CK8E8OCCCDy9CMPfbC0e78DYsm.png"
    }
  },
  {
    companyName: "DraftKings",
    logos: {
      square: "draftkings_square-d4GaUMO5TlTXfsvSyEjr3vQbc40Q7f.png"
    }
  },
  {
    companyName: "Dun & Bradstreet",
    logos: {
      square: "dun&bradstreet_square-dvsvcEi0nCDSICtlxmMMB843YG1KTV.png"
    }
  },
  {
    companyName: "D-Wave",
    logos: {
      horizontal: "dwave_horizontal-j5D9mR9Gd9i5SPxvOD1rbt6XRbqHHC.png"
    }
  },
  {
    companyName: "EA",
    logos: {
      square: "EA_square-iNQX9TxAHLuX8qWFpWgvVqXaqft8vg.png"
    }
  },
  {
    companyName: "eBay",
    logos: {
      horizontal: "ebay_horizontal-1IYGtYne9dQA7HRq1KSDC3Kc6m2Lm1.png"
    }
  },
  {
    companyName: "Eco",
    logos: {
      square: "eco_square-WHZLFaD1IaVRbnZWt3VmVsuIsBn1GM.png"
    }
  },
  {
    companyName: "Egen",
    logos: {
      horizontal: "egen_horizontal-ZDFzYX2ehiievWsVLyrn7jMTlCJmM5.png"
    }
  },
  {
    companyName: "Elevance Health",
    logos: {
      vertical: "elevance_health_vertical-N4LshovaHIlvBzJG8KkTDS8hUcErNH.png"
    }
  },
  {
    companyName: "Elevate Labs",
    logos: {
      square: "elevatelabs_square-tvdExG6j4tD5y2szB39nWIOjhjYtP0.png"
    }
  },

  {
    companyName: "ElevenLabs",

    logos: {
      horizontal: "ElevenLabs_horizontal-A1aaxN8JxuHxhVaR2zMBKpxCONVoiT.png"
    }
  },
  {

    companyName: "Eli Lilly",

    logos: {
      horizontal: "eli_lily_horizontal-v1ZHUg5NMJ77KDFgxJHFlFnn2nBYk2.png"
    }
  },
  {
    companyName: "Ellipsis Labs",
    logos: {
      horizontal: "ellipsis_labs_horizontal-V4dMaGdrHTbIkKpu2WCaRXdACEZEbd.png"
    }
  },
  {
    companyName: "Employwell",
    logos: {
      square: "employwell_square-zffxGOGykPgRRFabgk00D9JKVTiXtg.png"
    }
  },
  {
    companyName: "Empowerly",
    logos: {
      square: "empowerly_square-0733EOxAb1j2CPGrg414fPTE7ONbqb.png"
    }
  },
  {
    companyName: "Enable",
    logos: {
      horizontal: "enable_horizontal-oqdFRVvPAcxhr4OmKx4tNbe4n22n9i.png"
    }
  },
  {
    companyName: "Encord",
    logos: {
      square: "encord_square-74RDRj3xKfQPrh5Uq9NVtodh4TinsI.png"
    }
  },
  {
    companyName: "Entefy",
    logos: {
      square: "entefy_square-oAMn1Wa84FBEOPFXZiLDEBF8RpCRwJ.png"
    }
  },
  {
    companyName: "Entrata",
    logos: {
      horizontal: "Entrata_horizontal-zEyKrjexF3PvFHa7FnltLp7qkut6dc.png"
    }
  },
  {
    companyName: "Equinix",
    logos: {
      horizontal: "equinix_horizontal-vsELN8K0JWbvXV1sCs3ubYl8bmo41W.png"
    }
  },
  {
    companyName: "Equip",
    logos: {
      square: "Equip%20Health_square-P2n4hYfw8HqhAQWstWXBMAjmmFpH0c.png"
    }
  },
  {
    companyName: "ERG",
    logos: {
      square: "erg_square-R2m60dZHUjMhLd8OjVwTtBTqNNfujk.png"
    }
  },
  {
    companyName: "ERM",
    logos: {
      square: "erm_square-Vf8Vgk6R5uj7v83swvHfm4PNAqxhtP.png"
    }
  },
  {
    companyName: "ESET",
    logos: {
      square: "ESET_square-d1fgFeKIrW5Mk6WE1tktVfE4AQDZUW.png"
    }
  },
  {
    companyName: "Esri",
    logos: {
      square: "esri_square-0txCEOpH0d32O7BF6xY8GYh8ZuBkCg.png"
    }
  },
  {
    companyName: "ETHGlobal",
    logos: {
      square: "eth_square-P0KcVdtKtMDyQtjD4gy8R5afFNa6mO.png"
    }
  },
  {
    companyName: "Eventbrite",
    logos: {
      square: "eventbrite_square-Hp5S8ewfQ8mx0nogcwBoX7ImAf8pKm.png"
    }
  },
  {
    companyName: "Eventual",
    logos: {
      square: "eventual_square-NnQsHPtHKLERtiiYEZfAjk51HDeccm.png"
    }
  },
  {
    companyName: "EvenUp",
    logos: {
      square: "evenup_square-sR1auasECrUpEWDIcl4OlGZzdfQZGv.png"
    }
  },
  {
    companyName: "Everbridge",
    logos: {
      square: "everbridge_square-7r8RziVYOVQHcC4Zb6BOkPolGlnMYN.png"
    }
  },
  {
    companyName: "EverCommerce",
    logos: {
      horizontal: "evercommerce_horizontal-iTcQwf44hRSp8RYNHPHX7cPaZrRkBD.png"
    }
  },
  {
    companyName: "Everlane",
    logos: {
      square: "everlane_square-zRZPeFOyEUL87pEPsrHXuro2lvwjvr.png"
    }
  },
  {
    companyName: "Evolent Health",
    logos: {
      horizontal: "evolent_horizontal-hKqIjnF7B9Tu9hOTzWxPUbWGWchsWq.png"
    }
  },
  {
    companyName: "Exa",
    logos: {
      square: "exa_square-3vsHTjvRT7NjgQrXhmG1lGgg0zx54O.png"
    }
  },
  {
    companyName: "Exelon",
    logos: {
      square: "exelon_square-n5WpQKqSC1gG1Lz3NghJJFCbxGX9Jw.png"
    }
  },
  {
    companyName: "Extend",
    logos: {
      square: "extend_square-0Jy0Sfi5KWbmFuURRdeFFNutXhwrdi.png"
    }
  },
  {
    companyName: "EY",
    logos: {
      square: "ey_square-ztxA7AUnL11i2yMvBB2Ih20FIARQPx.png"
    }
  },
  {
    companyName: "F5",
    logos: {
      square: "f5_square-aSQG6U2lyGWiddv58oHSmVqu3sgssf.png"
    }
  },
  {
    companyName: "Faire",
    logos: {
      square: "faire_square-pX35nzl8nlmVwsbvnBnKv7KtK2uVqd.png"
    }
  },
  {
    companyName: "FalconX",
    logos: {
      square: "falconx_square-HNFJ5mSK8yhmPoWlWahQ8tstpi7XYG.png"
    }
  },
  {
    companyName: "FamPay",
    logos: {
      square: "fampay_square-8iUaqR4K46K6NbNlGRSbmiFGbLqZdv.png"
    }
  },
  {
    companyName: "Federal Reserve",
    logos: {
      square: "fr_square-Dyhs8IEqGIlnkHszolmL8VIYzpaHxw.png"
    }
  },
  {
    companyName: "Firmex",
    logos: {
      square: "firmex_square-Qyx3d55xwljPP0PL4UzoGogKvwmaPR.png"
    }
  },
  {
    companyName: "Firstbase",
    logos: {
      square: "firstbase_square-7xWHDsW8QikhYdSNMtnj8DJPDIqqkV.png"
    }
  },
  {
    companyName: "First Orion",
    logos: {
      square: "first_orion_square-7jIgrKgpd1uXCcPKUXaDBmrdrZkh4N.png"
    }
  },
  {
    companyName: "Fiserv",
    logos: {
      horizontal: "fiserv_horizontal-yEhllT3kFZS1jDEOklT6GnTl4kv0Ye.png"
    }
  },
  {
    companyName: "Five9",
    logos: {
      horizontal: "five9_horizontal-qtGJJVQhKFfUhT1hqcA7YX9rd2DdYv.png"
    }
  },
  {
    companyName: "Five Below",
    logos: {
      horizontal: "fivebelow_horizontal-nPCQs7DeSpiL8jvGvdsJf1gDww8g9K.png"
    }
  },
  {
    companyName: "Flexera",
    logos: {
      square: "flexera_square-OWtU8kT1fdktAxk0TOw2Vi0iu81vMx.png"
    }
  },
  {
    companyName: "Flexport",
    logos: {
      horizontal: "flexport_horizontal-Ng6nnb0jVEp1tkQOr4MUJKwEoodBu8.png"
    }
  },
  {
    companyName: "Fliff",
    logos: {
      square: "fliff_square-JYVTV9wPQQQlosiRzM9t3KK9KllOHk.png"
    }
  },
  {
    companyName: "Flip",
    logos: {
      horizontal: "flip_horizontal-ztQS5tf8bEE22swPtEPkVBshR5l1qh.png"
    }
  },
  {
    companyName: "FloQast",
    logos: {
      horizontal: "floqast_horizontal-hL5nnYUaKPEVaPdihVync2yEeSZVCV.png"
    }
  },
  {
    companyName: "Flow",
    logos: {
      horizontal: "flow_horizontal-haamRexC6O8bQj2VzuOLfUzj3RJnCD.png"
    }
  },
  {
    companyName: "FluidLogic",
    logos: {
      square: "fluidlogic_square-5JN0uk6aTqYDtLIK29ARdD355fN660.png"
    }
  },
  {
    companyName: "Forage",
    logos: {
      square: "forage_square-YOqoUy6W9TD2vn6O3Z919YMpKAuZpf.png"
    }
  },
  {
    companyName: "Formal",
    logos: {
      square: "formal_square-5bFbXvibrKjonI4ufesYkVkybmZIbA.png"
    }
  },
  {
    companyName: "Forum",
    logos: {
      horizontal: "forum_horizontal-U1YNRKJK6aKvYotYSHKpSHdG3uOqdq.png"
    }
  },
  {
    companyName: "FranklinCovey",
    logos: {
      square: "franklincovey_square-KxroItx9fS7nE57OtZHvtmxYF090Kh.png"
    }
  },
  {
    companyName: "Freshpaint",
    logos: {
      square: "freshpaint_square-fuJtslHx5w9vJx0o3ZcJs6hFKBP2mX.png"
    }
  },
  {
    companyName: "Front",
    logos: {
      square: "front_square-22kvuopaGsIqGDimwYs3hRkLiUt47V.png"
    }
  },
  {
    companyName: "G2i",
    logos: {
      square: "g2i_square-sWRFNUPoE19KBORGmLW7YUXc1oyrfC.png"
    }
  },
  {
    companyName: "Galileo",
    logos: {
      square: "galileo_square-dQdABuAJXGhz6VabVOhJhykHOfQ2wD.png"
    }
  },
  {
    companyName: "Garmin",
    logos: {
      horizontal: "garmin_horizontal-86uoURyZzSXntFwCNs3WYbwIaVpLZ3.png"
    }
  },
  {
    companyName: "Garner Health",
    logos: {
      square: "garner_square-vaNSx3CPNSnz4p8oPgargu6fdoy3iS.png"
    }
  },
  {
    companyName: "Gauntlet",
    logos: {
      square: "gauntlet_square-pQfh7ZdOCuawtMu7ehvFn6YjEcrIUq.png"
    }
  },
  {
    companyName: "GE Aerospace",
    logos: {
      square: "ge_square-uFAoBdDY2pYFkXK5qMD0cHU2iyHbFu.png"
    }
  },
  {
    companyName: "General Dynamics",
    logos: {
      square: "general_dynamics_square-yjOKVPf0DLhJOTF4N6p4NOlnpryb1P.png"
    }
  },
  {
    companyName: "General Motors",
    logos: {
      square: "gm_square-f3UqiX0bgtY3xS9DLvv3nC9na0Y4Qg.png"
    }
  },
  {
    companyName: "Genentech",
    logos: {
      horizontal: "Genentech_horizontal-UTPxagIgOkFilbv1yV3BToyoKAPqf1.png"
    }
  },
  {
    companyName: "GetMysa",
    logos: {
      horizontal: "getmysa_horizontal-vaVq1RgDQdDaM76X2A7UQwUASOEEgr.png"
    }
  },
  {
    companyName: "Ginkgo Bioworks",
    logos: {
      square: "ginkgo_square-AD0VLPEyQRAGqZJDTZYZpq97ibuVR7.png"
    }
  },
  {
    companyName: "GitLab",
    logos: {
      square: "gitlab_square-tboVTMiOM7ZXzh9wAk2GCDdsIQHGtM.png"
    }
  },
  {
    companyName: "GiveDirectly",
    logos: {
      horizontal: "givedirectly_horizontal-ff22C4jlNlip3zSfOt8E7EXhysU1Sf.png"
    }
  },
  {
    companyName: "Glean",
    logos: {
      square: "glean_square-w8f4MCx5eINhyNPRWMcj392HO87RM8.png"
    }
  },
  {
    companyName: "Glossier",
    logos: {
      square: "glossier_square-PD0TbfGpi6SmkJQKOAkxUetcy1DXha.png"
    }
  },
  {
    companyName: "Go Forward",
    logos: {
      horizontal: "goforward_horizontal-iOcQG38hqudpOMIgNnvsZY0Bw0ZSxr.png"
    }
  },
  {
    companyName: "Go1",
    logos: {
      horizontal: "go1_horizontal-dZkEBB91Zb8eQZOWYbjFU731CRyIPQ.png"
    }
  },
  {
    companyName: "Goldman Sachs",
    logos: {
      square: "goldman_sachs_square-Yt3X7sYGHISKaDsi891Bp6IzxR5kca.png"
    }
  },
  {
    companyName: "Good American",
    logos: {
      square: "goodamerican_square-agseXcWArYdfrgqpSu35lYVYzv3X9T.png"
    }
  },
  {
    companyName: "GoodTime",
    logos: {
      square: "goodtime_square-BeG6vvTuRKYrsgUmc3t73EClqstOFt.png"
    }
  },
  {
    companyName: "Google",
    logos: {
      square: "google_square-uRIODSRqMK7dIXH3TdlgNGHYexQ5Wu.png"
    }
  },
  {
    companyName: "Gorgias",
    logos: {
      square: "gorgias_square-MbW1KDyU1MtETdaNwsNMuC8xGwXD3u.png"
    }
  },
  {
    companyName: "GRAIL",
    logos: {
      horizontal: "GRAIL_horizontal-56rkJBhk0pbXKgCtZUg3J0MjxQZBBq.png"
    }
  },
  {
    companyName: "Grammarly",
    logos: {
      square: "grammarly_square-gQfmJ2L6nhv6h7hXvdOV9HTguuox7k.png"
    }
  },
  {
    companyName: "Granicus",
    logos: {
      square: "granicus_square-N8Xq3ifmlCn5wzWw0IJF9VGOSFQYQN.png"
    }
  },
  {
    companyName: "GrayMatter Robotics",
    logos: {
      square: "graymatter_square-fiEYpHFTP99mEREAJ1xnJX4Q7U8p3P.png"
    }
  },
  {
    companyName: "Gridmatic",
    logos: {
      horizontal: "gridmatic_horizontal-wSSJiwykBv3TPFe1cumovW2stdtlil.png"
    }
  },
  {
    companyName: "Groupon",
    logos: {
      square: "Groupon_square-BBrXXUjLtBIHVcy92eq9Lxbnr8x2GW.png"
    }
  },
  {
    companyName: "Grow Therapy",
    logos: {
      square: "grow_square-E2c7BvoeN4z7Kf28a1OIAlka495wsk.png"
    }
  },
  {
    companyName: "Gusto",
    logos: {
      horizontal: "gusto_horizontal-xqVSdlzmMxT9uyHauaWAsjEFjsgvTu.png"
    }
  },
  {
    companyName: "Hallow",
    logos: {
      square: "hallow_square-2EJbUCEZ3sfxUM87WVgRwGVtTs80Uv.png"
    }
  },
  {
    companyName: "Halter",
    logos: {
      square: "halter_square-mVVYkSgWTt1Pid9vgABmJbssUgwZ2D.png"
    }
  },
  {
    companyName: "Handshake",
    logos: {
      square: "handshake_square-ExtsUaZDW0NbDJbAW8XQqWiVEtTOhL.png"
    }
  },
  {
    companyName: "Hanna Andersson",
    logos: {
      square: "hanna_square-fE2o6DlGa0l4LoCVQzmTRln4x2OsnL.png"
    }
  },
  {
    companyName: "Harmonic",
    logos: {
      square: "harmonic_square-lBQIjfYeu9CY3tcIg3jSiwDqmDKqex.png"
    }
  },
  {
    companyName: "Harvey",
    logos: {
      square: "harvey_square-3iidVwm4f1RkLkGY4dixh8xuH4AEgI.png"
    }
  },
  {
    companyName: "Haus",
    logos: {
      square: "haus_square-3lPkoSNSzHIkWa0yKdY60Cj9zkJ3rD.png"
    }
  },
  {
    companyName: "Havenly",
    logos: {
      horizontal: "Havenly_horizontal-ha76V3CyklDyEOAnFFu2YhUIrIR1UZ.png"
    }
  },
  {
    companyName: "HCVT",
    logos: {
      square: "hcvt_square-tSehAUOkbtX3YFB5jPqfbldy5L1Zkv.png"
    }
  },
  {
    companyName: "Headspace",
    logos: {
      square: "headspace_square-CR61KHP7sWY0fl4FiwBvJe8pZnFhqW.png"
    }
  },
  {
    companyName: "Hedra",
    logos: {
      square: "hedra_square-CPf1zZudRAX7iMmr4i6Yq2byoaqto7.png"
    }
  },
  {
    companyName: "Hello Neighbor",
    logos: {
      horizontal: "hello_neighbor_horizontal-mGghDTda3sHLVEhBXZkYI8LN8A99Cm.png"
    }
  },
  {
    companyName: "Hermeus",
    logos: {
      square: "hermeus_square-pk2NM7BwkI0pKZnRq0YRyfObTZaX7b.png"
    }
  },
  {
    companyName: "Highland Machine Works",
    logos: {
      horizontal: "highland_machine_works_horizontal-XzuteQzUircr1nIS2V8i15lpASaawr.png"
    }
  },
  {
    companyName: "Hims & Hers Health",
    logos: {
      square: "hims&hers_square-5jhhVfZK0y5QdC93ORBsQQT0PIBirW.png"
    }
  },
  {
    companyName: "Hinge",
    logos: {
      square: "hinge_square-IU2XyXsNg13oaR8dlXu9vCK7ZPqsiW.png"
    }
  },
  {
    companyName: "Hippocratic AI",
    logos: {
      square: "hippocratic_square-tHhP90zROZZIbWcfPLSvqwc91kQsnv.png"
    }
  },
  {
    companyName: "Hitachi",
    logos: {
      horizontal: "hitachi_horizontal-9uTeNKNEIfLa9NlXBljOCjzmRra7LP.png"
    }
  },
  {
    companyName: "Hive",
    logos: {
      square: "hive_square-iGFhuFJOawItuTtfVCW84kdea8zAcm.png"
    }
  },
  {
    companyName: "Home Depot",
    logos: {
      square: "homedepot_square-Hs1DBstYuHysc7Tf6nz0sKSKHcCpw4.png"
    }
  },
  {
    companyName: "Honeywell",
    logos: {
      horizontal: "honeywell_horizontal-jDFMFEnr97mmbH89YsrS2XRe0FgASD.png"
    }
  },
  {
    companyName: "Hopper",
    logos: {
      square: "hopper_square-s19KkutlbNR4Sj69aBzS6QB4Kp1khI.png"
    }
  },
  {
    companyName: "Hotstar",
    logos: {
      horizontal: "hotstar_horizontal-g6rEijldDHznP1eLaaeRO3wqnGP7Kr.png"
    }
  },
  {
    companyName: "Houzz",
    logos: {
      square: "houzz_square-beCUGSngqlMQA4ttYq761X1m0rcdWG.png"
    }
  },
  {
    companyName: "HP",
    logos: {
      square: "hp_square-TVTRunUF0vPF1lNI2e81HfRLOVGcNb.png"
    }
  },
  {
    companyName: "HPE",
    logos: {
      horizontal: "HPE_horizontal-SbZH87kqaAyMC7iCQL0AgBaTfRZudp.png"
    }
  },
  {
    companyName: "HRL Laboratories",
    logos: {
      horizontal: "HRL%20Laboratories_horizontal-C0GFFXUVByg0F3MQ3e6ydpTOFT6wH7.png"
    }
  },
  {
    companyName: "Huntington Ingalls",
    logos: {
      horizontal: "huntington_ingalls_square-xB6jobffE1zaaEZEvy7FjNGiiSaqZT.png"
    }
  },
  {
    companyName: "HVO",
    logos: {
      square: "hyatt-vacation_horizontal-1HRpMNwTYEerG1td0hjkSB4s3oFFau.png"
    }
  },
  {
    companyName: "IBM",
    logos: {
      horizontal: "ibm_horizontal-XESGb96MZkXsy9hfExwLQFnXgiqXqP.png"
    }
  },
  {
    companyName: "Imbue",
    logos: {
      square: "imbue_square-D9ouGMavAHycgMOaZLc7b5hLMLpGJd.png"
    }
  },
  {
    companyName: "IMF",
    logos: {
      square: "imf_square-hYKNPiRTLeJtXpE9iAm2amSaryEgHy.png"
    }
  },
  {
    companyName: "Included Health",
    logos: {
      square: "inlcudedhealth_square-71mnM6DZV50O4PfSURUzlALt4cjjhe.png"
    }
  },
  {
    companyName: "Innovaccer",
    logos: {
      square: "innovaccer_square-lZks5Fesa6PZ5OayzVuf6RBZLosF3T.png"
    }
  },
  {
    companyName: "InPay",
    logos: {
      horizontal: "inpay2_horizontal-2AqHfrImSA5xULkgQTmgdGEkvmfAlh.png"
    }
  },
  {
    companyName: "Insomnia Cookies",
    logos: {
      square: "insomniacookies_square-Xx1siJJhGxLZqlP8jnCm4hqkHQHbX6.png"
    }
  },
  {
    companyName: "InstaDeep",
    logos: {
      square: "InstaDeep_square-PU9RjwpG6YcRK0Yp9uJVrdLR0JZ3jz.png"
    }
  },
  {
    companyName: "Intapp",
    logos: {
      horizontal: "intapp_horizontal-owNqwWHcyqzatFE0HR3CCFZQnoyRy1.png"
    }
  },
  {
    companyName: "Intel",
    logos: {
      horizontal: "intel_horizontal-p2BcXsqpFPiTi0uvbnwwwTX4ZkExvn.png"
    }
  },
  {
    companyName: "Intuit",
    logos: {
      square: "intuit_horizontal-9zGNgKc67nn5L33BmY784JZlvP1Fng.png"
    }
  },
  {
    companyName: "Intuitive Surgical",
    logos: {
      horizontal: "intuitive_horizontal-A1zNcv2Hc4obk0I3TxdCp0ynhxQe1f.png"
    }
  },
  {
    companyName: "Invesco",
    logos: {
      horizontal: "invesco_horizontal-u2hqAy5vyd1HC7ln9zz8PQnjWtixRH.png"
    }
  },
  {
    companyName: "Ion",
    logos: {
      horizontal: "ion_horizontal-VKYF5AIGavabeMvLEYBezZIVP5D1qq.png"
    }
  },
  {
    companyName: "IQVIA",
    logos: {
      horizontal: "iqvia_horizontal-mpTtd58PxAiDs7PaPDHOG1Dru6Ijjt.png"
    }
  },
  {
    companyName: "IXL Learning",
    logos: {
      horizontal: "IXL%20Learning_horizontal-w31i4YiFUoEHZquH6Y8689WR9AismJ.png"
    }
  },
  {
    companyName: "Jabil",
    logos: {
      horizontal: "jabil_horizontal-ZP3Jxq719koYH9HvaZrrPl8FbPAugi.png"
    }
  },
  {
    companyName: "January",
    logos: {
      vertical: "january_vertical-lckQdjIUYp1ZvATrdo1NSqYLSwAAUn.png"
    }
  },
  {
    companyName: "Jerry",
    logos: {
      horizontal: "jerry_horizontal-0XDdjxn6Mqgiln3uedpFOoXAZqguNq.png"
    }
  },
  {
    companyName: "JLL",
    logos: {
      square: "jll_square-2i2vQhexipCxQnqSUixVHHcnbJ4IMn.png"
    }
  },
  {
    companyName: "Johnson Controls",
    logos: {
      square: "johnson_controls_square-PA7jDDLXXrBiOTjGvNK0CawaxkGTlW.png"
    }
  },
  {
    companyName: "JP Morgan",
    logos: {
      horizontal: "jpm_horizontal-zgg9SmQmj7vcrUZ3S7Z5oDbWZK0fwW.png"
    }
  },
  {
    companyName: "Jump",
    logos: {
      horizontal: "jump_horizontal-PMTv9zyB3IqkUgtwVxYNQe1hTbnEeN.png"
    }
  },
  {
    companyName: "Juniper Networks",
    logos: {
      horizontal: "juniper_networks_horizontal-yt7AMTRKoEc7OND8JdTu1acxJtiMbg.png"
    }
  },
  {
    companyName: "KBR",
    logos: {
      horizontal: "kbr_horizontal-R2CP0KZDq2jf2kuRBxFi02JW6gNwn0.png"
    }
  },
  {
    companyName: "Keybank",
    logos: {
      horizontal: "keybank_horizontal-GxLLak0N5K4T6MdlpwzeEcW7NJmOB1.png"
    }
  },
  {
    companyName: "Khan Academy",
    logos: {
      square: "KhanAcademy_square-q3xsMZuZq5qZqbrtMzxuAsV2AZki0T.png"
    }
  },
  {
    companyName: "Kiddom",
    logos: {
      square: "kiddom_square-8wZaEaI6mmvMa54LIDy7yrAgvW2j4V.png"
    }
  },
  {
    companyName: "Klook",
    logos: {
      square: "klook_square-JFV9mPteR0NdHhHMi9f8ZROHeSrhI2.png"
    }
  },
  {
    companyName: "Koddi",
    logos: {
      horizontal: "koddi_horizontal-31C5MCm5KSdoYcjG56FeLMiWV30rYq.png"
    }
  },
  {
    companyName: "Kodiak",
    logos: {
      square: "kodiak_square-6LoFiZyOtcYkkr5vlvAIscsVZRo6rQ.png"
    }
  },
  {
    companyName: "Kong",
    logos: {
      square: "kong_square-ZpinKbUpZjKoPA5SedlYUW73o5fuEI.png"
    }
  },
  {
    companyName: "KPMG",
    logos: {
      horizontal: "kpmg_horizontal-9xSowt9CInCvdauO7KmesDAMWo4d27.png"
    }
  },
  {
    companyName: "Kraken",
    logos: {
      square: "kraken_square-prpXpXNpgo7oIJxCRdRoziYztpn4BV.png"
    }
  },
  {
    companyName: "L3Harris",
    logos: {
      square: "l3harris_square-hKMoDhHkSsE5oyrJnjuJnZtpkZ3RKR.png"
    }
  },
  {
    companyName: "Lab49",
    logos: {
      square: "lab49_square-YCW2s6t1GJDmGfKaKaT1bdzbAPlkBk.png"
    }
  },
  {
    companyName: "Lam Research",
    logos: {
      square: "lam_square-LByC5CgKxjs76R5rGTBju0pJAiTvDx.png"
    }
  },
  {
    companyName: "Laticrete",
    logos: {
      square: "laticrete_square-DQs8GRgOusZD0PpDavY6BKrn6trC9X.png"
    }
  },
  {
    companyName: "Layer",
    logos: {
      horizontal: "layer_horizontal-2IZGkIXffY7ceL5rQpRgHZ2EHhTrEx.png"
    }
  },
  {
    companyName: "LCBC",
    logos: {
      horizontal: "lcbcchurch_horizontal-Q1wRVfNBGVShIasoaoThYMD8mrCqbI.png"
    }
  },
  {
    companyName: "Lego",
    logos: {
      square: "lego_square-FtWUFymsGoyOpwf6GVBHSkHCgpXiAX.png"
    }
  },
  {
    companyName: "Level AI",
    logos: {
      horizontal: "Level%20AI_horizontal-CQrfpX2hXys8lToi197HIDw6Zl3mGl.png"
    }
  },
  {
    companyName: "LGG",
    logos: {
      square: "lgg_square-nxPy0Pc9IDPX1L8bZbshHLIOtFMU6K.png"
    }
  },
  {
    companyName: "Life.Church",
    logos: {
      horizontal: "lifechurch_horizontal-EXdfgGLPHQfz2GsAhzBRaFoj5lZdjR.png"
    }
  },
  {
    companyName: "Lightspeed Studios",
    logos: {
      square: "lightspeed_studios_square-bMUtop0he8okYY85tucMW5jkoZLYh4.png"
    }
  },
  {
    companyName: "Lilt",
    logos: {
      square: "lilt_square-5yk6GcscmAysrhvLsSIW1kg3khhsCO.png"
    }
  },
  {
    companyName: "Lime",
    logos: {
      square: "lime_square-nNSvvgH4yZ7eposfhekIzsyH59EEQE.png"
    }
  },
  {
    companyName: "Live Nation Entertainment",
    logos: {
      horizontal: "LiveNation_horizontal-MALDyYCbXrI2B2m2eFBrAi5BnHguxP.png"
    }
  },
  {
    companyName: "LiveRamp",
    logos: {
      square: "liveramp_square-7XFohcUqnT03E7JYP3HRzuWCFta1YQ.png"
    }
  },
  {
    companyName: "Loft Orbital",
    logos: {
      horizontal: "loft_horizontal-UBPglsPX0DzmUit7PbpNDDlzqnHd2p.png"
    }
  },
  {
    companyName: "LogRocket",
    logos: {
      square: "logrocket_square-VrHIBf1IpZW56jIEew2F0ZcEUJAQBH.png"
    }
  },
  {
    companyName: "Loop",
    logos: {
      square: "loop_square-hVvPDUIgIwCjAIkpYrJm8xdtUX6IMS.png"
    }
  },
  {
    companyName: "Lucid Motors",
    logos: {
      square: "lucid_square-SeOlGth7s57BkZfBHlNpXArrdsxQb4.png"
    }
  },
  {
    companyName: "Lucid Software",
    logos: {
      square: "lucid_software_square-GMsxvXKT7INAZEKLF02jMTRMaO2Qaa.png"
    }
  },
  {
    companyName: "Luma AI",
    logos: {
      square: "luma_square-bnQ8vnmvpL1JTqBbBgV0XlJyVEgUgk.png"
    }
  },
  {
    companyName: "Lumen",
    logos: {
      horizontal: "lumen_horizontal-LsqIHI2kplVqFZIXeV7xjVspsF0TO3.png"
    }
  },
  {
    companyName: "Lumeris",
    logos: {
      square: "lumeris_square-W2R8sSwnRfBEHtlFyp8IkndXVnHuu4.png"
    }
  },
  {
    companyName: "Luminar",
    logos: {
      square: "luminar_square-5Qa6xyxqJQ4m0t3uOOQCu8g6QFW9mh.png"
    }
  },
  {
    companyName: "Lyft",
    logos: {
      square: "lyft_square-TNTgtXneZ97XD33PBIf12a9k8iBI2Z.png"
    }
  },
  {
    companyName: "M&T",
    logos: {
      horizontal: "mt_horizontal-FQHuob7ydoGoNGXGOLExYH3vsTdTH3.png"
    }
  },
  {
    companyName: "Mactores",
    logos: {
      square: "mactores_square-pYZSH7U6Cr3XApFsp9msA7dG4osGZ8.png"
    }
  },
  {
    companyName: "Magnify",
    logos: {
      square: "magnify_square-9C7h9V1qkZtxMLUqgoITWssRav5NYu.png"
    }
  },
  {
    companyName: "Malt",
    logos: {
      horizontal: "malt_horizontal-6n2Y3ZPeUPt58gDtoWKhEjgR0BH7IL.png"
    }
  },
  {
    companyName: "Mangomint",
    logos: {
      square: "mangomint_square-0FwCrjuulEFt99rRSNUQrghWxNy2Zo.png"
    }
  },
  {
    companyName: "Manhattan Associates",
    logos: {
      square: "manhattan_associates_square-AHL8hcDyyylACAJFFE036nrSJEYovX.png"
    }
  },
  {
    companyName: "Marsh & McLennan",
    logos: {
      horizontal: "marsh_mclennan_horizontal-fNKEOoTtvxBhx6cumyIBQZN0EMik62.png"
    }
  },
  {
    companyName: "Mastercard",
    logos: {
      horizontal: "mastercard_horizontal-NwjJmXCDD4kYwZ2n4ZICrgS3BZHQuy.png"
    }
  },
  {
    companyName: "Match Group",
    logos: {
      horizontal: "Match%20Group_horizontal-8hrazzP0XVIQ9ehUT26GMBesmeZQmp.png"
    }
  },
  {
    companyName: "Matterport",
    logos: {
      square: "matterport_square-rLPKuRSI98slnV3BUuNgTX4gYY7WMc.png"
    }
  },
  {
    companyName: "Maze",
    logos: {
      horizontal: "maze_horizontal-kAJxvcDUJJFkRDCIKyy9jxgy29YNcu.png"
    }
  },
  {
    companyName: "McKesson",
    logos: {
      horizontal: "mckesson_horizontal-gC8cuj5s5wQMyAGPHSns0QY3foRsaJ.png"
    }
  },
  {
    companyName: "McKinsey",
    logos: {
      horizontal: "mckinsey_horizontal-gjXWhQiSkm5Oq3jFlgvHVtsPdshezz.png"
    }
  },
  {
    companyName: "Mediaocean",
    logos: {
      square: "mediaocean_square-mFokM5t0ZLN2gPJaXUowRJvUoHBzPs.png"
    }
  },
  {
    companyName: "Medtronic",
    logos: {
      horizontal: "medtronic_horizontal-AVs6tqIP6yjsdNkIWWXlKhZhVGfew3.png"
    }
  },
  {
    companyName: "Melio",
    logos: {
      horizontal: "melio_horizontal-Z6S15mm5XIwV8lnbNHdDgVQzVzl6Ug.png"
    }
  },
  {
    companyName: "Merge",
    logos: {
      square: "merge_square-xnQUf6KPgEZRfs01U7zO1nKskinMty.png"
    }
  },
  {
    companyName: "Merger Market",
    logos: {
      square: "mergermarket_square-EplrOBeeBfbu66fKktGcB5qbETHiHQ.png"
    }
  },
  {
    companyName: "Metabase",
    logos: {
      square: "metabase_square-Dd0DphDM4NydHe0XJQkAAL8a2fzpLA.png"
    }
  },
  {
    companyName: "Method",
    logos: {
      square: "method_square-DJYhLueaPUNF0Gu5CXVffUlVURSBYm.png"
    }
  },
  {
    companyName: "MetLife",
    logos: {
      square: "MetLife_square-PHg3nasaBW710EPHMflNzvN7WGrPsB.png"
    }
  },
  {
    companyName: "Micron",
    logos: {
      horizontal: "micron_horizontal-To3yeyK8fRvTu4qaRSnNCAlN83VkCM.png"
    }
  },
  {
    companyName: "Michelin",
    logos: {
      horizontal: "michelin_horizontal-io65y9w45vHQvRlwETuiUspP5z4WMj.png"
    }
  },
  {
    companyName: "Microchip",
    logos: {
      square: "microchip_square-lIT0SdjQlOhPYebIChcnQzi6pxBH9g.png"
    }
  },
  {
    companyName: "Microsoft",
    logos: {
      square: "microsoft_square-4gOWpBYOYma80AsrKp3JHgA8FV6NNb.png"
    }
  },
  {
    companyName: "Milk Moovement",
    logos: {
      horizontal: "milkmoovement_horizontal-NvHmf8QsikUYfkmq27OgOBf02dmHrK.png"
    }
  },
  {
    companyName: "Millennium",
    logos: {
      square: "millenium_square-025pgrMjVglyCEdjaaoUoUMrWR92ty.png"
    }
  },
  {
    companyName: "Mimecast",
    logos: {
      square: "mimecast_square-yKu5siSJLD8KgvHxpKE1vCwLVJ4Iz9.png"
    }
  },
  {
    companyName: "Mirakl",
    logos: {
      square: "mirakl_square-C5nySG7IcfIYncaZqw2mjb4U0HU0Is.png"
    }
  },
  {
    companyName: "Mistral AI",
    logos: {
      square: "Mistral%20AI_square-nA5L19veOt6rtdAErOS4nxj0m53kY5.png"
    }
  },
  {
    companyName: "Mitek",
    logos: {
      square: "Mitek_square-C0NKIZKy7fu3177VcUBD7c6h5YIg3d.png"
    }
  },
  {
    companyName: "Modal",
    logos: {
      square: "modal_square-wVxLD4pJPt41qZygSZ4oXzGJnclSf6.png"
    }
  },
  {
    companyName: "Moderna",
    logos: {
      horizontal: "moderna_horizontal-Ai9VCfRidY5TCq4P7O9rIy92Nl9zGR.png"
    }
  },
  {
    companyName: "ModSquad",
    logos: {
      horizontal: "modsquad_horizontal-sKIzHZ9ZA9p6KHOzw0N16xu4HxmjE7.png"
    }
  },
  {
    companyName: "Molina Healthcare",
    logos: {
      square: "molina_healthcare_square-q1c7fbzAMOvZ1Cf9EiheGWOgy2iXzb.png"
    }
  },
  {
    companyName: "Moloco",
    logos: {
      square: "moloco_square-upOgh1jRZ0ith9zasCHnxRNAQmGBSN.png"
    }
  },
  {
    companyName: "Moment",
    logos: {
      square: "momentinc_square-VllJPW91FeLlxKl8t8W48Th7Zrzf6S.png"
    }
  },
  {
    companyName: "Monad",
    logos: {
      square: "monad_square-fD57FCyj148pmeLpUXNLK7VMTnmbmN.png"
    }
  },
  {
    companyName: "Mondelez",
    logos: {
      horizontal: "mondelez_horizontal-jb9qweBA4K9W66I2QjTzpI7vuI6rf1.png"
    }
  },
  {
    companyName: "MongoDB",
    logos: {
      vertical: "mongodb_vertical-3HQexsyq4g8Ym94bHtETNYPopRRfxz.png"
    }
  },
  {
    companyName: "Monzo",
    logos: {
      square: "monzo_square-ntVTNJpQ7skZ6B0ssJfZT9pidRuR94.png"
    }
  },
  {
    companyName: "Moody's",
    logos: {
      square: "moodys_square-bgPkXxmfHhnyA3R0PbYKAvgGNKG598.png"
    }
  },
  {
    companyName: "Morgan Stanley",
    logos: {
      square: "morgan_stanley_square-du2hsfhsYDzaIShtVwrhydscxE6Uvk.png"
    }
  },
  {
    companyName: "Motion",
    logos: {
      square: "motion_square-VS6x9xOX0y7fIIMd51wEIQLJoo4l7Q.png"
    }
  },
  {
    companyName: "Motorola",
    logos: {
      square: "motorola_square-VEapXn2goUjSnz86ZKYB2EKPgh6yIE.png"
    }
  },
  {
    companyName: "Moxion Power",
    logos: {
      square: "moxionpower_square-hjyu5BeBjB3jE1Jk8ZJgtfp55SsWzp.png"
    }
  },
  {
    companyName: "Mozilla",
    logos: {
      square: "mozilla_square-BTqbYj0Hph8TJO5EZLWb5mT6r8baAA.png"
    }
  },
  {
    companyName: "Mux",
    logos: {
      horizontal: "mux_horizontal-2gi4XSoMheCxKRX709PecQxTMv0Y2Y.png"
    }
  },
  {
    companyName: "Mysa",
    logos: {
      horizontal: "Mysa_horizontal-NF3ycvypsNprRv9zFV4zl27PjQY3Qd.png"
    }
  },
  {
    companyName: "National Academy of Sciences, Engineering, and Medicine",
    logos: {
      square: "National%20Academies%20Of%20Sciences,%20Engineering,%20And%20Medicine_square-HV9P6N2Au4phvlLDtauVWLVOuBxi8f.png"
    }
  },
  {
    companyName: "Nava",
    logos: {
      horizontal: "nava_horizontal-flrOTnOs5G0aYZWvQnWPpK03Mi7993.png"
    }
  },
  {
    companyName: "Navan",
    logos: {
      square: "navan_square-ghluWVBQaTU5iqC7ijCFVBrHVMlpWR.png"
    }
  },
  {
    companyName: "NCSolutions",
    logos: {
      square: "ncsolutions_square-XQlEEizS5mtbpru3RFi4ZxOoif50Hj.png"
    }
  },
  {
    companyName: "NCR Voyix",
    logos: {
      square: "NCR_square-7lR7qjC4nsApEFbXxJ9avV8sm72QY9.png"
    }
  },
  {
    companyName: "Neon",
    logos: {
      square: "neon_square-9xkwJmiPbq7Jags6XafcpqIGRbBWdq.png"
    }
  },
  {
    companyName: "NetApp",
    logos: {
      square: "netapp_square-HUDDXgJ0iKY2V1ac1286xrdz5FkrCI.png"
    }
  },
  {
    companyName: "Netflix",
    logos: {
      vertical: "Netflix_vertical-bs7RQtvYxOceWxpdtIBsHW3NWnMuh2.png"
    }
  },
  {
    companyName: "New Era Technology",
    logos: {
      square: "neweratechnology_square-2LK8eAN2VQ1HYg0toXFh8QAxz4qbz4.png"
    }
  },
  {
    companyName: "Neuralink",
    logos: {
      horizontal: "nueralink_horizontal-GBnHroU31gimcFD6el1w4Vwt6Coucp.png"
    }
  },
  {
    companyName: "New Front Insurance",
    logos: {
      square: "newfrontinsurance_square-aaWt84YPcp6ScMqLjJpmSBVmGjVk0v.png"
    }
  },
  {
    companyName: "Nextera Energy",
    logos: {
      horizontal: "nextera_energy_horizontal-5UBKCQ72ewF9qttMV5Viy6zUZ1gwU4.png"
    }
  },
  {
    companyName: "Nextracker",
    logos: {
      square: "nextracker_square-O62aJXGZqyxroG9jkbLa98iO91ijFk.png"
    }
  },
  {
    companyName: "Nexus",
    logos: {
      square: "nexus_square-pa6o1naZ7BoAimp8quUrX9U7g4IMeT.png"
    }
  },
  {
    companyName: "Nexstar Media Group",
    logos: {
      horizontal: "nexstar_media_horizontal-FaZzyjQj681Dm5oRmydqHOGeI5hEqv.png"
    }
  },
  {
    companyName: "NFL",
    logos: {
      square: "NFL_square-ujh1q0HkPxYqUpLqfx9cmPceAd0xNP.png"
    }
  },
  {
    companyName: "Nielsen",
    logos: {
      horizontal: "nielsen_horizontal-qvz0TIpr5dINo4qL3uuzypvAIABQJB.png"
    }
  },
  {
    companyName: "Nike",
    logos: {
      horizontal: "nike_horizontal-KLjiqmwC9EhrW6c5WHxXuGBqPv8UwV.png"
    }
  },
  {
    companyName: "Nimble",
    logos: {
      square: "nimble_square-NwbJCS4P9DRbJfh9Rlrf1f0owtZxKK.png"
    }
  },
  {
    companyName: "Nisum",
    logos: {
      square: "nisum_square-KL5wPacZznG2ZyJBvJpZ4hAuuZQRTV.png"
    }
  },
  {
    companyName: "Nomi Health",
    logos: {
      square: "Nomi%20Health_square-FhJhDCAa7twqbY5hjsvEkOflsuVyFT.png"
    }
  },
  {
    companyName: "Nordstrom",
    logos: {
      square: "nordstrom_square-mmY7g7W9NH3A7I9Fi397zXtRZR2yi8.png"
    }
  },
  {
    companyName: "Northwood",
    logos: {
      horizontal: "northwood_horizontal-rl6eSvM9u3QiiAx4qvrMoHToDfe9uB.png"
    }
  },
  {
    companyName: "Northrop Grumman",
    logos: {
      horizontal: "northrop_grumman_horizontal-Xqd7b8NbvzhbO7QFFai0ojfOsGCIzr.png"
    }
  },
  {
    companyName: "Notable",
    logos: {
      horizontal: "notable_horizontal-0sUt9jmd1plRD4SjfzwsMQODy1u612.png"
    }
  },
  {
    companyName: "Notion",
    logos: {
      square: "notion_square-mZg2G8A7DlVVfVCbM1h4jiKimLEREm.png"
    }
  },
  {
    companyName: "Novanta",
    logos: {
      square: "novanta_square-OBsy3KH4unsNtMcrE09pFgD1uGUj50.png"
    }
  },
  {
    companyName: "NREL",
    logos: {
      square: "NREL_square-urOfLpxPkPZJxHMQkMwJjsTgqPBHAH.png"
    }
  },
  {
    companyName: "Nucleus",
    logos: {
      square: "nucleous_square-Sveh6IWjlKlXa28GPEoF0bSV9DGtLP.png"
    }
  },
  {
    companyName: "Nuna",
    logos: {
      horizontal: "nuna_horizontal-QfSCzveg68N2Vt1sBTFuydimCfZ3SH.png"
    }
  },
  {
    companyName: "Nutrafol",
    logos: {
      horizontal: "nutrafol_horizontal-4BuwrSJI9EXPheEyKH1QjEyjP1nEUK.png"
    }
  },
  {
    companyName: "Nuqleous",
    logos: {
      square: "nuqleous_square-vrt0ZdR88nWL9VSkr02nNVtSUaL95l.png"
    }
  },
  {
    companyName: "NVIDIA",
    logos: {
      square: "nvidia_square-dj3XlYKTVfadIJHqJqtFtiv9npleQY.png"
    }
  },
  {
    companyName: "NXP",
    logos: {
      horizontal: "NXP_horizontal-1DOVYR8QX2fV7204bgkQRVPLOrvF5b.png"
    }
  },
  {
    companyName: "NY Times",
    logos: {
      square: "nyt_square-Ooua8uPjtwlK2WuDMSvzN5T7EPiZAM.png"
    }
  },
  {
    companyName: "Octopus Energy",
    logos: {
      horizontal: "octoenergy_horizontal-vvf1VCmGdSuXGD3iJCV2k663mHICPX.png"
    }
  },
  {
    companyName: "Offchain Labs",
    logos: {
      horizontal: "offchain_labs_square-t6N92XvOQpnnc0PWReOlbvQgNsjriX.png"
    }
  },
  {
    companyName: "Oklo",
    logos: {
      square: "oklo_square-9XtaQWJ6ZlA2elikG68QhB6eQ2kfYv.png"
    }
  },
  {
    companyName: "Okta",
    logos: {
      square: "okta_square-6u77Cf2HsVD4fFL1UlsG30Lt735CTS.png"
    }
  },
  {
    companyName: "Onebrief",
    logos: {
      square: "onebrief_square-xnlTBuqEzvrDc3LgtsAdH76yYBuG9t.png"
    }
  },
  {
    companyName: "onsemi",
    logos: {
      horizontal: "onsemi_horizontal-jxH96f3Uo4CiOod6RBpjryywjXFALR.png"
    }
  },
  {
    companyName: "OpenAI",
    logos: {
      square: "openai_square-GdO0P3Zgc7VZ5zZTK0wzC7ZQinw2Ig.png"
    }
  },
  {
    companyName: "Opendoor",
    logos: {
      square: "opendoor_square-BHlwz4FR27H39tmoSTaBRfWxCFnEbz.png"
    }
  },
  {
    companyName: "OpenTable",
    logos: {
      square: "opentable_square-kgVJCWTIG8csXAHsvkqvyDtnrnPIDL.png"
    }
  },
  {
    companyName: "Orb",
    logos: {
      square: "orb_square-quCG8jsc7SbxzLvft9zLZdqFWR91zd.png"
    }
  },
  {
    companyName: "Orchard",
    logos: {
      square: "orchard_square-55mnB8fqdXuImYVA72NszFVMF8U1Cj.png"
    }
  },
  {
    companyName: "Oregon",
    logos: {
      square: "oregon_square-1ZixPDNyg1W1zVQbvxYA1X1zsZqFJW.png"
    }
  },
  {
    companyName: "Osedea",
    logos: {
      horizontal: "osedea_horizontal-8jQqKQePS15Xfi01HfDXx4QAFsx9hz.png"
    }
  },
  {
    companyName: "Oshkosh",
    logos: {
      horizontal: "oshkosh_horizontal-W5hUbBB8YPF9d9jVwNtAkiO1eUL0Ar.png"
    }
  },
  {
    companyName: "OSU",
    logos: {
      square: "ohio_square-rzv1Rtkl6HH4ELsFwsuvhgdu7RcLGs.png"
    }
  },
  {
    companyName: "Otis",
    logos: {
      horizontal: "otis_horizontal-T1KxxpjGcE9UrykUCXAOlLvbAytjKa.png"
    }
  },
  {
    companyName: "Outlier",
    logos: {
      square: "outlier_square-RNfSPe4JiscEOtgZbILQsVqGdAbhCt.png"
    }
  },
  {
    companyName: "P&G",
    logos: {
      square: "pg_square-mek2ckX8kvO0XJXgm4WvToPjMXqqMb.png"
    }
  },
  {
    companyName: "PagerDuty",
    logos: {
      square: "pagerduty_square-x1esUdlCb86Y4LqzbfTChhs8ydhLE2.png"
    }
  },
  {
    companyName: "Palantir",
    logos: {
      square: "palantir_square-lB1K5HVdMwvQu93X7zIQkZBV8VdSrv.png"
    }
  },
  {
    companyName: "Pano AI",
    logos: {
      horizontal: "pano_horizontal-Ct72cQtfgm57MomzSM5mRo6X8Bu6mS.png"
    }
  },
  {
    companyName: "Pareto.AI",
    logos: {
      square: "pareto_square-4o9pCxnfdr3Zv1ow5WoRsKPkYeEuzz.png"
    }
  },
  {
    companyName: "Patagonia",
    logos: {
      horizontal: "patagonia_horizontal-82smtnZgPuIIdS6oTiWYHOULvx22Nc.png"
    }
  },
  {
    companyName: "Patreon",
    logos: {
      square: "patreon_square-EE9wvfqTTtk83FyLD6JnOaYzJFNBIe.png"
    }
  },
  {
    companyName: "PAX",
    logos: {
      square: "pax_square-GHRpjWKCgkBcaoaxOweBDofRoEPI86.png"
    }
  },
  {
    companyName: "Peach Finance",
    logos: {
      square: "peach_square-CAOPznrzxz6GJ7WG1pd9zWHa2JM6Wl.png"
    }
  },
  {
    companyName: "Peerspace",
    logos: {
      horizontal: "peerspace_horizontal-8HxvpZuk84x45hG0sW77vlbSiU2SwM.png"
    }
  },
  {
    companyName: "Pelmorex",
    logos: {
      square: "pelmorex_square-OMOXcqFTQDSIHtV1z0FPkgVnrDQ2e8.png"
    }
  },
  {
    companyName: "Pendo",
    logos: {
      square: "pendo_square-djNXRGi7ew2Ehn4dFsWn0bd1KYgQCZ.png"
    }
  },
  {
    companyName: "People Data Labs",
    logos: {
      square: "PeoplDataLabs_square-lsWImI7qW7i2xVpuryLorOvxfJnmHQ.png"
    }
  },
  {
    companyName: "Penumbra",
    logos: {
      square: "penumbra_square-GjAnqNLcNiRAjGiVJRWcCoPSAJ0XcD.png"
    }
  },
  {
    companyName: "PerfectServe",
    logos: {
      square: "perfectserve_square-NJXgIYxyoaGn7Q4FPVnEjer4mw6DqR.png"
    }
  },
  {
    companyName: "PermitFlow",
    logos: {
      square: "permitflow_square-kTMhYEX9BFHLgpEHkZ6x2r2199AUCh.png"
    }
  },
  {
    companyName: "Persona",
    logos: {
      square: "persona_square-jFd2xgWNLA6z6QPwm9p5vvYhmKmfo6.png"
    }
  },
  {
    companyName: "Pfizer",
    logos: {
      square: "pfizer_square-cc08SbqQnrZQeoPnHqk6KElpu90OKD.png"
    }
  },
  {
    companyName: "Philips",
    logos: {
      horizontal: "Philips_horizontal-0ou5fFyai59wE53RZhyRjRij5qtQo3.png"
    }
  },
  {
    companyName: "PhysicsX",
    logos: {
      square: "physicsx_square-yQoSJjeNs9S74dadOaaGjDeU1OsJIV.png"
    }
  },
  {
    companyName: "Pie Insurance",
    logos: {
      square: "pie_square-8rdYxlaKVGYMT9s36NYeuyFr1qy2wR.png"
    }
  },
  {
    companyName: "Pigment",
    logos: {
      square: "pigment_square-neTKqT8oPXgCEOzievXXQGDXborBVb.png"
    }
  },
  {
    companyName: "Pinterest",
    logos: {
      square: "pinterest_square-3E0LrdKYfrMIADIFyHdtcrWnK5LS5q.png"
    }
  },
  {
    companyName: "Pison",
    logos: {
      square: "pison_square-dngsZE1Jie2xZaE3lSlMEuyzviQDHG.png"
    }
  },
  {
    companyName: "Plaid",
    logos: {
      horizontal: "plaid_horizontal-T7yVhe5HUuCyLSYGbMvQtR0UjXphTh.png"
    }
  },
  {
    companyName: "Plain",
    logos: {
      square: "plain_square-XkdJN0nuJv6iTE9gT8wBxKgDaZPgAv.png"
    }
  },
  {
    companyName: "Planner 5D",
    logos: {
      square: "planner5d_square-y0crHY7LGYNIWITRvHF2KyWwH40TQA.png"
    }
  },
  {
    companyName: "PlayStation",
    logos: {
      horizontal: "playstation_horizontal-5SJxGOJm4lGLHHrAx6VGpSoar8GwL9.png"
    }
  },
  {
    companyName: "PNC Bank",
    logos: {
      square: "PNC_square-olof82gbONt4jTl7A3U5K4I41fj2nZ.png"
    }
  },
  {
    companyName: "PointClickCare",
    logos: {
      square: "pointclickcare_square-o8iggAalGCS51KHXT7kXaGbAzgVRdQ.png"
    }
  },
  {
    companyName: "Pokemon",
    logos: {
      horizontal: "pokemon_horizontal-nEPaEwrd4sAmLSD49Lu7p4jvQx7wzu.png"
    }
  },
  {
    companyName: "Postman",
    logos: {
      square: "postman_square-dh7GKs9PQ56f2FNOfFoGE8uBvEi5Vw.png"
    }
  },
  {
    companyName: "Priceline",
    logos: {
      horizontal: "Priceline_horizontal-ZaX7UVlibFREpEWwhml5yxLun8czKb.png"
    }
  },
  {
    companyName: "Project Growth",
    logos: {
      square: "project_growth_square-lGhFcNemSE7JIb2cm5l2Mbyrv0ml74.png"
    }
  },
  {
    companyName: "Project Healthy Minds",
    logos: {
      horizontal: "ProjectHealthyMinds_horizontal-zZzDyo0QLBjrazNs7zsCAzyj635rnc.png"
    }
  },
  {
    companyName: "Proof",
    logos: {
      square: "proof_square-Qjrz8UhPVpm1GBjx3yPsiIDYDvmoS6.png"
    }
  },
  {
    companyName: "Prologue",
    logos: {
      square: "prologue_square-XFyYGqJq0c867LcYbUlWNBXTaQPkC7.png"
    }
  },
  {
    companyName: "Prologue Strategy",
    logos: {
      horizontal: "prologuestrategy_horizontal-CR0Jd2hG2U3JABmuXOFqhcySpW1YvN.png"
    }
  },
  {
    companyName: "Protective",
    logos: {
      square: "Protective_square-mSspOt5KcI9JlwGMh1tllTP9e1lgsa.png"
    }
  },
  {
    companyName: "Public",
    logos: {
      vertical: "public_vertical-olMH8n6lCdF6OX59QFRfo5mqzIZ9ij.png"
    }
  },
  {
    companyName: "Puma",
    logos: {
      square: "Puma_square-qAiWdR0KlOPnBf29Trca3wEbzjl32L.png"
    }
  },
  {
    companyName: "PwC",
    logos: {
      square: "PwC_square-uKJjebe9U6oaj0LJ6tCMpkout34Si2.png"
    }
  },
  {
    companyName: "Prysmian",
    logos: {
      square: "prysmian_square-Of4GwGnV5ecQYXIuLGj9UQq1DMIjeg.png"
    }
  },
  {
    companyName: "Pylon",
    logos: {
      square: "pylon_square-vOIRUNUvjfa7o9Sq5EX3doVEgKPJsF.png"
    }
  },
  {
    companyName: "Qualcomm",
    logos: {
      square: "qualcomm_square-DpKU9BdedaKkvGNFm2c3DcngRUTOVW.png"
    }
  },
  {
    companyName: "Qualtrics",
    logos: {
      horizontal: "qualtrics_horizontal-QtPUhSEaV9us0Hsbm1EvJZMSDDqoWm.png"
    }
  },
  {
    companyName: "Qualys",
    logos: {
      square: "qualys_square-oWFxG04xP83o8R6lGzLAOZw1PO5sAW.png"
    }
  },
  {
    companyName: "Quench",
    logos: {
      square: "Quench_square-XPKfzmGNI54RNJBbny26cN226vsPHx.png"
    }
  },
  {
    companyName: "QuickCruit",
    logos: {
      square: "QuickCruit_square-5erTzbgqoSPF81sfYdeuUeA6BeBZkP.png"
    }
  },
  {
    companyName: "Quince",
    logos: {
      horizontal: "quince_horizontal-YLqxo05GsnaReju59ImDygsiWnVALh.png"
    }
  },
  {
    companyName: "Quizlet",
    logos: {
      square: "quizlet_square-m6OCO1hmGTWeGGxbzVg8i1XAIJeFdc.png"
    }
  },
  {
    companyName: "Quora",
    logos: {
      horizontal: "quora_horizontal-RBdYFRKA1CASFsjjOp9P2g9kr5HCTc.png"
    }
  },
  {
    companyName: "Qventus",
    logos: {
      square: "qventus_square-fpj95iEsLooVlzn0fJx3wPMpVbz6V9.png"
    }
  },
  {
    companyName: "Rackspace Technology",
    logos: {
      horizontal: "rackspace_horizontal-xZpkIEmn1QlFDdREaxmBnYuoxnTbck.png"
    }
  },
  {
    companyName: "Railway",
    logos: {
      square: "railway_square-J19BTrQ4FhV6DSDAwF2vj5mH3kJTMR.png"
    }
  },
  {
    companyName: "Rakuten",
    logos: {
      horizontal: "rakuten_horizontal-USND5L5STXGylgpkNN3kXEdFyp0URN.png"
    }
  },
  {
    companyName: "Ramp",
    logos: {
      square: "ramp_square-i27xe9Pol8Pm7BeVTSbLYTvFohNHVW.png"
    }
  },
  {
    companyName: "Razer",
    logos: {
      square: "Razer_square-jd4jF1pbZyNF378U4f122g5y1Vn1D4.png"
    }
  },
  {
    companyName: "Reach Power",
    logos: {
      square: "reach_square-E9ifKGuKlS3TBbSnG9G9d7jV3CLXUg.png"
    }
  },
  {
    companyName: "Recharge",
    logos: {
      square: "recharge_square-bGzjp5L83fbe6w0X9Mk7H2w0lNpPSU.png"
    }
  },
  {
    companyName: "Red Canary",
    logos: {
      square: "red_canary_square-znjCw1UGFiZMqYcQ9RtMNjeWhsas0j.png"
    }
  },
  {
    companyName: "Red Hat",
    logos: {
      square: "redhat_square-HkzfERqNk8tR8AbdHP3wktD6siaCgX.png"
    }
  },
  {
    companyName: "Reddit",
    logos: {
      square: "reddit_square-CjHMUFTqlgmOSlIDFumJWskL57jWO9.png"
    }
  },
  {
    companyName: "Redwood Software",
    logos: {
      square: "redwood_software_square-Owsi2h4paHFCLKU7uuxg5Rzgm6mMjF.png"
    }
  },
  {
    companyName: "Regeneron",
    logos: {
      square: "regeneron_square-NZhUej3D4PJEXOIVYNSfS4DILzSZGr.png"
    }
  },
  {
    companyName: "Relativity Space",
    logos: {
      square: "relativity_space_square-XgHmFjfCgttmCXNpoeYBJuTc7jkJhM.png"
    }
  },
  {
    companyName: "Relay",
    logos: {
      horizontal: "relay_horizontal-AaclvGdFBD3wNK6BwGxu5GByHGxWT4.png"
    }
  },
  {
    companyName: "Remedy",
    logos: {
      square: "remedy_square-qKIeKXErwukak9AWo0ReF9opAa6mAb.png"
    }
  },
  {
    companyName: "Remitly",
    logos: {
      square: "remitly_square-G0r0pKCz65OdNBRfTEjsYCMXXQKRFJ.png"
    }
  },
  {
    companyName: "Remote",
    logos: {
      square: "remote_square-OSRsjKC3JHQahoU8Eai0gEbp0NdEkU.png"
    }
  },
  {
    companyName: "Replicated",
    logos: {
      square: "replicated_square-gXUNPzKPlgJlKaChvCf4zCM1o424k3.png"
    }
  },
  {
    companyName: "Replit",
    logos: {
      square: "replit_square-n9Bq0E8DB1BGHpJjLIB3xF5FwPkEem.png"
    }
  },
  {
    companyName: "Reputation",
    logos: {
      square: "reputation_square-U50hPZUH7jieyJRZ0CvZVIHmmgeusa.png"
    }
  },
  {
    companyName: "Restaurant365",
    logos: {
      square: "restaurant365_square-5rLPOmnfS1Sp0NYiSb53t30YNLHTnA.png"
    }
  },
  {
    companyName: "Richardson RFPD",
    logos: {
      horizontal: "richardson_rfpd_horizontal-xh5xZYf86ilxXSaVOfdBRWdXSAOAcd.png"
    }
  },
  {
    companyName: "Rigetti",
    logos: {
      horizontal: "rigetti_horizontal-Fbfw5WtZoO97xtBWobeJ2I3HtowpzW.png"
    }
  },
  {
    companyName: "RingCentral",
    logos: {
      square: "ringcentral_square-VBa73igxj7CDnu7GMvhgJAKSBLOqHV.png"
    }
  },
  {
    companyName: "Rinsed",
    logos: {
      square: "rinsed_square-7iUHtRskiYzUO0fSiYMTyXNMyRbQY6.png"
    }
  },
  {
    companyName: "RippleMatch",
    logos: {
      square: "ripplematch_square-Y7ci6eOCPviHbtVLSedhlyWLjB3z1c.png"
    }
  },
  {
    companyName: "Risc Zero",
    logos: {
      square: "risc_zero_square-yzdHMpSfmI9BLDXdfF8dt1DhZF9Y4W.png"
    }
  },
  {
    companyName: "River",
    logos: {
      square: "river_square-FqS1O847oitzZz5RZa6eEnOLMIRokA.png"
    }
  },
  {
    companyName: "Ro",
    logos: {
      horizontal: "ro_horizontal-V6MIATpQo0MpV6ZHWAsyNB2kwt0IV6.png"
    }
  },
  {
    companyName: "Roadie",
    logos: {
      square: "roadie_square-ZAUEjd31QEiQsKjndixkWMVOlr1qtd.png"
    }
  },
  {
    companyName: "Roblox",
    logos: {
      horizontal: "roblox_horizontal-MCjiLuJ7peJSid6xcpJ5PjREwVOuuP.png"
    }
  },
  {
    companyName: "Robinhood",
    logos: {
      square: "robinhood_square-ODX0aMrq3RKC4swqe7NMMYep3aFS9f.png"
    }
  },
  {
    companyName: "Robotic Assistance Devices",
    logos: {
      square: "robotica_assistance%20_devices_square-k3HQaeQYidHo48qe1Lw8MzJTMdtznR.png"
    }
  },
  {
    companyName: "Rocket Money",
    logos: {
      square: "rocketmoney_square-psSAYzUnQwD7UVxma4KDzs5PsqTv2x.png"
    }
  },
  {
    companyName: "Roku",
    logos: {
      horizontal: "roku_horizontal-Nrnzd8pDuY3a6iwRQKKwHfJXZvm3dZ.png"
    }
  },
  {
    companyName: "Rula",
    logos: {
      square: "rula_square-2uEFK4shKNqmzkQgNThjFxE2bcjUKg.png"
    }
  },
  {
    companyName: "Sagent",
    logos: {
      square: "sagent_square-vqgCxQn2uGYSrVnpyNkfUIvVfm0LG3.png"
    }
  },
  {
    companyName: "Salesforce",
    logos: {
      square: "salesforce_square-nr0zLS2FbHobQulTrkWZ4vE1UbZeX9.png"
    }
  },
  {
    companyName: "Samsara",
    logos: {
      square: "samsara_square-n4VW1Nt3h90nsVXUwiD4CBB3rwO3O8.png"
    }
  },
  {
    companyName: "Samsung",
    logos: {
      horizontal: "Samsung_horizontal-01zHH7FpocmMCUtCzL2wZKhfe8dng3.png"
    }
  },
  {
    companyName: "Samsung Semiconductor",
    logos: {
      square: "samsungsemiconductor_square-Yx2FrtpejYTf5WvhKZYQ10n8Cd27PA.png"
    }
  },
  {
    companyName: "Sanofi",
    logos: {
      horizontal: "sanofi_horizontal-mNse0Lm5k9sWUKHgB66HiBxtxnJUjP.png"
    }
  },
  {
    companyName: "Santander",
    logos: {
      square: "santander_square-xlt792vZFKPETHTxDYFD9tOJvcVP3P.png"
    }
  },
  {
    companyName: "SAP",
    logos: {
      horizontal: "sap_horizontal-am9OmACS1zTBM5esyBoKkGFdVe7Nup.png"
    }
  },
  {
    companyName: "Sapphire Ventures",
    logos: {
      square: "sapphire_ventures_square-4uxgDiPOJzaefI9Rg330PzxSbjgDa5.png"
    }
  },
  {
    companyName: "Saronic Technologies",
    logos: {
      square: "saronic_square-0n9quGWc4cEh09peo1XUeFKAn3d8rf.png"
    }
  },
  {
    companyName: "Satair",
    logos: {
      horizontal: "satair_horizontal-ColwKW33RuhUEEvUtTfd8QjoW8vwNI.png"
    }
  },
  {
    companyName: "Scale AI",
    logos: {
      horizontal: "scale_horizontal-jxn5nE2sPRwSLSP5iyDFe8O20WtleK.png"
    }
  },
  {
    companyName: "Scribd",
    logos: {
      square: "scribd_square-6rfgen1TLwAiQCMp30i6YHro1B2L8W.png"
    }
  },
  {
    companyName: "Second Dinner",
    logos: {
      square: "second_dinner_square-e1ogU5poxtjv11TxWr4ZHGbfqSSiq6.png"
    }
  },
  {
    companyName: "Seedify",
    logos: {
      vertical: "seedify_vertical-CIQXTMf1MV9cuUh82JY0chn98r4psU.png"
    }
  },
  {
    companyName: "Semtech",
    logos: {
      horizontal: "Semtech_horizontal-Ikh9kbsS5uYSQhEHrQJAezdLmeSR4F.png"
    }
  },
  {
    companyName: "Settle",
    logos: {
      square: "settle_square-5Oj5uqdLNxp0uO38yWjcafov7ivgWZ.png"
    }
  },
  {
    companyName: "Shield AI",
    logos: {
      square: "Shield%20AI_square-PCLnXkksKtp8WrxY8OmNngFxXdMSAa.png"
    }
  },
  {
    companyName: "ShopMonkey",
    logos: {
      square: "shopmonkey_square-t2tkRH4UIhHSYae35YnASlgL38lxYB.png"
    }
  },
  {
    companyName: "Shyftlabs",
    logos: {
      square: "shyftlabs_square-mYcSoMRCzPFYHIqxuOwornjhXPUkzf.png"
    }
  },
  {
    companyName: "Siemens Gamesa",
    logos: {
      square: "Siemens%20Gamesa_square-8dV6vlBNhVr3uUpSlO4u8wF6T1gTMg.png"
    }
  },
  {
    companyName: "Sierra Club",
    logos: {
      square: "Sierra%20Club_square-aciOt3lud6NdmuMWF5kZPtuXyuPxbx.png"
    }
  },
  {
    companyName: "Signal",
    logos: {
      square: "signal_square-QHlzZzvef1IhPTMrW3nB32ojgwkxtA.png"
    }
  },
  {
    companyName: "Simpro",
    logos: {
      square: "simpro_square-KkWL7FHRqGfOiNQlvaofr7ErLFNHob.png"
    }
  },
  {
    companyName: "SKIMS",
    logos: {
      horizontal: "skims_horizontal-3z0R2ZfWVmvoGb8thBZXDFOzEEWvF5.png"
    }
  },
  {
    companyName: "Slack",
    logos: {
      square: "slack_square-aayBh61duPcwGpdbYOzrXFb3o7s4EF.png"
    }
  },
  {
    companyName: "Sleeper",
    logos: {
      square: "sleeper_square-7FVKMhf9elSYUPITflynwUOdGnltNr.png"
    }
  },
  {
    companyName: "Smartsheet",
    logos: {
      square: "smartsheet_square-78VuEh4Z1ynqIByKutFSdoJKk1jRDt.png"
    }
  },
  {
    companyName: "Snap",
    logos: {
      square: "snap_square-kW622antmc8tBkmUriJ4WrEgK1yrBV.png"
    }
  },
  {
    companyName: "Snorkel AI",
    logos: {
      square: "snorkel_square-cIWYYQIfgCoTi0DIpLYvtJYftHLWJ8.png"
    }
  },
  {
    companyName: "Snowflake",
    logos: {
      square: "snowflake_square-ErlzZVORGxLvNZV5GIfeArZI1tHXNV.png"
    }
  },
  {
    companyName: "Snyk",
    logos: {
      vertical: "snyk_vertical-PAJbmmk5YuvyP0JvkNH69BN2YbWdFc.png"
    }
  },
  {
    companyName: "SoFi",
    logos: {
      square: "sofi_square-NBeGM4yhMqm6RIwWNJfd7tVyD5zBJh.png"
    }
  },
  {
    companyName: "Solace Health",
    logos: {
      horizontal: "solace_horizontal-RZWgccXXdYQ1y4uVwRWctNP3X83JqR.png"
    }
  },
  {
    companyName: "Solink",
    logos: {
      square: "solink_square-FaDMWJCd7xG4mZJXJOjVUIQ9APLfxf.png"
    }
  },
  {
    companyName: "Solventum",
    logos: {
      square: "solventum_square-SRIDkP3UHZcPiMAXKwbFcIFrxmvIYA.png"
    }
  },
  {
    companyName: "Sonatus",
    logos: {
      square: "sonatus_square-bi3Q4Tk0UlgkVAJpXUdpQeHMoVJYhy.png"
    }
  },
  {
    companyName: "Sony",
    logos: {
      horizontal: "sony_horizontal-aGv1ebXxjL4fskVzNArYVjjFEAPjX9.png"
    }
  },
  {
    companyName: "SoundHound AI",
    logos: {
      square: "soundhoundai_square-e5Mq7VtX62iTAqtVQiOE8j97SveDlN.png"
    }
  },
  {
    companyName: "Southwest Airlines",
    logos: {
      square: "southwest_square-xeAWglnvFjJXMMy7mwL5eEH5YSVAix.png"
    }
  },
  {
    companyName: "SpaceX",
    logos: {
      horizontal: "spacex_horizontal-iMgZBBzrp4yKDDIMgsyRwXC4xQ0BZ1.png"
    }
  },
  {
    companyName: "Speechify",
    logos: {
      horizontal: "speechify_horizontal-LnNocvA10UEMU4SlGftnRudKeFHdi5.png"
    }
  },
  {
    companyName: "Sphera",
    logos: {
      square: "Sphera_square-6nQq9gmvJJDk8o36kerKSJUG4GYfR2.png"
    }
  },
  {
    companyName: "Speak",
    logos: {
      square: "speak_square-FS3pa9dq5UywfaK8u4hvfsoDI0wbIw.png"
    }
  },
  {
    companyName: "Splunk",
    logos: {
      horizontal: "splunk_horizontal-EyEeZSwrLvCXnb8K0NFasZmXzKJWr0.png"
    }
  },
  {
    companyName: "Sporty",
    logos: {
      square: "sporty_square-xqbbHsX9IKbKusPRJZ9q2lu9iyZ3Xs.png"
    }
  },
  {
    companyName: "Spotify",
    logos: {
      square: "spotify_square-Upt44Z2Ml7KY890hp1GmT22fP7rLd7.png"
    }
  },
  {
    companyName: "SpotOn",
    logos: {
      square: "spoton_square-iCw928QmxHrGGFK4d6qRXO1SSSA9Hs.png"
    }
  },
  {
    companyName: "Squire",
    logos: {
      horizontal: "squire_horizontal-cSmkw05BpGZwcBNtfrLURMqgFwYiYp.png"
    }
  },
  {
    companyName: "StackAdapt",
    logos: {
      square: "stackadapt_square-ostQTh8zV0KYfz55IDBgaaxD32LBNE.png"
    }
  },
  {
    companyName: "Stanley Black & Decker",
    logos: {
      square: "stanley_square-kgwSX1BiiQNFkI1Px0SBTBWuFkuwgx.png"
    }
  },
  {
    companyName: "Stars",
    logos: {
      square: "stars_square-Feoa6MXcNZz5aOY7rT3owMzpvk92FF.png"
    }
  },
  {
    companyName: "State Farm",
    logos: {
      horizontal: "statefarm_horizontal-yz8OglI7P5PYM2d7iPTvB5ALkw5dqg.png"
    }
  },
  {
    companyName: "Statista",
    logos: {
      square: "statista_square-7oxvm5GNMjrBOjKT8pDLvwpssOStI4.png"
    }
  },
  {
    companyName: "Stensul",
    logos: {
      square: "stensul_square-KXUqksn7eEzUgmzW1bHz8Afd0N9Y5r.png"
    }
  },
  {
    companyName: "Story Protocol",
    logos: {
      square: "storyprotocol_square-m4t2EKQeHjTCm47CaLfdLOY7ai3U2d.png"
    }
  },
  {
    companyName: "Strava",
    logos: {
      square: "strava_square-wlAHbus8ltJsowltKs9bXCszZU5BSq.png"
    }
  },
  {
    companyName: "Stripe",
    logos: {
      horizontal: "stripe_horizontal-8BtGs7BLWJdy84GKLSl5BjI84Gzc5x.png"
    }
  },
  {
    companyName: "Stryker",
    logos: {
      horizontal: "stryker_horizontal-nWLLrK3SJ6BqxyFyxsnqk37xrCWKW5.png"
    }
  },
  {
    companyName: "StubHub",
    logos: {
      horizontal: "stubhub_horizontal-tLbW2LgT0kk5KTTiJcZuON0by6KdMC.png"
    }
  },
  {
    companyName: "Substack",
    logos: {
      square: "substack_square-74VHN5xM0n2MwairWZxpRXK8sl78LB.png"
    }
  },
  {
    companyName: "Sumo Logic",
    logos: {
      square: "sumo_square-ZcOCmbCwmcUTrdAgsrwIpvitfIXFta.png"
    }
  },
  {
    companyName: "Suno",
    logos: {
      horizontal: "suno_horizontal-Oxs094UQaVKfiXbvfRCy9TR4Hj2MMn.png"
    }
  },
  {
    companyName: "Super.com",
    logos: {
      square: "super_square-WZ1gpPMoP5T9A5jSzDrrd42YgNEJC0.png"
    }
  },
  {
    companyName: "Sure",
    logos: {
      square: "sure_square-yD9dkudqZKq2JLYyLiKPESd0I4SeSy.png"
    }
  },
  {
    companyName: "Sword Health",
    logos: {
      vertical: "sword_vertical-fHeWweAflkyI9t3AwWmUShuSh3061R.png"
    }
  },
  {
    companyName: "Tableau",
    logos: {
      square: "tableau_square-uBSYZMV9zQTBFzPTvfFlIwXuUENMML.png"
    }
  },
  {
    companyName: "Tailscale",
    logos: {
      square: "tailscale_square-Ab7bIny1tZYkR3y2peB3p6kgdDEyLT.png"
    }
  },
  {
    companyName: "Target",
    logos: {
      square: "target_square-UlebA6NHGFPcyvlhqFigSytYqyNYXT.png"
    }
  },
  {
    companyName: "Taskrabbit",
    logos: {
      horizontal: "taskrabbit_horizontal-AxfxErcmrTyn1wZ2qQsi8JNpuPPjiD.png"
    }
  },
  {
    companyName: "TCS",
    logos: {
      horizontal: "tcs_horizontal-QfGxjzeYIG5cXPt108606AcNwbYHen.png"
    }
  },
  {
    companyName: "TE",
    logos: {
      square: "te_square-Rqk9aw6jxLOUd0f4fnbE1RAKVNgWnD.png"
    }
  },
  {
    companyName: "Tendo",
    logos: {
      square: "tendo_square-DBeNilk9zsFQDL6YHtPa1cMaiLgHEM.png"
    }
  },
  {
    companyName: "Tesla",
    logos: {
      square: "tesla_square-NisoY7jAZ9iRJ4uQr5ZfeorOr1JItz.png"
    }
  },
  {
    companyName: "Terradot",
    logos: {
      square: "terradot_square-JBJoZ66CTK73UVzn6li5KWwBjvoP2G.png"
    }
  },
  {
    companyName: "The Block",
    logos: {
      square: "The%20Block_square-PFXuCAwRsuH9QfCTQxwPyS442EUdkL.png"
    }
  },
  {
    companyName: "The Browser Company",
    logos: {
      square: "browser_company_square-WMzxAD7Hl5QqIWwAYHbumL8IKmrdvC.png"
    }
  },
  {
    companyName: "The Hartford",
    logos: {
      square: "hartford_square-T1uZ6gfORRSW3GJnxOS6XkpbavX3tR.png"
    }
  },
  {
    companyName: "The Rounds",
    logos: {
      horizontal: "therounds_horizontal-adtUuGxAOQNMM9Ugx70k44iqKA21Yf.png"
    }
  },
  {
    companyName: "The Trevor Project",
    logos: {
      square: "thetrevorproject_square-ZUrZA5qeqzmPzdODngsjfq8ZP01bPn.png"
    }
  },
  {
    companyName: "Therapy Brands",
    logos: {
      horizontal: "therapy_brands_horizontal-91v2xa4oYhlejTpm57qZeSzDpESmO0.png"
    }
  },
  {
    companyName: "ThousandEyes",
    logos: {
      horizontal: "thousandeyes_horizontal-JWA6qwF9KroGdG15aNuAa9UKNh8jxN.png"
    }
  },
  {
    companyName: "Thermo Fisher",
    logos: {
      square: "thermo_fisher_square-uXweDqHnzkSlxhos8OAy5Ne198UpCG.png"
    }
  },
  {
    companyName: "Thrive Causemetics",
    logos: {
      horizontal: "thrivecausemetics_horizontal-unDV6MvGFjsYjlmhEk8FK4QLf03GUH.png"
    }
  },
  {
    companyName: "Thumbtack",
    logos: {
      square: "thumbtack_square-zgJ1AmpmZmyL6c2h6OyP5FWrQkvcfB.png"
    }
  },
  {
    companyName: "Toyota",
    logos: {
      horizontal: "toyota_horizontal-Q5iwZdWb3sEv1646YhvnSWgguVW3DU.png"
    }
  },
  {
    companyName: "Travel + Leisure",
    logos: {
      horizontal: "t%2Bl_horizontal-PCASJyqmVzrDWiHWJgOShpx0YJqHXK.png"
    }
  },
  {
    companyName: "Trend Micro",
    logos: {
      square: "Trend%20Micro_square-e5ZI8WtwMdRbIbdEhugcNwM9O8eIRx.png"
    }
  },
  {
    companyName: "Tremendous",
    logos: {
      square: "tremendous_square-yezsMouTusm9ALL6F0LnoNsPe5W5sO.png"
    }
  },
  {
    companyName: "Trevor Project",
    logos: {
      horizontal: "trevor_horizontal-VsOTLZWUMZqda8JYMS83O524V1sKZW.png"
    }
  },
  {
    companyName: "Trimble",
    logos: {
      square: "Trimble_square-Cip49racf5uhPBCJk4bMk7ziW2oFoJ.png"
    }
  },
  {
    companyName: "TriNet",
    logos: {
      horizontal: "trinet_horizontal-1crVZbxb294dNC5vzZOydr4FdQy80V.png"
    }
  },
  {
    companyName: "TripActions",
    logos: {
      horizontal: "tripactions_horizontal-AGzCbke6z2Cj4pQbCACnmG41n4eSXd.png"
    }
  },
  {
    companyName: "TrueML",
    logos: {
      square: "trueml_square-OIAcD0hPpAJwJaxOkF8rSgTvCxFkR9.png"
    }
  },
  {
    companyName: "Trustly",
    logos: {
      square: "trustly_square-8NHCHuNTMDKgCpswmiZDQuSg6ylfjJ.png"
    }
  },
  {
    companyName: "Tubi",
    logos: {
      horizontal: "tubi_horizontal-ubOp6EDru76pNJzgIl3deMTM4ad716.png"
    }
  },
  {
    companyName: "Twilio",
    logos: {
      square: "twilio_square-dXeg7Yg9EBGY12tc38gyCMLXlHcDir.png"
    }
  },
  {
    companyName: "Twitch",
    logos: {
      square: "twitch_square-YYDycPztTVbdeLmjbIM4AK0pTcUfFc.png"
    }
  },
  {
    companyName: "Tyson Foods",
    logos: {
      horizontal: "tyson_foods_horizontal-z50kENlFnUl0JBz8taXJ67Z9sn1RGd.png"
    }
  },
  {
    companyName: "Uber",
    logos: {
      horizontal: "uber_horizontal-6IuNDrJaZYfXbefM1z4Qks5W47er2B.png"
    }
  },
  {
    companyName: "UMiami",
    logos: {
      horizontal: "umiami_horizontal-ORNSSkeya5uZRBOvGNZwIRNVqCF67l.png"
    }
  },
  {
    companyName: "Uniphore",
    logos: {
      horizontal: "Uniphore_horizontal-CS5hpZArICdij68HspxFtCyakq5Aar.png"
    }
  },
  {
    companyName: "Unisys",
    logos: {
      square: "Unisys_square-xc7M6PGojaXjQWzYnitKNO31Dji60Q.png"
    }
  },
  {
    companyName: "Unlock Health",
    logos: {
      horizontal: "unlockhealth_horizontal-saQu8LCM8ivuKiXOAHyOqcmvlGk95g.png"
    }
  },
  {
    companyName: "UpGuard",
    logos: {
      square: "UpGuard_square-fynHF8RrIDIyZVCKIOnBaJcXYKuRUV.png"
    }
  },
  {
    companyName: "Upflow",
    logos: {
      horizontal: "upflow_horizontal-DEBX9ch9b9SBCRCOSqRRN3ZofdxDvW.png"
    }
  },
  {
    companyName: "UPS",
    logos: {
      square: "UPS_square-W1TAziMJHcth7vVUsZtGFdffch1WGZ.png"
    }
  },
  {
    companyName: "US Mobile",
    logos: {
      horizontal: "USmobile_horizontal-xCewQ64VpW34FsqmIRDP5A710sEOdH.png"
    }
  },
  {
    companyName: "Vail",
    logos: {
      horizontal: "Vail_horizontal-Ng6Wg7Jfx3DqfNUtCOuAeju0kYWdrX.png"
    }
  },
  {
    companyName: "Vanta",
    logos: {
      square: "vanta_square-OHKqhugCsgi9jhA29a8V8oCfaMbCIY.png"
    }
  },
  {
    companyName: "Varo",
    logos: {
      square: "Varo_square-873CLAsoA8SEvzDycyGkOjq4P38Rpn.png"
    }
  },
  {
    companyName: "Veeva",
    logos: {
      square: "veeva_square-Xy8DR1H5hiXgUHAGhXwT1h9uxFw9VB.png"
    }
  },
  {
    companyName: "Verizon",
    logos: {
      square: "verizon_square-SwlwCKbCUDbqMlhjyQIXi5wrfr28Og.png"
    }
  },
  {
    companyName: "Verkada",
    logos: {
      square: "verkada_square-QXDNosGz7BwnhyESIyYwOw71g6CQvO.png"
    }
  },
  {
    companyName: "Vertex",
    logos: {
      horizontal: "vertex_horizontal-Wanpvt7iM2yqpMcDRytVmj5LLiqX9n.png"
    }
  },
  {
    companyName: "Vida",
    logos: {
      square: "vida_square-4PgnMAKeB9tZyhvRGh9ijT8kGroYH3.png"
    }
  },
  {
    companyName: "Vimeo",
    logos: {
      square: "vimeo_square-W2Aj53stsSzdq0RFR35zrHjC5h9Pn4.png"
    }
  },
  {
    companyName: "Virtuous",
    logos: {
      square: "virtuous_square-xH13PMeAWr0tE1ZQ2fjQKKBTcwxMK3.png"
    }
  },
  {
    companyName: "Visa",
    logos: {
      horizontal: "visa_horizontal-8olbEhojWBK05DL9FHu3stQkM8kkAm.png"
    }
  },
  {
    companyName: "Voleon",
    logos: {
      horizontal: "Voleon_horizontal-dNNdxPIvYUMxfCQ0rHprIbgVWmMsia.png"
    }
  },
  {
    companyName: "Vumc",
    logos: {
      square: "vumc_square-WhrWEyXkDZQ9QCyJGCKrAhxgrdOcwF.png"
    }
  },
  {
    companyName: "Waabi",
    logos: {
      horizontal: "waabi_horizontal-ohRaE6yAcLOe7jILb1pSGbMts6PCJy.png"
    }
  },
  {
    companyName: "Wahed",
    logos: {
      horizontal: "wahed_horizontal-w2pVL4CYGa3EcPiFw2JEzNLgwZmOYl.png"
    }
  },
  {
    companyName: "Walmart",
    logos: {
      square: "walmart_square-uNNOh2BqSdnJvFCc929qpZUvl01S76.png"
    }
  },
  {
    companyName: "Wander",
    logos: {
      square: "wander_square-ooyT75JnlJs5plXwi5hXWJkkWLpMU7.png"
    }
  },
  {
    companyName: "Warby Parker",
    logos: {
      square: "warby_parker_square-bkJwbIUwfX0KnNH362cqgwJH3hsYaL.png"
    }
  },
  {
    companyName: "Warner Bros",
    logos: {
      square: "warnerbros_square-cNRNX6KgIYVngcFcQITQiFot7awfoD.png"
    }
  },
  {
    companyName: "Wasabi",
    logos: {
      square: "wasabi_square-qLl0W5TC1UG8eGwDUIlg97bnEVasui.png"
    }
  },
  {
    companyName: "Washington Post",
    logos: {
      square: "washington_post_square-FimfdQAssqPQpUTCIJByWnrVmNaeay.png"
    }
  },
  {
    companyName: "Waymo",
    logos: {
      square: "waymo_square-On9FriYFQ4NOZQxpuRHShOtIR5HVOA.png"
    }
  },
  {
    companyName: "Wealth.com",
    logos: {
      square: "wealth_square-FSugdejelQE35tdbbivnyw4PIG4l1O.png"
    }
  },
  {
    companyName: "Wealthfront",
    logos: {
      square: "wealthfront_square-uMnRa29PgmRBIVUF9DuCWTX0h7YdE5.png"
    }
  },
  {
    companyName: "Wealthsimple",
    logos: {
      square: "wealthsimple_square-XmqTlaacccZYLjmAVfAaEl6uI9wP3o.png"
    }
  },
  {
    companyName: "Webflow",
    logos: {
      square: "webflow_square-B3rKh0ypVZJhAPXVANK2p1dYKyh452.png"
    }
  },
  {
    companyName: "Weee!",
    logos: {
      horizontal: "weee!_horizontal-dprJpuuzO5ChXdTfHn3BMgC0w9OE3Q.png"
    }
  },
  {
    companyName: "Welocalize",
    logos: {
      square: "welocalize_square-IAyxBJTmH92aeRNHCAAJIihQHceNRO.png"
    }
  },
  {
    companyName: "Wells Fargo",
    logos: {
      square: "Wells%20Fargo_square-kgZRFpLpkL2t4jkYa1fe5CVOA4ItLh.png"
    }
  },
  {
    companyName: "WHOOP",
    logos: {
      horizontal: "whoop_horizontal-nePVarGllNWfERbXgePuCkf8u4uHA6.png"
    }
  },
  {
    companyName: "Whole Foods",
    logos: {
      square: "wholefoods_square-n5ozjrZlpdC2fq2TGTiEMsG5qkVwnX.png"
    }
  },
  {
    companyName: "Windfall",
    logos: {
      square: "windfall_square-Jvf82AQS6WtRgOBluJSNFoV9mA9sB8.png"
    }
  },
  {
    companyName: "Wipro",
    logos: {
      square: "wipro_square-zHqqixUD4qfLoqshDBOOwtpgaA2WOL.png"
    }
  },
  {
    companyName: "WireScreen",
    logos: {
      square: "wirescreen_square-3MTAoL7PHerZw8JHueTTsGA0M47RTh.png"
    }
  },
  {
    companyName: "Wonder",
    logos: {
      horizontal: "wonder_horizontal-2q7wF6zbUSncKlHCYlsHAR3aDXBvri.png"
    }
  },
  {
    companyName: "Workday",
    logos: {
      horizontal: "workday_horizontal-zs4ZDJi4380ECXTsJtc80WXx9tPFRR.png"
    }
  },
  {
    companyName: "World Labs",
    logos: {
      square: "world_labs_square-gx5uJxtkWDYBG9DtyL8ncLjLyHthKM.png"
    }
  },
  {
    companyName: "World Vision",
    logos: {
      square: "World%20Vision_square-hXlMAZlZ7VVUUBAxXHt3k4recFEdJ9.png"
    }
  },
  {
    companyName: "Writer",
    logos: {
      horizontal: "writer_horizontal-5tv7MVVpS0ur5gtqImg7S93AGES9ni.png"
    }
  },
  {
    companyName: "Wurl",
    logos: {
      horizontal: "wurl_horizontal-P8DL2CxH5hy6uBj1dYmilA0wLTaSfy.png"
    }
  },
  {
    companyName: "X",
    logos: {
      square: "x_square-D2ekMpmaL4dNk9I4vwAPpNZaiQUvUs.png"
    }
  },
  {
    companyName: "xAI",
    logos: {
      square: "xai_square-3ALA0NookyXras8BU3AccfHNQQG2QP.png"
    }
  },
  {
    companyName: "Xometry",
    logos: {
      square: "xometry_square-rXiFtkE6eqF0m0DmQsB2cUpuKplNT5.png"
    }
  },
  {
    companyName: "Xsolla",
    logos: {
      square: "xsolla_square-TJfAKhf2WqUB0jrgAyCHbzapKXUvFC.png"
    }
  },
  {
    companyName: "Yahoo",
    logos: {
      horizontal: "yahoo_horizontal-FgsXK3P85KlH1mF6kaRVTNxz9xaWUn.png"
    }
  },
  {
    companyName: "Zebra",
    logos: {
      square: "zebra_square-9E8PQxtOarcTV3myn2NcxfUxdGDX5e.png"
    }
  },
  {
    companyName: "Zendesk",
    logos: {
      square: "zendesk_square-T8nvNFE4wBtxntnLJ33FFUtdbRQiXc.png"
    }
  },
  {
    companyName: "Zen Educate",
    logos: {
      horizontal: "zeneducate_horizontal-VWCvhpUtp026jd7jD9ZZCJONVs9tyo.png"
    }
  },
  {
    companyName: "Zip",
    logos: {
      horizontal: "zip_horizontal-6M82r7Jb055JhuTPu1uRat9oI22YUZ.png"
    }
  },
  {
    companyName: "Zoox",
    logos: {
      horizontal: "zoox_horizontal-OOzm8Rs0BL8AY6klAAe3JCB1COjmbI.png"
    }
  }
];

export type { LogoVariant, CompanyLogoMapping };
