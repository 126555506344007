import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import '../styles/components/jobTile.scss';
import { useApplicationModal } from '../contexts/ApplicationModalContext';
import { JobButtonContext, getJobButtonText } from '../constant';
import { blobStorageService } from '../services/blobStorage.service';

interface JobTileProps {
  job: any;
  userId: string;
  handleSelection: (data: any) => void;
  context?: JobButtonContext;
  isApplied?: boolean;
  onApplicationUpdate?: () => void;
}

const JobTile: React.FC<JobTileProps> = ({ job, userId, handleSelection, context = JobButtonContext.MAIN, isApplied = false, onApplicationUpdate }) => {
  const { _id, title, company, location, salary, days_to_live } = job;
  
  // Initialize job type variable
  let jobTypeValue = '';
  
  // Method 1: direct extraction from standard fields
  if (job.jobType || job.job_type) {
    jobTypeValue = job.jobType || job.job_type || '';
    console.log('Method 1 - Direct field:', jobTypeValue);
  } 
  // Method 2: try to extract from raw data
  else if (job.raw && typeof job.raw === 'string') {
    // First try to find job_type field directly
    const jobTypeRegex = /"job_type"\s*:\s*"([^"]+)"/;
    const match = job.raw.match(jobTypeRegex);
    if (match && match[1]) {
      jobTypeValue = match[1];
      console.log('Method 2A - job_type field match:', jobTypeValue);
    } 
    // Next look for Internship, Contract, etc. explicitly mentioned in the raw text
    else if (job.raw.includes('Internship')) {
      jobTypeValue = 'Internship';
      console.log('Method 2B - Found "Internship" in raw data');
    } else if (job.raw.includes('Part-time') || job.raw.includes('Part Time')) {
      jobTypeValue = 'Part-time';
      console.log('Method 2B - Found "Part-time" in raw data');
    } else if (job.raw.includes('Contract')) {
      jobTypeValue = 'Contract';
      console.log('Method 2B - Found "Contract" in raw data');
    } else if (job.raw.includes('Full-time') || job.raw.includes('Full Time')) {
      jobTypeValue = 'Full-time';
      console.log('Method 2B - Found "Full-time" in raw data');
    }
  }

  // Method 3: Try to detect from job title and check for demo jobs
  if (!jobTypeValue) {
    const lowerTitle = (job.title || '').toLowerCase();
    
    // Check for demo jobs first
    if (job.title && job.title.includes('DEMO')) {
      jobTypeValue = 'DEMO';
      console.log('Method 3A - Demo job detected:', jobTypeValue);
    }
    // Check for internship in title
    else if (lowerTitle.includes('intern')) {
      jobTypeValue = 'Internship';
      console.log('Method 3B - Title-based detection:', jobTypeValue);
    }
  }

  // Method 4: Last resort fallback
  if (!jobTypeValue) {
    jobTypeValue = 'FULL-TIME'; // Default fallback
    console.log('Method 4 - Default fallback:', jobTypeValue);
  }
  
  console.log(`Final jobTypeValue for job "${title}":`, jobTypeValue);
  
  const { setModalData } = useApplicationModal();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);

  // Add helper function to get first location and additional count
  const getLocationInfo = (location: string): { firstLocation: string; additionalCount: number } => {
    if (!location) return { firstLocation: '', additionalCount: 0 };
    const locations = location.split(';').map(loc => loc.trim()).filter(Boolean);
    return {
      firstLocation: locations[0] || '',
      additionalCount: Math.max(0, locations.length - 1)
    };
  };

  useEffect(() => {
    const loadLogo = async () => {
      if (company) {
        if (job._preserveLogoState && job.logo) {
          setLogoUrl(job.logo);
          setLogoVariant('square');
        } else {
          const logo = await blobStorageService.getBestLogoVariant(company);
          if (logo) {
            setLogoUrl(logo.url);
            setLogoVariant(logo.variant);
          }
        }
      }
    };
    loadLogo();
  }, [company, job]);

  // Helper function to normalize job type strings
  const normalizeJobType = (jobType: string): string => {
    if (!jobType) return '';
    
    // Remove both 'Job Type:' prefix and any surrounding quotes
    const cleanedType = jobType
      .replace(/^Job Type:\s*/i, '')
      .replace(/^['"]|['"]$/g, '') // Remove surrounding quotes
      .toLowerCase()
      .replace(/[-_]/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();

    return cleanedType;
  };

  // Function to map job types to corresponding class names
  const getJobTypeClass = (jobType: string): string => {
    if (!jobType) return 'full-time'; // Default class
    
    // Handle DEMO jobs
    if (jobType === 'DEMO') return 'demo';
    
    const normalizedType = normalizeJobType(jobType);
    
    // Check for internship variations first
    if (/intern(ship)?/.test(normalizedType)) {
      return 'internship';
    }
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'full-time';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'part-time';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'contract';
      default:
        return normalizedType.replace(/\s+/g, '-');
    }
  };

  const formatJobType = (jobType: string): string => {
    if (!jobType) return 'FULL-TIME'; // Default display text
    
    // Handle DEMO jobs
    if (jobType === 'DEMO') return 'DEMO';
    
    const normalizedType = normalizeJobType(jobType);
    
    // Check for internship variations first
    if (/intern(ship)?/.test(normalizedType)) {
      return 'INTERNSHIP';
    }
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'FULL-TIME';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'PART-TIME';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'CONTRACT';
      default:
        // If we can't map it to a standard format, return the original in uppercase
        return jobType.toUpperCase().replace(/[-_]/g, ' ');
    }
  };

  // Get CSS class and formatted text for job type
  const jobTypeClass = getJobTypeClass(jobTypeValue);
  const formattedJobType = formatJobType(jobTypeValue);
  
  console.log('Job type display values:', { 
    jobTypeValue, 
    jobTypeClass, 
    formattedJobType 
  });

  const getStatusClass = (daysToLive: any): string => {
    const days = daysToLive?.$numberInt 
      ? parseInt(daysToLive.$numberInt) 
      : typeof daysToLive === 'number' 
        ? daysToLive 
        : 0;

    if (days >= 10) return 'recent';
    if (days >= 5) return 'moderate';
    return 'old';
  };

  return (
    <div 
      className="job-tile" 
      onClick={() => {
        // Log job URL before selection
        console.log(`Job tile clicked: ${job.title} (${job._id})`);
        console.log(`job_url before passing to modal:`, job.job_url);
        
        // Create a clean job object with explicitly copied job_url
        const jobWithUrl = {
          ...job,
          job_url: job.job_url // Explicitly copy the job_url
        };
        console.log("Passing job with URL:", jobWithUrl.job_url);
        
        if (context !== JobButtonContext.DEMO) handleSelection(jobWithUrl);
      }}
      style={{ cursor: context === JobButtonContext.DEMO ? 'default' : 'pointer' }}
    >
      <div className={`status-dot ${getStatusClass(days_to_live)}`} />

      {/* Always show job type tag */}
      <div className={`job-type-tag ${jobTypeClass}`}>{formattedJobType}</div>

      <div className="job-info">
        <div className={`company-logo-container ${logoVariant === 'horizontal' ? 'horizontal-logo-container' : ''}`}>
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={company || 'Company'}
              className={`company-logo ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
            />
          ) : (
            <div className="logo-placeholder" />
          )}
        </div>

        <h3 className="job-title" title={title}>
          {title}
        </h3>
        <p className="company-location">
          {company} • {getLocationInfo(location).firstLocation}
          {getLocationInfo(location).additionalCount > 0 && (
            <span className="additional-locations">+{getLocationInfo(location).additionalCount} more</span>
          )}
        </p>

        <div className="salary-placeholder" />

        <div className="job-actions">
          <button 
            onClick={(e) => {
              e.stopPropagation();
              // Log job URL before selection from apply button
              console.log(`Apply button clicked: ${job.title} (${job._id})`);
              console.log(`job_url from apply button:`, job.job_url);
              
              handleSelection(job);
            }} 
            className={`apply-button ${isApplied ? 'applied' : ''} ${context === JobButtonContext.DEMO ? 'demo-button' : ''}`}
            title={context === JobButtonContext.DEMO ? 'Start searching for jobs' : isApplied ? 'Click to view application details' : 'Apply for this job'}
          >
            {isApplied ? 'Applied' : getJobButtonText(context)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobTile;
