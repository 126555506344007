import React, { useState, useEffect, useRef, DragEvent } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../styles/components/accountInfo.scss";
import axiosClient from "../axios-client";
import axios, { AxiosError } from "axios";
import { mainUrl } from "../constant";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { MdDragIndicator } from "react-icons/md";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Sidebar from "./Sidebar";
import moment from "moment";
import "../styles/global.scss";
import "@radix-ui/themes/styles.css";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
  DialogFooter,
} from "../components/ui/Dialog";

import { Input } from "../components/ui/Input";
import { Label } from "../components/ui/Label";
import { Bounce, Slide, Zoom, Flip, toast, ToastOptions } from "react-toastify";
import { Button } from "./ui/Button";

/** --------------------------------------------------------------------
 * Interfaces
 * -------------------------------------------------------------------- */
interface ExperienceItem {
  title: string;
  company: string;
  location: string;
  startDate: string;      // e.g. "Jan 2020"
  endDate: string | null; // e.g. "Jun 2021" or null if Present
  isCurrentPosition: boolean;
  description: Array<{ id: string; text: string }>;
}

interface ProjectItem {
  title: string;          // optional "Project Lead", or some role
  projectName: string;    // "Cool Project"
  startDate: string;      // "Jun 2021"
  endDate: string | null; // "Sep 2021" or null if ongoing
  isOngoing: boolean;     // similar to "Current Position"
  description: Array<{ id: string; text: string }>;
}

interface CustomDropdownProps {
  value: string;
  onChange: (value: string) => void;
  options: string[];
  label: string;
}

interface EducationItem {
  degree: string;
  field: string;
  university: string;
  graduationDate: string;  // e.g. "Jun 2023"
  gpa: string;
}

/** --------------------------------------------------------------------
 * parseMonthYear & compareMonthYear
 * -------------------------------------------------------------------- */
function parseMonthYear(dateStr: string): { year: number; monthIndex: number } | null {
  if (!dateStr || dateStr === "Present") return null;
  
  const months = [
    "Jan","Feb","Mar","Apr","May","Jun",
    "Jul","Aug","Sep","Oct","Nov","Dec"
  ];
  
  const [monthStr, yearStr] = dateStr.split(" ");
  const monthIndex = months.indexOf(monthStr);
  const year = parseInt(yearStr, 10);

  if (monthIndex < 0 || isNaN(year)) return null;
  return { year, monthIndex };
}

// compare two { year, monthIndex } => -1 if a < b, 0 if equal, 1 if a > b
function compareMonthYear(a: { year: number; monthIndex: number }, b: { year: number; monthIndex: number }) {
  if (a.year < b.year) return -1;
  if (a.year > b.year) return 1;
  if (a.monthIndex < b.monthIndex) return -1;
  if (a.monthIndex > b.monthIndex) return 1;
  return 0;
}

/** --------------------------------------------------------------------
 * CustomDropdown
 * -------------------------------------------------------------------- */
const CustomDropdown: React.FC<CustomDropdownProps> = ({
  value,
  onChange,
  options,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="custom-dropdown-field" ref={dropdownRef}>
      <label>{label}</label>
      <div className="custom-dropdown">
        <button
          type="button"
          className={`dropdown-trigger ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {value}
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            {options.map((option) => (
              <div
                key={option}
                className={`dropdown-item ${value === option ? "selected" : ""}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

/** --------------------------------------------------------------------
 * Utility: Phone & URL Format
 * -------------------------------------------------------------------- */
const formatPhoneNumber = (value: string) => {
  const number = value.replace(/[^\d]/g, "");
  if (number.length <= 3) return number;
  else if (number.length <= 6) {
    return `(${number.slice(0, 3)}) ${number.slice(3)}`;
  } else {
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
  }
};

const formatUrl = (url: string, type: "website" | "linkedin") => {
  if (!url) return "";
  
  if (type === "website") {
    // Handle GitHub URL formatting
    const githubRegex = /(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/\s]+)/;
    const match = url.match(githubRegex);
    
    if (match) {
      // If it's already a GitHub URL, extract username and format
      return `https://github.com/${match[1]}`;
    } else if (!url.includes('/')) {
      // If it's just a username (no slashes)
      return `https://github.com/${url.trim()}`;
    }
    // For any other URL format
    let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, "");
    return `https://${cleanUrl}`;
  }
  
  // LinkedIn URL handling remains the same
  let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, "");
  return type === "linkedin"
    ? cleanUrl.startsWith("linkedin.com/in/")
      ? `https://${cleanUrl}`
      : `https://linkedin.com/${cleanUrl}`
    : `https://${cleanUrl}`;
};

/** --------------------------------------------------------------------
 * Fully Controlled Date Picker
 * - Allows year changes before choosing a month
 * -------------------------------------------------------------------- */
interface CustomDatePickerProps {
  value: string; // e.g., "January 2023" or "" or "Present"
  onChange: (newDateStr: string) => void;
  placeholder?: string;
}
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const months = [
    "Jan","Feb","Mar","Apr","May","Jun",
    "Jul","Aug","Sep","Oct","Nov","Dec",
  ];

  // We'll keep local states for the selected year & month
  const [localYear, setLocalYear] = useState(() => {
    if (!value || value === "Present") return new Date().getFullYear();
    const [m, y] = value.split(" ");
    const parsedYear = parseInt(y, 10);
    return isNaN(parsedYear) ? new Date().getFullYear() : parsedYear;
  });
  const [localMonthIndex, setLocalMonthIndex] = useState<number | null>(() => {
    if (!value || value === "Present") return null;
    const [m, y] = value.split(" ");
    const idx = months.indexOf(m);
    return idx === -1 ? null : idx;
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // If value changes externally, sync local states
  useEffect(() => {
    if (!value || value === "Present") {
      setLocalYear(new Date().getFullYear());
      setLocalMonthIndex(null);
    } else {
      const [m, y] = value.split(" ");
      const parsedYear = parseInt(y, 10);
      const idx = months.indexOf(m);
      if (!isNaN(parsedYear)) setLocalYear(parsedYear);
      if (idx !== -1) setLocalMonthIndex(idx);
    }
  }, [value]);

  const handleSelect = (monthIndex: number, year: number) => {
    const newDateStr = `${months[monthIndex]} ${year}`;
    onChange(newDateStr);
    setIsOpen(false);
  };

  const handleYearChange = (increment: number) => {
    const newY = localYear + increment;
    const currentYear = new Date().getFullYear();
    if (newY <= currentYear && newY >= currentYear - 34) {
      setLocalYear(newY);
    }
  };

  return (
    <div className="custom-date-picker" ref={dropdownRef}>
      <input
        type="text"
        className="date-input"
        value={!value || value === "" ? "" : value}
        placeholder={placeholder}
        onClick={() => setIsOpen(!isOpen)}
        readOnly
      />
      {isOpen && (
        <div className="calendar-dropdown">
          <div className="calendar-header">
            <button
              className="year-nav prev-fast"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleYearChange(-5);
              }}
              disabled={localYear <= new Date().getFullYear() - 30}
              title="Back 5 years"
            >
              ‹‹
            </button>
            <button
              className="year-nav prev"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleYearChange(-1);
              }}
              disabled={localYear <= new Date().getFullYear() - 34}
              title="Previous year"
            >
              ‹
            </button>
            <div className="year-display">{localYear}</div>
            <button
              className="year-nav next"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleYearChange(1);
              }}
              disabled={localYear >= new Date().getFullYear()}
              title="Next year"
            >
              ›
            </button>
            <button
              className="year-nav next-fast"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleYearChange(5);
              }}
              disabled={localYear >= new Date().getFullYear() - 4}
              title="Forward 5 years"
            >
              ››
            </button>
          </div>
          <div className="month-grid">
            {months.map((month, index) => {
              return (
                <div
                  key={month}
                  className={`month-item ${
                    localMonthIndex === index ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(index, localYear);
                  }}
                >
                  {month.substring(0, 3)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

/** --------------------------------------------------------------------
 * Main AccountInfo
 * -------------------------------------------------------------------- */
const AccountInfo: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [github, setGithub] = useState("");
  const [location, setLocation] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [education, setEducation] = useState<EducationItem[]>([
    { degree: "", field: "", university: "", graduationDate: "", gpa: "" },
  ]);
  const [experience, setExperience] = useState<ExperienceItem[]>([]);
  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [interests, setInterests] = useState<string[]>([]);
  const [profileImage, setProfileImage] = useState("");
  const [error, setError] = useState<string | null>(null);
  // Used to control our custom "Edit Profile" modal
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // For "invalid date" errors
  const [experienceErrors, setExperienceErrors] = useState<{ [idx: number]: string }>({});
  const [projectsErrors, setProjectsErrors] = useState<{ [idx: number]: string }>({});

  const navigate = useNavigate();

  // "Basic info" modal states
  const [tempFirstName, setTempFirstName] = useState("");
  const [tempLastName, setTempLastName] = useState("");
  const [tempExperienceLevel, setTempExperienceLevel] = useState("");
  const [tempLocation, setTempLocation] = useState("");
  const [tempLinkedin, setTempLinkedin] = useState("");
  const [tempGithub, setTempGithub] = useState("");
  const [resumeEmail, setResumeEmail] = useState("");
  const [twitter, setTwitter] = useState("");

  // Education / Experience / Projects being edited
  const [tempEducation, setTempEducation] = useState(education);
  const [tempExperience, setTempExperience] = useState<ExperienceItem[]>([]);
  const [tempProjects, setTempProjects] = useState<ProjectItem[]>([]);
  const [editingEducationIndex, setEditingEducationIndex] = useState<number | null>(null);
  const [editingExperienceIndex, setEditingExperienceIndex] = useState<number | null>(null);
  const [editingProjectIndex, setEditingProjectIndex] = useState<number | null>(null);

  // Cursor
  const [isClosing, setIsClosing] = useState(false);
  const [username, setUsername] = useState("");

  // Add state for drag source
  const [dragSource, setDragSource] = useState<{ type: "skill" | "interest" | null; index: number | null }>({
    type: null,
    index: null
  });

  // Add state for upload status
  const [uploadStatus, setUploadStatus] = useState<{
    isUploading: boolean;
    statusMessage: string;
    statusType: 'info' | 'success' | 'error' | 'loading';
  } | null>(null);

  /** --------------------------------------------------------------------
   * Resume Click Handler
   * -------------------------------------------------------------------- */
  const handleResumeClick = async () => {
    try {
      // Format the resume data
      const resumeData = {
        firstName,
        lastName,
        email: resumeEmail || email,
        phone,
        linkedin,
        website: github,
        location,
        citizenship,
        education,
        experience: experience.map(exp => ({
          ...exp,
          duration: `${exp.startDate} - ${exp.isCurrentPosition ? "Present" : exp.endDate}`
        })),
        projects: projects.map(proj => ({
          ...proj,
          duration: `${proj.startDate} - ${proj.isOngoing ? "Present" : proj.endDate}`
        })),
        skills,
        interests
      };

      console.log('🚀 Sending resume data:', JSON.stringify(resumeData, null, 2));

      const response = await axiosClient.post('/pdf/generate-pdf', resumeData, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 60000 // 60 second timeout
      });

      console.log('✅ Server response:', response.data);

      if (response.data && response.data.downloadUrl) {
        window.open(`${mainUrl}${response.data.downloadUrl}`, '_blank');
      } else {
        throw new Error('Download URL not received from server');
      }
    } catch (error) {
      const err = error as AxiosError;
      console.error('❌ Error Details:', {
        error: err,
        isAxiosError: axios.isAxiosError(err),
        response: err.response?.data,
        status: err.response?.status,
        headers: err.response?.headers,
        config: {
          url: err.config?.url,
          method: err.config?.method,
          headers: err.config?.headers
        }
      });
      
      if (axios.isAxiosError(err) && err.response) {
        toast.error(`Error: ${(err.response.data as { message?: string })?.message || err.response.statusText || "Unknown error"}`);
      } else {
        toast.error("An error occurred while generating the PDF. Please try again.");
      }
    }
  };

  /** --------------------------------------------------------------------
   * 1) Fetch user data
   * -------------------------------------------------------------------- */
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user) {
        navigate("/auth/login");
        return;
      }
      try {
        const response = await axiosClient.get(`users/${user._id}`);
        const data = response.data;
        if (data) {
          // Parse Experience
          const updatedExperience: ExperienceItem[] = data.experience.map((exp: any) => ({
            ...exp,
            description: exp.description.map((desc: any) =>
              typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
            ),
          }));

          // Parse Projects
          const updatedProjects: ProjectItem[] = data.projects.map((proj: any) => ({
            ...proj,
            startDate: proj.startDate || "",
            endDate: proj.endDate || null,
            isOngoing: proj.isOngoing || false,
            description: proj.description.map((desc: any) =>
              typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
            ),
          }));

          // Parse Education
          const updatedEducation: EducationItem[] = data.education.map((edu: any) => ({
            ...edu,
            graduationDate: edu.graduationDate || "",
          }));

          setFirstName(data.firstName || "");
          setLastName(data.lastName || "");
          setEmail(data.email || "");
          setPhone(data.phone || "");
          setLinkedin(data.linkedin || "");
          setGithub(data.github || data.website || "");
          setLocation(data.location || "");
          setCitizenship(data.citizenship || "");
          setExperienceLevel(data.experienceLevel || "Entry Level");
          setEducation(updatedEducation || []);
          setExperience(updatedExperience || []);
          setProjects(updatedProjects || []);
          setSkills(data.skills || []);
          setInterests(data.interests || []);
          setProfileImage(data.profileImage || "");
          setUsername(data.username || "");
          setResumeEmail(data.resume_email || data.email || "");
          setTwitter(data.twitter || "");

          // Temp states
          setTempFirstName(data.firstName || "");
          setTempLastName(data.lastName || "");
          setTempExperienceLevel(data.experienceLevel || "Entry Level");
          setTempLocation(data.location || "");
          setTempLinkedin(data.linkedin || "");
          setTempGithub(data.github || data.website || "");
          setTempEducation(JSON.parse(JSON.stringify(data.education)));
          setTempExperience(JSON.parse(JSON.stringify(updatedExperience)));
          setTempProjects(JSON.parse(JSON.stringify(updatedProjects)));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data.");
        setTimeout(() => setError(""), 10000);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  /** --------------------------------------------------------------------
   * 2) Reorder Helpers (Descending by most recent)
   * -------------------------------------------------------------------- */

  // For Experience:
  // 1) If current, put it at top
  // 2) Otherwise compare end dates
  // 3) Fallback => compare start date
  const reorderExperience = (expArray: ExperienceItem[]) => {
    return [...expArray].sort((a, b) => {
      // If A is current and B is not, A goes first
      if (a.isCurrentPosition && !b.isCurrentPosition) return -1;
      // If B is current and A is not, B goes first
      if (!a.isCurrentPosition && b.isCurrentPosition) return 1;

      // Both are not current => compare end dates
      const aEnd = parseMonthYear(a.endDate || "");
      const bEnd = parseMonthYear(b.endDate || "");
      if (aEnd && bEnd) {
        const endCompare = compareMonthYear(bEnd, aEnd);
        if (endCompare !== 0) return endCompare;
      } else if (aEnd && !bEnd) {
        // A has an end date, B doesn't (treat missing date as older)
        return -1;
      } else if (!aEnd && bEnd) {
        return 1;
      }

      // Fallback => compare start dates
      const parsedAStart = parseMonthYear(a.startDate || "");
      const parsedBStart = parseMonthYear(b.startDate || "");
      if (!parsedAStart || !parsedBStart) return 0;
      return compareMonthYear(parsedBStart, parsedAStart);
    });
  };

  // For Projects:
  // 1) If A is ongoing, put it first
  // 2) Compare end date
  // 3) Compare start date
  const reorderProjects = (projArray: ProjectItem[]) => {
    return [...projArray].sort((a, b) => {
      if (a.isOngoing && !b.isOngoing) return -1;
      if (!a.isOngoing && b.isOngoing) return 1;

      const parsedAEnd = a.isOngoing ? null : parseMonthYear(a.endDate || "");
      const parsedBEnd = b.isOngoing ? null : parseMonthYear(b.endDate || "");

      if (parsedAEnd && parsedBEnd) {
        const c = compareMonthYear(parsedBEnd, parsedAEnd);
        if (c !== 0) return c;
      } else if (parsedAEnd && !parsedBEnd) {
        return -1;
      } else if (!parsedAEnd && parsedBEnd) {
        return 1;
      }

      // fallback => compare start
      const parsedAStart = parseMonthYear(a.startDate || "");
      const parsedBStart = parseMonthYear(b.startDate || "");
      if (parsedAStart && parsedBStart) {
        return compareMonthYear(parsedBStart, parsedAStart);
      }
      return 0;
    });
  };

  // For Education:
  // Already sorting by newest graduation date first
  const reorderEducation = (eduArray: EducationItem[]) => {
    return [...eduArray].sort((a, b) => {
      const parsedA = parseMonthYear(a.graduationDate);
      const parsedB = parseMonthYear(b.graduationDate);
      if (parsedA && parsedB) return compareMonthYear(parsedB, parsedA);
      return 0;
    });
  };

  /** --------------------------------------------------------------------
   * 3) handleUpdate => Patch user data
   * -------------------------------------------------------------------- */
  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user) return;

      // reorder
      const finalEducation = reorderEducation(education);
      const finalExperience = reorderExperience(experience);
      const finalProjects = reorderProjects(projects);

      await axiosClient.patch(`users/${user._id}`, {
        firstName,
        lastName,
        email,
        phone,
        linkedin,
        github,
        location,
        citizenship,
        education: finalEducation,
        experience: finalExperience,
        projects: finalProjects,
        skills,
        interests,
        profileImage,
      });
      setEducation(finalEducation);
      setExperience(finalExperience);
      setProjects(finalProjects);

      toast.success("Profile updated successfully", toastConfig);
    } catch (error: any) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile.");
      setTimeout(() => setError(""), 10000);
    }
  };

  /** --------------------------------------------------------------------
   * 4) Skills & Interests
   * -------------------------------------------------------------------- */
  const handleSkillsUpdate = async (newSkills: string[]) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user || !user._id) {
        throw new Error("User information not found");
      }
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      // Filter out empty skills before making the API call
      const filteredSkills = newSkills.filter(skill => skill.trim() !== "");
      
      const response = await axios({
        method: "patch",
        url: `${mainUrl}users/${user._id}/skills`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { skills: filteredSkills },
      });

      if (response?.data) {
        setSkills(filteredSkills);
        toast.success("Skills updated successfully", toastConfig);
      } else {
        throw new Error("Failed to update skills");
      }
    } catch (error: any) {
      console.error("Error updating skills:", error);
      toast.error("Failed to update skills. Please try again.", toastConfig);
      // Revert to previous state on error
      setSkills([...skills]);
    }
  };

  const handleInterestsUpdate = async (newInterests: string[]) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user || !user._id) {
        throw new Error("User information not found");
      }
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      // Filter out empty interests before making the API call
      const filteredInterests = newInterests.filter(interest => interest.trim() !== "");
      
      const response = await axios({
        method: "patch",
        url: `${mainUrl}users/${user._id}/interests`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { interests: filteredInterests },
      });

      if (response?.data) {
        setInterests(filteredInterests);
        toast.success("Interests updated successfully", toastConfig);
      } else {
        throw new Error("Failed to update interests");
      }
    } catch (error: any) {
      console.error("Error updating interests:", error);
      toast.error("Failed to update interests. Please try again.", toastConfig);
      // Revert to previous state on error
      setInterests([...interests]);
    }
  };

  const addSkill = () => {
    const newSkills = [...skills, ""];
    setSkills(newSkills);
    // Focus on the new input after a brief delay to ensure the element is rendered
    setTimeout(() => {
      const inputs = document.querySelectorAll('.skill-badge .skill-input');
      const lastInput = inputs[inputs.length - 1] as HTMLInputElement;
      if (lastInput) {
        lastInput.focus();
      }
    }, 50);
  };

  const removeSkill = (index: number) => {
    // If it's a new tile (hasn't been saved to database yet)
    if (!skills[index] || (editedSkills[index] && !skills[index].trim())) {
      // Just remove from UI without making database call
      const newSkills = skills.filter((_, i) => i !== index);
      setSkills(newSkills);
      return;
    }

    // For existing tiles or tiles that were previously saved
    const newSkills = skills.filter((_, i) => i !== index);
    setSkills(newSkills);
    handleSkillsUpdate(newSkills);
  };

  const addInterest = () => {
    const newInterests = [...interests, ""];
    setInterests(newInterests);
    // Focus on the new input after a brief delay to ensure the element is rendered
    setTimeout(() => {
      const inputs = document.querySelectorAll('.skill-badge .skill-input');
      const lastInput = inputs[inputs.length - 1] as HTMLInputElement;
      if (lastInput) {
        lastInput.focus();
      }
    }, 50);
  };

  const removeInterest = (index: number) => {
    // If it's a new tile (hasn't been saved to database yet)
    if (!interests[index] || (editedInterests[index] && !interests[index].trim())) {
      // Just remove from UI without making database call
      const newInterests = interests.filter((_, i) => i !== index);
      setInterests(newInterests);
      return;
    }

    // For existing tiles or tiles that were previously saved
    const newInterests = interests.filter((_, i) => i !== index);
    setInterests(newInterests);
    handleInterestsUpdate(newInterests);
  };

  /** --------------------------------------------------------------------
   * 5) handleImageChange
   * -------------------------------------------------------------------- */
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  /** --------------------------------------------------------------------
   * 6) Education CRUD
   * -------------------------------------------------------------------- */
  const addEducation = () => {
    const newEducation = {
      degree: "",
      field: "",
      university: "",
      graduationDate: "",
      gpa: "",
    };
    setEducation([...education, newEducation]);
    setTempEducation([...education, newEducation]);
    setEditingEducationIndex(education.length);
  };

  const removeEducation = async (index: number) => {
    try {
      const newEd = education.filter((_, i) => i !== index);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user) return;
      await axiosClient.patch(`users/${user._id}`, { education: newEd });
      setEducation(newEd);
      setTempEducation(JSON.parse(JSON.stringify(newEd)));
      setEditingEducationIndex(null);
    } catch (error) {
      console.error("Error removing education:", error);
    }
  };

  /** --------------------------------------------------------------------
   * 7) Experience CRUD
   * -------------------------------------------------------------------- */
  const addExperience = () => {
    const newExperience: ExperienceItem = {
      title: "",
      company: "",
      location: "",
      startDate: "",
      endDate: null,
      isCurrentPosition: false,
      description: [{ id: uuidv4(), text: "" }],
    };
    setExperience([...experience, newExperience]);
    setTempExperience([...experience, newExperience]);
    setEditingExperienceIndex(experience.length);
  };

  const removeExperience = async (index: number) => {
    try {
      const newExp = experience.filter((_, i) => i !== index);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user) return;
      await axiosClient.patch(`users/${user._id}`, { experience: newExp });
      setExperience(newExp);
      setTempExperience(JSON.parse(JSON.stringify(newExp)));
      setEditingExperienceIndex(null);
    } catch (error) {
      console.error("Error removing experience:", error);
    }
  };

  const addExperienceDescription = (expIndex: number) => {
    const newExp = [...experience];
    newExp[expIndex].description.push({ id: uuidv4(), text: "" });
    setExperience(newExp);
  };

  const removeExperienceDescription = (expIndex: number, descId: string) => {
    const newExp = [...experience];
    newExp[expIndex].description = newExp[expIndex].description.filter(
      (desc) => desc.id !== descId
    );
    setExperience(newExp);
  };

  /** --------------------------------------------------------------------
   * 8) Projects CRUD
   * -------------------------------------------------------------------- */
  const addProject = () => {
    const newProj: ProjectItem = {
      title: "",
      projectName: "",
      startDate: "",
      endDate: null,
      isOngoing: false,
      description: [{ id: uuidv4(), text: "" }],
    };
    setProjects([...projects, newProj]);
    setTempProjects([...projects, newProj]);
    setEditingProjectIndex(projects.length);
  };

  const removeProject = async (index: number) => {
    try {
      const newProjs = projects.filter((_, i) => i !== index);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user) return;
      await axiosClient.patch(`/users/${user._id}/projects`, { projects: newProjs });
      setProjects(newProjs);
      setTempProjects(JSON.parse(JSON.stringify(newProjs)));
      setEditingProjectIndex(null);
    } catch (error) {
      console.error("Error removing project:", error);
    }
  };

  const addProjectDescription = (index: number) => {
    const newProjs = [...projects];
    newProjs[index].description.push({ id: uuidv4(), text: "" });
    setProjects(newProjs);
  };

  const removeProjectDescription = (projIndex: number, descId: string) => {
    const newProjs = [...projects];
    newProjs[projIndex].description = newProjs[projIndex].description.filter(
      (desc) => desc.id !== descId
    );
    setProjects(newProjs);
  };

  /** --------------------------------------------------------------------
   * 9) "Edit" modal logic for basic profile info
   * -------------------------------------------------------------------- */
  const handleEditClick = () => {
    setTempFirstName(firstName);
    setTempLastName(lastName);
    setTempExperienceLevel(experienceLevel);
    setTempLocation(location);
    setTempLinkedin(linkedin);
    setTempGithub(github);
    setIsDialogOpen(true);
    setIsClosing(false);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsDialogOpen(false);
      setIsClosing(false);
      document.body.style.overflow = "unset";
    }, 300);
  };

  const handleSaveProfile = async () => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user) return;
      const response = await axiosClient.patch(`/users/${user._id}/basic-info`, {
        firstName: tempFirstName,
        lastName: tempLastName,
        linkedin: formatUrl(tempLinkedin, "linkedin"),
        github: formatUrl(tempGithub, "website"),
        location: tempLocation,
        experienceLevel: tempExperienceLevel,
        resume_email: resumeEmail,
        twitter: twitter,
        phone,
        citizenship,
      });
      if (response?.data) {
        setFirstName(tempFirstName);
        setLastName(tempLastName);
        setLinkedin(formatUrl(tempLinkedin, "linkedin"));
        setGithub(formatUrl(tempGithub, "website"));
        setLocation(tempLocation);
        setExperienceLevel(tempExperienceLevel);
        handleCloseModal();
        toast.success("Changes saved", toastConfig);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /** --------------------------------------------------------------------
   * 10) Cancel Editing a Section
   * -------------------------------------------------------------------- */
  const handleCancelEdit = (section: string) => {
    switch (section) {
      case "education": {
        if (
          editingEducationIndex === education.length - 1 &&
          tempEducation[editingEducationIndex]?.degree === ""
        ) {
          const newEd = education.slice(0, -1);
          setEducation(newEd);
          setTempEducation(JSON.parse(JSON.stringify(newEd)));
        } else {
          setTempEducation(JSON.parse(JSON.stringify(education)));
        }
        setEditingEducationIndex(null);
        break;
      }
      case "experience": {
        if (
          editingExperienceIndex === experience.length - 1 &&
          tempExperience[editingExperienceIndex]?.title === ""
        ) {
          const newExp = experience.slice(0, -1);
          setExperience(newExp);
          setTempExperience(JSON.parse(JSON.stringify(newExp)));
        } else {
          setTempExperience(JSON.parse(JSON.stringify(experience)));
        }
        setEditingExperienceIndex(null);
        break;
      }
      case "projects": {
        if (
          editingProjectIndex === projects.length - 1 &&
          tempProjects[editingProjectIndex]?.projectName === ""
        ) {
          const newProjs = projects.slice(0, -1);
          setProjects(newProjs);
          setTempProjects(JSON.parse(JSON.stringify(newProjs)));
        } else {
          setTempProjects(JSON.parse(JSON.stringify(projects)));
        }
        setEditingProjectIndex(null);
        break;
      }
      case "profile":
        handleCloseModal();
        break;
      default:
        break;
    }
  };

  /** --------------------------------------------------------------------
   * 11) Save logic for Education / Experience / Projects
   *     - If there's a date error, disable Save
   * -------------------------------------------------------------------- */
  const toastConfig: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
    style: {
      background: "#ffffff",
      color: "#000000",
      fontSize: "15px",
      fontWeight: 450,
      borderRadius: "4px",
      padding: "8px 16px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      border: "none",
      width: "auto",
      minWidth: "240px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    className: "custom-toast",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 
           10C20 4.47715 15.5228 0 10 0C4.47715 0 0 
           4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.6329 
           8.41457C15.1223 7.92513 15.1223 7.13161 
           14.6329 6.64217C14.1434 6.15273 13.3499 
           6.15273 12.8604 6.64217L8.75 10.7526L7.13961 
           9.14217C6.65017 8.65273 5.85665 8.65273 5.36721 
           9.14217C4.87777 9.63161 4.87777 10.4251 5.36721 
           10.9146L7.86721 13.4146C8.35665 13.904 9.15017 
           13.904 9.63961 13.4146L14.6329 8.41457Z"
          fill="#057642"
        />
      </svg>
    ),
    closeButton: ({ closeToast }) => (
      <button
        onClick={(e) => {
          closeToast?.(e);
          const toastElement = document.querySelector(".Toastify__toast");
          if (toastElement) {
            toastElement.remove();
          }
        }}
        className="toast-close-button"
        style={{
          background: "transparent",
          border: "none",
          padding: "4px",
          cursor: "pointer",
          marginLeft: "auto",
          opacity: 0.6,
          transition: "opacity 0.2s ease",
          display: "flex",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 3L3 13M3 3L13 13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </button>
    ),
  };

  const handleSaveEducation = async (index: number) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user || !user._id) throw new Error("User information not found");

      // reorder before saving
      const finalEd = reorderEducation(tempEducation);

      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "patch",
        url: `${mainUrl}users/${user._id}/education`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { education: finalEd },
      });
      if (response?.data) {
        setEducation(finalEd);
        setEditingEducationIndex(null);
        toast.success("Changes saved", toastConfig);
      }
    } catch (error: any) {
      console.error("Error updating education:", error);
    }
  };

  const handleSaveExperience = async (index: number) => {
    // If there's an error at this index, don't let them save
    if (experienceErrors[index]) {
      return;
    }

    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user || !user._id) throw new Error("User information not found");

      // reorder
      const finalExp = reorderExperience(tempExperience);

      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "patch",
        url: `${mainUrl}users/${user._id}/experience`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { experience: finalExp },
      });
      if (response?.data) {
        setExperience(finalExp);
        setEditingExperienceIndex(null);
        toast.success("Changes saved", toastConfig);
      }
    } catch (error: any) {
      console.error("Error updating experience:", error);
    }
  };

  const handleSaveProjects = async (index: number) => {
    if (projectsErrors[index]) {
      return;
    }
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (!user || !user._id) throw new Error("User information not found");

      // reorder
      const finalProjs = reorderProjects(tempProjects);

      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "patch",
        url: `${mainUrl}users/${user._id}/projects`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { projects: finalProjs },
      });
      if (response?.data) {
        setProjects(finalProjs);
        setEditingProjectIndex(null);
        toast.success("Changes saved", toastConfig);
      }
    } catch (error: any) {
      console.error("Error updating projects:", error);
    }
  };

  /** --------------------------------------------------------------------
   * 12) toggleEdit => set editing index, deep clone
   * -------------------------------------------------------------------- */
  const toggleEdit = (section: string, index: number) => {
    switch (section) {
      case "education": {
        if (editingEducationIndex === index) {
          handleCancelEdit(section);
        } else {
          setEditingEducationIndex(index);
          setTempEducation(JSON.parse(JSON.stringify(education)));
        }
        break;
      }
      case "experience": {
        if (editingExperienceIndex === index) {
          handleCancelEdit(section);
        } else {
          setEditingExperienceIndex(index);
          setTempExperience(JSON.parse(JSON.stringify(experience)));
        }
        break;
      }
      case "projects": {
        if (editingProjectIndex === index) {
          handleCancelEdit(section);
        } else {
          setEditingProjectIndex(index);
          setTempProjects(JSON.parse(JSON.stringify(projects)));
        }
        break;
      }
      default:
        break;
    }
  };

  const handleSave = (section: string, index: number) => {
    if (section === "experience") {
      handleSaveExperience(index);
    } else if (section === "education") {
      handleSaveEducation(index);
    } else if (section === "projects") {
      handleSaveProjects(index);
    }
  };

  /** --------------------------------------------------------------------
   * handleShare
   * -------------------------------------------------------------------- */
  const handleShare = () => {
    if (username) {
      const portfolioUrl = `${window.location.origin}/portfolio/${username}`;
      window.open(portfolioUrl, "_blank", "noopener,noreferrer");
    } else {
      toast("Username not found", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  /** --------------------------------------------------------------------
   * Drag & drop for rearranging descriptions
   * -------------------------------------------------------------------- */
  const handleDragStart = (
    e: DragEvent<HTMLDivElement>,
    section: string,
    itemIndex: number,
    descIndex: number
  ) => {
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        section,
        itemIndex,
        descIndex,
      })
    );
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (
    e: DragEvent<HTMLDivElement>,
    section: string,
    itemIndex: number,
    descIndex: number
  ) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (data.section !== section || data.itemIndex !== itemIndex) return;

    if (section === "experience") {
      const newExp = [...tempExperience];
      const descriptions = newExp[itemIndex].description;
      const [movedDesc] = descriptions.splice(data.descIndex, 1);
      descriptions.splice(descIndex, 0, movedDesc);
      setTempExperience(newExp);
    } else if (section === "projects") {
      const newProjs = [...tempProjects];
      const descriptions = newProjs[itemIndex].description;
      const [movedDesc] = descriptions.splice(data.descIndex, 1);
      descriptions.splice(descIndex, 0, movedDesc);
      setTempProjects(newProjs);
    }
  };

  /** --------------------------------------------------------------------
   * Avatars, LinkedIn/Github paste, phone
   * -------------------------------------------------------------------- */
  const generatePastelColor = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = hash % 360;
    return `hsla(${h}, 70%, 80%, 0.2)`;
  };

  const getInitials = () => {
    const firstInitial = firstName ? firstName[0] : "";
    const lastInitial = lastName ? lastName[0] : "";
    return (firstInitial + lastInitial).toUpperCase();
  };

  const handleLinkedinPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    setTempLinkedin(formatUrl(pastedText, "linkedin"));
  };
  const handleGithubPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const githubRegex = /(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/\s]+)/;
    const match = pastedText.match(githubRegex);
    if (match) {
      // If it's a GitHub URL, extract just the username
      setTempGithub(`https://github.com/${match[1]}`);
    } else {
      // If it's just a username or other text
      setTempGithub(`https://github.com/${pastedText.trim()}`);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(formatPhoneNumber(e.target.value));
  };
  const handlePhonePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPhone(formatPhoneNumber(e.clipboardData.getData("text")));
  };

  /** --------------------------------------------------------------------
   * Skills/Interests in-line editing
   * -------------------------------------------------------------------- */
  const [editedSkills, setEditedSkills] = useState<{ [key: number]: boolean }>({});
  const [editedInterests, setEditedInterests] = useState<{ [key: number]: boolean }>({});
  const [forceUnhoverSkills, setForceUnhoverSkills] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [forceUnhoverInterests, setForceUnhoverInterests] = useState<{
    [key: number]: boolean;
  }>({});

  const handleSkillInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newSkills = [...skills];
    newSkills[index] = e.target.value;
    setSkills(newSkills);
    e.target.style.width = `${e.target.value.length + 1}ch`;
    setEditedSkills({ ...editedSkills, [index]: true });
  };

  const handleSkillInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const input = e.target as HTMLInputElement;
      const newSkills = [...skills];
      if (newSkills[index].trim() === "") {
        removeSkill(index);
      } else {
        handleSkillsUpdate(newSkills);
        setEditedSkills({ ...editedSkills, [index]: false });
        setForceUnhoverSkills({ ...forceUnhoverSkills, [index]: true });
        input.blur();
      }
    }
  };

  const handleSkillInputBlur = (index: number) => {
    const newSkills = [...skills];
    if (newSkills[index].trim() === "") {
      removeSkill(index);
    } else if (editedSkills[index]) {
      handleSkillsUpdate(newSkills);
      setEditedSkills({ ...editedSkills, [index]: false });
    }
  };

  const handleInterestInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInterests = [...interests];
    newInterests[index] = e.target.value;
    setInterests(newInterests);
    e.target.style.width = `${e.target.value.length + 1}ch`;
    setEditedInterests({ ...editedInterests, [index]: true });
  };

  const handleInterestInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const input = e.target as HTMLInputElement;
      const newInterests = [...interests];
      if (newInterests[index].trim() === "") {
        removeInterest(index);
      } else {
        handleInterestsUpdate(newInterests);
        setEditedInterests({ ...editedInterests, [index]: false });
        setForceUnhoverInterests({ ...forceUnhoverInterests, [index]: true });
        input.blur();
      }
    }
  };

  const handleInterestInputBlur = (index: number) => {
    const newInterests = [...interests];
    if (newInterests[index].trim() === "") {
      removeInterest(index);
    } else if (editedInterests[index]) {
      handleInterestsUpdate(newInterests);
      setEditedInterests({ ...editedInterests, [index]: false });
    }
  };

  const handleSkillMouseLeave = (index: number) => {
    if (forceUnhoverSkills[index]) {
      setForceUnhoverSkills({ ...forceUnhoverSkills, [index]: false });
    }
  };

  const handleInterestMouseLeave = (index: number) => {
    if (forceUnhoverInterests[index]) {
      setForceUnhoverInterests({ ...forceUnhoverInterests, [index]: false });
    }
  };

  // Add new drag handlers for skills and interests
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const [dragOverType, setDragOverType] = useState<"skill" | "interest" | null>(null);

  const handleSkillDragStart = (e: DragEvent<HTMLSpanElement>, index: number) => {
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "skill",
        index,
      })
    );
    const target = e.target as HTMLElement;
    target.classList.add("dragging");
    setDragSource({ type: "skill", index });
    setDragOverType("skill");
  };

  const handleInterestDragStart = (e: DragEvent<HTMLSpanElement>, index: number) => {
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "interest",
        index,
      })
    );
    const target = e.target as HTMLElement;
    target.classList.add("dragging");
    setDragSource({ type: "interest", index });
    setDragOverType("interest");
  };

  const handleDragEnd = (e: DragEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLElement;
    target.classList.remove("dragging");
    setDragOverIndex(null);
    setDragOverType(null);
    setDragSource({ type: null, index: null });
  };

  const handleDragEnter = (type: "skill" | "interest", index: number) => {
    if (dragSource.type !== type) return;
    if (dragSource.index === index) return;
    setDragOverIndex(index);
  };

  const handleDragLeave = (e: DragEvent<HTMLSpanElement>) => {
    // Only clear if we're actually leaving the container
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (!relatedTarget?.closest('.skills-container, .interests-container')) {
      setDragOverIndex(null);
    }
  };

  const handleSkillDrop = async (e: DragEvent<HTMLSpanElement>, dropIndex: number) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (data.type !== "skill") return;

    const dragIndex = data.index;
    if (dragIndex === dropIndex) return;

    const newSkills = [...skills];
    const [draggedSkill] = newSkills.splice(dragIndex, 1);
    newSkills.splice(dropIndex, 0, draggedSkill);
    setSkills(newSkills);
    handleSkillsUpdate(newSkills);
  };

  const handleInterestDrop = async (e: DragEvent<HTMLSpanElement>, dropIndex: number) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (data.type !== "interest") return;

    const dragIndex = data.index;
    if (dragIndex === dropIndex) return;

    const newInterests = [...interests];
    const [draggedInterest] = newInterests.splice(dragIndex, 1);
    newInterests.splice(dropIndex, 0, draggedInterest);
    setInterests(newInterests);
    handleInterestsUpdate(newInterests);
  };

  /** --------------------------------------------------------------------
   * Render
   * -------------------------------------------------------------------- */
  // Add this function to check if profile is empty
  const isProfileEmpty = () => {
    return (
      tempEducation.length === 0 &&
      tempExperience.length === 0 &&
      tempProjects.length === 0
    );
  };

  // Add file input ref
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Add trigger upload function
  const triggerResumeUpload = () => {
    fileInputRef.current?.click();
  };

  // Function to handle the resume file upload from the input change event
  const handleResumeFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Log file information for debugging
      console.log('Resume file selected:', {
        name: file.name,
        type: file.type,
        size: `${(file.size / 1024).toFixed(2)} KB`
      });
      
      // Call the existing resume upload functionality
      handleResumeUpload(file);
    }
  };

  // Function to handle the actual resume upload and processing
  const handleResumeUpload = async (file: File) => {
    try {
      setLoading(true);
      
      if (!file) {
        setError('Please select a file');
        setUploadStatus({
          isUploading: false,
          statusMessage: 'Please select a file',
          statusType: 'error'
        });
        setLoading(false);
        return;
      }

      // Set initial upload status
      setUploadStatus({
        isUploading: true,
        statusMessage: 'Uploading and processing your resume...',
        statusType: 'loading'
      });

      const formData = new FormData();
      formData.append('resume', file);
      
      console.log('Starting resume upload...');
      // First, upload and parse the resume
      const uploadResponse = await axiosClient.post('/builder/resume-parser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000,
      });
      
      console.log('Resume upload response:', uploadResponse);
      
      // If we have data, immediately save it to the user's profile in the database
      if (uploadResponse.data) {
        // Update status message
        setUploadStatus({
          isUploading: true,
          statusMessage: 'Saving your resume data to your profile...',
          statusType: 'loading'
        });
        
        // Get user id from localStorage
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        
        // Instead of sending the data wrapped in resumeData, send it directly at the top level
        console.log('Sending parsed resume data to backend:', uploadResponse.data);
        
        // Send the data at the top level instead of wrapping it in resumeData
        await axiosClient.patch(`/users/${user._id}`, uploadResponse.data);
        
        // Update status before reload
        setUploadStatus({
          isUploading: true,
          statusMessage: 'Resume uploaded successfully! Refreshing your profile...',
          statusType: 'success'
        });
        
        // Short delay to show success message before reload
        setTimeout(() => {
          // Reload page to fetch fresh data
          window.location.reload();
        }, 1500);
      } else {
        throw new Error('No data received from resume parser');
      }
    } catch (error: any) {
      console.error('Error uploading resume:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
      console.error('Upload error details:', {
        message: errorMessage,
        response: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText,
        rawError: error
      });
      
      // Update status with error
      setUploadStatus({
        isUploading: false,
        statusMessage: `Failed to upload resume: ${errorMessage}`,
        statusType: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-info-container">
      <Sidebar />
      <div className="account-info">
        <div className="profile-header">
          {loading ? (
            <>
              <div className="left-section">
                <div className="profile-info">
                  <div className="profile-picture skeleton-circle"></div>
                  <div className="name-skeleton skeleton"></div>
                </div>
                <div className="profile-details">
                  <div className="skills-section">
                    <div className="skill-skeleton skeleton"></div>
                    <div className="skill-skeleton skeleton"></div>
                    <div className="skill-skeleton skeleton"></div>
                  </div>
                  <div className="experience-location">
                    <div className="info-item">
                      <div className="info-value-skeleton skeleton"></div>
                      <div className="info-label-skeleton skeleton"></div>
                    </div>
                    <div className="divider"></div>
                    <div className="info-item">
                      <div className="info-value-skeleton skeleton"></div>
                      <div className="info-label-skeleton skeleton"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-section">
                <div className="social-icons">
                  <div className="icon-skeleton skeleton"></div>
                  <div className="icon-skeleton skeleton"></div>
                  <div className="icon-skeleton skeleton"></div>
                  <div className="icon-skeleton skeleton"></div>
                </div>
                <div className="button-skeleton skeleton"></div>
                <div className="button-skeleton skeleton"></div>
              </div>
            </>
          ) : (
            <>
              <div className="left-section">
                <div className="profile-info">
                  <div
                    className="profile-picture"
                    style={
                      !profileImage
                        ? {
                            backgroundColor: generatePastelColor(firstName + lastName),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "120px",
                            height: "120px",
                          }
                        : undefined
                    }
                  >
                    {profileImage ? (
                      <img src={profileImage} alt="Profile" />
                    ) : (
                      <span className="initials">{getInitials()}</span>
                    )}
                  </div>
                  <h1>
                    {firstName} {lastName}
                  </h1>
                </div>

                <div className="profile-details">
                  <div className="skills-section">
                    {skills.slice(0, 3).map((skill, index) => (
                      <span key={index} className="skill-badge">
                        {skill}
                      </span>
                    ))}
                  </div>
                  <div className="experience-location">
                    <div className="info-item">
                      <span className="info-value">{experienceLevel}</span>
                      <span className="info-label">Exp. Level</span>
                    </div>
                    <div className="divider"></div>
                    <div className="info-item">
                      <span className="info-value">{location}</span>
                      <span className="info-label">Located</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-section">
                <div className="social-icons">
                  <a href={`mailto:${resumeEmail || email}`} target="_blank" rel="noopener noreferrer">
                    <FaEnvelope />
                  </a>
                  <a href={linkedin} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                  <a href={github} target="_blank" rel="noopener noreferrer">
                    <FaGithub />
                  </a>
                  <a href={`https://twitter.com/${twitter?.replace('@', '')}`} target="_blank" rel="noopener noreferrer">
                    <FaXTwitter />
                  </a>
                </div>
                <div className="share-button" onClick={handleShare}>
                  <FiShare2 />
                  Share Portfolio
                </div>
                <div 
                  className="resume-button"
                  onClick={handleResumeClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                    <polyline points="7 10 12 15 17 10" />
                    <line x1="12" y1="15" x2="12" y2="3" />
                  </svg>
                  Resume
                </div>
              </div>
              <button className="floating-edit-button" onClick={handleEditClick}>
                <AiOutlineEdit />
              </button>
            </>
          )}
        </div>

        {/* Modify the notification banner to show upload status */}
        {(isProfileEmpty() || uploadStatus) && (
          <div className={`profile-empty-notification ${uploadStatus?.statusType === 'error' ? 'error-state' : ''} ${uploadStatus?.statusType === 'success' ? 'success-state' : ''}`}>
            <div className="empty-profile-wrapper">
              <div className="empty-profile-message">
                {uploadStatus ? (
                  <>
                    <strong>
                      {uploadStatus.statusType === 'error' ? 'Error' : 
                       uploadStatus.statusType === 'success' ? 'Success' : 'Processing'}
                    </strong>
                    <p>{uploadStatus.statusMessage}</p>
                  </>
                ) : (
                  <>
                    <strong>Complete Your Profile</strong>
                    <p>Skip the manual work - let us help you build your professional profile in seconds by importing your resume.</p>
                  </>
                )}
              </div>
            </div>
            {(!uploadStatus || !uploadStatus.isUploading || uploadStatus.statusType === 'error') && (
              <button 
                className="quick-import-button" 
                onClick={triggerResumeUpload}
              >
                {uploadStatus?.isUploading ? (
                  <>
                    <svg className="spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4" />
                      <path d="M17 8l-5-5-5 5" />
                      <line x1="12" y1="3" x2="12" y2="15" />
                    </svg>
                    {uploadStatus?.statusType === 'error' ? 'Try Again' : 'Import Resume'}
                  </>
                )}
              </button>
            )}
          </div>
        )}

        {/* Hidden file input for resume upload */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".pdf,.doc,.docx"
          onChange={handleResumeFileUpload}
          id="resume-upload"
        />

        {error && <p className="error-message">{error}</p>}

        <form onSubmit={handleUpdate}>
          {/* EDUCATION SECTION */}
          <div className={`section ${tempEducation.length === 0 ? 'empty-state' : ''}`}>
            <h3 className="section-title">Education</h3>
            {loading ? (
              <div className="loading-placeholder">
                <div className="title-skeleton"></div>
                <div className="content-skeleton"></div>
                <div className="bullet-skeleton"></div>
                <div className="bullet-skeleton"></div>
              </div>
            ) : tempEducation.length === 0 ? (
              <div className="empty-state"></div>
            ) : (
              tempEducation.map((edu, index) => {
                const dateError = experienceErrors[index] || "";
                const canSave = !dateError; // if there's an error => disable Save
                return (
                  <div
                    key={index}
                    className={`education-item ${
                      editingEducationIndex === index ? "editing" : ""
                    }`}
                    onClick={() => {
                      if (editingEducationIndex !== index) {
                        toggleEdit("education", index);
                      }
                    }}
                  >
                    {editingEducationIndex === index ? (
                      <div className="input-field" onClick={(e) => e.stopPropagation()}>
                        <label>Degree</label>
                        <input
                          type="text"
                          value={tempEducation[index].degree}
                          onChange={(e) => {
                            const newEd = [...tempEducation];
                            newEd[index].degree = e.target.value;
                            setTempEducation(newEd);
                          }}
                        />
                        <label>Field of Study</label>
                        <input
                          type="text"
                          value={tempEducation[index].field}
                          onChange={(e) => {
                            const newEd = [...tempEducation];
                            newEd[index].field = e.target.value;
                            setTempEducation(newEd);
                          }}
                        />
                        <label>University</label>
                        <input
                          type="text"
                          value={tempEducation[index].university}
                          onChange={(e) => {
                            const newEd = [...tempEducation];
                            newEd[index].university = e.target.value;
                            setTempEducation(newEd);
                          }}
                        />
                        <label>Graduation Date</label>
                        <CustomDatePicker
                          value={tempEducation[index].graduationDate}
                          onChange={(date) => {
                            const newEd = [...tempEducation];
                            newEd[index].graduationDate = date;
                            setTempEducation(newEd);
                          }}
                          placeholder="Select graduation date"
                        />
                        <label>GPA (Optional)</label>
                        <input
                          type="text"
                          value={tempEducation[index].gpa}
                          onChange={(e) => {
                            const newEd = [...tempEducation];
                            newEd[index].gpa = e.target.value;
                            setTempEducation(newEd);
                          }}
                          className="gpa-input"
                        />
                        <div className="edit-buttons">
                          <button
                            type="button"
                            className="save-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveEducation(index);
                            }}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="cancel-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelEdit("education");
                            }}
                          >
                            Cancel
                          </button>
                          <Dialog>
                            <DialogTrigger onClick={(e) => e.stopPropagation()}>
                              Remove
                            </DialogTrigger>
                            <DialogContent onClick={(e) => e.stopPropagation()}>
                              <DialogHeader>
                                <DialogTitle>Are you absolutely sure?</DialogTitle>
                                <DialogDescription>
                                  This action cannot be undone. Are you sure you
                                  want to permanently delete this education?
                                </DialogDescription>
                              </DialogHeader>
                              <DialogFooter>
                                <Button type="submit" onClick={() => removeEducation(index)}>
                                  Confirm
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    ) : (
                      <div className="info-content">
                        <div className="top-row">
                          <div className="item-title">
                            {edu.degree ? `${edu.degree}, ${edu.field}` : edu.field}
                          </div>
                          <div className="item-duration">{edu.graduationDate}</div>
                        </div>
                        <div className="item-subtitle">
                          {edu.university}
                          {edu.gpa && <span className="gpa"> | GPA: {edu.gpa}</span>}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
            <button type="button" className="section-add-button" onClick={addEducation}>
              Add Education
            </button>
          </div>

          {/* EXPERIENCE SECTION */}
          <div className={`section ${tempExperience.length === 0 ? 'empty-state' : ''}`}>
            <h3 className="section-title">Experience</h3>
            {loading ? (
              <div className="loading-placeholder">
                <div className="title-skeleton"></div>
                <div className="content-skeleton"></div>
                <div className="bullet-skeleton"></div>
                <div className="bullet-skeleton"></div>
              </div>
            ) : tempExperience.length === 0 ? (
              <div className="empty-state"></div>
            ) : (
              tempExperience.map((exp, index) => {
                const dateError = experienceErrors[index] || "";
                const canSave = !dateError; // if there's an error => disable Save
                return (
                  <div
                    key={index}
                    className={`experience-item ${
                      editingExperienceIndex === index ? "editing" : ""
                    }`}
                  >
                    {editingExperienceIndex === index ? (
                      <div className="input-field">
                        <label>Title</label>
                        <input
                          type="text"
                          value={exp.title}
                          onChange={(e) => {
                            const newExp = [...tempExperience];
                            newExp[index].title = e.target.value;
                            setTempExperience(newExp);
                          }}
                        />
                        <label>Company</label>
                        <input
                          type="text"
                          value={exp.company}
                          onChange={(e) => {
                            const newExp = [...tempExperience];
                            newExp[index].company = e.target.value;
                            setTempExperience(newExp);
                          }}
                        />
                        <label>Location</label>
                        <input
                          type="text"
                          value={exp.location}
                          onChange={(e) => {
                            const newExp = [...tempExperience];
                            newExp[index].location = e.target.value;
                            setTempExperience(newExp);
                          }}
                        />
                        <label>Duration</label>
                        <div className="date-range-container">
                          <CustomDatePicker
                            value={exp.startDate}
                            onChange={(newStart) => {
                              const newExp = [...tempExperience];
                              newExp[index].startDate = newStart;
                              // check
                              const parsedStart = parseMonthYear(newStart);
                              const parsedEnd = parseMonthYear(newExp[index].endDate || "");
                              const newErrors = { ...experienceErrors };
                              newErrors[index] = "";

                              if (parsedStart && parsedEnd) {
                                if (compareMonthYear(parsedStart, parsedEnd) === 1) {
                                  newErrors[index] = "Start date cannot be after end date.";
                                }
                              }
                              setExperienceErrors(newErrors);
                              setTempExperience(newExp);
                            }}
                            placeholder="Start Date"
                          />

                          <div className="current-position-checkbox" style={{ cursor: "pointer" }}>
                            <input
                              type="checkbox"
                              checked={exp.isCurrentPosition}
                              onChange={(ev) => {
                                const newExp = [...tempExperience];
                                newExp[index].isCurrentPosition = ev.target.checked;
                                if (ev.target.checked) {
                                  newExp[index].endDate = null;
                                }
                                // clear errors if any
                                const newErrors = { ...experienceErrors };
                                newErrors[index] = "";
                                setExperienceErrors(newErrors);
                                setTempExperience(newExp);
                              }}
                              id={`current-position-${index}`}
                            />
                            <label htmlFor={`current-position-${index}`} style={{ cursor: "pointer" }}>
                              Current Position
                            </label>
                          </div>

                          {!exp.isCurrentPosition && (
                            <CustomDatePicker
                              value={exp.endDate || ""}
                              onChange={(newEnd) => {
                                const newExp = [...tempExperience];
                                newExp[index].endDate = newEnd === "" ? null : newEnd;
                                // check
                                const parsedEnd = parseMonthYear(newEnd);
                                const parsedStart = parseMonthYear(newExp[index].startDate);
                                const newErrors = { ...experienceErrors };
                                newErrors[index] = "";

                                if (parsedEnd && parsedStart) {
                                  if (compareMonthYear(parsedEnd, parsedStart) === -1) {
                                    newErrors[index] = "End date cannot be before start date.";
                                  }
                                }
                                setExperienceErrors(newErrors);
                                setTempExperience(newExp);
                              }}
                              placeholder="End Date"
                            />
                          )}
                        </div>

                        {/* If there's an error, show it */}
                        {dateError && (
                          <div className="date-error-message">
                            {dateError}
                          </div>
                        )}

                        <label>Description</label>
                        {exp.description.map((desc, descIndex) => (
                          <div
                            key={desc.id}
                            className="description-item"
                            draggable
                            onDragStart={(e) =>
                              handleDragStart(e, "experience", index, descIndex)
                            }
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, "experience", index, descIndex)}
                          >
                            <div className="description-controls">
                              <AiOutlinePlus 
                                className="add-icon"
                                onClick={() => {
                                  const newExp = [...tempExperience];
                                  newExp[index].description.splice(descIndex + 1, 0, { id: uuidv4(), text: "" });
                                  setTempExperience(newExp);
                                }}
                              />
                              <MdDragIndicator className="drag-icon" />
                            </div>
                            <textarea
                              value={desc.text}
                              onChange={(e) => {
                                const newExp = [...tempExperience];
                                newExp[index].description[descIndex].text = e.target.value;
                                setTempExperience(newExp);
                              }}
                              rows={2}
                              className="description-input"
                            />
                            <AiOutlineDelete
                              className="delete-icon"
                              onClick={() => {
                                const newExp = [...tempExperience];
                                newExp[index].description = newExp[index].description.filter(d => d.id !== desc.id);
                                setTempExperience(newExp);
                              }}
                            />
                          </div>
                        ))}

                        <div className="edit-buttons">
                          <button
                            type="button"
                            className="save-button"
                            onClick={() => handleSave("experience", index)}
                            disabled={!canSave}
                            style={{ opacity: canSave ? 1 : 0.5, cursor: canSave ? "pointer" : "not-allowed" }}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="cancel-button"
                            onClick={() => handleCancelEdit("experience")}
                          >
                            Cancel
                          </button>
                          <Dialog>
                            <DialogTrigger>Remove</DialogTrigger>
                            <DialogContent>
                              <DialogHeader>
                                <DialogTitle>Are you absolutely sure?</DialogTitle>
                                <DialogDescription>
                                  This action cannot be undone. Are you sure you
                                  want to permanently delete this Project?
                                </DialogDescription>
                              </DialogHeader>
                              <DialogFooter>
                                <Button
                                  type="submit"
                                  onClick={() => removeExperience(index)}
                                >
                                  Confirm
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="info-content"
                        onClick={() => {
                          if (editingExperienceIndex !== index) {
                            toggleEdit("experience", index);
                          }
                        }}
                      >
                        <div className="top-row">
                          <div className="item-title">{exp.title}</div>
                          <div className="item-duration">
                            {exp.startDate} - {exp.isCurrentPosition ? "Present" : exp.endDate}
                          </div>
                        </div>
                        <div className="bottom-row">
                          <div className="item-company">{exp.company}</div>
                          <div className="item-location">{exp.location}</div>
                        </div>
                        <ul className="item-subtitle">
                          {exp.description.map((desc, descIndex) => (
                            <li key={descIndex}>{desc.text}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })
            )}
            <button type="button" className="section-add-button" onClick={addExperience}>
              Add Experience
            </button>
          </div>

          {/* PROJECTS SECTION (with optional start/end & "isOngoing") */}
          <div className={`section ${tempProjects.length === 0 ? 'empty-state' : ''}`}>
            <h3 className="section-title">Projects</h3>
            {loading ? (
              <div className="loading-placeholder">
                <div className="title-skeleton"></div>
                <div className="content-skeleton"></div>
                <div className="bullet-skeleton"></div>
                <div className="bullet-skeleton"></div>
              </div>
            ) : tempProjects.length === 0 ? (
              <div className="empty-state"></div>
            ) : (
              tempProjects.map((proj, index) => {
                const dateError = projectsErrors[index] || "";
                const canSave = !dateError;
                return (
                  <div
                    key={index}
                    className={`project-item ${editingProjectIndex === index ? "editing" : ""}`}
                  >
                    {editingProjectIndex === index ? (
                      <div className="input-field">
                        <label>Title (Optional)</label>
                        <input
                          type="text"
                          value={proj.title}
                          onChange={(e) => {
                            const newProjs = [...tempProjects];
                            newProjs[index].title = e.target.value;
                            setTempProjects(newProjs);
                          }}
                        />
                        <label>Project Name</label>
                        <input
                          type="text"
                          value={proj.projectName}
                          onChange={(e) => {
                            const newProjs = [...tempProjects];
                            newProjs[index].projectName = e.target.value;
                            setTempProjects(newProjs);
                          }}
                        />
                        <label>Duration</label>
                        <div className="date-range-container">
                          <CustomDatePicker
                            value={proj.startDate || ""}
                            onChange={(newStart) => {
                              const newProjs = [...tempProjects];
                              newProjs[index].startDate = newStart;
                              const parsedStart = parseMonthYear(newStart);
                              const parsedEnd = parseMonthYear(newProjs[index].endDate || "");
                              const newErrs = { ...projectsErrors };
                              newErrs[index] = "";
                              if (parsedStart && parsedEnd) {
                                if (compareMonthYear(parsedStart, parsedEnd) === 1) {
                                  newErrs[index] = "Start date cannot be after end date.";
                                }
                              }
                              setProjectsErrors(newErrs);
                              setTempProjects(newProjs);
                            }}
                            placeholder="Start Date"
                          />

                          <div className="current-position-checkbox" style={{ cursor: "pointer" }}>
                            <input
                              type="checkbox"
                              checked={!!proj.isOngoing}
                              onChange={(ev) => {
                                const newProjs = [...tempProjects];
                                newProjs[index].isOngoing = ev.target.checked;
                                if (ev.target.checked) {
                                  newProjs[index].endDate = null;
                                }
                                const newErrs = { ...projectsErrors };
                                newErrs[index] = "";
                                setProjectsErrors(newErrs);
                                setTempProjects(newProjs);
                              }}
                              id={`ongoing-project-${index}`}
                            />
                            <label htmlFor={`ongoing-project-${index}`} style={{ cursor: "pointer" }}>
                              Ongoing
                            </label>
                          </div>

                          {!proj.isOngoing && (
                            <CustomDatePicker
                              value={proj.endDate || ""}
                              onChange={(newEnd) => {
                                const newProjs = [...tempProjects];
                                newProjs[index].endDate = newEnd === "" ? null : newEnd;
                                const parsedEnd = parseMonthYear(newEnd);
                                const parsedStart = parseMonthYear(newProjs[index].startDate || "");
                                const newErrs = { ...projectsErrors };
                                newErrs[index] = "";
                                if (parsedEnd && parsedStart) {
                                  if (compareMonthYear(parsedEnd, parsedStart) === -1) {
                                    newErrs[index] = "End date cannot be before start date.";
                                  }
                                }
                                setProjectsErrors(newErrs);
                                setTempProjects(newProjs);
                              }}
                              placeholder="End Date"
                            />
                          )}
                        </div>
                        {dateError && (
                          <div className="date-error-message">
                            {dateError}
                          </div>
                        )}
                        <label>Description</label>
                        {proj.description.map((desc, descIndex) => (
                          <div
                            key={desc.id}
                            className="description-item"
                            draggable
                            onDragStart={(e) => handleDragStart(e, "projects", index, descIndex)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, "projects", index, descIndex)}
                          >
                            <div className="description-controls">
                              <AiOutlinePlus 
                                className="add-icon"
                                onClick={() => {
                                  const newProjs = [...tempProjects];
                                  newProjs[index].description.splice(descIndex + 1, 0, { id: uuidv4(), text: "" });
                                  setTempProjects(newProjs);
                                }}
                              />
                              <MdDragIndicator className="drag-icon" />
                            </div>
                            <textarea
                              value={desc.text}
                              onChange={(e) => {
                                const newProjs = [...tempProjects];
                                newProjs[index].description[descIndex].text = e.target.value;
                                setTempProjects(newProjs);
                              }}
                              rows={2}
                              className="description-input"
                            />
                            <AiOutlineDelete
                              className="delete-icon"
                              onClick={() => {
                                const newProjs = [...tempProjects];
                                newProjs[index].description = newProjs[index].description.filter(d => d.id !== desc.id);
                                setTempProjects(newProjs);
                              }}
                            />
                          </div>
                        ))}
                        <div className="edit-buttons">
                          <button
                            type="button"
                            className="save-button"
                            onClick={() => handleSave("projects", index)}
                            disabled={!canSave}
                            style={{ opacity: canSave ? 1 : 0.5, cursor: canSave ? "pointer" : "not-allowed" }}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="cancel-button"
                            onClick={() => handleCancelEdit("projects")}
                          >
                            Cancel
                          </button>
                          <Dialog>
                            <DialogTrigger>Remove</DialogTrigger>
                            <DialogContent>
                              <DialogHeader>
                                <DialogTitle>Are you absolutely sure?</DialogTitle>
                                <DialogDescription>
                                  This action cannot be undone. Are you sure you
                                  want to permanently delete this file?
                                </DialogDescription>
                              </DialogHeader>
                              <DialogFooter>
                                <Button
                                  type="submit"
                                  onClick={() => removeProject(index)}
                                >
                                  Confirm
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="info-content"
                        onClick={() => {
                          if (editingProjectIndex !== index) {
                            toggleEdit("projects", index);
                          }
                        }}
                      >
                        <div className="top-row">
                          <div className="item-title">
                            {proj.title
                              ? `${proj.projectName} (${proj.title})`
                              : proj.projectName}
                          </div>
                          <div className="item-duration">
                            {proj.startDate}
                            {(proj.isOngoing || proj.endDate) && (
                              <>
                                {" - "}
                                {proj.isOngoing ? "Present" : proj.endDate}
                              </>
                            )}
                          </div>
                        </div>
                        <ul className="item-subtitle">
                          {proj.description.map((desc, descIndex) => (
                            <li key={descIndex}>{desc.text}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })
            )}
            <button type="button" className="section-add-button" onClick={addProject}>
              Add Project
            </button>
          </div>

          {/* SKILLS SECTION */}
          <div className="section">
            <h3 className="section-title">Skills</h3>
            {loading ? (
              <div className="loading-placeholder">
                <div className="bullet-skeleton" style={{ width: '100px', height: '28px', borderRadius: '14px', display: 'inline-block', marginRight: '8px' }}></div>
                <div className="bullet-skeleton" style={{ width: '120px', height: '28px', borderRadius: '14px', display: 'inline-block', marginRight: '8px' }}></div>
                <div className="bullet-skeleton" style={{ width: '90px', height: '28px', borderRadius: '14px', display: 'inline-block', marginRight: '8px' }}></div>
              </div>
            ) : (
              <div className="skills-container">
                {skills.map((skill, index) => (
                  <span
                    key={index}
                    className={`skill-badge ${editedSkills[index] ? "editing" : ""} ${
                      forceUnhoverSkills[index] ? "force-unhover" : ""
                    } ${dragOverType === "skill" && dragOverIndex === index ? "drag-over" : ""} ${
                      dragSource.type === "skill" && dragSource.index === index ? "dragging" : ""
                    }`}
                    draggable
                    onDragStart={(e) => handleSkillDragStart(e, index)}
                    onDragEnd={handleDragEnd}
                    onDragEnter={() => handleDragEnter("skill", index)}
                    onDragLeave={handleDragLeave}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleSkillDrop(e, index)}
                    onMouseLeave={() => handleSkillMouseLeave(index)}
                  >
                    <MdDragIndicator className="drag-handle" />
                    <input
                      type="text"
                      value={skill}
                      onChange={(e) => handleSkillInputChange(e, index)}
                      onBlur={() => handleSkillInputBlur(index)}
                      onKeyDown={(e) => handleSkillInputKeyDown(e, index)}
                      className="skill-input"
                      style={{ width: `${skill.length + 1}ch` }}
                    />
                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => removeSkill(index)}
                    >
                      ×
                    </button>
                  </span>
                ))}
                <button type="button" className="add-button" onClick={addSkill}>
                  Add Skill
                </button>
              </div>
            )}
          </div>

          {/* INTERESTS SECTION */}
          <div className="section">
            <h3 className="section-title">Interests</h3>
            {loading ? (
              <div className="loading-placeholder">
                <div className="bullet-skeleton" style={{ width: '95px', height: '28px', borderRadius: '14px', display: 'inline-block', marginRight: '8px' }}></div>
                <div className="bullet-skeleton" style={{ width: '130px', height: '28px', borderRadius: '14px', display: 'inline-block', marginRight: '8px' }}></div>
              </div>
            ) : (
              <div className="skills-container">
                {interests.map((interest, index) => (
                  <span
                    key={index}
                    className={`skill-badge ${editedInterests[index] ? "editing" : ""} ${
                      forceUnhoverInterests[index] ? "force-unhover" : ""
                    } ${dragOverType === "interest" && dragOverIndex === index ? "drag-over" : ""} ${
                      dragSource.type === "interest" && dragSource.index === index ? "dragging" : ""
                    }`}
                    draggable
                    onDragStart={(e) => handleInterestDragStart(e, index)}
                    onDragEnd={handleDragEnd}
                    onDragEnter={() => handleDragEnter("interest", index)}
                    onDragLeave={handleDragLeave}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleInterestDrop(e, index)}
                    onMouseLeave={() => handleInterestMouseLeave(index)}
                  >
                    <MdDragIndicator className="drag-handle" />
                    <input
                      type="text"
                      value={interest}
                      onChange={(e) => handleInterestInputChange(e, index)}
                      onBlur={() => handleInterestInputBlur(index)}
                      onKeyDown={(evt) => handleInterestInputKeyDown(evt, index)}
                      className="skill-input"
                      style={{ width: `${interest.length + 1}ch` }}
                    />
                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => removeInterest(index)}
                    >
                      ×
                    </button>
                  </span>
                ))}
                <button type="button" className="add-button" onClick={addInterest}>
                  Add Interest
                </button>
              </div>
            )}
          </div>
        </form>
      </div>

      {/* CUSTOM "Edit Profile" Modal */}
      {isDialogOpen && (
        <div
          className={`custom-edit-profile-modal-overlay modal-open ${isClosing ? "closing" : ""}`}
          onClick={(e) => {
            handleCancelEdit("profile");
            e.stopPropagation();
          }}
        >
          <div className="custom-edit-profile-modal" onClick={(e) => e.stopPropagation()}>
            <button
              className="modal-close-button"
              onClick={() => handleCancelEdit("profile")}
              aria-label="Close modal"
            >
              <AiOutlineClose />
            </button>
            <h2>Edit Profile</h2>
            <p>Update your profile details below.</p>

            <div className="fields-row">
              <div className="custom-modal-field">
                <label>First Name</label>
                <input
                  type="text"
                  value={tempFirstName}
                  onChange={(e) => setTempFirstName(e.target.value)}
                />
              </div>

              <div className="custom-modal-field">
                <label>Last Name</label>
                <input
                  type="text"
                  value={tempLastName}
                  onChange={(e) => setTempLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="fields-row">
              <div className="custom-modal-field">
                <label>Phone</label>
                <input
                  type="tel"
                  value={phone}
                  onChange={handlePhoneChange}
                  onPaste={handlePhonePaste}
                  placeholder="(123) 456-7890"
                  maxLength={14}
                />
              </div>

              <div className="custom-modal-field">
                <label>Citizenship</label>
                <input
                  type="text"
                  value={citizenship}
                  onChange={(e) => setCitizenship(e.target.value)}
                />
              </div>
            </div>

            <div className="fields-row">
              <div className="custom-modal-field">
                <CustomDropdown
                  value={tempExperienceLevel}
                  onChange={setTempExperienceLevel}
                  options={["Entry Level", "Mid Career", "Experienced"]}
                  label="Experience Level"
                />
              </div>

              <div className="custom-modal-field">
                <label>Location</label>
                <input
                  type="text"
                  value={tempLocation}
                  onChange={(e) => setTempLocation(e.target.value)}
                />
              </div>
            </div>

            <div className="fields-row">
              <div className="custom-modal-field">
                <label>LinkedIn URL</label>
                <input
                  type="text"
                  value={tempLinkedin}
                  onChange={(e) => setTempLinkedin(formatUrl(e.target.value, "linkedin"))}
                  onPaste={handleLinkedinPaste}
                  placeholder="https://"
                />
              </div>

              <div className="custom-modal-field">
                <label>GitHub URL</label>
                <input
                  type="text"
                  value={tempGithub}
                  onChange={(e) => setTempGithub(formatUrl(e.target.value, "website"))}
                  onPaste={handleGithubPaste}
                  placeholder="https://github.com/username"
                />
              </div>
            </div>

            <div className="fields-row">
              <div className="custom-modal-field">
                <label>Resume Email</label>
                <input
                  type="email"
                  value={resumeEmail}
                  onChange={(e) => setResumeEmail(e.target.value)}
                  placeholder="Email to show on resume"
                />
              </div>

              <div className="custom-modal-field">
                <label>X (Twitter)</label>
                <div className="input-with-prefix">
                  <span className="input-prefix">@</span>
                  <input
                    type="text"
                    value={twitter?.replace("@", "")}
                    onChange={(e) =>
                      setTwitter(
                        e.target.value.startsWith("@") ? e.target.value : `@${e.target.value}`
                      )
                    }
                    placeholder="username"
                  />
                </div>
              </div>
            </div>

            <div className="custom-modal-actions">
              <button className="cancel-button" onClick={() => handleCancelEdit("profile")}>
                Cancel
              </button>
              <button className="save-button" onClick={handleSaveProfile}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountInfo;
