import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import JobTile from '../JobTile';
import { StrictModeDroppable } from '../StrictModeDroppable';
import styles from '../../styles/components/applicationTracker/applicationTracker.module.scss';
import { getApplications, Application as UserApplication, updateApplicationStatus, deleteApplication } from '../../services/applicationService';
import { fetchJobById } from '../../services/jobService';
import Sidebar from '../Sidebar';
import JobDetailsModal from './JobDetailsModal';
import { JobButtonContext } from '../../constant';

interface JobData {
  _id: string;
  title: string;
  company: string;
  location: string;
  type: string;
  description: string;
  requirements: string[];
  salary?: string;
  posted: string;
  deadline?: string;
  logo?: string;
  days_to_live: any;
  jobType: string;
  _preserveLogoState?: boolean;
  status?: 'applied' | 'interviewing' | 'offer';
  appliedDate?: string;
  lastStatusUpdate?: string;
  isDemo?: boolean;
}

interface ApplicationWithJob {
  id: string;
  job: JobData;
  status: 'applied' | 'interviewing' | 'offer';
  appliedDate: string;
  lastStatusUpdate?: string;
  isDeleting?: boolean;
}

interface Column {
  id: string;
  title: string;
  items: ApplicationWithJob[];
}

interface BoardState {
  columns: {
    [key: string]: Column;
  };
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const demoJobs = {
  applied1: {
    _id: 'demo-applied-1',
    title: "Senior Software Engineer",
    company: "QuickCruit",
    location: "San Francisco, CA",
    type: "DEMO",
    jobType: "DEMO",
    description: "This is a demo job tile to showcase the application tracking functionality. Once you apply to real jobs, they will appear here instead.",
    requirements: ["This is a demo job to showcase the application tracker functionality"],
    posted: new Date().toISOString(),
    days_to_live: { $numberInt: "30" },
    status: 'applied' as 'applied',
    appliedDate: new Date().toISOString(),
    lastStatusUpdate: new Date().toISOString(),
    logo: "https://qaloopkm7ji3ppwh.public.blob.vercel-storage.com/companies/QuickCruit_square-5erTzbgqoSPF81sfYdeuUeA6BeBZkP.png",
    _preserveLogoState: true,
    isDemo: true
  },
  applied2: {
    _id: 'demo-applied-2',
    title: "Product Marketing Manager",
    company: "QuickCruit",
    location: "New York, NY",
    type: "DEMO",
    jobType: "DEMO",
    description: "This is a demo job tile to showcase the application tracking functionality. Once you apply to real jobs, they will appear here instead.",
    requirements: ["This is a demo job to showcase the application tracker functionality"],
    posted: new Date().toISOString(),
    days_to_live: { $numberInt: "30" },
    status: 'applied' as 'applied',
    appliedDate: new Date().toISOString(),
    lastStatusUpdate: new Date().toISOString(),
    logo: "https://qaloopkm7ji3ppwh.public.blob.vercel-storage.com/companies/QuickCruit_square-5erTzbgqoSPF81sfYdeuUeA6BeBZkP.png",
    _preserveLogoState: true,
    isDemo: true
  },
  offer: {
    _id: 'demo-offer',
    title: "Machine Learning Engineer",
    company: "QuickCruit",
    location: "Austin, TX",
    type: "DEMO",
    jobType: "DEMO",
    description: "This is a demo job tile to showcase the offer stage. Your successful job applications will appear here when you receive offers.",
    requirements: ["This is a demo job to showcase the application tracker functionality"],
    posted: new Date().toISOString(),
    days_to_live: { $numberInt: "30" },
    status: 'offer' as 'offer',
    appliedDate: new Date().toISOString(),
    lastStatusUpdate: new Date().toISOString(),
    logo: "https://qaloopkm7ji3ppwh.public.blob.vercel-storage.com/companies/QuickCruit_square-5erTzbgqoSPF81sfYdeuUeA6BeBZkP.png",
    _preserveLogoState: true,
    isDemo: true
  }
};

const ApplicationTracker: React.FC = () => {
  const navigate = useNavigate();
  const [boardState, setBoardState] = useState<BoardState>({
    columns: {
      applied: {
        id: 'applied',
        title: 'Applied',
        items: [
          {
            id: 'demo-applied-1',
            job: demoJobs.applied1,
            status: 'applied',
            appliedDate: new Date().toISOString()
          },
          {
            id: 'demo-applied-2',
            job: demoJobs.applied2,
            status: 'applied',
            appliedDate: new Date().toISOString()
          }
        ]
      },
      interviewing: {
        id: 'interviewing',
        title: 'Interviewing',
        items: []
      },
      offer: {
        id: 'offer',
        title: 'Offer',
        items: [{
          id: 'demo-offer',
          job: demoJobs.offer,
          status: 'offer',
          appliedDate: new Date().toISOString()
        }]
      }
    }
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [selection, setSelection] = useState<JobData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (!userData) {
          navigate('/auth/login');
          return;
        }

        const user = JSON.parse(userData);
        if (!user._id) {
          navigate('/auth/login');
          return;
        }

        setUser(user);
        await fetchApplications();
      } catch (error) {
        setError('Failed to initialize application tracker.');
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [navigate]);

  const handleJobSelection = (job: JobData) => {
    const selectedApplication = boardState.columns[job.status || 'applied'].items.find(
      item => item.job._id === job._id
    );
    setSelection({
      ...job,
      status: selectedApplication?.status,
      appliedDate: selectedApplication?.appliedDate,
      lastStatusUpdate: selectedApplication?.lastStatusUpdate
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelection(null);
  };

  const fetchApplications = async () => {
    try {
      const userData = localStorage.getItem('user');
      if (!userData) {
        navigate('/auth/login');
        return;
      }

      const user = JSON.parse(userData);
      if (!user._id) {
        navigate('/auth/login');
        return;
      }

      // Fetch user's applications
      const applications = await getApplications();
      console.log('Fetched applications:', applications);
      
      // If there are no applications, keep the demo state
      if (!applications || applications.length === 0) {
        return;
      }
      
      // If we have real applications, proceed with setting up the real board
      const applicationPromises = applications.map(async (application: UserApplication) => {
        try {
          console.log('Fetching job details for ID:', application.jobId);
          const jobDetails = await fetchJobById(application.jobId);
          console.log('Received job details:', jobDetails);
          return {
            id: `job-${application.jobId}`,
            job: jobDetails,
            status: application.status,
            appliedDate: application.appliedDate
          } as ApplicationWithJob;
        } catch (error) {
          console.error(`Error fetching job details for ${application.jobId}:`, error);
          return null;
        }
      });

      const resolvedApplications = (await Promise.all(applicationPromises)).filter((app: ApplicationWithJob | null): app is ApplicationWithJob => app !== null);
      console.log('Final resolved applications:', resolvedApplications);
      
      // Only update the board state if we have real applications
      if (resolvedApplications.length > 0) {
        const newState: BoardState = {
          columns: {
            applied: {
              id: 'applied',
              title: 'Applied',
              items: resolvedApplications.filter((app: ApplicationWithJob) => app.status === 'applied')
            },
            interviewing: {
              id: 'interviewing',
              title: 'Interviewing',
              items: resolvedApplications.filter((app: ApplicationWithJob) => app.status === 'interviewing')
            },
            offer: {
              id: 'offer',
              title: 'Offer',
              items: resolvedApplications.filter((app: ApplicationWithJob) => app.status === 'offer')
            }
          }
        };
        console.log('Final board state:', newState);
        setBoardState(newState);
      }
      
      setError(null);
    } catch (err) {
      console.error('Error fetching applications:', err);
      setError('Failed to fetch applications');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();

    // Listen for application updates
    const handleApplicationUpdate = () => {
      fetchApplications();
    };

    window.addEventListener('application-updated', handleApplicationUpdate);

    return () => {
      window.removeEventListener('application-updated', handleApplicationUpdate);
    };
  }, []);

  const updateStatus = async (jobId: string, status: 'applied' | 'interviewing' | 'offer', retries = 0): Promise<void> => {
    try {
      await updateApplicationStatus(jobId, status);
    } catch (error) {
      console.error('Error updating status:', error);
      if (retries < MAX_RETRIES) {
        await sleep(RETRY_DELAY);
        await updateStatus(jobId, status, retries + 1);
      }
    }
  };

  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;
    
    if (source.droppableId === destination.droppableId && 
        source.index === destination.index) {
      return;
    }

    setBoardState(prevState => {
      const sourceColumn = prevState.columns[source.droppableId];
      const destColumn = prevState.columns[destination.droppableId];
      
      const sourceItems = [...sourceColumn.items];
      const destItems = source.droppableId === destination.droppableId 
        ? sourceItems 
        : [...destColumn.items];
      
      const [removed] = sourceItems.splice(source.index, 1);
      
      // Skip database update for demo items
      const isDemoItem = removed.id.startsWith('demo-');
      
      // Preserve the job data during status change
      const updatedApplication: ApplicationWithJob = {
        ...removed,
        status: destination.droppableId as 'applied' | 'interviewing' | 'offer',
        job: {
          ...removed.job,
          _preserveLogoState: true
        }
      };
      
      destItems.splice(destination.index, 0, updatedApplication);

      // Only update status in database for real applications
      if (!isDemoItem) {
        const jobId = removed.id.replace('job-', '');
        updateStatus(jobId, destination.droppableId as 'applied' | 'interviewing' | 'offer');
      }

      return {
        columns: {
          ...prevState.columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems
          }
        }
      };
    });
  }, []);

  const handleDeleteJob = async (jobId: string) => {
    try {
      // For demo jobs, just return
      if (jobId.startsWith('demo-')) {
        return;
      }

      // Find the job in the board state and add a fade-out class
      setBoardState(prev => {
        const newState = { ...prev };
        Object.keys(newState.columns).forEach(columnKey => {
          const itemIndex = newState.columns[columnKey].items.findIndex(
            item => item.job._id === jobId
          );
          if (itemIndex !== -1) {
            // Add fadeOut class to the item
            newState.columns[columnKey].items[itemIndex] = {
              ...newState.columns[columnKey].items[itemIndex],
              isDeleting: true
            };
          }
        });
        return newState;
      });

      // Wait for animation to complete before removing from state
      setTimeout(() => {
        setBoardState(prev => {
          const newState = { ...prev };
          Object.keys(newState.columns).forEach(columnKey => {
            newState.columns[columnKey].items = newState.columns[columnKey].items.filter(
              item => item.job._id !== jobId
            );
          });
          return newState;
        });
      }, 300); // Match this with CSS animation duration

    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const renderColumn = useCallback((columnId: string) => {
    const column = boardState.columns[columnId];
    
    return (
      <div className={styles.kanbanColumn} key={columnId}>
        <h2>
          {column.title}
          <span className={styles.count}>{column.items.length}</span>
        </h2>
        <StrictModeDroppable droppableId={columnId}>
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${styles.columnContent} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
            >
              {column.items.map((application, index) => (
                <Draggable
                  key={application.id}
                  draggableId={application.id}
                  index={index}
                >
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${styles.draggableContainer} ${snapshot.isDragging ? styles.dragging : ''} ${application.isDeleting ? styles.fadeOut : ''}`}
                      style={provided.draggableProps.style}
                    >
                      <JobTile
                        key={application.job._id}
                        job={application.job}
                        userId={user._id}
                        handleSelection={handleJobSelection}
                        context={JobButtonContext.APPLICATION_TRACKER}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </div>
    );
  }, [boardState, user]);

  if (loading) {
    return (
      <div className={styles.appTrackerContainer}>
        <Sidebar />
        <div className={styles.appTrackerMain}>
          <header className={styles.dashboardHeader}>
            <div className={`${styles.greeting} ${styles.skeleton}`}></div>
          </header>
          <div className={styles.kanbanBoard}>
            <div className={styles.appTrackerTilesContainer}>
              {['applied', 'interviewing', 'offer'].map((columnId) => (
                <div key={columnId} className={styles.kanbanColumn}>
                  <h2 className={styles.skeleton}></h2>
                  <div className={styles.columnContent}>
                    {[1, 2, 3].map((i) => (
                      <div key={i} className={`${styles.jobTileSkeleton} ${styles.skeleton}`}></div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const totalApplications = Object.values(boardState.columns).reduce(
    (total, column) => total + column.items.length,
    0
  );

  if (error) {
    return (
      <div className={styles.appTrackerContainer}>
        <Sidebar />
        <div className={styles.appTrackerMain}>
          <div className={styles.error}>{error}</div>
        </div>
      </div>
    );
  }

  if (totalApplications === 0) {
    return (
      <div className={styles.appTrackerContainer}>
        <Sidebar />
        <div className={styles.appTrackerMain}>
          <header className={styles.dashboardHeader}>
            <h1 className={styles.greeting}>Application Tracker</h1>
          </header>
          <div className={styles.kanbanBoard}>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className={styles.appTrackerTilesContainer}>
                <div className={styles.kanbanColumn}>
                  <h2>
                    Applied
                    <span className={styles.count}>2</span>
                  </h2>
                  <StrictModeDroppable droppableId="applied">
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`${styles.columnContent} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
                      >
                        <Draggable draggableId="demo-applied-1" index={0}>
                          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${styles.draggableContainer} ${snapshot.isDragging ? styles.dragging : ''}`}
                              style={provided.draggableProps.style}
                            >
                              <JobTile
                                job={demoJobs.applied1}
                                userId={user?._id}
                                handleSelection={() => navigate('/job-search')}
                                context={JobButtonContext.DEMO}
                              />
                            </div>
                          )}
                        </Draggable>
                        <Draggable draggableId="demo-applied-2" index={1}>
                          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${styles.draggableContainer} ${snapshot.isDragging ? styles.dragging : ''}`}
                              style={provided.draggableProps.style}
                            >
                              <JobTile
                                job={demoJobs.applied2}
                                userId={user?._id}
                                handleSelection={() => navigate('/job-search')}
                                context={JobButtonContext.DEMO}
                              />
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </div>

                <div className={styles.kanbanColumn}>
                  <h2>
                    Interviewing
                    <span className={styles.count}>0</span>
                  </h2>
                  <StrictModeDroppable droppableId="interviewing">
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`${styles.columnContent} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
                      >
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </div>

                <div className={styles.kanbanColumn}>
                  <h2>
                    Offer
                    <span className={styles.count}>1</span>
                  </h2>
                  <StrictModeDroppable droppableId="offer">
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`${styles.columnContent} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
                      >
                        <Draggable draggableId="demo-offer" index={0}>
                          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${styles.draggableContainer} ${snapshot.isDragging ? styles.dragging : ''}`}
                              style={provided.draggableProps.style}
                            >
                              <JobTile
                                job={demoJobs.offer}
                                userId={user?._id}
                                handleSelection={() => navigate('/job-search')}
                                context={JobButtonContext.DEMO}
                              />
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </div>
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.appTrackerContainer}>
      <Sidebar />
      <div className={styles.appTrackerMain}>
        <header className={styles.dashboardHeader}>
          <h1 className={styles.greeting}>Application Tracker</h1>
        </header>
        <div className={styles.kanbanBoard}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.appTrackerTilesContainer}>
              {renderColumn('applied')}
              {renderColumn('interviewing')}
              {renderColumn('offer')}
            </div>
          </DragDropContext>
        </div>
      </div>
      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onStatusChange={(newStatus) => {
            // Handle status change
            console.log('Status changed to:', newStatus);
          }}
          onDelete={handleDeleteJob}
        />
      )}
    </div>
  );
};

export default ApplicationTracker;