import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./styles/main.scss";
import LandingPage from "./components/landing-pages/LandingPage";
import Signup from "./components/Signup";
import Login from "./components/Login";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import Metrics from "./components/Metrics";
import AccountInfo from "./components/AccountInfo";
import JobSearch from "./components/JobSearch";
import ResumeBuilder from "./components/ResumeBuilder";
// import NewResumeBuilder from "./components/resume-builder/ResumeBuilder";
import Preferences from "./components/Preferences";
import SEO from "./SEO";
import EmailVerified from "./components/EmailVerified";
import Onboarding from "./components/Onboarding";
import "@radix-ui/themes/styles.css";
import JobPage from "./components/landing-pages/JobPage";
import PortfolioPage from "./components/Portfolio";
import PricingPage_second from "./components/landing-pages/Pricing";
import About from "./components/landing-pages/About";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "./constant";
import GoogleRedirect from "./components/GoogleRedirect";
import Chat from "./components/chat/Chat";
import AiMockInterview from "./components/AiMockInterview";
import MockInterview from "./components/MockInterview";
import TermsOfService from "./components/TermsofService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import BringYourJob from "./components/bringYourJob/BringYourJob";
import ApplicationTracker from "./components/applicationTracker/ApplicationTracker";
import ResumeTailoringPage from "./components/landing-pages/ResumeTailoringPage";
import CoverLetterPage from "./components/landing-pages/CoverLetterPage";
import MockInterviewsPage from "./components/landing-pages/MockInterviewsPage";
import ApplicationTrackerPage from "./components/landing-pages/ApplicationTrackerPage";
import ChatPage from "./components/landing-pages/ChatPage";
import CareerCoachingPage from "./components/landing-pages/CareerCoachingPage";
import { ApplicationModalProvider } from "./contexts/ApplicationModalContext";
import ApplicationConfirmModal from "./components/ApplicationConfirmModal";
import SubscriptionPlans from "./components/Subscription/SubscriptionPlans";
import DevTestJobSearch from "./components/DevTestJobSearch";
import OutreachPage from "./components/landing-pages/OutreachPage";
import NotFound from "./components/landing-pages/NotFound";
import axiosClient from "./axios-client";
import SuccessPage from "./components/SuccessPage";
import CancelPage from "./components/CancelPage";
import ContactUs from "./components/ContactUs";
import Tutorials from "./components/tutorials/Tutorials";

const Loading: React.FC = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div className="spinner"></div>
  </div>
);

function App() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("accessToken");
      const storedUser = localStorage.getItem("user");

      if (token && storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);
          // Set the token in axios default headers
          axiosClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
        } catch (error) {
          console.error("Error parsing user data:", error);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <ApplicationModalProvider>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Router>
          <ScrollToTop />
          <SEO />
          <Routes>
            <Route
              path="/"
              element={user ? <Navigate to="/dashboard" /> : <LandingPage />}
            />
            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/metrics"
              element={user ? <Metrics /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/account-info"
              element={user ? <AccountInfo /> : <Navigate to="/auth/login" />}
            />
            <Route path="/jobs" element={<JobPage />} />
            <Route
              path="/job-search"
              element={user ? <JobSearch /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/job-search/preferences"
              element={
                user ? (
                  <Preferences onSave={(handleSavePreferences) => {}} />
                ) : (
                  <Navigate to="/auth/login" />
                )
              }
            />
            <Route
              path="/job-search/jobs"
              element={user ? <JobSearch /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/job-search/dev-test"
              element={
                user ? <DevTestJobSearch /> : <Navigate to="/auth/login" />
              }
            />
            {/* <Route
              path="/resume-builder/*"
              element={user ? <NewResumeBuilder /> : <Navigate to="/auth/login" />}
            /> */}
            <Route
              path="/legacy-resume-system-v1-archive"
              element={user ? <ResumeBuilder /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/ai-mock-interview"
              element={
                user ? <AiMockInterview /> : <Navigate to="/auth/login" />
              }
            />
            <Route
              path="/mock-interview"
              element={user ? <MockInterview /> : <Navigate to="/auth/login" />}
            />
            <Route path="/auth/signup" element={<Signup />} />
            <Route
              path="/auth/login"
              element={user ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route
              path="/stripe"
              element={user ? <SubscriptionPlans /> : <Login />}
            />
            <Route path="/auth/verify-email" element={<VerifyEmail />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/email-verified" element={<EmailVerified />} />
            <Route
              path="/onboarding"
              element={
                user ? (
                  user.userOnboarding ? (
                    <Navigate to="/dashboard" replace />
                  ) : (
                    <Onboarding />
                  )
                ) : (
                  <Navigate to="/auth/login" replace />
                )
              }
            />
            <Route path="/logout" element={<Logout setUser={setUser} />} />
            <Route path="/portfolio/:username" element={<PortfolioPage />} />
            <Route path="/pricing" element={<PricingPage_second />} />
            <Route 
              path="/subscription" 
              element={user ? <SubscriptionPlans /> : <Navigate to="/auth/login" />} 
            />
            <Route path="/about" element={<About />} />
            <Route path="/auth/google/callback" element={<GoogleRedirect />} />
            {/* <Route
              path="/chat"
              element={user ? <Chat /> : <Navigate to="/auth/login" />}
            /> */}
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/tutorials"
              element={user ? <Tutorials /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/bring-your-job"
              element={user ? <BringYourJob /> : <Navigate to="/auth/login" />}
            />
            <Route
              path="/application-tracker"
              element={
                user ? <ApplicationTracker /> : <Navigate to="/auth/login" />
              }
            />
            <Route
              path="/subscription"
              element={
                user ? <SubscriptionPlans /> : <Navigate to="/auth/login" />
              }
            />
            <Route
              path="/payment/success"
              element={user ? <SuccessPage /> : <Navigate to="/auth/login" />}
            />

            <Route
              path="/payment/cancel"
              element={user ? <CancelPage /> : <Navigate to="/auth/login" />}
            />
            <Route path="/features" element={<Navigate to="/" replace />} />
            <Route
              path="/features/resume-tailoring"
              element={<ResumeTailoringPage />}
            />
            <Route
              path="/features/cover-letter"
              element={<CoverLetterPage />}
            />
            <Route
              path="/features/mock-interviews"
              element={<MockInterviewsPage />}
            />
            <Route
              path="/features/application-tracker"
              element={<ApplicationTrackerPage />}
            />
            <Route path="/features/outreach" element={<OutreachPage />} />
            <Route
              path="/features/career-coaching"
              element={<CareerCoachingPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
      <ApplicationConfirmModal />
    </ApplicationModalProvider>
  );
}

const Logout: React.FC<{ setUser: (user: any) => void }> = ({ setUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      setUser(null);
      navigate("/");
    };

    logoutUser();
  }, [navigate, setUser]);

  return null;
};

export default App;
