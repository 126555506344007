import React, { useState, useEffect } from 'react';
import '../styles/components/applicationConfirmModal.scss';
import { useApplicationModal } from '../contexts/ApplicationModalContext';
import { addApplication } from '../services/applicationService';
import { toast } from 'react-hot-toast';
import axiosClient from '../axios-client';
import { AxiosError } from 'axios';

const ApplicationConfirmModal: React.FC = () => {
  const { 
    showModal, 
    jobTitle, 
    companyName, 
    jobId, 
    resumeId,
    coverLetterId,
    setModalData, 
    onConfirm,
    resumeContent,
    coverLetterContent
  } = useApplicationModal();
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationData, setApplicationData] = useState<any>(null);

  useEffect(() => {
    if (showModal) {
      setIsClosing(false);
      // Try to get application data from localStorage
      const savedData = localStorage.getItem('currentApplication');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        setApplicationData(parsedData);
        console.log('Retrieved application data:', {
          hasResumeContent: !!parsedData.resumeContent,
          resumeKeys: Object.keys(parsedData.resumeContent || {}),
          hasCoverLetter: !!parsedData.coverLetterContent
        });
      }
    }
  }, [showModal]);

  const saveApplicationDocuments = async () => {
    const user = JSON.parse(String(localStorage.getItem("user")));
    
    // Get the most recent resume and cover letter content
    const currentResumeContent = localStorage.getItem(`currentResume_${jobId}`);
    const currentCoverLetterContent = localStorage.getItem(`currentCoverLetter_${jobId}`);
    
    // Parse resume content if available
    let finalResumeContent;
    try {
      finalResumeContent = currentResumeContent 
        ? JSON.parse(currentResumeContent)
        : (applicationData?.resumeContent || resumeContent);

      // Ensure contact information is included
      if (user) {
        finalResumeContent = {
          ...finalResumeContent,
          firstName: finalResumeContent.firstName || user.firstName,
          lastName: finalResumeContent.lastName || user.lastName,
          email: finalResumeContent.email || user.email,
          phone: finalResumeContent.phone || user.phone,
          linkedin: finalResumeContent.linkedin || user.linkedin,
          website: finalResumeContent.website || user.website,
          location: finalResumeContent.location || user.location,
          citizenship: finalResumeContent.citizenship || user.citizenship,
        };
      }
    } catch (error) {
      console.error('Error parsing resume content:', error);
      finalResumeContent = applicationData?.resumeContent || resumeContent;
    }

    // Use the most recent content available
    const finalCoverLetterContent = currentCoverLetterContent || 
      applicationData?.coverLetterContent || 
      coverLetterContent;

    // Format cover letter content to preserve paragraphs
    const formattedCoverLetter = finalCoverLetterContent
      ? finalCoverLetterContent
          .split(/\n\s*\n/)  // Split into paragraphs
          .map((paragraph: string) => paragraph.trim())  // Trim each paragraph
          .join('\n\n')      // Join with double newlines
      : null;
    
    const finalJobId = applicationData?.jobId || jobId;
    
    console.log('Starting document save with:', {
      hasResumeContent: !!finalResumeContent,
      resumeSections: finalResumeContent ? Object.keys(finalResumeContent) : [],
      contactInfo: {
        hasName: !!(finalResumeContent?.firstName || finalResumeContent?.lastName),
        hasEmail: !!finalResumeContent?.email,
        hasPhone: !!finalResumeContent?.phone,
        hasLinkedin: !!finalResumeContent?.linkedin
      },
      hasCoverLetter: !!formattedCoverLetter,
      coverLetterLength: formattedCoverLetter?.length,
      paragraphs: formattedCoverLetter?.split('\n\n').length,
      jobId: finalJobId
    });

    if (!user?._id) {
      console.error('No user ID available');
      toast.error('User ID is required');
      return false;
    }

    if (!finalJobId) {
      console.error('No job ID available');
      toast.error('Job ID is required');
      return false;
    }

    if (!finalResumeContent || typeof finalResumeContent !== 'object') {
      console.error('Invalid resume content:', finalResumeContent);
      toast.error('Valid resume content is required');
      return false;
    }

    try {
      // Prepare the request data
      const requestData = {
        jobId: finalJobId,
        userId: user._id,
        resume: finalResumeContent,
        coverLetter: formattedCoverLetter || null
      };

      console.log('Sending application documents:', {
        jobId: requestData.jobId,
        userId: requestData.userId,
        resumeSections: Object.keys(requestData.resume),
        hasCoverLetter: !!requestData.coverLetter,
        coverLetterLength: requestData.coverLetter?.length,
        coverLetterParagraphs: requestData.coverLetter?.split('\n\n').length
      });

      const response = await axiosClient.post('/application-documents', requestData);

      if (response.status === 200) {
        console.log('Application documents saved successfully:', response.data);
        toast.success('Application documents saved successfully');
        // Clear all stored application data
        localStorage.removeItem('currentApplication');
        localStorage.removeItem(`currentResume_${jobId}`);
        localStorage.removeItem(`currentCoverLetter_${jobId}`);
        return true;
      } else {
        console.error('Unexpected response status:', response.status);
        toast.error('Failed to save application documents');
        return false;
      }
    } catch (error) {
      console.error('Error saving application documents:', error);
      if (error instanceof AxiosError && error.response) {
        console.error('Error response:', error.response.data);
        toast.error(error.response.data.message || 'Failed to save application documents');
      } else {
        toast.error('Failed to save application documents');
      }
      return false;
    }
  };

  const handleClose = async (didApply: boolean) => {
    if (didApply && jobId) {
      setIsSubmitting(true);
      try {
        console.log('Starting application process...');
        
        // First save the documents
        const documentsSaved = await saveApplicationDocuments();
        if (!documentsSaved) {
          toast.error('Failed to save application documents');
          return;
        }

        // Then track the application
        await addApplication(jobId);
        
        toast.success('Application tracked successfully!');
        window.dispatchEvent(new Event('application-updated'));
        onConfirm(true);
      } catch (error) {
        console.error('Error processing application:', error);
        toast.error('Failed to track application. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      onConfirm(false);
    }

    setIsClosing(true);
    setTimeout(() => {
      setModalData({ showModal: false });
    }, 200);
  };

  if (!showModal) return null;

  return (
    <div className={`application-confirm-backdrop ${isClosing ? 'closing' : ''}`}>
      <div className="application-confirm-content">
        <h2>Did you apply?</h2>
        <p>If you applied, we'll add it to your application tracker.</p>
        <div className="button-group">
          <button 
            className="keep-button" 
            onClick={() => handleClose(false)}
            disabled={isSubmitting}
          >
            No
          </button>
          <button 
            className="discard-button" 
            onClick={() => handleClose(true)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Tracking...' : 'Yes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationConfirmModal; 