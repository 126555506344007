import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { deleteApplication } from '../../services/applicationService';
import '../../styles/components/applicationTracker/deleteConfirmModal.scss';

interface DeleteConfirmModalProps {
  showModal: boolean;
  jobId: string;
  onConfirm: (success: boolean) => void;
  setModalData: ({ showModal }: { showModal: boolean }) => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  showModal,
  jobId,
  onConfirm,
  setModalData,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setModalData({ showModal: false });
      // Don't call onConfirm here, we only want to close the delete confirmation modal
    }, 200);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      // Get the current user's ID from localStorage
      const userData = localStorage.getItem('user');
      if (!userData) {
        throw new Error('User not authenticated');
      }
      const { _id: userId } = JSON.parse(userData);

      // Delete the application (backend will handle document deletion)
      await deleteApplication(jobId);

      // Update the user's profile in localStorage
      const user = JSON.parse(userData);
      if (user.applications) {
        user.applications = user.applications.filter((app: any) => app.jobId !== jobId);
        user.totalApplications = Math.max(0, parseInt(user.totalApplications) - 1).toString();
        localStorage.setItem('user', JSON.stringify(user));
      }

      // Trigger UI update
      window.dispatchEvent(new Event('application-updated'));

      // Close both modals and update the UI
      setModalData({ showModal: false });
      onConfirm(true);
    } catch (error) {
      console.error('Error deleting application:', error);
      setModalData({ showModal: false });
      onConfirm(false);
    }
    setIsDeleting(false);
  };

  if (!showModal) return null;

  return createPortal(
    <div className={`delete-confirm-backdrop ${isClosing ? 'closing' : ''}`}>
      <div className="delete-confirm-content">
        <h2>Delete Application</h2>
        <p className="warning">This will permanently remove this application and its documents from your tracker.</p>
        <div className="button-container">
          <button 
            className="cancel-button" 
            onClick={handleClose}
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button 
            className="delete-button" 
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteConfirmModal; 