import React, { createContext, useContext, useState } from 'react';

interface ApplicationModalContextType {
  showModal: boolean;
  jobTitle?: string;
  companyName?: string;
  jobId?: string;
  resumeId?: string;
  coverLetterId?: string;
  resumeContent?: any;
  coverLetterContent?: any;
  setModalData: (data: Partial<ApplicationModalContextType>) => void;
  onConfirm: (didApply: boolean) => void;
}

const defaultContext: ApplicationModalContextType = {
  showModal: false,
  setModalData: () => {},
  onConfirm: () => {},
};

const ApplicationModalContext = createContext<ApplicationModalContextType>(defaultContext);

export const ApplicationModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalData, setModalData] = useState<ApplicationModalContextType>(defaultContext);

  const handleSetModalData = (data: Partial<ApplicationModalContextType>) => {
    setModalData(prev => {
      // Log previous state
      console.log('Previous modal state:', {
        resumeContent: prev.resumeContent ? 'Present' : 'Missing',
        coverLetterContent: prev.coverLetterContent ? 'Present' : 'Missing'
      });

      // Log incoming data
      console.log('Incoming modal data:', {
        resumeContent: data.resumeContent ? 'Present' : 'Missing',
        coverLetterContent: data.coverLetterContent ? 'Present' : 'Missing'
      });

      // Create new data object with all previous data
      const newData = { ...prev };
      
      // Update all fields from the new data
      Object.keys(data).forEach(key => {
        const typedKey = key as keyof ApplicationModalContextType;
        if (data[typedKey] !== undefined) {
          newData[typedKey] = data[typedKey];
        }
      });

      // Ensure we preserve resume/cover letter content if not explicitly being updated
      if (data.resumeContent === undefined && prev.resumeContent) {
        newData.resumeContent = prev.resumeContent;
      }
      if (data.coverLetterContent === undefined && prev.coverLetterContent) {
        newData.coverLetterContent = prev.coverLetterContent;
      }

      // Log final state
      console.log('Final modal state:', {
        resumeContent: newData.resumeContent ? 'Present' : 'Missing',
        coverLetterContent: newData.coverLetterContent ? 'Present' : 'Missing'
      });

      return newData;
    });
  };

  return (
    <ApplicationModalContext.Provider value={{ ...modalData, setModalData: handleSetModalData }}>
      {children}
    </ApplicationModalContext.Provider>
  );
};

export const useApplicationModal = () => useContext(ApplicationModalContext); 