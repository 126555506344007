// PortfolioPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/components/portfolio.scss';
import { mainUrl } from '../constant';
import { FaGithub, FaLinkedin, FaEnvelope, FaDownload } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { FaXTwitter } from 'react-icons/fa6';
import { MdDragIndicator } from 'react-icons/md'; // Import the drag icon
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import axiosClient from '../axios-client';

// Add generatePastelColor function
const generatePastelColor = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = hash % 360;
    return `hsla(${h}, 70%, 80%, 0.2)`;
};

// Add function to create dynamic favicon
const createFavicon = (initials: string, backgroundColor: string) => {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const ctx = canvas.getContext('2d');
    
    if (ctx) {
        // Create circular background
        ctx.beginPath();
        ctx.arc(32, 32, 32, 0, Math.PI * 2);
        ctx.fillStyle = backgroundColor;
        ctx.fill();
        
        // Draw text
        ctx.fillStyle = '#333333';
        ctx.font = 'bold 28px Inter';  // Slightly smaller font to fit better in circle
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(initials, 32, 32);
        
        // Convert to favicon
        let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
        if (!link) {
            link = document.createElement('link');
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = canvas.toDataURL();
    }
};

interface PortfolioData {
    firstName: string;
    lastName: string;
    email: string;
    linkedin: string;
    location: string;
    education: [{
        degree: string;
        field: string;
        university: string;
        graduationDate: string;
        gpa: string;
    }];
    experience: [{
        title: string;
        company: string;
        location: string;
        duration: string;
        startDate: string;
        endDate: string;
        isCurrentPosition: boolean;
        description: Array<{ id: string, text: string }>
    }];
    projects: {
        title: string;
        projectName: string;
        startDate: string;
        endDate: string | null;
        isOngoing: boolean;
        description: { id: string, text: string }[]
    }[];
    profileImage?: string;
    skills: string[];
    interests: string[];
    experienceLevel: string;
}

const PortfolioPage = () => {
    const { username } = useParams();
    const [portfolioData, setPortfolioData] = useState<PortfolioData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPortfolioData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get<PortfolioData>(`${mainUrl}/users/get-user/${username}`);
                setPortfolioData(response.data);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
                setError('Failed to load portfolio data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPortfolioData();
    }, [username]);

    useEffect(() => {
        if (portfolioData?.firstName && portfolioData?.lastName) {
            // Update title
            document.title = `${portfolioData.firstName} ${portfolioData.lastName}`;
            
            // Create and update favicon
            const initials = `${portfolioData.firstName[0]}${portfolioData.lastName[0]}`;
            const backgroundColor = generatePastelColor(portfolioData.firstName + portfolioData.lastName);
            createFavicon(initials, backgroundColor);
        }
        
        // Cleanup function to reset both title and favicon
        return () => {
            document.title = 'QuickCruit';
            const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
            if (link) {
                link.href = '/favicon.ico'; // Reset to default favicon
            }
        };
    }, [portfolioData?.firstName, portfolioData?.lastName]);

    const handleResumeDownload = async () => {
        if (!portfolioData) {
            toast.error("No portfolio data available");
            return;
        }

        try {
            // Format the resume data
            const resumeData = {
                firstName: portfolioData.firstName,
                lastName: portfolioData.lastName,
                email: portfolioData.email,
                phone: '',
                linkedin: portfolioData.linkedin,
                website: '',
                location: portfolioData.location,
                citizenship: '',
                education: portfolioData.education,
                experience: portfolioData.experience.map(exp => ({
                    ...exp,
                    duration: `${exp.startDate} - ${exp.isCurrentPosition ? "Present" : exp.endDate}`
                })),
                projects: portfolioData.projects.map(proj => ({
                    ...proj,
                    duration: `${proj.startDate} - ${proj.isOngoing ? "Present" : proj.endDate}`
                })),
                skills: portfolioData.skills,
                interests: portfolioData.interests
            };

            // Use direct axios call without authentication
            const response = await axios.post(`${mainUrl}/pdf/generate-pdf`, resumeData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000 // 60 second timeout
            });

            if (response.data && response.data.downloadUrl) {
                window.open(`${mainUrl}${response.data.downloadUrl}`, '_blank');
            } else {
                throw new Error('Download URL not received from server');
            }
        } catch (error) {
            console.error('Error downloading resume:', error);
            if (axios.isAxiosError(error) && error.response) {
                toast.error(`Error: ${error.response.data.message || error.response.statusText || "Unknown error"}`);
            } else {
                toast.error("An error occurred while generating the PDF. Please try again.");
            }
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!portfolioData) {
        return <div>No portfolio data found</div>;
    }

    return (
        <div className='portfolio-container'>
            <div className='portfolio-content'>
                <div className="portfolio-header">
                    <div className="portfolio-left-section">
                        <div className="portfolio-info">
                            <div className="portfolio-picture" style={
                                !portfolioData.profileImage
                                    ? {
                                        backgroundColor: generatePastelColor(portfolioData.firstName + portfolioData.lastName),
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "120px",
                                        height: "120px",
                                    }
                                    : undefined
                            }>
                                {portfolioData.profileImage ? (
                                    <img src={portfolioData.profileImage} alt='Profile' />
                                ) : (
                                    <div className="default-icon">
                                        {`${portfolioData.firstName[0]}${portfolioData.lastName[0]}`}
                                    </div>
                                )}
                            </div>
                            <h1>{portfolioData.firstName} {portfolioData.lastName}</h1>
                        </div>

                        <div className="portfolio-details">
                            <div className="portfolio-skills-section">
                                {portfolioData.skills.slice(0, 3).map((skill: string, index: number) => (
                                    <span key={index} className='portfolio-skill-badge'>{skill}</span>
                                ))}
                            </div>
                            <div className="portfolio-experience-location">
                                <div className="portfolio-info-item">
                                    <span className='portfolio-info-value'>{portfolioData.experienceLevel}</span>
                                    <span className='portfolio-info-label'>Exp. Level</span>
                                </div>
                                <div className="portfolio-divider"></div>
                                <div className="portfolio-info-item">
                                    <span className='portfolio-info-value'>{portfolioData.location}</span>
                                    <span className='portfolio-info-label'>Located</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portfolio-right-section">
                        <div className="portfolio-social-icons">
                            <a href={`mailto:${portfolioData.email}`} target="_blank" rel="noopener noreferrer">
                                <FaEnvelope />
                            </a>
                            <a href={portfolioData.linkedin} target="_blank" rel="noopener noreferrer">
                                <FaLinkedin />
                            </a>
                            <a href={`https://github.com/${portfolioData.email.split('@')[0]}`} target="_blank" rel="noopener noreferrer">
                                <FaGithub />
                            </a>
                            <a href={`https://twitter.com/${portfolioData.email.split('@')[0]}`} target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </a>
                        </div>
                        <div className="resume-button" onClick={handleResumeDownload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                <polyline points="7 10 12 15 17 10" />
                                <line x1="12" y1="15" x2="12" y2="3" />
                            </svg>
                            Resume
                        </div>
                    </div>
                </div>

                <div>
                    <div className="portfolio-section">
                        <h3 className='portfolio-section-title'>Education</h3>
                        {portfolioData.education.map((edu, index) => (
                            <div className="portfolio-education-item" key={index}>
                                <div className="portfolio-info-content">
                                    <div className="portfolio-item-title">
                                        {edu.degree ? `${edu.degree}, ${edu.field}` : edu.field}
                                        <span className="portfolio-item-date">{edu.graduationDate}</span>
                                    </div>
                                    <div className="portfolio-item-subtitle">
                                        {edu.university}
                                        {edu.gpa && <span className="portfolio-item-gpa" style={{ fontWeight: 'normal' }}>GPA: {edu.gpa}</span>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="portfolio-section">
                        <h3 className="portfolio-section-title">Experience</h3>
                        {portfolioData.experience.map((exp, index) => (
                            <div key={index} className='portfolio-experience-item'>
                                <div className="portfolio-info-content">
                                    <div className="portfolio-item-title">
                                        {exp.title}
                                        <span className="portfolio-item-date">
                                            {exp.startDate} - {exp.isCurrentPosition ? 'Present' : exp.endDate}
                                        </span>
                                    </div>
                                    <div className="portfolio-item-subtitle">
                                        {exp.company}
                                        <span className="portfolio-item-location">{exp.location}</span>
                                    </div>
                                    <ul className="portfolio-item-subtitle">
                                        {exp.description.map((desc, descIndex) => (
                                            <li key={descIndex}>{desc.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='portfolio-section'>
                        <h3 className="portfolio-section-title">Projects</h3>
                        {portfolioData.projects.map((project, index) => (
                            <div className='portfolio-project-item' key={index}>
                                <div className="portfolio-info-content">
                                    <div className="portfolio-item-title">
                                        {project.title ? `${project.projectName} (${project.title})` : project.projectName}
                                        <span className="portfolio-item-date">
                                            {project.startDate}{project.isOngoing || project.endDate ? ` - ${project.isOngoing ? 'Present' : project.endDate}` : ''}
                                        </span>
                                    </div>
                                    <ul className="portfolio-item-subtitle">
                                        {project.description.map((desc, descIndex) => (
                                            <li key={descIndex}>{desc.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="portfolio-section">
                        <h3 className="portfolio-section-title">Skills</h3>
                        <div className="portfolio-skills-container">
                            {portfolioData.skills.map((skill, index) => (
                                <span key={index} className="portfolio-skill-badge">
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Interests section - only show if there are interests */}
                    {portfolioData.interests && portfolioData.interests.length > 0 && (
                        <div className="portfolio-section">
                            <h3 className="portfolio-section-title">Interests</h3>
                            <div className="portfolio-skills-container">
                                {portfolioData.interests.map((interest, index) => (
                                    <span key={index} className="portfolio-skill-badge">
                                        {interest}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PortfolioPage;
