/**
 * Add UTM parameters to a URL for tracking purposes
 */
export function addUtmParameters(url: string): string {
  if (!url) return '';
  
  console.log('Original URL:', url);
  
  try {
    const urlObj = new URL(url);
    
    // Add only utm_source parameter
    urlObj.searchParams.set('utm_source', 'quickcruit');
    // Remove other UTM parameters if they exist
    urlObj.searchParams.delete('utm_medium');
    urlObj.searchParams.delete('utm_campaign');
    
    const finalUrl = urlObj.toString();
    console.log('URL with utm_source param:', finalUrl);
    
    return finalUrl;
  } catch (error) {
    console.error('Error adding UTM parameters:', error);
    return url; // Return original URL if there's an error
  }
} 